import { createIcon } from '@chakra-ui/react'

export const OmAllergenSesame = createIcon({
  displayName: 'Sesame',
  viewBox: '0 0 54 48',
  path: (
    <g>
      <path
        fill="currentColor"
        d="M 44.415 38.238 C 40.815 35.558 39.098 29.437 41.344 26.738 C 43.455 24.201 45.92 24.93 48.199 26.562 C 51.644 29.028 53.316 35.465 51.308 38.159 C 49.832 40.14 47.011 40.172 44.415 38.238"
      />
      <path
        fill="currentColor"
        d="M 11.975 42.772 C 9.529 40.023 6.233 37.149 8.967 33.463 C 11.535 30.001 15.164 32.048 17.732 34.019 C 20.663 36.268 23.485 39.505 20.832 43.153 C 18.27 46.675 14.904 44.096 11.975 42.772"
      />
      <path
        fill="currentColor"
        d="M 7.52 12.424 C 11.475 15.418 13.117 21.563 10.771 24.144 C 8.556 26.579 6.084 25.882 3.868 24.163 C 0.316 21.408 -1.06 14.971 1.324 12.54 C 3.461 10.361 5.669 11.242 7.52 12.424"
      />
      <path
        fill="currentColor"
        d="M 25.358 21.627 C 28.801 24.259 30.449 30.767 28.201 33.282 C 26.176 35.548 23.85 34.898 21.719 33.438 C 18.011 30.897 16.3 24.7 18.43 21.935 C 20.022 19.866 22.888 19.739 25.358 21.627"
      />
      <path
        fill="currentColor"
        d="M 31.687 21.784 C 27.903 18.964 26.428 12.896 28.735 10.172 C 30.698 7.855 32.963 8.357 35.096 9.827 C 38.622 12.258 40.372 18.606 38.282 21.291 C 36.215 23.946 33.835 23.082 31.687 21.784"
      />
      <path
        fill="currentColor"
        d="M 46.706 20.024 C 42.863 17.165 41.159 10.897 43.312 8.233 C 45.463 5.571 47.831 6.503 50.066 8.165 C 53.809 10.947 55.166 16.837 52.714 19.56 C 50.684 21.814 48.51 21.21 46.706 20.024"
      />
      <path
        fill="currentColor"
        d="M 28.574 45.326 C 26.62 43.66 25.497 41.595 27.718 39.081 C 29.923 36.585 36.146 36.674 39.546 39.29 C 41.754 40.989 43.13 43.074 41.082 45.707 C 38.938 48.466 32.407 48.309 28.574 45.326"
      />
      <path
        fill="currentColor"
        d="M 16.576 2.182 C 20.313 5.178 21.938 11.234 19.762 13.665 C 17.529 16.158 15.108 15.271 12.874 13.515 C 9.441 10.818 7.848 4.809 10.041 2.358 C 12.279 -0.139 14.671 0.802 16.576 2.182"
      />
    </g>
  ),
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 54 48"
//     id="vector">
//     <path
//         id="path"
//         d="M 44.415 38.238 C 40.815 35.558 39.098 29.437 41.344 26.738 C 43.455 24.201 45.92 24.93 48.199 26.562 C 51.644 29.028 53.316 35.465 51.308 38.159 C 49.832 40.14 47.011 40.172 44.415 38.238"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_2"
//         d="M 11.975 42.772 C 9.529 40.023 6.233 37.149 8.967 33.463 C 11.535 30.001 15.164 32.048 17.732 34.019 C 20.663 36.268 23.485 39.505 20.832 43.153 C 18.27 46.675 14.904 44.096 11.975 42.772"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_3"
//         d="M 7.52 12.424 C 11.475 15.418 13.117 21.563 10.771 24.144 C 8.556 26.579 6.084 25.882 3.868 24.163 C 0.316 21.408 -1.06 14.971 1.324 12.54 C 3.461 10.361 5.669 11.242 7.52 12.424"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_4"
//         d="M 25.358 21.627 C 28.801 24.259 30.449 30.767 28.201 33.282 C 26.176 35.548 23.85 34.898 21.719 33.438 C 18.011 30.897 16.3 24.7 18.43 21.935 C 20.022 19.866 22.888 19.739 25.358 21.627"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_5"
//         d="M 31.687 21.784 C 27.903 18.964 26.428 12.896 28.735 10.172 C 30.698 7.855 32.963 8.357 35.096 9.827 C 38.622 12.258 40.372 18.606 38.282 21.291 C 36.215 23.946 33.835 23.082 31.687 21.784"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_6"
//         d="M 46.706 20.024 C 42.863 17.165 41.159 10.897 43.312 8.233 C 45.463 5.571 47.831 6.503 50.066 8.165 C 53.809 10.947 55.166 16.837 52.714 19.56 C 50.684 21.814 48.51 21.21 46.706 20.024"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_7"
//         d="M 28.574 45.326 C 26.62 43.66 25.497 41.595 27.718 39.081 C 29.923 36.585 36.146 36.674 39.546 39.29 C 41.754 40.989 43.13 43.074 41.082 45.707 C 38.938 48.466 32.407 48.309 28.574 45.326"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_8"
//         d="M 16.576 2.182 C 20.313 5.178 21.938 11.234 19.762 13.665 C 17.529 16.158 15.108 15.271 12.874 13.515 C 9.441 10.818 7.848 4.809 10.041 2.358 C 12.279 -0.139 14.671 0.802 16.576 2.182"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
