import { APIService } from '../service'
import { IJsonApiModel, IJsonApiModelWithId } from '../types'
import { IProduct } from './product'

declare module '../service' {
  interface APIService {
    applyBasketDiscountFreeProductPromotion(data: IApplyBasketDiscountFreeProductPromotion): Promise<IAppliedBasketDiscountFreeProductPromotion>
  }
}

export const ENTITY_TYPE_BASKET_DISCOUNT_FREE_PRODUCT_PROMOTION = 'basket-discount-free-product-promotions'
export const ENTITY_TYPE_APPLIED_BASKET_DISCOUNT_FREE_PRODUCT_PROMOTION = 'applied-basket-free-product-promotions'

export interface IBasketDiscountFreeProductPromotion<B extends IJsonApiModelWithId, P extends IJsonApiModelWithId>
  extends IJsonApiModelWithId {
  id: string
  promoteAtCheckout: boolean
  basket: B
  product: P
}

export type IBasketDiscountFreeProductPromotionAny = IBasketDiscountFreeProductPromotion<IJsonApiModelWithId, IJsonApiModelWithId>
export type IBasketDiscountFreeProductPromotionWithProduct = IBasketDiscountFreeProductPromotion<IJsonApiModelWithId, IProduct>

export interface IAppliedBasketDiscountFreeProductPromotion extends IJsonApiModelWithId {
  basket: IJsonApiModelWithId
  freeProductPromotion: IJsonApiModelWithId
}

export interface IApplyBasketDiscountFreeProductPromotion extends Omit<IJsonApiModel, 'type'> {
  freeProductPromotion: IJsonApiModelWithId
}

APIService.prototype.applyBasketDiscountFreeProductPromotion = async function (data: IApplyBasketDiscountFreeProductPromotion) {
  return this.create<IAppliedBasketDiscountFreeProductPromotion>(ENTITY_TYPE_APPLIED_BASKET_DISCOUNT_FREE_PRODUCT_PROMOTION, {
    type: ENTITY_TYPE_APPLIED_BASKET_DISCOUNT_FREE_PRODUCT_PROMOTION,
    ...data,
  })
}
