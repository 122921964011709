import React from 'react'
import {
  HTMLChakraProps,
  forwardRef,
  chakra,
  useMultiStyleConfig,
  ThemingProps,
  omitThemingProps,
  SystemStyleObject,
  createStylesContext,
} from '@chakra-ui/react'
import { MaybeRenderProp } from '@chakra-ui/react-utils'
import { runIfFn, cx } from '@chakra-ui/utils'

import {
  UseNavProps,
  useNav,
  NavProvider,
  useNavLink,
  useNavList,
  useNavPositioner,
  useNavItem,
} from './use-nav'

const [StylesProvider, useStyles] = createStylesContext('Nav')

export interface NavProps extends UseNavProps, ThemingProps {
  children: MaybeRenderProp<{
    isOpen: boolean
    onClose: () => void
    forceUpdate?: () => void
  }>
}

export const Nav: React.FC<NavProps> = (props) => {
  const { children } = props

  const styles = useMultiStyleConfig('Nav', props)
  const ownProps = omitThemingProps(props)

  const ctx = useNav(ownProps)
  const context = React.useMemo(() => ctx, [ctx])

  const { isOpen, onClose, forceUpdate } = context

  return (
    <NavProvider value={context}>
      <StylesProvider value={styles}>
        {runIfFn(children, { isOpen, onClose, forceUpdate })}
      </StylesProvider>
    </NavProvider>
  )
}

export interface NavLinkProps extends HTMLChakraProps<'a'>, ThemingProps {
  preventDefault?: boolean
}

const StyledNavLink = forwardRef<NavLinkProps, 'a'>((props, ref) => {
  const styles = useStyles()
  const ownProps = omitThemingProps(props)
  return (
    <chakra.a
      ref={ref}
      {...ownProps}
      __css={{
        display: 'inline-flex',
        appearance: 'none',
        alignItems: 'center',
        outline: 0,
        ...styles.link,
      }}
    />
  )
})

/**
 * The trigger for the nav list. Must be a direct child of `Nav`.
 */
export const NavLink = forwardRef<NavLinkProps, 'a'>((props, ref) => {
  const { children, as: As, ...rest } = props

  const linkProps = useNavLink(rest, ref)

  const LinkComp = As || StyledNavLink

  return (
    <LinkComp {...linkProps} className={cx('om-nav__nav-link', props.className)}>
      <chakra.span
        __css={{
          pointerEvents: 'none',
          flex: '1 1 auto',
        }}
      >
        {props.children}
      </chakra.span>
    </LinkComp>
  )
})

export interface NavListProps extends HTMLChakraProps<'div'> {}

export const NavList = forwardRef<NavListProps, 'div'>((props, ref) => {
  const listProps = useNavList(props, ref)
  const positionerProps = useNavPositioner()

  const styles = useStyles()
  return (
    <>
      <chakra.div {...positionerProps} __css={{ zIndex: styles.list?.zIndex }}>
        <chakra.div
          {...listProps}
          className={cx('om-nav__nav-list', listProps.className)}
          __css={{
            outline: 0,
            ...styles.list,
          }}
        />
      </chakra.div>
    </>
  )
})

export interface StyledNavItemProps extends HTMLChakraProps<'a'> {}

const StyledNavItem = forwardRef<StyledNavItemProps, 'a'>((props, ref) => {
  const { type, ...rest } = props
  const styles = useStyles()

  const buttonStyles: SystemStyleObject = {
    textDecoration: 'none',
    color: 'inherit',
    userSelect: 'none',
    // display: "flex",
    // width: "100%",
    // alignItems: "center",
    textAlign: 'left',
    // flex: "0 0 auto",
    outline: 0,
    ...styles.item,
  }

  return <chakra.a ref={ref} {...rest} __css={buttonStyles} />
})

export interface NavItemProps extends HTMLChakraProps<'a'> {}

export const NavItem = forwardRef<NavItemProps, 'a'>((props, ref) => {
  const { children, ...rest } = props

  const navItemProps = useNavItem(rest, ref) as NavItemProps

  return (
    <StyledNavItem {...navItemProps} className={cx('om-nav__navitem', navItemProps.className)}>
      {children}
    </StyledNavItem>
  )
})
