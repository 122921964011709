import { useRef, useState } from 'react'
import {
  AlertDialogProps,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
  Button,
} from '@chakra-ui/react'

export interface IRemoveBasketConfirmDialog {
  type: 'remove-basket'
  basketName: string
  onConfirm: () => void
}

export interface IConfirmBasketConfirmDialog {
  type: 'confirm-basket'
  basketName: string
  onConfirm: () => void
}

export interface IConfirmOpenOrderConfirmDialog {
  type: 'open-order'
  onConfirm: () => void
}

export interface ICancelOrderConfirmDialog {
  type: 'cancel-order'
  orderName: string
  onConfirm: () => void
}

export interface IDeleteMenuConfirmDialog {
  type: 'delete-menu'
  menuName: string
  onConfirm: () => void
}

export interface IConflictingProductsConfirmDialog {
  type: 'conflicting-products'
  productsCount: number
  onConfirm: () => void
}

export interface ICreateNewBasketForSameDateConfirmDialog {
  type: 'create-basket-for-same-date'
  onConfirm: () => void
}

export interface ISomeItemsWillNotBeCopiedWarningDialog {
  type: 'some-items-are-unavailable-warning'
  onConfirm: () => void
}

export type IUseConfirmDialogProps =
  | IRemoveBasketConfirmDialog
  | IConfirmBasketConfirmDialog
  | IConfirmOpenOrderConfirmDialog
  | ICancelOrderConfirmDialog
  | IDeleteMenuConfirmDialog
  | IConflictingProductsConfirmDialog
  | ICreateNewBasketForSameDateConfirmDialog
  | ISomeItemsWillNotBeCopiedWarningDialog

export function useConfirmDialog(props: IUseConfirmDialogProps): IUseConfirmDialog {
  const [isOpen, setIsOpen] = useState(false)
  let dialogProps: any = {
    onConfirm: () => {
      setIsOpen(false)
      props.onConfirm()
    },
  }

  switch (props.type) {
    case 'some-items-are-unavailable-warning':
      dialogProps = {
        ...dialogProps,
        title: 'Some items are unavailable',
        message: 'Only the available products will be copied to the order.',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue',
      }
      break
    case 'remove-basket':
      dialogProps = {
        ...dialogProps,
        title: `Remove ${props.basketName}?`,
        message: 'Are you sure you want to remove this order?',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Remove Order',
      }
      break
    case 'confirm-basket':
      dialogProps = {
        ...dialogProps,
        title: `Confirm ${props.basketName}?`,
        message: 'Are you sure you want to confirm this order?',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Confirm Order',
      }
      break
    case 'open-order':
      dialogProps = {
        ...dialogProps,
        title: `Open Order?`,
        message:
          'This order has already been confirmed. This action will open it up for editing, any changes will then need to be re-confirmed?',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Open Order',
      }
      break
    case 'cancel-order':
      dialogProps = {
        ...dialogProps,
        title: `Remove ${props.orderName}?`,
        message: 'Are you sure you want to cancel this order?',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Cancel Order',
      }
      break
    case 'delete-menu':
      dialogProps = {
        ...dialogProps,
        title: `Remove ${props.menuName}?`,
        message: 'Are you sure you want to delete this list?',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete List',
      }
      break
    case 'conflicting-products':
      const isPlural = props.productsCount > 1
      dialogProps = {
        ...dialogProps,
        message: `${props.productsCount} product${
          isPlural ? 's are' : 'is'
        } already included on the order you are adding to. The quantities will not be adjusted. Please check your order carefully before confirming it.`,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue',
      }
      break
    case 'create-basket-for-same-date':
      dialogProps = {
        ...dialogProps,
        // title: `Create ?`,
        message:
          'Are you sure you want to create a second order to the same address on the same date?',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Create New',
      }
      break
  }

  return {
    ...dialogProps,
    isOpen,
    onOpen: () => setIsOpen(true),
    onClose: () => setIsOpen(false),
  }
}

export interface IUseConfirmDialog {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  title?: string
  message: string
  cancelButtonText?: string
  confirmButtonText: string
  onConfirm: () => void
}

export interface IConfirmDialogProps
  extends IUseConfirmDialog,
    Omit<AlertDialogProps, 'leastDestructiveRef' | 'children'> {}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = (props) => {
  const { title, message, cancelButtonText, confirmButtonText, onConfirm, onClose, ...rest } = props
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (
    <AlertDialog isCentered={true} onClose={onClose} {...rest} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title ?? ''}
          </AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogBody>{message}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} variant="ghost" onClick={onClose}>
              {cancelButtonText || 'Cancel'}
            </Button>
            <Button colorScheme="red" ml={3} onClick={onConfirm}>
              {confirmButtonText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
