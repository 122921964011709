import { chakra, Box } from '@chakra-ui/react'
import { MAX_WIDTH } from '../theme'

export const ResponsiveContainer = chakra(Box, {
  baseStyle: {
    maxWidth: MAX_WIDTH,
    mx: 'auto',
    px: 4,
  },
})
