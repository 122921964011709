import { createIcon } from '@chakra-ui/react'

export const OmDietaryVegan = createIcon({
  displayName: 'Vegan',
  viewBox: '0 0 52 62',
  d: 'M 48.36 1.485 C 47.905 0.065 45.955 -0.129 45.175 1.163 C 39.13 12.142 25.935 15.5 27.625 24.8 C 28.015 26.802 29.185 28.481 30.875 29.773 C 33.8 24.606 37.375 21.183 39.585 19.31 L 23.14 53.087 L 5.33 16.598 C 4.68 15.306 3.12 14.79 1.82 15.371 C 0.52 16.017 0 17.567 0.585 18.858 L 20.735 60.192 C 21.19 61.096 22.1 61.677 23.075 61.677 C 24.05 61.677 25.025 61.096 25.415 60.192 L 38.87 32.679 C 42.77 33.002 46.735 31.969 49.075 29.256 C 53.82 23.702 51.09 9.494 48.36 1.485 Z',
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 52 62"
//     id="vector">
//     <path
//         id="path_1"
//         d="M 48.36 1.485 C 47.905 0.065 45.955 -0.129 45.175 1.163 C 39.13 12.142 25.935 15.5 27.625 24.8 C 28.015 26.802 29.185 28.481 30.875 29.773 C 33.8 24.606 37.375 21.183 39.585 19.31 L 23.14 53.087 L 5.33 16.598 C 4.68 15.306 3.12 14.79 1.82 15.371 C 0.52 16.017 0 17.567 0.585 18.858 L 20.735 60.192 C 21.19 61.096 22.1 61.677 23.075 61.677 C 24.05 61.677 25.025 61.096 25.415 60.192 L 38.87 32.679 C 42.77 33.002 46.735 31.969 49.075 29.256 C 53.82 23.702 51.09 9.494 48.36 1.485 Z"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
