import { useCallback, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  productsListingSelector,
  SET_FACETS,
  getFacetsFromQuery,
  IFilter,
} from '../../stores/catalog'
import { IAvailableFacet, FACETS } from '../facets'
import API from '../../api'

export interface IUseMobileFilterMenuProps {
  hideFacets?: string[]
}

export function useMobileFilterMenu(props?: IUseMobileFilterMenuProps) {
  const hiddenFacets = props?.hideFacets ?? []
  const dispatch = useDispatch()
  const { key } = useLocation()
  const { extra, query: initialQuery } = useSelector(productsListingSelector)(key!)
  const { initialFacets, baseQuery } = useMemo(() => {
    return {
      initialFacets: extra,
      baseQuery: initialQuery,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [availableFacets, setAvailableFacets] = useState<IAvailableFacet[]>(
    initialFacets?.available ?? [],
  )

  const onAddFacet = useCallback(
    async (field: string) => {
      if (isLoading) {
        return
      }

      // apply the facets to the filters
      const facetFields = FACETS.map((facet) => facet.field)
      const filters: IFilter[] = [
        ...(baseQuery?.filters?.filter((filter) => !facetFields.includes(filter.key)) ?? []), // remove all filters but facets
        ...availableFacets
          .filter((facet) => facet.isSelected || facet.field === field)
          .map((facet) => ({
            key: facet.field,
            value: facet.value,
          })),
      ]

      setIsLoading(true)
      try {
        const response = await API.fetchProducts({
          ...baseQuery,
          sort: [], // use default sort
          filters: filters.reduce<{ [key: string]: string }>(
            (value, filter) => ({ ...value, [filter.key]: filter.value }),
            {},
          ),
          pageSize: 1, // keep return payload size to a minimum
        })
        const facets = getFacetsFromQuery({ ...baseQuery, filters }, response.meta.facets)
        setAvailableFacets(facets.available)
      } catch (e) {}
      setIsLoading(false)
    },
    [setIsLoading, baseQuery, availableFacets, isLoading],
  )

  const onRemoveFacet = useCallback(
    async (field: string) => {
      if (isLoading) {
        return
      }

      // apply the facets to the filters
      const facetFields = FACETS.map((facet) => facet.field)
      const filters: IFilter[] = [
        ...(baseQuery?.filters?.filter((filter) => !facetFields.includes(filter.key)) ?? []), // remove all filters but facets
        ...availableFacets
          .filter((facet) => facet.isSelected && facet.field !== field)
          .map((facet) => ({
            key: facet.field,
            value: facet.value,
          })),
      ]

      setIsLoading(true)
      try {
        const response = await API.fetchProducts({
          ...baseQuery,
          sort: [], // use default sort
          filters: filters.reduce<{ [key: string]: string }>(
            (value, filter) => ({ ...value, [filter.key]: filter.value }),
            {},
          ),
          pageSize: 0, // keep return payload size to a minimum
        })
        const facets = getFacetsFromQuery({ ...baseQuery, filters }, response.meta.facets)
        setAvailableFacets(facets.available)
      } catch (e) {}
      setIsLoading(false)
    },
    [setIsLoading, baseQuery, availableFacets, isLoading],
  )

  const onApply = useCallback(
    () =>
      dispatch(
        SET_FACETS({
          request: key!,
          fields: availableFacets.filter((facet) => facet.isSelected).map((facet) => facet.field),
        }),
      ),
    [dispatch, availableFacets, key],
  )

  return {
    facets: availableFacets.filter((facet) => !hiddenFacets.includes(facet.field)),
    isLoading,
    onAddFacet,
    onRemoveFacet,
    onApply,
  }
}
