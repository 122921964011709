import { Box } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'

import { ResponsiveContainer } from '../components/responsive-container'
import { PageLayout } from '../components/page-layout'
import { PageHeading } from '../components/page-heading'
import { SearchResults, SearchResultsSkeleton } from '../components/search-results'
import { useSearchResults, DEFAULT_PAGE_TITLE } from '../lib'

export const SEARCH_ROUTE = '/catalog/search'

export const SearchPage: React.FC = () => {
  const { query, totalCount, isLoadingInitial, ...searchProps } = useSearchResults()
  const search = query && query.search

  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          {isLoadingInitial && (
            <>
              <SearchResultsSkeleton mt={28} />
            </>
          )}
          {!isLoadingInitial && (
            <>
              {totalCount > 0 && (
                <>
                  <PageHeading>
                    {search ? (
                      <>
                        {totalCount} results for “{search}”
                      </>
                    ) : (
                      'All Products'
                    )}
                  </PageHeading>
                  <SearchResults {...searchProps} />
                </>
              )}
              {!totalCount && (
                <Box textAlign="center" p={16}>
                  Sorry, we couldn't find any results for “{search}”
                </Box>
              )}
            </>
          )}
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
