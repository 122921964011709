import {
  Box,
  BoxProps,
  forwardRef,
  chakra,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { TMenuWithItems } from '../../api'
import { useMenu } from '../../lib'
import { useConfirmDialog, ConfirmDialog } from '../confirm-dialog'
import { OmDotsVertical } from '../icons'
import { BasketFormModalButton } from '../basket-form'

export const DefinitionList = chakra('dl', {
  baseStyle: {
    '& > dt:not(style) ~ dt:not(style)': {
      mt: 4,
    },
  },
})

export const DefinitionTerm = chakra('dt', {
  baseStyle: {
    fontWeight: 'light',
  },
})

export const Definition = chakra('dd', {
  baseStyle: {
    fontWeight: 'bold',
  },
})

export interface IMenuBoxProps extends BoxProps {
  menu: TMenuWithItems
}

export const MenuBox = forwardRef<IMenuBoxProps, 'button'>((props, ref) => {
  const { menu, ...rest } = props

  const { href, onDelete, handleCreateBasketSuccess, handleSelectExisting, canCreateOrder } =
    useMenu(menu)

  const deleteDialog = useConfirmDialog({
    type: 'delete-menu',
    menuName: menu.title,
    onConfirm: onDelete,
  })

  const handleDelete = () => {
    deleteDialog.onOpen()
  }

  return (
    <Box ref={ref} borderWidth={1} p={[3, 4]} borderRadius="sm" {...rest}>
      {deleteDialog.isOpen && <ConfirmDialog {...deleteDialog} />}
      <>
        <DefinitionList>
          <DefinitionTerm m={0}>Name</DefinitionTerm>
          <Definition>{menu.title}</Definition>

          <DefinitionTerm>Description</DefinitionTerm>
          <Definition>{menu.description || '—'}</Definition>
        </DefinitionList>

        <Box as="dl" display="grid" gridTemplateColumns="auto auto" mt={4} gridGap={2}>
          <DefinitionTerm m={0}>Lines</DefinitionTerm>
          <Definition textAlign="right">{menu.items.length}</Definition>

          <DefinitionTerm m={0}>Total</DefinitionTerm>
          <Definition textAlign="right">£{menu.netTotal}</Definition>
        </Box>
      </>

      <Box mt={4} display="flex" justifyContent="space-between">
        <Box>
          <Menu autoSelect={false}>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              variant="outline"
              icon={<OmDotsVertical />}
              display={['inline-flex', 'none']}
            />
            <MenuList>
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </MenuList>
          </Menu>
          <Button variant="outline" display={['none', 'inline-flex']} onClick={handleDelete}>
            Delete
          </Button>
        </Box>
        <Box>
          <BasketFormModalButton
            menu={menu}
            ml={[3, 4]}
            onCreated={handleCreateBasketSuccess}
            onSelectExisting={handleSelectExisting}
            isDisabled={!canCreateOrder}
          >
            Create Order
          </BasketFormModalButton>
          <Button ml={[3, 4]} as={RouterLink} to={href}>
            View
          </Button>
        </Box>
      </Box>
    </Box>
  )
})
