import { APIService } from '../service'
import { IJsonApiModel } from '../types'

declare module '../service' {
  interface APIService {
    createPasswordResetRequest(data: INewPasswordResetRequest): Promise<undefined>
  }
}

export const ENTITY_TYPE_PASSWORD_RESET_REQUEST = 'password-reset-requests'

export interface INewPasswordResetRequest extends Omit<IJsonApiModel, 'type'> {
  email: string
}

APIService.prototype.createPasswordResetRequest = async function (data: INewPasswordResetRequest) {
  return this.create(ENTITY_TYPE_PASSWORD_RESET_REQUEST, {
    type: ENTITY_TYPE_PASSWORD_RESET_REQUEST,
    ...data,
  })
}
