import { Box, BoxProps, forwardRef } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { ResponsiveContainer } from '../components/responsive-container'
import { PageLayout } from '../components/page-layout'
import { PageHeading, PageHeadingProps } from '../components/page-heading'
import { TabList, TabButton } from '../components/tab-list'
import { useBasketsListing, DEFAULT_PAGE_TITLE } from '../lib'
import { ORDERS_ROUTE } from './orders'
import { OrderBox } from '../components/order-box'
import { BasketFormModalButton } from '../components/basket-form'

export const BASKETS_ROUTE = '/orders/unconfirmed'

const BasketsPageContent = forwardRef<BoxProps, 'div'>((props, ref) => {
  const { items } = useBasketsListing()
  return (
    <Box ref={ref} {...props}>
      <TabList>
        <TabButton as={RouterLink} to={BASKETS_ROUTE} isActive={true}>
          Unconfirmed
        </TabButton>
        <TabButton as={RouterLink} to={ORDERS_ROUTE}>
          Confirmed
        </TabButton>
      </TabList>
      {!items.length && (
        <Box textAlign="center" p={16}>
          You have no unconfirmed orders.{' '}
          <BasketFormModalButton variant="link">Create one</BasketFormModalButton>.
        </Box>
      )}
      {items.map((b) => (
        <OrderBox key={b.id} mt={4} order={b} />
      ))}
    </Box>
  )
})

const BasketsPageHeader = forwardRef<PageHeadingProps, 'div'>((props, ref) => {
  return (
    <PageHeading ref={ref} {...props} as="div">
      <Box display="flex" justifyContent="space-between">
        <Box as="h1" mr={4}>
          Unconfirmed Orders
        </Box>
        <BasketFormModalButton />
      </Box>
    </PageHeading>
  )
})

export const BasketsPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          <BasketsPageHeader />
          <BasketsPageContent />
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
