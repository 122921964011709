import { mode } from '@chakra-ui/theme-tools'

const parts = ['link', 'item', 'list', 'overlay']

const baseStyleOverlay = {
  bg: 'blackAlpha.600',
  zIndex: 'overlay',
}

function baseStyleList(props: Record<string, any>) {
  return {
    bg: mode(`gray.50`, `gray.700`)(props),
    boxShadow: mode(`sm`, `dark-lg`)(props),
    color: 'inherit',
    minW: '3xs',
    zIndex: 'overlay',
  }
}

function baseStyleLink(props: Record<string, any>) {
  return {
    cursor: 'pointer',
    textDecoration: 'none',
    outline: 'none',
    color: 'inherit',
    _hover: {
      textDecoration: 'underline',
    },
    _focus: {
      boxShadow: 'outline',
    },
    _expanded: {
      bg: mode(`gray.50`, `gray.700`)(props),
    },
  }
}

const baseStyleItem = {
  cursor: 'pointer',
  textDecoration: 'none',
  outline: 'none',
  color: 'inherit',
  _hover: {
    textDecoration: 'underline',
  },
  _focus: {
    boxShadow: 'outline',
  },
}

const baseStyle = (props: Record<string, any>) => ({
  link: baseStyleLink(props),
  list: baseStyleList(props),
  item: baseStyleItem,
  overlay: baseStyleOverlay,
})

export const Nav = {
  parts,
  baseStyle,
}
