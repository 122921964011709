import React from 'react'
import { findIndex } from 'lodash'
import {
  Button,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from '@chakra-ui/react'

import { OmSort, OmCheck } from '../icons'

export const SORT_OPTIONS = [
  { value: undefined, label: 'Relevance' },
  { value: '-case_price', label: 'Price: high to low' },
  { value: 'case_price', label: 'Price: low to high' },
]

export interface ISortMenuProps {
  sort?: string
  onClick: (sort?: string) => void
}

export const SortMenu: React.FC<ISortMenuProps> = ({ sort, onClick }) => {
  const foundOption = findIndex(SORT_OPTIONS, ['value', sort])
  const selectedIndex = foundOption >= 0 ? foundOption : 0

  return (
    <Popover variant="filter">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button variant="outline" colorScheme="birchallBlue" rightIcon={<OmSort />}>
              <Box as="span" display={['none', null, 'inline']}>
                Sort by&nbsp;
              </Box>
              {SORT_OPTIONS[selectedIndex].label}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              {SORT_OPTIONS.map((option, index) => (
                <Button
                  key={index}
                  variant="filteritem"
                  onClick={() => {
                    onClose()
                    onClick(option.value)
                    // dispatch(SORT_PRODUCTS(option.value))
                  }}
                  rightIcon={selectedIndex === index ? <OmCheck /> : undefined}
                >
                  {option.label}
                </Button>
              ))}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
