import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
  Textarea,
  forwardRef,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { CREATE_TICKET, selectTicketCreate } from '../../stores/ticket'

const Schema = Yup.object().shape({
  subject: Yup.string().trim().required('Subject required'),
  description: Yup.string().trim().required('Description required'),
})

export interface ITicketFormValues {
  subject: string
  description: string
}

export interface ITicketFormProps extends Omit<ModalProps, 'children'> {
  onSuccess?: (id: string) => void
}

export const TicketForm = forwardRef<ITicketFormProps, 'div'>((props, ref) => {
  const { onSuccess, ...modalProps } = props
  const { onClose } = modalProps
  const dispatch = useDispatch()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const {
    isFetching: isCreating,
    error: creationError,
    id: createdId,
  } = useSelector(selectTicketCreate)

  const isLoading = hasSubmitted && isCreating
  const apiError = hasSubmitted && creationError

  useEffect(() => {
    if (hasSubmitted && !isLoading && !apiError) {
      onClose()
      if (onSuccess) {
        onSuccess(createdId!)
      }
    }
  }, [onClose, hasSubmitted, isLoading, apiError, onSuccess, createdId])

  const initialValues: ITicketFormValues = {
    subject: '',
    description: '',
  }

  const handleSubmit = ({ subject, description }: ITicketFormValues) => {
    dispatch(
      CREATE_TICKET.request({
        subject,
        description,
      }),
    )
    setHasSubmitted(true)
  }

  const formBody = (
    <>
      <Stack spacing={4}>
        <Box>
          <Field name="subject">
            {({ field, form }: FieldProps) => {
              const { name } = field
              const { touched, errors } = form
              const errorMessage = touched[name] ? errors[name] : ''
              return (
                <FormControl isInvalid={!!errorMessage}>
                  <FormLabel htmlFor={name}>Subject</FormLabel>
                  <InputGroup>
                    <Input {...field} id={name} placeholder="Name" />
                  </InputGroup>
                  <FormHelperText>Required</FormHelperText>
                  {errorMessage && <FormErrorMessage>{`${errorMessage}`}</FormErrorMessage>}
                </FormControl>
              )
            }}
          </Field>
        </Box>
        <Box>
          <Field name="description">
            {({ field, form }: FieldProps) => {
              const { name } = field
              const { touched, errors } = form
              const errorMessage = touched[name] ? errors[name] : ''
              return (
                <FormControl isInvalid={!!errorMessage}>
                  <FormLabel htmlFor={name}>Description</FormLabel>
                  <InputGroup>
                    <Textarea {...field} id={name} placeholder="Description" />
                  </InputGroup>
                  <FormHelperText>Required</FormHelperText>
                  {errorMessage && <FormErrorMessage>{`${errorMessage}`}</FormErrorMessage>}
                </FormControl>
              )
            }}
          </Field>
        </Box>
      </Stack>
      {apiError && (
        <Alert status="error" mt={4} p={4} borderRadius="sm">
          <AlertIcon />
          <Box>
            <Text fontWeight="bold">{apiError.message}</Text>
            {/* <Text fontWeight="light">Please check the form and try again.</Text> */}
          </Box>
        </Alert>
      )}
    </>
  )

  return (
    <Modal size="3xl" {...modalProps}>
      <ModalOverlay />
      <Formik initialValues={initialValues} validationSchema={Schema} onSubmit={handleSubmit}>
        {({ isValid }) => (
          <ModalContent>
            <Form>
              <ModalHeader>Create Message</ModalHeader>
              <ModalCloseButton />
              <ModalBody>{formBody}</ModalBody>
              <ModalFooter>
                <Button variant="ghost" mr={4} onClick={onClose} colorScheme="gray">
                  Cancel
                </Button>
                <Button isDisabled={!isValid} type="submit" isLoading={isLoading}>
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </ModalContent>
        )}
      </Formik>
    </Modal>
  )
})
