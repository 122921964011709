import { all, put, takeLeading, select, call } from 'redux-saga/effects'
import { formatISO } from 'date-fns'

import API, {
  CallReturnType,
  JsonApiError,
  INewBasket,
  IUpdateBasket,
  ENTITY_TYPE_ADDRESS,
  IUpdateBasketItem,
  INewBasketItem,
  ENTITY_TYPE_BASKET,
  ENTITY_TYPE_PRODUCT,
  IReorder,
  ENTITY_TYPE_ORDER,
  IMenuToBasket,
  ENTITY_TYPE_MENU,
  // TBasketWithAddressAndItems,
} from '../../../api'
import {
  displayErrorToastSaga,
  history,
  orderPath,
  basketPath,
  ensureError,
  AccountOnHoldError,
} from '../../../lib'
import { SEARCH_ROUTE } from '../../../pages'
import { selectAccountIsOnHold } from '../../account'

import * as actions from './actions'
import { selectBasketsSelectedBasket } from './selectors'

import { setBasket, removeBasket } from '../../../components/stored-basket-checker'
import { FETCH_BASKET_DISCOUNTS } from '../discounts/actions'

function* selectStoredBasket(action: ReturnType<typeof actions.SELECT_STORED_BASKET.request>) {
  try {
    // fetch the basket
    const basket: CallReturnType<typeof API.fetchBasket> = yield API.fetchBasket(action.payload)

    // store the basket
    yield put(actions.FETCH_BASKET.success(basket))

    // select the basket
    yield put(
      actions.SELECT_BASKET.request({
        basketId: action.payload,
        redirectAfterSelect: false,
      }),
    )
  } catch (e) {
    // silently fail, i.e. no error toast
    // Clear the stored basket id on error
    removeBasket()
  }
}

function* fetchBaskets() {
  try {
    const result: CallReturnType<typeof API.fetchAllBaskets> = yield API.fetchAllBaskets()
    // yield fetchSubstitutes(result)
    yield put(actions.FETCH_BASKETS.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_BASKETS.failure(error))
  }
}

// function *fetchSubstitutes(basket: TBasketWithAddressAndItems) {
//   const basketSubProductIds = basket.items.reduce<string[]>((productIds, item) => {
//     return [...productIds, ...item.substitutes.map(sub => sub.id)]
//   }, [])

//   if (productIds.length === 0) {
//     return
//   }

//   try {
//   }
//   catch (e) {
//   }
// }

function* fetchBasketForAddressAndDate(
  action: ReturnType<typeof actions.FETCH_BASKETS_FOR_ADDRESS_AND_DATE.request>,
) {
  const { payload } = action
  try {
    const result: CallReturnType<typeof API.fetchAllBaskets> = yield API.fetchAllBaskets({
      filters: {
        requested_delivery_date: formatISO(payload.requestedDeliveryDate, {
          representation: 'date',
        }),
        deliver_to: payload.addressId,
        is_submittable: 'true',
      },
    })
    yield put(actions.FETCH_BASKETS_FOR_ADDRESS_AND_DATE.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_BASKETS_FOR_ADDRESS_AND_DATE.failure(error))
  }
}

function* fetchBasket(action: ReturnType<typeof actions.FETCH_BASKET.request>) {
  try {
    const basket: CallReturnType<typeof API.fetchBasket> = yield API.fetchBasket(action.payload)
    yield put(actions.FETCH_BASKET.success(basket))
    yield put(FETCH_BASKET_DISCOUNTS.request(action.payload))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_BASKET.failure(error))
  }
}

function* deleteBasket(action: ReturnType<typeof actions.DELETE_BASKET.request>) {
  const basketId = action.payload
  try {
    yield API.deleteBasket(basketId)
    yield put(actions.DELETE_BASKET.success(basketId))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.DELETE_BASKET.failure(error))
  }
}

function* createBasket(action: ReturnType<typeof actions.CREATE_BASKET.request>) {
  const { addressId, ...rest } = action.payload
  const data: INewBasket = {
    ...rest,
    deliverTo: {
      id: addressId,
      type: ENTITY_TYPE_ADDRESS,
    },
  }
  try {
    const result: CallReturnType<typeof API.createBasket> = yield API.createBasket(data)
    yield put(actions.CREATE_BASKET.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.CREATE_BASKET.failure(error))
  }
}

function* updateBasket(action: ReturnType<typeof actions.UPDATE_BASKET.request>) {
  const { addressId, ...rest } = action.payload
  const data: IUpdateBasket = {
    ...rest,
    deliverTo: {
      id: addressId,
      type: ENTITY_TYPE_ADDRESS,
    },
  }
  try {
    const result: CallReturnType<typeof API.updateBasket> = yield API.updateBasket(data)
    yield put(actions.UPDATE_BASKET.success(result))
    yield put(FETCH_BASKET_DISCOUNTS.request(action.payload.id))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.UPDATE_BASKET.failure(error))
  }
}

function* deleteBasketItem(action: ReturnType<typeof actions.DELETE_BASKET_ITEM.request>) {
  const { id, basketId } = action.payload
  try {
    yield API.deleteBasketItem(id)
    yield put(actions.FETCH_BASKET.request(basketId))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(
      actions.DELETE_BASKET_ITEM.failure({
        id,
        error,
      }),
    )
  }
}

function* updateBasketItem(action: ReturnType<typeof actions.UPDATE_BASKET_ITEM.request>) {
  const { basketId, ...rest } = action.payload
  const data: IUpdateBasketItem = {
    ...rest,
  }
  try {
    const result: CallReturnType<typeof API.updateBasketItem> = yield API.updateBasketItem(data)
    yield put(actions.UPDATE_BASKET_ITEM.success(result))
    yield put(actions.FETCH_BASKET.request(action.payload.basketId))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(
      actions.UPDATE_BASKET_ITEM.failure({
        id: data.id,
        error,
      }),
    )
  }
}

function* createBasketItem(action: ReturnType<typeof actions.CREATE_BASKET_ITEM.request>) {
  const { basketId, productId, asBasketSelectionFlow, ...rest } = action.payload
  const data: INewBasketItem = {
    ...rest,
    basket: {
      id: basketId,
      type: ENTITY_TYPE_BASKET,
    },
    product: {
      id: productId,
      type: ENTITY_TYPE_PRODUCT,
    },
  }

  try {
    const result: CallReturnType<typeof API.createBasketItem> = yield API.createBasketItem(data)
    yield put(
      actions.CREATE_BASKET_ITEM.success({
        ...result,
        asBasketSelectionFlow,
      }),
    )
  } catch (e) {
    if (e instanceof JsonApiError && e.errors.length === 1 && e.errors[0].code === 'unique') {
      // deliberately ignore unique errors
      yield put(actions.CREATE_BASKET_ITEM.cancel(productId))
      return
    }

    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(
      actions.CREATE_BASKET_ITEM.failure({
        productId,
        error,
        asBasketSelectionFlow,
      }),
    )
  }
}

function* selectBasket(action: ReturnType<typeof actions.SELECT_BASKET.request>) {
  const { basketId, redirectAfterSelect, onSuccess } = action.payload
  const isOnHold: ReturnType<typeof selectAccountIsOnHold> = yield select(selectAccountIsOnHold)
  if (isOnHold) {
    yield call(displayErrorToastSaga, {
      type: action.type,
      payload: new AccountOnHoldError(),
    })
    yield put(actions.SELECT_BASKET.failure())
  } else {
    if (onSuccess) {
      yield call(onSuccess)
    }
    yield put(actions.SELECT_BASKET.success(basketId))
    if (redirectAfterSelect) {
      history.push(SEARCH_ROUTE)
    }

    setBasket(basketId)
  }
}

function* confirmBasket(action: ReturnType<typeof actions.CONFIRM_BASKET.request>) {
  const basketId = action.payload
  try {
    const order: CallReturnType<typeof API.confirmBasket> = yield API.confirmBasket(basketId)
    yield put(
      actions.CONFIRM_BASKET.success({
        basketId,
        order,
      }),
    )

    // check if the selected basket was related to the cancelled order
    // if so unselect it
    const selected: ReturnType<typeof selectBasketsSelectedBasket> = yield select(
      selectBasketsSelectedBasket,
    )
    if (selected?.basket?.id === basketId) {
      yield put(actions.UNSELECT_BASKET())
    }

    // redirect to the order details
    history.push(orderPath(order))
    // remove the basket id from local storage
    // this prevents the most common case of where
    // we send a request we can't meet
    removeBasket()
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(
      actions.CONFIRM_BASKET.failure({
        basketId,
        error,
      }),
    )
  }
}

function* confirmBasketFailure(action: ReturnType<typeof actions.CONFIRM_BASKET.failure>) {
  const { basketId, error } = action.payload

  // display the error
  yield call(displayErrorToastSaga, {
    type: action.type,
    payload: error,
  })

  // refetch the basket
  yield put(actions.FETCH_BASKET.request(basketId))
}

function* copyOrderToBasket(action: ReturnType<typeof actions.COPY_ORDER_TO_BASKET.request>) {
  const { basketId, orderId } = action.payload
  const data: IReorder = {
    basket: {
      id: basketId,
      type: ENTITY_TYPE_BASKET,
    },
    order: {
      id: orderId,
      type: ENTITY_TYPE_ORDER,
    },
  }
  try {
    const result: CallReturnType<typeof API.reorder> = yield API.reorder(data)
    yield put(actions.COPY_ORDER_TO_BASKET.success(result))

    // redirect to the basket details
    history.push(basketPath(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.COPY_ORDER_TO_BASKET.failure(error))
  }
}

function* copyMenuToBasket(action: ReturnType<typeof actions.COPY_MENU_TO_BASKET.request>) {
  const { basketId, menuId } = action.payload
  const data: IMenuToBasket = {
    basket: {
      id: basketId,
      type: ENTITY_TYPE_BASKET,
    },
    menu: {
      id: menuId,
      type: ENTITY_TYPE_MENU,
    },
  }
  try {
    const result: CallReturnType<typeof API.copyMenuToBasket> = yield API.copyMenuToBasket(data)
    yield put(actions.COPY_MENU_TO_BASKET.success(result))

    // redirect to the basket details
    history.push(basketPath(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.COPY_MENU_TO_BASKET.failure(error))
  }
}

function* createBasketItemFailure(action: ReturnType<typeof actions.CREATE_BASKET_ITEM.failure>) {
  const { error, asBasketSelectionFlow } = action.payload
  // only display the basket is already selected
  if (!asBasketSelectionFlow) {
    yield call(displayErrorToastSaga, {
      type: action.type,
      payload: error,
    })
    yield put(actions.UNSELECT_BASKET())
  }
}

export function* saga() {
  yield all([
    takeLeading(actions.SELECT_STORED_BASKET.request, selectStoredBasket),
    takeLeading(actions.FETCH_BASKETS.request, fetchBaskets),
    takeLeading(actions.FETCH_BASKETS.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_BASKETS_FOR_ADDRESS_AND_DATE.request, fetchBasketForAddressAndDate),
    takeLeading(actions.FETCH_BASKETS_FOR_ADDRESS_AND_DATE.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_BASKET.request, fetchBasket),
    takeLeading(actions.FETCH_BASKET.failure, displayErrorToastSaga),
    takeLeading(actions.DELETE_BASKET.request, deleteBasket),
    takeLeading(actions.DELETE_BASKET.failure, displayErrorToastSaga),
    takeLeading(actions.CREATE_BASKET.request, createBasket),
    takeLeading(actions.UPDATE_BASKET.request, updateBasket),
    takeLeading(actions.DELETE_BASKET_ITEM.request, deleteBasketItem),
    takeLeading(actions.UPDATE_BASKET_ITEM.request, updateBasketItem),
    takeLeading(actions.CREATE_BASKET_ITEM.request, createBasketItem),
    takeLeading(actions.SELECT_BASKET.request, selectBasket),
    takeLeading(actions.CONFIRM_BASKET.request, confirmBasket),
    takeLeading(actions.CONFIRM_BASKET.failure, confirmBasketFailure),
    takeLeading(actions.COPY_ORDER_TO_BASKET.request, copyOrderToBasket),
    takeLeading(actions.COPY_ORDER_TO_BASKET.failure, displayErrorToastSaga),
    takeLeading(actions.COPY_MENU_TO_BASKET.request, copyMenuToBasket),
    takeLeading(actions.COPY_MENU_TO_BASKET.failure, displayErrorToastSaga),
    takeLeading(actions.CREATE_BASKET_ITEM.failure, createBasketItemFailure),
  ])
}
