import { ActionType, getType } from 'typesafe-actions'

import { IRequestDetail } from '../../types'
import { OPEN_ORDER_FOR_EDITING } from '..'

import * as actions from './actions'
import { IBasketDiscountWithDiscounts } from '../../../api/models/basket-discount'

interface IState {
  items?: { [key: string]: IBasketDiscountWithDiscounts }
  detail?: IRequestDetail<Error>
}

const initialState: IState = {}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions | typeof OPEN_ORDER_FOR_EDITING>,
): IState => {
  switch (action.type) {
    case getType(actions.FETCH_BASKET_DISCOUNTS.request): {
      return {
        ...state,
        detail: {
          id: action.payload,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_BASKET_DISCOUNTS.success): {
      const item = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
        detail: {
          id: item.id,
          isFetching: false,
        },
      }
    }
  }

  return state
}
