import { Box } from '@chakra-ui/react'

export const DemoBar = () => {
  if (process.env.REACT_APP_IS_PRODUCTION !== 'true') {
    return (
      <Box height={6} background="coral.500" color="white" fontWeight="bold" textAlign="center">
        DEMO
      </Box>
    )
  }
  return null
}
