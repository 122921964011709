import React from 'react'
import { useField } from 'formik'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  Input,
  PinInput,
  PinInputField,
} from '@chakra-ui/react'

export interface IFormikPinFieldProps {
  name: string
  hideErrorUntilTouched?: boolean
}

export const FormikPinField: React.FC<IFormikPinFieldProps> = ({
  name,
  hideErrorUntilTouched = true,
}) => {
  const [field, meta, helpers] = useField(name)
  const handleChange = (value: string) => {
    helpers.setTouched(true)
    helpers.setValue(value)
  }

  const errorMessage = meta.touched ? meta.error : ''

  return (
    <FormControl isInvalid={!!errorMessage}>
      <FormLabel>Verification Code</FormLabel>
      <Input {...field} type="hidden" />
      <Stack direction="row">
        <PinInput otp={true} type="number" value={field.value} onChange={handleChange}>
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </Stack>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
