import { APIService } from '../service'
import { IJsonApiModelWithId, IFetchMultipleQueryOptions } from '../types'
import { deserializeTransformer } from '../serializer'

declare module '../service' {
  interface APIService {
    fetchAllNonDeliveryDays(requestOpts?: IFetchMultipleQueryOptions): Promise<INonDeliveryDay[]>
  }
}

export const ENTITY_TYPE_NON_DELIVERY_DAY = 'non-delivery-days'

export interface INonDeliveryDay extends IJsonApiModelWithId {
  id: string
  date: Date
}

APIService.prototype.fetchAllNonDeliveryDays = async function (
  requestOpts?: IFetchMultipleQueryOptions,
) {
  return this.fetchAll<INonDeliveryDay>(ENTITY_TYPE_NON_DELIVERY_DAY, requestOpts)
}

deserializeTransformer.register(ENTITY_TYPE_NON_DELIVERY_DAY, {
  date: (v: string) => new Date(v),
})
