import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FETCH_CATEGORY, categorySelector, selectHasRoots } from '../../stores/catalog'

export function useCategory(categoryId?: string) {
  const dispatch = useDispatch()
  const hasRoots = useSelector(selectHasRoots)
  const category = useSelector(categorySelector)(categoryId)

  useEffect(() => {
    // ensure roots are loaded dispatching
    if (categoryId && !category && hasRoots) {
      dispatch(FETCH_CATEGORY.request(categoryId))
    }
  }, [dispatch, categoryId, category, hasRoots])

  return category
}
