import { extendTheme } from '@chakra-ui/react'

import { AllergenIconBadge } from './components/allergen-icon-badge'
import { Accordion } from './components/accordion'
import { Button } from './components/button'
import { Form } from './components/form'
import { IconBadge } from './components/icon-badge'
import { IconLinkBox } from './components/icon-link-box'
import { Nav } from './components/nav'
import { Popover } from './components/popover'
import { SelectMenu } from './components/select-menu'

export const MAX_WIDTH = '80em'

export const theme = extendTheme({
  initialColorMode: 'system',
  colors: {
    // https://colorshadesgenerator.com/#9b9b9b
    gray: {
      50: '#fafafa', // 95%
      100: '#f0f0f0', // 85%
      200: '#d7d7d7', // 60%
      300: '#c3c3c3', // 40%
      400: '#afafaf', // 20%
      500: '#9b9b9b', // 0%
      600: '#6d6d6d', // 30%
      700: '#4e4e4e', // 50%
      800: '#2f2f2f', // 70%
      900: '#171717', // 85%
    },
    // https://colorshadesgenerator.com/#97bf0f
    green: {
      50: '#fafcf3', // 95%
      100: '#eff5db', // 85%
      200: '#d5e59f', // 60%
      300: '#c1d96f', // 40%
      400: '#accc3f', // 20%
      500: '#97bf0f', // 0%
      600: '#79990c', // 20%
      700: '#627c0a', // 35%
      800: '#536908', // 45%
      900: '#3c4c06', // 60%
    },
    // https://colorshadesgenerator.com/#ff4f4f
    coral: {
      50: '#fff6f6', // 95%
      100: '#ffe5e5', // 85%
      200: '#ffb9b9', // 60%
      300: '#ff9595', // 40%
      400: '#ff7272', // 20%
      500: '#ff4f4f', // 0%
      600: '#cc3f3f', // 20%
      700: '#a63333', // 35%
      800: '#8c2b2b', // 45%
      900: '#662020', // 60%
    },
    // https://colorshadesgenerator.com/#003b79
    birchallBlue: {
      50: '#f2f5f8', // 95%
      100: '#d9e2eb', // 85%
      200: '#99b1c9', // 60%
      300: '#6689af', // 40%
      400: '#336294', // 20%
      500: '#003b79', // 0%
      600: '#002f61', // 20%
      700: '#00264f', // 35%
      800: '#002043', // 45%
      900: '#001830', // 60%
    },
  },
  fonts: {
    body: 'Avenir, system-ui, Helvetica, Arial, sans-serif',
    heading: 'AlteDin1451, system-ui, Helvetica, Arial, sans-serif',
  },
  radii: {
    // keep radius to 2px regardless of scale
    none: '0',
    sm: '0.125rem',
    base: '0.125rem',
    md: '0.125rem',
    lg: '0.125rem',
    xl: '0.125rem',
    '2xl': '0.125rem',
    '3xl': '0.125rem',
    full: '9999px',
  },
  styles: {
    global: {
      body: {
        color: 'gray.800',
      },
      'html, body, #root': {
        // height: '100%',
      },
    },
    // global: () => ({
    //   'h1, h2, h3, h4, h5, h6': {
    //     color: 'blue.500',
    //     fontWeight: 400,
    //   }
    // }),
  },
  components: {
    AllergenIconBadge,
    Accordion,
    Button,
    Form,
    IconBadge,
    IconLinkBox,
    Nav,
    Popover,
    SelectMenu,
    Heading: {
      baseStyle: {
        // color: 'birchallBlue.500',
        fontWeight: '400',
      },
    },
    Modal: {
      baseStyle: {
        list: {
          zIndex: 'modal',
        },
      },
    },
  },
})
