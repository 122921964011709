import { all, call, put, takeLeading } from 'redux-saga/effects'
import { addDays, formatISO } from 'date-fns'

import API, { CallReturnType } from '../../../api'
import { displayErrorToastSaga, ensureError } from '../../../lib'
import * as actions from './actions'

function* fetchTomorrowsCutoff() {
  const tomorrow = formatISO(addDays(new Date(), 1), { representation: 'date' })

  try {
    const cutoff: CallReturnType<typeof API.fetchCutoffTime> = yield call(
      [API, API.fetchCutoffTime],
      tomorrow,
    )
    yield put(actions.FETCH_TOMORROWS_CUTOFF.success(cutoff))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_TOMORROWS_CUTOFF.failure(error))
  }
}

export function* saga() {
  yield all([
    takeLeading(actions.FETCH_TOMORROWS_CUTOFF.request, fetchTomorrowsCutoff),
    takeLeading(actions.FETCH_TOMORROWS_CUTOFF.failure, displayErrorToastSaga),
  ])
}
