import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_DUPLICATE_ORDER_CHECK, selectDuplicateOrderCheck } from '../../stores/order'

export const useDuplicateOrderCheck = (
  addressId: string,
  deliveryDate?: Date,
  isExistingBasket?: boolean,
) => {
  const dispatch = useDispatch()
  const detail = useSelector(selectDuplicateOrderCheck)

  useEffect(() => {
    if (isExistingBasket) {
      return
    }
    if (deliveryDate) {
      dispatch(FETCH_DUPLICATE_ORDER_CHECK.cancel())
      dispatch(
        FETCH_DUPLICATE_ORDER_CHECK.request({
          addressId,
          requestedDeliveryDate: deliveryDate,
        }),
      )
    }
  }, [dispatch, addressId, deliveryDate, isExistingBasket])

  const onCancel = useCallback(() => {
    dispatch(FETCH_DUPLICATE_ORDER_CHECK.cancel())
  }, [dispatch])

  return {
    ...detail,
    onCancel,
  }
}
