import { APIService } from '../service'
import { IJsonApiModelWithId, IFetchMultipleResponseMeta } from '../types'

declare module '../service' {
  interface APIService {
    fetchAccounts(): Promise<IAccount[]>
  }
}

export const ENTITY_TYPE_ACCOUNT = 'accounts'

export interface IAccount extends IJsonApiModelWithId {
  name: string
  code: string
  isPoRequired: boolean
  isOnHold: boolean
  isActive: boolean
}

APIService.prototype.fetchAccounts = async function () {
  const accounts = await this.fetchMultiple<IAccount, IFetchMultipleResponseMeta>(
    ENTITY_TYPE_ACCOUNT,
  )
  return accounts.data
}
