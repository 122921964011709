import { useMemo } from 'react'
import useSWR, { SWRConfiguration } from 'swr'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { ICmsPage } from './cms-page-content'
import { BASE_URL, CmsApiError, fetcher } from './fetcher'

export function useCmsPageContent(path: string, options?: SWRConfiguration) {
  const { search } = useLocation()
  const params = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  let url = BASE_URL + 'pages/find/?html_path=' + path
  if (params.content_type && params.token) {
    // is CMS preview: https://github.com/torchbox/wagtail-headless-preview
    const previewQueryParams = {
      content_type: params.content_type,
      token: params.token,
      forma: 'json',
    }
    url = BASE_URL + 'page_preview/1/?' + queryString.stringify(previewQueryParams)
  }
  return useSWR<ICmsPage, CmsApiError>(url, fetcher, options)
}
