import { Box, BoxProps, forwardRef } from '@chakra-ui/react'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Navigate, Link as RouterLink } from 'react-router-dom'

import { MenuBox } from '../components/menu-box'
import { IMenuFormModalButtonProps, MenuFormModalButton } from '../components/menu-form'
import { PageHeading, PageHeadingProps } from '../components/page-heading'
import { PageLayout } from '../components/page-layout'
import { ResponsiveContainer } from '../components/responsive-container'
import { TabButton, TabList } from '../components/tab-list'
import { DEFAULT_PAGE_TITLE, menuPath, useMenusListing } from '../lib'
import { FAVOURITES_ROUTE } from './favourites'

export const MENUS_ROUTE = '/lists'

interface IMenusPageContentProps extends BoxProps {
  buttonProps: IMenuFormModalButtonProps
}

const MenusPageContent = forwardRef<IMenusPageContentProps, 'div'>((props, ref) => {
  const { buttonProps, ...rest } = props
  const { items } = useMenusListing()
  return (
    <Box ref={ref} {...rest}>
      <TabList>
        <TabButton as={RouterLink} to={MENUS_ROUTE} isActive={true}>
          Lists
        </TabButton>
        <TabButton as={RouterLink} to={FAVOURITES_ROUTE}>
          Favourites
        </TabButton>
      </TabList>
      {!items.length && (
        <Box textAlign="center" p={16}>
          You have no lists.{' '}
          <MenuFormModalButton {...buttonProps} variant="link">
            Create one
          </MenuFormModalButton>
          .
        </Box>
      )}
      {items.map((menu) => (
        <MenuBox key={menu.id} mt={4} menu={menu} />
      ))}
    </Box>
  )
})

interface IListsPageHeaderProps extends PageHeadingProps {
  buttonProps: IMenuFormModalButtonProps
}

const ListsPageHeader = forwardRef<IListsPageHeaderProps, 'div'>((props, ref) => {
  const { buttonProps, ...rest } = props

  return (
    <PageHeading ref={ref} {...rest} as="div">
      <Box display="flex" justifyContent="space-between">
        <Box as="h1" mr={4}>
          Lists
        </Box>
        <MenuFormModalButton {...buttonProps} />
      </Box>
    </PageHeading>
  )
})

export const MenusPage: React.FC = () => {
  const [createdMenuId, setCreatedMenuId] = useState<string | undefined>()
  const onSuccess = (menuId: string) => {
    setCreatedMenuId(menuId)
  }

  if (createdMenuId) {
    return (
      <Navigate
        to={{
          pathname: menuPath(createdMenuId),
        }}
      />
    )
  }

  const buttonProps: IMenuFormModalButtonProps = {
    onSuccess,
  }

  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          <ListsPageHeader buttonProps={buttonProps} />
          <MenusPageContent buttonProps={buttonProps} />
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
