import { PropsWithChildren, createRef, useState } from 'react'
import { Box, BoxProps, IconButton, Input, forwardRef } from '@chakra-ui/react'
import { createContext } from '@chakra-ui/react-utils'
import queryString from 'query-string'

import { OmSearch } from '../icons'
import { SEARCH_ROUTE } from '../../pages'
import { history } from '../../lib'

const [SearchFormProvider, useSearchFormContext] = createContext<UseSearchFormReturn>({
  strict: false,
  name: 'SearchFormContext',
})

export { SearchFormProvider, useSearchFormContext }

export function useSearchForm() {
  const params = queryString.parse(history.location!.search)
  const codes = Array.isArray(params.codes) ? params.codes[0] : params.codes
  const q = Array.isArray(params.q) ? params.q[0] : params.q

  const [query, setQuery] = useState(q || '')
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)
  const onSubmit = () => {
    history.push(SEARCH_ROUTE + '?' + queryString.stringify({ q: query }))
  }

  return {
    codes,
    query,
    onChange,
    onSubmit,
  }
}

export interface UseSearchFormReturn extends ReturnType<typeof useSearchForm> {}

export const SearchFormContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const context = useSearchForm()
  return <SearchFormProvider value={context}>{children}</SearchFormProvider>
}

export const SearchForm = forwardRef<BoxProps, 'div'>((props, ref) => {
  const context = useSearchFormContext()

  if (!context) {
    throw new Error(
      `useSearchFormContext: context is undefined. Seems you forgot to wrap component within <SearchFormContainer>`,
    )
  }
  const { query, onChange, onSubmit } = context

  const inputRef = createRef<HTMLInputElement>()

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()
    if (inputRef.current) {
      inputRef.current.blur()
    }
    onSubmit()
  }

  return (
    <Box
      position="relative"
      ref={ref}
      as="form"
      action={SEARCH_ROUTE}
      method="GET"
      onSubmit={handleSubmit}
      {...props}>
      <Input
        tabIndex={2}
        color="black"
        placeholder="Search"
        autoComplete="off"
        autoCapitalize="off"
        name="q"
        value={query}
        onChange={onChange}
        ref={inputRef}
        pr={16}
      />
      <IconButton
        tabIndex={3}
        aria-label="Search"
        icon={<OmSearch />}
        type="submit"
        borderTopRightRadius="sm"
        borderBottomRightRadius="sm"
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        position="absolute"
        top="1px"
        right="1px"
        zIndex={1}
        height="38px"
        width="38px"
      />
    </Box>
  )
})
