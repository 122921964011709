import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IAddress } from '../../api'

import { selectOrdersOpenListingResults, FETCH_OPEN_ORDERS } from '../../stores/order'

interface IUseOpenOrdersListingProps {
  address: IAddress
  deliveryDate: Date
}

export function useOpenOrdersListing(props?: IUseOpenOrdersListingProps) {
  const dispatch = useDispatch()
  const listing = useSelector(selectOrdersOpenListingResults)

  const onLoadInitial = useCallback(() => {
    dispatch(
      FETCH_OPEN_ORDERS.request(
        props
          ? {
              addressId: props.address.id,
              requestedDeliveryDate: props.deliveryDate,
            }
          : undefined,
      ),
    )
  }, [dispatch, props])

  // automatically call load initial
  // the extra pages will be lost but data will be fresh
  useEffect(() => {
    onLoadInitial()
  }, [onLoadInitial])

  return {
    ...listing,
    onLoadInitial,
  }
}
