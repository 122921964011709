import { formatISO } from 'date-fns'
import { APIService } from '../service'
import { IJsonApiModelWithId, IFetchOneQueryOptions } from '../types'

declare module '../service' {
  interface APIService {
    fetchOrderSettings(
      dateToday: Date,
      requestOpts?: IFetchOneQueryOptions,
    ): Promise<IOrderSettings>
    fetchOrderSettingsById(id: string, requestOpts?: IFetchOneQueryOptions): Promise<IOrderSettings>
  }
}

export const ENTITY_TYPE_ORDER_SETTINGS = 'order-settings'

export interface IOrderSettings extends IJsonApiModelWithId {
  id: string
  dateNow: Date
  maximumFutureDeliveryDays: number
}

APIService.prototype.fetchOrderSettings = async function (
  dateToday: Date,
  requestOpts?: IFetchOneQueryOptions,
) {
  const id = formatISO(dateToday, { representation: 'date' })
  return this.fetchOrderSettingsById(id, {
    ...requestOpts,
  })
}

APIService.prototype.fetchOrderSettingsById = async function (
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  return this.fetchOne<IOrderSettings>(ENTITY_TYPE_ORDER_SETTINGS + '/' + id, {
    ...requestOpts,
  })
}
