import { createAsyncAction } from 'typesafe-actions'
import { IBasketDiscountWithDiscounts } from '../../../api/models/basket-discount'
import { IAppliedBasketDiscountBxgy, IAppliedBasketDiscountFreeProductPromotion } from '../../../api'

export const FETCH_BASKET_DISCOUNTS = createAsyncAction(
  'order/FETCH_BASKET_DISCOUNTS',
  'order/FETCH_BASKET_DISCOUNTS_SUCCESS',
  'order/FETCH_BASKET_DISCOUNTS_FAILURE',
  'order/FETCH_BASKET_DISCOUNTS_CANCEL',
)<string, IBasketDiscountWithDiscounts, Error, undefined>()

export interface IApplyBxgyPayload {
  bxgyId: string
  productId: string
}

export const APPLY_BXGY = createAsyncAction(
  'order/APPLY_BXGY',
  'order/APPLY_BXGY_SUCCESS',
  'order/APPLY_BXGY_FAILURE',
)<IApplyBxgyPayload, IAppliedBasketDiscountBxgy, Error>()

export interface IApplyFreeProductPromotionPayload {
  freeProductPromotionId: string
}

export const APPLY_FREE_PRODUCT_PROMOTION = createAsyncAction(
  'order/APPLY_FREE_PRODUCT_PROMOTION',
  'order/APPLY_FREE_PRODUCT_PROMOTION_SUCCESS',
  'order/APPLY_FREE_PRODUCT_PROMOTION_FAILURE',
)<IApplyFreeProductPromotionPayload, IAppliedBasketDiscountFreeProductPromotion, Error>()

export interface IAddFreeProductToBasketPayload {
  productId: string
  basketId: string
}

export const ADD_FREE_PRODUCT_TO_BASKET = createAsyncAction(
  'order/ADD_FREE_PRODUCT_TO_BASKET',
  'order/ADD_FREE_PRODUCT_TO_BASKET_SUCCESS',
  'order/ADD_FREE_PRODUCT_TO_BASKET_FAILURE',
)<IAddFreeProductToBasketPayload, string, Error>()
