import React, { useEffect, useMemo } from 'react'
import {
  Box,
  BoxProps,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  forwardRef,
  chakra,
  Spinner,
} from '@chakra-ui/react'

import { TabList, TabButton } from '../tab-list'
import {
  useSelectedBasket,
  useOrderPicker,
  useBasketsListing,
  useOrderOrBasketDetail,
  useOpenOrdersListing,
  formatDeliveryDate,
  deliveryAddressDisplay,
} from '../../lib'
import { IAddress, TBasketWithAddressAndItems, TOrderWithAddressAndItems } from '../../api'
import { useConfirmDialog, ConfirmDialog } from '../confirm-dialog'
import { BasketForm } from '../basket-form'
import { IBasketFormData } from '../../stores/ui'

export const DefinitionList = chakra('dl', {
  baseStyle: {
    '& > dt:not(style) ~ dt:not(style)': {
      '::before': { content: '"\\A"', whiteSpace: 'pre' },
    },
  },
})

export const DefinitionTerm = chakra('dt', {
  baseStyle: {
    fontWeight: 'light',
    display: 'inline',
  },
})

export const Definition = chakra('dd', {
  baseStyle: {
    fontWeight: 'bold',
    display: 'inline',
  },
})

export const OrderPickerModal: React.FC = () => {
  const { isOpen, onClose, onSelectBasket, onSelectExisting, existingOrderFilter } =
    useOrderPicker()
  const [content, setContent] = React.useState<'new' | 'unconfirmed' | 'confirmed'>('unconfirmed')

  useEffect(() => {
    setContent('unconfirmed')
  }, [existingOrderFilter])

  const handlePushToNew = () => {
    setContent('new')
  }

  const handleClose = React.useCallback(() => {
    setContent('unconfirmed') // reset to the default state
    onClose()
  }, [onClose])

  const handleBasketCreated = React.useCallback(
    (id: string) => {
      onClose()
      onSelectBasket(id)
    },
    [onClose, onSelectBasket],
  )

  const handleSelectExisting = React.useCallback(
    (props: IBasketFormData) => {
      onSelectExisting(props)
    },
    [onSelectExisting],
  )

  if (!isOpen) {
    return null
  }

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Choose Order</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={0}>
          <Box>
            <TabList>
              <TabButton
                isActive={content === 'unconfirmed'}
                onClick={() => setContent('unconfirmed')}
              >
                Unconfirmed
              </TabButton>
              <TabButton isActive={content === 'confirmed'} onClick={() => setContent('confirmed')}>
                Confirmed
              </TabButton>
              {!existingOrderFilter && (
                <TabButton isActive={content === 'new'} onClick={() => setContent('new')}>
                  Create Order
                </TabButton>
              )}
            </TabList>
            <Box>
              {content === 'new' && (
                <Box pt={4}>
                  <BasketForm
                    asModal={false}
                    isOpen={isOpen}
                    onClose={handleClose} // for cancel
                    onCreated={handleBasketCreated}
                    onSelectExisting={handleSelectExisting}
                  />
                </Box>
              )}
              {content === 'unconfirmed' && (
                <OrderPickerBaskets
                  maxHeight="50vh"
                  overflowY="auto"
                  mx={-6}
                  px={6}
                  onEmptyClick={handlePushToNew}
                  existingOrderFilter={existingOrderFilter}
                />
              )}
              {content === 'confirmed' && (
                <OrderPickerOrders
                  maxHeight="50vh"
                  overflowY="auto"
                  mx={-6}
                  px={6}
                  onEmptyClick={handlePushToNew}
                  existingOrderFilter={existingOrderFilter}
                />
              )}
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export interface IOrderPickerOrderBoxProps extends BoxProps {
  order: TBasketWithAddressAndItems | TOrderWithAddressAndItems
  isSelected?: boolean
}

export const OrderPickerOrderBox = forwardRef<IOrderPickerOrderBoxProps, 'button'>((props, ref) => {
  const { order, isSelected, ...rest } = props
  const { onSelectBasket, onSelectOrder, isFetching, productCodesToCopy } = useOrderPicker()
  const { isBasket, addressDisplay, deliveryDateDisplay } = useOrderOrBasketDetail(order)

  const conflictCount = useMemo(() => {
    if (!productCodesToCopy.length) {
      return 0
    }
    const productCodes = order.items.map((i) => i.productCode)
    return productCodes.filter((code) => productCodesToCopy.includes(code)).length
  }, [productCodesToCopy, order])

  const conflictingProductCodesConfirmDialog = useConfirmDialog({
    type: 'conflicting-products',
    productsCount: conflictCount,
    onConfirm: () => {
      if (isBasket) {
        onSelectBasket(order.id)
      } else {
        onSelectOrder(order.id)
      }
    },
  })
  const openOrderConfirmDialog = useConfirmDialog({
    type: 'open-order',
    onConfirm: () => {
      if (conflictCount) {
        conflictingProductCodesConfirmDialog.onOpen()
      } else {
        onSelectOrder(order.id)
      }
    },
  })

  const handleSelect = React.useCallback(() => {
    if (isBasket) {
      if (conflictCount) {
        conflictingProductCodesConfirmDialog.onOpen()
      } else {
        onSelectBasket(order.id)
      }
    } else {
      openOrderConfirmDialog.onOpen()
    }
  }, [
    onSelectBasket,
    order,
    isBasket,
    conflictCount,
    openOrderConfirmDialog,
    conflictingProductCodesConfirmDialog,
  ])

  return (
    <Box ref={ref} {...rest} borderWidth={1} p={[3, 4]} borderRadius="sm">
      {conflictingProductCodesConfirmDialog.isOpen && (
        <ConfirmDialog {...conflictingProductCodesConfirmDialog} />
      )}
      {openOrderConfirmDialog.isOpen && <ConfirmDialog {...openOrderConfirmDialog} />}
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <DefinitionList mr={4}>
          {order.title && (
            <>
              <DefinitionTerm>Title: </DefinitionTerm>
              <Definition>{order.title}</Definition>
            </>
          )}
          {order.poNumber && (
            <>
              <DefinitionTerm>PO Number: </DefinitionTerm>
              <Definition>{order.poNumber}</Definition>
            </>
          )}
          <DefinitionTerm>Address: </DefinitionTerm>
          <Definition>{addressDisplay}</Definition>
          <DefinitionTerm>Delivery date: </DefinitionTerm>
          <Definition>{deliveryDateDisplay}</Definition>
        </DefinitionList>
        <Box>
          <Button
            onClick={handleSelect}
            isLoading={isFetching}
            variant={isSelected ? 'outline' : 'solid'}
          >
            Select
          </Button>
        </Box>
      </Box>
    </Box>
  )
})

const LoadingSpinner = forwardRef<BoxProps, 'div'>((props, ref) => {
  return (
    <Box ref={ref} {...props}>
      <Box display="flex" justifyContent="center" p={8}>
        <Spinner thickness="4px" speed="0.8s" emptyColor="gray.100" color="green.500" size="xl" />
      </Box>
    </Box>
  )
})

interface IOrderPickerBasketsProps extends BoxProps {
  onEmptyClick: () => void
  existingOrderFilter?: {
    address: IAddress
    deliveryDate: Date
  }
}

const OrderPickerBaskets = forwardRef<IOrderPickerBasketsProps, 'div'>((props, ref) => {
  const { onEmptyClick, existingOrderFilter, ...rest } = props
  const { basket } = useSelectedBasket()
  const { items, isFetching } = useBasketsListing(existingOrderFilter)
  const editableItems = items.filter((basket) => basket.canEdit)
  if (isFetching) {
    return <LoadingSpinner ref={ref} {...rest} />
  }
  return (
    <Box ref={ref} {...rest}>
      {editableItems.length === 0 && (
        <Box textAlign="center" p={16}>
          {existingOrderFilter ? (
            <>
              You have no available unconfirmed orders to{' '}
              {deliveryAddressDisplay(existingOrderFilter.address)} on{' '}
              {formatDeliveryDate(existingOrderFilter.deliveryDate)}.
            </>
          ) : (
            <>
              You have no available unconfirmed orders,{' '}
              <Button variant="link" onClick={onEmptyClick}>
                create a new order
              </Button>
              .
            </>
          )}
        </Box>
      )}
      {editableItems.map((item) => (
        <OrderPickerOrderBox
          key={item.id}
          my={4}
          order={item}
          isSelected={!existingOrderFilter && basket?.id === item.id}
        />
      ))}
    </Box>
  )
})

interface IOrderPickerOrdersProps extends BoxProps {
  onEmptyClick: () => void
  existingOrderFilter?: {
    address: IAddress
    deliveryDate: Date
  }
}

const OrderPickerOrders = forwardRef<IOrderPickerOrdersProps, 'div'>((props, ref) => {
  const { onEmptyClick, existingOrderFilter, ...rest } = props
  const { items, isFetching } = useOpenOrdersListing(existingOrderFilter)
  const editableItems = items.filter((order) => order.canEdit)
  if (isFetching) {
    return <LoadingSpinner ref={ref} {...rest} />
  }
  return (
    <Box ref={ref} {...rest}>
      {editableItems.length === 0 && (
        <Box textAlign="center" p={16}>
          {existingOrderFilter ? (
            <>
              You have no available confirmed orders to{' '}
              {deliveryAddressDisplay(existingOrderFilter.address)} on{' '}
              {formatDeliveryDate(existingOrderFilter.deliveryDate)}.
            </>
          ) : (
            <>
              You have no available confirmed orders,{' '}
              <Button variant="link" onClick={onEmptyClick}>
                create a new order
              </Button>
              .
            </>
          )}
        </Box>
      )}
      {editableItems.map((item) => (
        <OrderPickerOrderBox key={item.id} my={4} order={item} />
      ))}
    </Box>
  )
})
