import { createIcon } from '@chakra-ui/react'

export const OmAllergenMolluscs = createIcon({
  displayName: 'Molluscs',
  viewBox: '0 0 62 62',
  d: 'M 25.65 52.433 C 37.298 49.215 48.204 44.57 58.194 38.161 C 48.991 46.085 38.11 50.779 25.65 52.433 Z M 20.355 51.019 C 32.551 43.677 45.484 37.275 55.582 27.245 C 46.537 38.624 33.642 45.073 20.355 51.019 Z M 17.195 48.807 C 27.456 37.553 38.307 26.751 47.728 14.81 C 39.768 27.827 28.767 38.537 17.195 48.807 Z M 37.316 8.531 C 29.711 20.566 22.26 32.676 15.677 45.23 C 20.487 31.8 28.146 19.786 37.316 8.531 Z M 13.893 41.228 C 13.132 26.593 20.377 14.639 28.477 2.973 C 22.228 15.261 14.775 27.149 13.893 41.228 Z M 10.509 41.476 C 9.307 29.136 10.53 17.057 15.279 5.376 C 12.434 17.271 11.388 29.358 10.509 41.476 Z M 61.367 26.32 C 58.581 11.182 44.299 0.279 27.104 0.232 C 19.843 -0.03 13.031 2.3 7.186 7.13 C 3.851 9.885 1.758 13.297 1.137 17.6 C 0.357 23 1.516 28.095 3.169 33.161 C 3.72 34.85 3.976 36.327 2.47 37.84 C -0.71 41.032 -0.55 42.257 3.14 44.866 C 4.304 45.69 5.581 46.408 6.896 46.985 C 9.865 48.286 12.645 49.743 14.502 52.478 C 15.446 53.871 17.073 54.146 18.674 54.053 C 20.849 53.925 22.459 54.738 23.835 56.295 C 24.907 57.51 26.11 58.628 27.326 59.717 C 30.814 62.846 33.103 62.475 34.756 58.227 C 35.685 55.84 37.157 54.683 39.711 54.413 C 41.044 54.273 42.334 53.79 43.655 53.511 C 58.808 50.318 63.304 36.841 61.367 26.32 L 61.367 26.32 Z',
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 62 62"
//     id="vector">
//     <path
//         id="path"
//         d="M 25.65 52.433 C 37.298 49.215 48.204 44.57 58.194 38.161 C 48.991 46.085 38.11 50.779 25.65 52.433 Z M 20.355 51.019 C 32.551 43.677 45.484 37.275 55.582 27.245 C 46.537 38.624 33.642 45.073 20.355 51.019 Z M 17.195 48.807 C 27.456 37.553 38.307 26.751 47.728 14.81 C 39.768 27.827 28.767 38.537 17.195 48.807 Z M 37.316 8.531 C 29.711 20.566 22.26 32.676 15.677 45.23 C 20.487 31.8 28.146 19.786 37.316 8.531 Z M 13.893 41.228 C 13.132 26.593 20.377 14.639 28.477 2.973 C 22.228 15.261 14.775 27.149 13.893 41.228 Z M 10.509 41.476 C 9.307 29.136 10.53 17.057 15.279 5.376 C 12.434 17.271 11.388 29.358 10.509 41.476 Z M 61.367 26.32 C 58.581 11.182 44.299 0.279 27.104 0.232 C 19.843 -0.03 13.031 2.3 7.186 7.13 C 3.851 9.885 1.758 13.297 1.137 17.6 C 0.357 23 1.516 28.095 3.169 33.161 C 3.72 34.85 3.976 36.327 2.47 37.84 C -0.71 41.032 -0.55 42.257 3.14 44.866 C 4.304 45.69 5.581 46.408 6.896 46.985 C 9.865 48.286 12.645 49.743 14.502 52.478 C 15.446 53.871 17.073 54.146 18.674 54.053 C 20.849 53.925 22.459 54.738 23.835 56.295 C 24.907 57.51 26.11 58.628 27.326 59.717 C 30.814 62.846 33.103 62.475 34.756 58.227 C 35.685 55.84 37.157 54.683 39.711 54.413 C 41.044 54.273 42.334 53.79 43.655 53.511 C 58.808 50.318 63.304 36.841 61.367 26.32 L 61.367 26.32 Z"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
