import { Box, BoxProps, Button, forwardRef } from '@chakra-ui/react'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Navigate } from 'react-router-dom'
import { PageHeading, PageHeadingProps } from '../components/page-heading'
import { PageLayout } from '../components/page-layout'
import { PageLoadingSpinner } from '../components/page-loading-spinner'
import { ResponsiveContainer } from '../components/responsive-container'
import { TicketBox } from '../components/ticket-box'
import { ITicketFormModalButtonProps, TicketFormModalButton } from '../components/ticket-form'
import { DEFAULT_PAGE_TITLE, ticketPath, useTicketsListing } from '../lib'

export const TICKETS_ROUTE = '/messages'

const TicketsPageContent = forwardRef<BoxProps, 'div'>((props, ref) => {
  const { ...rest } = props
  const { items, hasMore, onLoadMore, isFetching } = useTicketsListing()
  return (
    <Box ref={ref} {...rest}>
      {!isFetching && !items.length && (
        <Box textAlign="center" p={16}>
          You have no messages.
        </Box>
      )}
      {isFetching && !items.length && <PageLoadingSpinner />}
      {items.map((ticket) => (
        <TicketBox key={ticket.id} ticket={ticket} mt={4} />
      ))}
      {hasMore && (
        <Box my={10} display="flex" justifyContent="center">
          <Button size="lg" onClick={onLoadMore} isLoading={isFetching}>
            Load more
          </Button>
        </Box>
      )}
    </Box>
  )
})

interface ITicketPageHeaderProps extends PageHeadingProps {
  buttonProps: ITicketFormModalButtonProps
}

const TicketsPageHeader = forwardRef<ITicketPageHeaderProps, 'div'>((props, ref) => {
  const { buttonProps, ...rest } = props

  return (
    <PageHeading ref={ref} {...rest} as="div">
      <Box display="flex" justifyContent="space-between">
        <Box as="h1" mr={4}>
          Messages
        </Box>
        <TicketFormModalButton {...buttonProps} />
      </Box>
    </PageHeading>
  )
})

export const TicketsPage: React.FC = () => {
  const [createdTicketId, setCreatedTicketId] = useState<string | undefined>()
  const onSuccess = (ticketId: string) => {
    setCreatedTicketId(ticketId)
  }

  if (createdTicketId) {
    return (
      <Navigate
        to={{
          pathname: ticketPath(createdTicketId),
        }}
      />
    )
  }

  const buttonProps: ITicketFormModalButtonProps = {
    onSuccess,
  }

  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          <TicketsPageHeader buttonProps={buttonProps} />
          <TicketsPageContent />
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
