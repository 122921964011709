import { createAsyncAction } from 'typesafe-actions'
import { IDuplicateOrderCheck } from '../../../api'

export interface IFetchDuplicateOrderPayload {
  addressId: string
  requestedDeliveryDate: Date
}

export const FETCH_DUPLICATE_ORDER_CHECK = createAsyncAction(
  'order/FETCH_DUPLICATE_ORDER_CHECK',
  'order/FETCH_DUPLICATE_ORDER_CHECK_SUCCESS',
  'order/FETCH_DUPLICATE_ORDER_CHECK_FAILURE',
  'order/FETCH_DUPLICATE_ORDER_CHECK_CANCEL',
)<IFetchDuplicateOrderPayload, IDuplicateOrderCheck, Error, undefined>()
