import { isString } from 'lodash'
import { parseISO } from 'date-fns'

import { APIService } from '../service'
import {
  IJsonApiModel,
  IJsonApiModelWithId,
  IFetchMultipleQueryOptions,
  IFetchMultipleResponse,
  IFetchMultipleResponseMeta,
  IFetchOneQueryOptions,
} from '../types'
import { deserializeTransformer } from '../serializer'

declare module '../service' {
  interface APIService {
    fetchTickets(url: string): Promise<IFetchMultipleResponse<ITicket, IFetchMultipleResponseMeta>>
    fetchTickets(
      requestOpts?: IFetchMultipleQueryOptions,
    ): Promise<IFetchMultipleResponse<ITicket, IFetchMultipleResponseMeta>>
    fetchTicket(id: string, requestOpts?: IFetchOneQueryOptions): Promise<ITicket>
    createTicket(data: INewTicket): Promise<ITicket>
  }
}

export const ENTITY_TYPE_TICKET = 'tickets'

export interface ITicket extends IJsonApiModelWithId {
  id: string
  subject: string
  description: string
  requester: string
  status: string
  createdAt: Date
  updatedAt: Date
  isOrderTicket: boolean
  orderTicketStatus: string
}

export interface INewTicket extends Omit<IJsonApiModel, 'type'> {
  subject: string
  description: string
}

APIService.prototype.fetchTickets = async function (
  requestOptsOrUrl?: IFetchMultipleQueryOptions | string,
) {
  if (isString(requestOptsOrUrl)) {
    return this.fetchMultiple<ITicket, IFetchMultipleResponseMeta>(requestOptsOrUrl)
  }
  return this.fetchMultiple<ITicket, IFetchMultipleResponseMeta>(
    ENTITY_TYPE_TICKET,
    requestOptsOrUrl,
  )
}

APIService.prototype.fetchTicket = async function (
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  return this.fetchOne<ITicket>(ENTITY_TYPE_TICKET + '/' + id, requestOpts)
}

APIService.prototype.createTicket = async function (data: INewTicket) {
  return this.create<ITicket>(ENTITY_TYPE_TICKET, {
    type: ENTITY_TYPE_TICKET,
    ...data,
  })
}

deserializeTransformer.register(ENTITY_TYPE_TICKET, {
  createdAt: (v: string) => parseISO(v),
  updatedAt: (v: string) => parseISO(v),
})
