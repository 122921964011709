import { APIService } from '../service'
import { IJsonApiModelWithId, IFetchMultipleQueryOptions, IFetchOneQueryOptions } from '../types'

declare module '../service' {
  interface APIService {
    fetchAllCategories(requestOpts?: IFetchMultipleQueryOptions): Promise<TCategoryAny[]>
    fetchCategory(
      id: string,
      requestOpts?: IFetchOneQueryOptions,
    ): Promise<TCategoryWithAncestorsAndChildren>
  }
}

export const ENTITY_TYPE_CATEGORY = 'categories'

export type TCategoryAny = ICategory<IJsonApiModelWithId, IJsonApiModelWithId, IJsonApiModelWithId>
export type TCategoryWithAncestorsAndChildren = ICategory<
  IJsonApiModelWithId,
  ICategory<IJsonApiModelWithId, TCategoryAny, TCategoryAny>,
  ICategory<IJsonApiModelWithId, TCategoryAny, TCategoryAny>
>
export interface ICategory<
  P extends IJsonApiModelWithId,
  A extends IJsonApiModelWithId,
  C extends IJsonApiModelWithId,
> extends IJsonApiModelWithId {
  slug: string
  name: string
  level: number
  position: number
  parent: P | null
  ancestors: A[]
  children: C[]
}

APIService.prototype.fetchAllCategories = async function (
  requestOpts?: IFetchMultipleQueryOptions,
) {
  return this.fetchAll<TCategoryAny>(ENTITY_TYPE_CATEGORY, requestOpts)
}

APIService.prototype.fetchCategory = async function (
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  const include = requestOpts?.include || []
  return this.fetchOne<TCategoryWithAncestorsAndChildren>(ENTITY_TYPE_CATEGORY + '/' + id, {
    ...requestOpts,
    include: [...include, 'ancestors', 'children'],
  })
}
