import { all, call, put, takeLeading } from 'redux-saga/effects'

import API, { CallReturnType, ENTITY_TYPE_PRODUCT, INewFavourite } from '../../api'
import { displayErrorToastSaga, ensureError } from '../../lib'

import * as actions from './actions'

// function* fetchFavourites() {
//   try {
//     const result: CallReturnType<typeof API.fetchFavourites> = yield API.fetchFavourites({
//       pageSize: DEFAULT_PAGE_SIZE,
//     })
//     yield put(actions.FETCH_FAVOURITES.success(result))
//   } catch (e) {
//     yield put(actions.FETCH_FAVOURITES.failure(e))
//   }
// }

// function* fetchMoreFavourites() {
//   try {
//     const { moreUrl }: ReturnType<typeof selectFavouriteListingResults> = yield select(
//       selectFavouriteListingResults,
//     )
//     const response: CallReturnType<typeof API.fetchFavourites> = yield API.fetchFavourites(moreUrl!)
//     yield put(actions.FETCH_MORE_FAVOURITES.success(response))
//   } catch (e) {
//     yield put(actions.FETCH_MORE_FAVOURITES.failure(e))
//   }
// }

function* createFavourite(action: ReturnType<typeof actions.CREATE_FAVOURITE.request>) {
  const data: INewFavourite = {
    product: {
      id: action.payload,
      type: ENTITY_TYPE_PRODUCT,
    },
  }

  try {
    const result: CallReturnType<typeof API.createFavourite> = yield API.createFavourite(data)
    yield put(actions.CREATE_FAVOURITE.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.CREATE_FAVOURITE.failure(error))
  }
}

function* deleteFavourite(action: ReturnType<typeof actions.DELETE_FAVOURITE.request>) {
  const { id } = action.payload
  try {
    yield API.deleteFavorite(id)
    yield put(actions.DELETE_FAVOURITE.success(action.payload))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.DELETE_FAVOURITE.failure(error))
  }
}

export function* saga() {
  yield all([
    // takeLeading(actions.FETCH_FAVOURITES.request, fetchFavourites),
    // takeLeading(actions.FETCH_FAVOURITES.failure, displayErrorToastSaga),
    // takeLeading(actions.FETCH_MORE_FAVOURITES.request, fetchMoreFavourites),
    // takeLeading(actions.FETCH_MORE_FAVOURITES.failure, displayErrorToastSaga),
    takeLeading(actions.CREATE_FAVOURITE.request, createFavourite),
    takeLeading(actions.CREATE_FAVOURITE.failure, displayErrorToastSaga),
    takeLeading(actions.DELETE_FAVOURITE.request, deleteFavourite),
    takeLeading(actions.DELETE_FAVOURITE.failure, displayErrorToastSaga),
  ])
}
