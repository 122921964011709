import { Box, BoxProps, Link, forwardRef } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { OmChevronRight } from './icons'
import { PageHeading, PageHeadingProps } from './page-heading'

interface IBreadcrumbItem {
  name: string
  to: string
}

// interface IBreadcrumbItem extends Omit<IBreadcrumbItemProps, 'isLast'> {}
export interface BreadcrumbItemProps extends BoxProps, IBreadcrumbItem {
  isLast: boolean
}

export const BreadcrumbItem = forwardRef<BreadcrumbItemProps, 'h1'>((props, ref) => {
  const { name, to, isLast, ...rest } = props
  return (
    <Box as="span" {...rest} display="flex" alignItems="center">
      {isLast ? (
        name
      ) : (
        <>
          <Link as={RouterLink} to={to}>
            {name}
          </Link>
          <OmChevronRight />
        </>
      )}
    </Box>
  )
})

export interface IBreadcrumbProps extends PageHeadingProps {
  items: IBreadcrumbItem[]
}

export const Breadcrumb = forwardRef<IBreadcrumbProps, 'h1'>((props, ref) => {
  const { items, ...rest } = props
  return (
    <PageHeading {...rest} display="flex" alignItems="center">
      {items.map((item, index) => (
        <BreadcrumbItem key={index} isLast={index === items.length - 1} {...item} />
      ))}
    </PageHeading>
  )
})

// function BreadcrumbItem({ name, to, isLast }: IBreadcrumbItemProps) {
//   return (
//     <Box as="span">
//       {isLast ? (
//         name
//       ) : (
//         <>
//           <Link as={RouterLink} to={to}>
//             {name}
//           </Link>
//           <Icon as={BiChevronRight} />
//         </>
//       )}
//     </Box>
//   )
// }

// export function Breadcrumb({ items }: IBreadcrumbProps) {
//   return (
//     <PageHeading>
//       {items.map((item, index) => (
//         <BreadcrumbItem key={index} isLast={index === items.length - 1} {...item} />
//       ))}
//     </PageHeading>
//   )
// }
