import {
  NavigateFunction,
  NavigateOptions,
  Path,
  To,
  useLocation,
  useNavigate,
} from 'react-router-dom'

// Create a global history object which can be used in the sagas
// https://stackoverflow.com/a/70002872

type History = {
  navigate: NavigateFunction | null
  push: NavigateFunction
  location: Path | null
}

// the global history object
export const history: History = {
  navigate: null,
  location: null,
  push: (to: To | number, options?: NavigateOptions) => {
    if (!history.navigate) {
      return
    }
    if (typeof to === 'number') {
      history.navigate(to)
      return
    }
    history.navigate(to, options)
  },
}

// a react component which must be rendered once in the app to set the global history object
export const NavigateSetter: React.FC = () => {
  history.navigate = useNavigate()
  history.location = useLocation()
  return null
}
