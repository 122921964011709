import { createAsyncAction } from 'typesafe-actions'
import { TFavouriteAny } from '../../api'

// export const FETCH_FAVOURITES = createAsyncAction(
//   'favourite/FETCH_FAVOURITES',
//   'favourite/FETCH_FAVOURITES_SUCCESS',
//   'favourite/FETCH_FAVOURITES_FAILURE',
// )<undefined, IFetchMultipleResponse<TFavouriteWithProduct, IFetchMultipleResponseMeta>, Error>()

// export const FETCH_MORE_FAVOURITES = createAsyncAction(
//   'order/FETCH_MORE_ORDERS',
//   'order/FETCH_MORE_ORDERS_SUCCESS',
//   'order/FETCH_MORE_ORDERS_FAILURE',
// )<
//   undefined,
//   IFetchMultipleResponse<TFavouriteWithProduct, IFetchMultipleResponseMeta>,
//   Error
// >()

export const CREATE_FAVOURITE = createAsyncAction(
  'favourite/CREATE_FAVOURITE',
  'favourite/CREATE_FAVOURITE_SUCCESS',
  'favourite/CREATE_FAVOURITE_FAILURE',
)<string, TFavouriteAny, Error>()

export interface IDeleteFavouriteItemPayload {
  id: string
  productId: string
}

export const DELETE_FAVOURITE = createAsyncAction(
  'favourite/DELETE_FAVOURITE',
  'favourite/DELETE_FAVOURITE_SUCCESS',
  'favourite/DELETE_FAVOURITE_FAILURE',
)<IDeleteFavouriteItemPayload, IDeleteFavouriteItemPayload, Error>()
