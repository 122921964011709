import { createSelector } from 'reselect'
import { memoize } from 'lodash'

import { IRootState } from '../../root'
import { IBasketDiscountFreeProductPromotionAny } from '../../../api'

export const selectDiscountsState = (state: IRootState) => state.order.discounts

const selectDiscountItems = createSelector(selectDiscountsState, (state) => state.items)

const selectDiscountDetail = createSelector(selectDiscountsState, (state) => state.detail)

export const discountDetailSelector = createSelector(
  selectDiscountItems,
  selectDiscountDetail,
  (items, detail) =>
    memoize((id: string) => {
      return {
        item: items && items[id],
        isFetching: detail && detail.isFetching,
        error: detail && detail.error,
      }
    }),
)

export const freeProductPromotionForProductSelector = createSelector(
  selectDiscountItems,
  selectDiscountDetail,
  (items, detail) =>
    memoize((basketId: string, productId: string): IBasketDiscountFreeProductPromotionAny | undefined => {
      const basketDiscount = items && items[basketId]
      if (!basketDiscount || !detail || detail.id !== basketId || detail.error || detail.isFetching) {
        return
      }

      return basketDiscount.freeProductPromotions.find(
        (promotion) => promotion.product.id === productId,
      )
    }),
)
