import React from 'react'
import {
  UseDisclosureProps,
  useDisclosure,
  usePopper,
  UsePopperProps,
  useOutsideClick,
  useIds,
  useId,
} from '@chakra-ui/react'
import { useClickable } from '@chakra-ui/clickable'
import { createDescendantContext } from '@chakra-ui/descendant'
import { createContext, mergeRefs } from '@chakra-ui/react-utils'
import { callAllHandlers, dataAttr, mergeWith } from '@chakra-ui/utils'

/* -------------------------------------------------------------------------------------------------
 * Create context to track descendants and their indices
 * -----------------------------------------------------------------------------------------------*/

export const [
  NavDescendantsProvider,
  useNavDescendantsContext,
  useNavDescendants,
  useNavDescendant,
] = createDescendantContext<HTMLElement>()

/* -------------------------------------------------------------------------------------------------
 * Create context to track nav state and logic
 * -----------------------------------------------------------------------------------------------*/

const [NavProvider, useNavContext] = createContext<UseNavReturn>({
  strict: false,
  name: 'NavContext',
})

export { NavProvider, useNavContext }

export interface UseNavProps extends UsePopperProps, UseDisclosureProps {
  /**
   * If `true`, the nav will close when a nav item is
   * clicked
   *
   * @default true
   */
  closeOnSelect?: boolean
  /**
   * If `true`, the nav will close when you click outside
   * the nav list
   *
   * @default true
   */
  closeOnBlur?: boolean
  /**
   * Performance 🚀:
   * If `true`, the NavItem rendering will be deferred
   * until the nav is open.
   */
  isLazy?: boolean
}

export function useNav(props: UseNavProps) {
  const { id, closeOnSelect = true, closeOnBlur = true, isLazy, placement = 'bottom-start' } = props

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure(props)

  /**
   * Prepare the reference to the nav and disclosure
   */
  const navRef = React.useRef<HTMLDivElement>(null)
  const linkRef = React.useRef<HTMLLinkElement>(null)

  useOutsideClick({
    ref: navRef,
    handler: (event) => {
      if (isOpen && closeOnBlur && !linkRef.current?.contains(event.target as HTMLElement)) {
        closeAndHideOverlay()
      }
    },
  })

  const openAndShowOverlay = React.useCallback(() => {
    onOpen()
    // if (menuRef.current) focus(menuRef.current)
  }, [onOpen])

  const closeAndHideOverlay = React.useCallback(() => {
    onClose()
    // if (menuRef.current) focus(menuRef.current)
  }, [onClose])

  /**
   * Add some popper.js for dynamic positioning
   */
  const popper = usePopper({
    placement,
    offset: [0, 0],
    ...props,
  })

  /**
   * Context to register all nav item nodes
   */
  const descendants = useNavDescendants()

  /**
   * Generate unique ids for nav's list and link
   */
  const [linkId, navId] = useIds(id, `nav-link`, `nav-list`)

  return {
    descendants,
    popper,
    linkId,
    navId,
    forceUpdate: popper.forceUpdate,
    orientation: 'vertical',
    isOpen,
    onToggle,
    onOpen: openAndShowOverlay,
    onClose: closeAndHideOverlay,
    navRef,
    linkRef,
    closeOnSelect,
    closeOnBlur,
    isLazy,
  }
}

export interface UseNavReturn extends ReturnType<typeof useNav> {}

/**
 * React Hook to manage a nav link.
 *
 * The assumption here is that the `useNav` hook is used
 * in a component higher up the tree, and its return value
 * is passed as `context` to this hook.
 */

export interface UseNavLinkProps extends Omit<React.HTMLAttributes<Element>, 'color'> {
  preventDefault?: boolean
}

export function useNavLink(props: UseNavLinkProps, externalRef: React.Ref<any> = null) {
  const nav = useNavContext()

  const { preventDefault = true, ...rest } = props
  const { isOpen, onOpen, onClose, popper } = nav

  const onClick = React.useCallback(
    (event: React.SyntheticEvent) => {
      if (isOpen) {
        onClose()
      } else {
        onOpen()
      }
      if (preventDefault) {
        event.preventDefault()
      }
    },
    [isOpen, onOpen, onClose, preventDefault],
  )

  const linkProps = {
    ...rest,
    id: nav.linkId,
    'data-active': dataAttr(nav.isOpen),
    'aria-expanded': nav.isOpen,
    'aria-haspopup': 'nav' as React.AriaAttributes['aria-haspopup'],
    'aria-controls': nav.navId,
    onClick: callAllHandlers(props.onClick, onClick),
  }

  return popper.getReferenceProps(linkProps, mergeRefs(nav.linkRef, externalRef))
}

/**
 * React Hook to manage a nav list.
 *
 * The assumption here is that the `useNav` hook is used
 * in a component higher up the tree, and its return value
 * is passed as `context` to this hook.
 */

export interface UseNavListProps extends Omit<React.HTMLAttributes<Element>, 'color'> {}

export function useNavList(props: UseNavListProps, ref: React.Ref<any> = null) {
  const nav = useNavContext()

  if (!nav) {
    throw new Error(
      `useNavList: context is undefined. Seems you forgot to wrap component within <Nav>`,
    )
  }

  const { navRef, isOpen, navId, popper, isLazy } = nav

  const navListProps = {
    ...props,
    ref: mergeRefs(navRef, ref),
    children: !isLazy || isOpen ? props.children : null,
    tabIndex: -1,
    role: 'nav',
    id: navId,
    style: {
      ...props.style,
      transformOrigin: popper.transformOrigin,
    },
    'aria-orientation': 'vertical' as React.AriaAttributes['aria-orientation'],
  }

  return navListProps
}

export function useNavPositioner(props: any = {}) {
  const { popper, isOpen } = useNavContext()
  return mergeWith(popper.getPopperProps(props), {
    style: {
      visibility: isOpen ? 'visible' : 'hidden',
      width: '100%',
    },
  })
}

export interface UseNavItemProps extends Omit<React.HTMLAttributes<Element>, 'color'> {
  isDisabled?: boolean
  isFocusable?: boolean
}

export function useNavItem(props: UseNavItemProps, externalRef: React.Ref<any> = null) {
  const {
    onMouseEnter: onMouseEnterProp,
    onMouseMove: onMouseMoveProp,
    onMouseLeave: onMouseLeaveProp,
    onClick: onClickProp,
    isDisabled,
    isFocusable,
    ...htmlProps
  } = props

  const nav = useNavContext()

  const { closeOnSelect, onClose } = nav

  const ref = React.useRef<HTMLDivElement>(null)
  const id = `nav-item-${useId()}`

  const onClick = React.useCallback(
    (event: React.MouseEvent) => {
      onClickProp?.(event)
      /**
       * Close nav if `closeOnSelect` is set to `true`
       */
      if (closeOnSelect) {
        onClose()
      }
    },
    [onClose, onClickProp, closeOnSelect],
  )

  const tabbable = useClickable({
    onClick,
    ref: mergeRefs(ref, externalRef),
    isDisabled,
    isFocusable,
  })

  return {
    ...htmlProps,
    ...tabbable,
    id,
    role: 'nav-item',
  }
}
