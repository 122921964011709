import { createSelector } from 'reselect'
import { memoize } from 'lodash'

import { IRootState } from '../root'

export const selectFavouriteState = (state: IRootState) => state.favourite

const selectFavouriteUpdateState = createSelector(selectFavouriteState, (state) => state.update)

export const selectFavouriteIsFetchingUpdate = createSelector(selectFavouriteUpdateState, (state) =>
  memoize((productId: string) => {
    return (state && state[productId] && state[productId].isFetching) ?? false
  }),
)
