import { isString } from 'lodash'

import { APIService } from '../service'
import {
  IJsonApiModelWithId,
  IFetchMultipleQueryOptions,
  IFetchMultipleResponse,
  IFetchOneQueryOptions,
} from '../types'

import {
  IFacet,
  IFetchMultiplePublicProductResponseMeta,
  IProductBase,
  IPublicProduct,
} from './public-product'

declare module '../service' {
  interface APIService {
    fetchProducts(
      url: string,
    ): Promise<IFetchMultipleResponse<IProduct, IFetchMultipleProductResponseMeta>>
    fetchProducts(
      requestOpts?: IFetchMultipleQueryOptions,
    ): Promise<IFetchMultipleResponse<IProduct, IFetchMultipleProductResponseMeta>>
    fetchProduct(id: string, requestOpts?: IFetchOneQueryOptions): Promise<IProduct>
  }
}

export const ENTITY_TYPE_PRODUCT = 'products'

export interface IFetchMultipleProductResponseMeta extends IFetchMultiplePublicProductResponseMeta {
  facets: IFacet[]
}

export interface IProduct extends IProductBase {
  type: 'products'
  isOnPromotion: boolean
  casePrice: string
  splitPrice: string
  baseSplitPrice: string
  baseCasePrice: string
  cutoffTime: string
  favourite: IJsonApiModelWithId | null
}

export type TProduct = IProduct | IPublicProduct

APIService.prototype.fetchProducts = async function (
  requestOptsOrUrl?: IFetchMultipleQueryOptions | string,
) {
  if (isString(requestOptsOrUrl)) {
    return this.fetchMultiple<IProduct, IFetchMultipleProductResponseMeta>(requestOptsOrUrl)
  }
  return this.fetchMultiple<IProduct, IFetchMultipleProductResponseMeta>(
    ENTITY_TYPE_PRODUCT,
    requestOptsOrUrl,
  )
}

APIService.prototype.fetchProduct = async function (
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  return this.fetchOne<IProduct>(ENTITY_TYPE_PRODUCT + '/' + id, requestOpts)
}
