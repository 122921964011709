import { ActionType, getType } from 'typesafe-actions'

import { IRequestDetail } from '../types'

import * as actions from './actions'

interface IState {
  update?: { [key: string]: IRequestDetail<Error> } // keyed by product id
}

const initialState: IState = {}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions>,
): IState => {
  switch (action.type) {
    case getType(actions.CREATE_FAVOURITE.request): {
      const productId = action.payload
      return {
        ...state,
        update: {
          ...state.update,
          [productId]: {
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.CREATE_FAVOURITE.success): {
      const item = action.payload
      const productId = item.product.id
      return {
        ...state,
        update: {
          ...state.update,
          [productId]: {
            isFetching: false,
          },
        },
      }
    }

    case getType(actions.DELETE_FAVOURITE.request): {
      const { productId } = action.payload
      return {
        ...state,
        update: {
          ...state.update,
          [productId]: {
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.DELETE_FAVOURITE.success): {
      const { productId } = action.payload
      const update = { ...state.update }
      delete update[productId]
      return {
        ...state,
        update,
      }
    }
  }

  return state
}
