import { createIcon } from '@chakra-ui/react'

export const OmAllergenFish = createIcon({
  displayName: 'Fish',
  viewBox: '0 0 62 36',
  path: (
    <g>
      <path
        fill="currentColor"
        d="M 11.204 19.47 C 9.745 19.47 8.558 18.357 8.558 16.988 C 8.558 15.619 9.745 14.508 11.204 14.508 C 12.665 14.508 13.851 15.619 13.851 16.988 C 13.851 18.357 12.665 19.47 11.204 19.47 Z M 17.635 4.918 C 7.104 9.286 0.198 18.574 0.198 18.574 C 0.254 18.634 4.857 23.944 9.718 22.727 C 9.976 22.663 9.972 23.355 9.834 23.569 C 9.536 24.03 8.819 24.041 8.26 24.041 C 5.298 24.041 2.159 21.686 0.561 20.287 C 2.823 22.939 8.937 29.462 16.886 33.006 C 22.819 23.523 18.562 7.987 17.635 4.918 L 17.635 4.918 Z"
      />
      <path
        fill="currentColor"
        d="M 51.794 15.516 C 49.734 13.388 46.767 10.655 43.175 8.273 C 42.441 7.787 42.094 6.439 38.957 4.128 C 34.023 0.495 33.759 0.835 29.188 0.624 C 28.855 0.608 27.625 2.864 27.288 2.864 C 24.317 2.864 21.454 3.483 18.77 4.475 C 19.679 7.368 24.255 23.344 18.007 33.475 C 20.392 34.429 22.925 35.099 25.559 35.307 C 26.13 35.353 26.706 35.377 27.288 35.377 C 28.172 35.377 29.047 35.322 29.91 35.22 C 31.343 35.05 32.745 34.746 34.105 34.34 C 42.371 31.873 49.153 25.579 52.577 21.906 C 52.577 21.906 58.691 30.447 60.906 29.444 C 63.309 28.357 60.196 23.194 59.923 17.967 C 59.662 12.968 62.329 7.897 60.727 6.907 C 58.608 5.598 51.794 15.516 51.794 15.516"
      />
    </g>
  ),
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 62 36"
//     id="vector">
//     <path
//         id="path"
//         d="M 11.204 19.47 C 9.745 19.47 8.558 18.357 8.558 16.988 C 8.558 15.619 9.745 14.508 11.204 14.508 C 12.665 14.508 13.851 15.619 13.851 16.988 C 13.851 18.357 12.665 19.47 11.204 19.47 Z M 17.635 4.918 C 7.104 9.286 0.198 18.574 0.198 18.574 C 0.254 18.634 4.857 23.944 9.718 22.727 C 9.976 22.663 9.972 23.355 9.834 23.569 C 9.536 24.03 8.819 24.041 8.26 24.041 C 5.298 24.041 2.159 21.686 0.561 20.287 C 2.823 22.939 8.937 29.462 16.886 33.006 C 22.819 23.523 18.562 7.987 17.635 4.918 L 17.635 4.918 Z"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_2"
//         d="M 51.794 15.516 C 49.734 13.388 46.767 10.655 43.175 8.273 C 42.441 7.787 42.094 6.439 38.957 4.128 C 34.023 0.495 33.759 0.835 29.188 0.624 C 28.855 0.608 27.625 2.864 27.288 2.864 C 24.317 2.864 21.454 3.483 18.77 4.475 C 19.679 7.368 24.255 23.344 18.007 33.475 C 20.392 34.429 22.925 35.099 25.559 35.307 C 26.13 35.353 26.706 35.377 27.288 35.377 C 28.172 35.377 29.047 35.322 29.91 35.22 C 31.343 35.05 32.745 34.746 34.105 34.34 C 42.371 31.873 49.153 25.579 52.577 21.906 C 52.577 21.906 58.691 30.447 60.906 29.444 C 63.309 28.357 60.196 23.194 59.923 17.967 C 59.662 12.968 62.329 7.897 60.727 6.907 C 58.608 5.598 51.794 15.516 51.794 15.516"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
