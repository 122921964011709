import { IJsonApiModel } from './types'

export type TPropertyTransformer<I, O> = (input: I) => O

export class SerializerTransformer {}

interface IEntityTransformer {
  [property: string]: TPropertyTransformer<any, any>
}

interface IDeserializeTransformRegistry {
  [entity: string]: IEntityTransformer
}

export class DeserializeTransformer {
  registry: IDeserializeTransformRegistry = {}

  register(entity: string, transformers: IEntityTransformer) {
    this.registry[entity] = transformers
  }

  transform(input: IJsonApiModel): IJsonApiModel {
    if (input.type in this.registry) {
      const dst = {
        type: input.type,
      }
      this.transformObject(input, dst, this.registry[input.type])
      return dst
    }
    return input
  }

  private transformObject(src: IJsonApiModel, dst: IJsonApiModel, transforms: IEntityTransformer) {
    Object.keys(src).forEach((propName) => {
      if (propName in transforms) {
        dst[propName] = transforms[propName](src[propName])
      } else {
        dst[propName] = src[propName]
      }
    })
  }
}
