import { Link as RouterLink } from 'react-router-dom'
import { forwardRef, Box, BoxProps, Heading, Text, Link } from '@chakra-ui/react'

export const Error404PageContent = forwardRef<BoxProps, 'div'>((props, ref) => {
  return (
    <Box ref={ref} textAlign="center" p={16} {...props}>
      <Heading as="h1" fontSize="5xl">
        Error 404: Page Not Found
      </Heading>
      <Text mt={8}>
        Return to the{' '}
        <Link as={RouterLink} to="/" color="coral.500">
          home page
        </Link>
        .
      </Text>
    </Box>
  )
})
