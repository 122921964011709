import { createIcon } from '@chakra-ui/react'

export const OmDietaryVegetarian = createIcon({
  displayName: 'Vegetarian',
  viewBox: '0 0 62 62',
  path: (
    <g>
      <path
        fill="currentColor"
        d="M 30.525 50.243 L 7.031 2.108 C 6.174 0.404 4.116 -0.278 2.401 0.489 C 0.686 1.341 0 3.386 0.772 5.09 L 27.353 59.614 C 27.953 60.807 29.153 61.574 30.439 61.574 C 31.726 61.574 33.012 60.807 33.526 59.614 L 30.525 50.243 Z"
      />
      <path
        fill="currentColor"
        d="M 31.475 50.243 L 54.969 2.108 C 55.826 0.404 57.884 -0.278 59.599 0.489 C 61.314 1.341 62 3.386 61.228 5.09 L 34.647 59.614 C 34.047 60.807 32.847 61.574 31.561 61.574 C 30.274 61.574 28.988 60.807 28.474 59.614 L 31.475 50.243 Z"
      />
    </g>
  ),
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 62 62"
//     id="vector">
//     <path
//         id="path"
//         d="M 30.525 50.243 L 7.031 2.108 C 6.174 0.404 4.116 -0.278 2.401 0.489 C 0.686 1.341 0 3.386 0.772 5.09 L 27.353 59.614 C 27.953 60.807 29.153 61.574 30.439 61.574 C 31.726 61.574 33.012 60.807 33.526 59.614 L 30.525 50.243 Z"
//         fill="#98bf0f"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_2"
//         d="M 31.475 50.243 L 54.969 2.108 C 55.826 0.404 57.884 -0.278 59.599 0.489 C 61.314 1.341 62 3.386 61.228 5.09 L 34.647 59.614 C 34.047 60.807 32.847 61.574 31.561 61.574 C 30.274 61.574 28.988 60.807 28.474 59.614 L 31.475 50.243 Z"
//         fill="#98bf0f"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
