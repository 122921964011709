import { Box } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'

import { useAccount, useUser, DEFAULT_PAGE_TITLE } from '../lib'
import { PageLayout } from '../components/page-layout'
import { ResponsiveContainer } from '../components/responsive-container'
import { PageHeading } from '../components/page-heading'
import { DefinitionList, DefinitionTerm, Definition } from '../components/order-box'

export const ACCOUNT_ROUTE = '/account'

export const AccountPage: React.FC = () => {
  const user = useUser()
  const account = useAccount()

  if (!account || !user) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          <PageHeading>Your Account</PageHeading>
          <Box borderWidth={1} borderRadius="sm" p={4}>
            <DefinitionList>
              <DefinitionTerm>User</DefinitionTerm>
              <Definition>
                {user.firstName} {user.lastName}
              </Definition>

              <DefinitionTerm>Company</DefinitionTerm>
              <Definition>{account.name}</Definition>

              <DefinitionTerm>Account Number</DefinitionTerm>
              <Definition>{account.code}</Definition>
            </DefinitionList>
          </Box>
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
