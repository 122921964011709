import { APIService } from '../service'
import { IJsonApiModel } from '../types'

declare module '../service' {
  interface APIService {
    createPasswordReset(data: INewPasswordReset): Promise<undefined>
  }
}

export const ENTITY_TYPE_PASSWORD_RESET = 'password-resets'

export interface INewPasswordReset extends Omit<IJsonApiModel, 'type'> {
  email: string
  verificationCode: string
  newPassword: string
  confirmPassword: string
}

APIService.prototype.createPasswordReset = async function (data: INewPasswordReset) {
  return this.create(ENTITY_TYPE_PASSWORD_RESET, {
    type: ENTITY_TYPE_PASSWORD_RESET,
    ...data,
  })
}
