import { Box, BoxProps, Button, forwardRef } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { ResponsiveContainer } from '../components/responsive-container'
import { PageLayout } from '../components/page-layout'
import { PageHeading, PageHeadingProps } from '../components/page-heading'
import { TabList, TabButton } from '../components/tab-list'
import { useOrdersListing, DEFAULT_PAGE_TITLE } from '../lib'
import { BASKETS_ROUTE } from './baskets'
import { OrderBox } from '../components/order-box'
import { BasketFormModalButton } from '../components/basket-form'

export const ORDERS_ROUTE = '/orders/confirmed'

const OrdersPageContent = forwardRef<BoxProps, 'div'>((props, ref) => {
  const { items, hasMore, onLoadMore } = useOrdersListing()
  return (
    <Box ref={ref} {...props}>
      <TabList>
        <TabButton as={RouterLink} to={BASKETS_ROUTE}>
          Unconfirmed
        </TabButton>
        <TabButton as={RouterLink} to={ORDERS_ROUTE} isActive={true}>
          Confirmed
        </TabButton>
      </TabList>
      {!items.length && (
        <Box textAlign="center" p={16}>
          You have no confirmed orders.{' '}
          <BasketFormModalButton variant="link">Create a new order</BasketFormModalButton>.
        </Box>
      )}
      {items.map((o) => (
        <OrderBox key={o.id} order={o} mt={4} />
      ))}
      {hasMore && (
        <Box my={10} display="flex" justifyContent="center">
          <Button size="lg" onClick={onLoadMore}>
            Load more
          </Button>
        </Box>
      )}
    </Box>
  )
})

const OrdersPageHeader = forwardRef<PageHeadingProps, 'div'>((props, ref) => {
  return (
    <PageHeading ref={ref} {...props} as="div">
      <Box display="flex" justifyContent="space-between">
        <Box as="h1" mr={4}>
          Confirmed Orders
        </Box>
        <BasketFormModalButton />
      </Box>
    </PageHeading>
  )
})

export const OrdersPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          <OrdersPageHeader />
          <OrdersPageContent />
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
