import { useRef } from 'react'
import {
  Box,
  Button,
  Flex,
  Image,
  IconButton,
  Stack,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react'
import { BiMenu } from 'react-icons/bi'
import Headroom from 'react-headroom'
import { Link as RouterLink } from 'react-router-dom'

import { HOME_ROUTE, LOGIN_ROUTE } from '../../pages'
import { ResponsiveContainer } from '../responsive-container'
import { DesktopPrimaryNav, MobilePrimaryNav } from '../primary-nav'
import { useUser, useHasAuth } from '../../lib'
import { SearchFormContainer, SearchForm } from './search-form'
import { ContentOverlay } from '../content-overlay'
import { AccountMenu } from './account-menu'
import { BasketButton } from './basket-button'

export function Header() {
  const { isOpen: isDrawOpen, onOpen: onOpenDraw, onClose: onCloseDraw } = useDisclosure()
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })
  const mobileMenuBtnRef = useRef<HTMLButtonElement>(null)
  const { onRedirectToLogin } = useHasAuth()
  const user = useUser()

  const loggedOutButtons = (
    <>
      <Button
        href="https://www.birchallfoodservice.co.uk/ordermate-sign-up"
        as="a"
        variant="outline"
      >
        Register
      </Button>
      <Button as={RouterLink} to={LOGIN_ROUTE} ml={4} onClick={() => onRedirectToLogin()}>
        Sign In
      </Button>
    </>
  )

  return (
    <ContentOverlay>
      <Box zIndex="docked" position="relative">
        <Headroom>
          {!user && (
            <Box px={4} py={[3, 4]} bg="gray.100" display={['block', 'none']}>
              <Stack
                spacing={4}
                direction="row"
                align="center"
                display="flex"
                justifyContent="center"
              >
                {loggedOutButtons}
              </Stack>
            </Box>
          )}
          <Box bg="white">
            <SearchFormContainer>
              <ResponsiveContainer
                py={[3, 4]}
                borderBottom={['1px', null, 0]}
                borderColor="gray.200"
              >
                <Flex justify="space-between" align="center">
                  <RouterLink to={HOME_ROUTE}>
                    <Image
                      src="/images/logo-splash.png"
                      alt="Birchall OrderMate"
                      h={['44px', '64px']}
                    />
                  </RouterLink>
                  <SearchForm display={['none', null, 'flex']} flexGrow={1} maxWidth="50%" mx={4} />
                  <Stack spacing={4} direction="row" align="center">
                    {!user && <Box display={['none', 'block']}>{loggedOutButtons}</Box>}
                    {user && <BasketButton />}
                    <IconButton
                      ref={mobileMenuBtnRef}
                      onClick={onOpenDraw}
                      display={['inline-flex', null, 'none']}
                      tabIndex={1}
                      aria-label="Menu"
                      icon={<BiMenu />}
                    />
                    {user && !isMobile && <AccountMenu />}
                  </Stack>
                </Flex>
                <SearchForm mt={[3, 4]} display={['block', null, 'none']} />
              </ResponsiveContainer>
            </SearchFormContainer>
          </Box>

          {!isMobile && <DesktopPrimaryNav display={['none', null, 'block']} />}
          {isMobile && (
            <MobilePrimaryNav
              isOpen={isDrawOpen}
              onClose={onCloseDraw}
              size={'sm'}
              finalFocusRef={mobileMenuBtnRef}
            />
          )}
        </Headroom>
      </Box>
    </ContentOverlay>
  )
}
