import React, { PropsWithChildren } from 'react'

import { Header } from './header'
import { Footer } from './footer'
import { OrderConfirmationSpinner } from './order-confirmation-spinner'
import { OrderPickerModal } from '../order-picker-modal'
import { MenuPickerModal } from '../menu-picker-modal'
import { DemoBar } from './demo-bar'

export const PageLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <DemoBar />
      <Header />
      {children}
      <Footer />
      <OrderConfirmationSpinner />
      <OrderPickerModal />
      <MenuPickerModal />
    </>
  )
}
