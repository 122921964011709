import {
  Box,
  BoxProps,
  ButtonProps,
  chakra,
  forwardRef,
  omitThemingProps,
  SystemStyleObject,
} from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'

export const TabList = forwardRef<BoxProps, 'div'>((props, ref) => {
  return <Box ref={ref} borderBottomWidth={2} {...props} />
})

export interface ITabButtonProps extends ButtonProps {
  isActive?: boolean
}

export const TabButton = forwardRef<ITabButtonProps, 'button'>((props, ref) => {
  const { isActive = false, ...rest } = omitThemingProps(props)

  const styles: SystemStyleObject = {
    color: 'coral.500',
    fontFamily: 'heading',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '1.2rem',
    height: '2.5rem',
    px: 4,
    borderBottomWidth: 2,
    mb: '-2px',
    borderColor: isActive ? 'coral.500' : undefined,
  }

  return (
    <chakra.button
      ref={ref}
      {...rest}
      className={cx('om--tab-button', props.className)}
      __css={styles}
    />
  )
})
