import useSWR from 'swr'
import { getUnixTime } from 'date-fns'

const LOCAL_STORAGE_KEY = 'om:ui-version'

interface IAppInfo {
  version: string
}

const fetcher = async (url: string) => {
  const res = await fetch(`${url}?t=${getUnixTime(new Date())}`)

  return res.json()
}

export function useUiVersion() {
  const url = '/app-info.json'

  const { data } = useSWR<IAppInfo, Error>(url, fetcher)
  let latestVersion = undefined
  let shouldRefresh = false

  if (data) {
    // get the current stored version
    latestVersion = data.version
    const stored = window.localStorage.getItem(LOCAL_STORAGE_KEY)
    if (!stored) {
      // store the new version
      window.localStorage.setItem(LOCAL_STORAGE_KEY, latestVersion)
    } else if (stored && latestVersion !== stored) {
      // store the new version
      window.localStorage.setItem(LOCAL_STORAGE_KEY, latestVersion)

      // check that it is stored correctly and refresh if it has
      if (window.localStorage.getItem(LOCAL_STORAGE_KEY) === latestVersion) {
        shouldRefresh = true
      }
    }
  }

  return {
    shouldRefresh,
    latestVersion,
  }
}

export const UiVersionChecker = () => {
  const { shouldRefresh } = useUiVersion()
  if (shouldRefresh) {
    window.location.reload()
  }
  return null
}
