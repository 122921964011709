import { useMemo, useEffect } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Heading, Text, Button } from '@chakra-ui/react'

import {
  CLEAR_PASSWORD_RESET,
  selectPasswordReset,
  selectPasswordResetRequest,
} from '../../stores/account'
import { LOGIN_ROUTE } from '../../pages'
import { PasswordResetRequestForm } from './password-reset-request-form'
import { PasswordResetForm } from './password-reset-form'

export const PasswordResetFormSelector = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    // clear the password reset data on mount
    dispatch(CLEAR_PASSWORD_RESET())
  }, [dispatch])

  const { email: storeEmail } = useSelector(selectPasswordResetRequest)
  const { isSuccess } = useSelector(selectPasswordReset)
  const qsEmail = useMemo(() => {
    const params = queryString.parse(location.search)
    const qsEmail = (Array.isArray(params.email) ? params.email[0] : params.email) || ''
    return qsEmail
  }, [location])
  const email = storeEmail || qsEmail

  if (email && isSuccess) {
    // complete
    return (
      <>
        <Heading as="h1" size="lg" my={8}>
          Reset Password Complete
        </Heading>

        <Text mb={8} fontWeight="light">
          You can now login with your new password.
        </Text>

        <Box>
          <Button type="submit" w="100%" as={RouterLink} to={LOGIN_ROUTE}>
            Login
          </Button>
        </Box>
      </>
    )
  }
  if (email) {
    return (
      <>
        <Heading as="h1" size="lg" my={8}>
          Reset Password
        </Heading>
        <PasswordResetForm email={email} />
      </>
    )
  }
  return (
    <>
      <Heading as="h1" size="lg" my={8}>
        Reset Password
      </Heading>
      <PasswordResetRequestForm />
    </>
  )
}
