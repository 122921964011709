import { createSelector } from 'reselect'
import { memoize } from 'lodash'

import { IRootState } from '../root'

export const selectMenuState = (state: IRootState) => state.menu

const selectMenus = createSelector(selectMenuState, (state) => state.items)

const selectMenuListing = createSelector(selectMenuState, (state) => state.listing)

const selectMenuDetail = createSelector(selectMenuState, (state) => state.detail)

export const selectMenuListingResults = createSelector(
  selectMenus,
  selectMenuListing,
  (items, listing) => {
    return {
      ...listing,
      items: items && listing && listing.ids ? listing.ids.map((id) => items[id]) : [],
      ids: (listing && listing.ids) ?? [],
    }
  },
)

export const menuDetailSelector = createSelector(selectMenus, selectMenuDetail, (items, detail) =>
  memoize((id: string) => {
    return {
      item: items && items[id],
      isFetching: detail && detail.isFetching,
      error: detail && detail.error,
    }
  }),
)

const selectMenuDeleteState = createSelector(selectMenuState, (state) => state.delete)

export const selectMenuDelete = createSelector(selectMenuDeleteState, (state) => {
  return {
    ...state,
    isFetching: state && state.isFetching,
    error: state && state.error,
  }
})

const selectMenuCreateState = createSelector(selectMenuState, (state) => state.create)

export const selectMenuCreate = createSelector(selectMenuCreateState, (state) => {
  return {
    ...state,
    isFetching: state && state.isFetching,
    error: state && state.error,
  }
})

const selectMenuUpdateState = createSelector(selectMenuState, (state) => state.update)

export const selectMenuUpdate = createSelector(selectMenuUpdateState, (state) => {
  return {
    ...state,
    isFetching: state && state.isFetching,
    error: state && state.error,
  }
})

export const selectMenuSelectedState = createSelector(selectMenuState, (state) => state.selected)

export const selectMenuSelectedMenu = createSelector(
  selectMenuSelectedState,
  selectMenus,
  (selected, items) => {
    if (!selected || !items) {
      return
    }
    return {
      menu: items[selected],
    }
  },
)
