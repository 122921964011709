import { ActionType, getType } from 'typesafe-actions'
import { zipObject } from 'lodash'

import { IAllRequest, IRequestDetail } from '../../types'
import { TBasketWithAddressAndItems } from '../../../api'
import { OPEN_ORDER_FOR_EDITING } from '..'

import * as actions from './actions'
import { calculateItemsTotal } from './utils'

interface IState {
  items?: { [key: string]: TBasketWithAddressAndItems }
  listing?: IAllRequest<TBasketWithAddressAndItems>
  listingForAddressAndDate?: IAllRequest<TBasketWithAddressAndItems>
  detail?: IRequestDetail<Error>
  delete?: IRequestDetail<Error>
  create?: IRequestDetail<Error>
  update?: IRequestDetail<Error>
  confirm?: IRequestDetail<Error>
  updateItems?: { [key: string]: IRequestDetail<Error> } // keyed by item id
  createItems?: { [key: string]: IRequestDetail<Error> } // keyed by product id
  selected?: string
}

const initialState: IState = {}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions | typeof OPEN_ORDER_FOR_EDITING>,
): IState => {
  switch (action.type) {
    case getType(actions.FETCH_BASKETS.request): {
      const { listing } = state
      return {
        ...state,
        listing: {
          ...listing,
          isFetching: true,
        },
      }
    }

    // case getType(actions.LOAD_BASKET.success): {
    //   return {
    //     ...state,
    //     selected: action.payload,
    //   }
    // }

    case getType(actions.FETCH_BASKETS.success): {
      const data = action.payload
      const ids = data.map((p) => p.id)
      const items = zipObject(ids, data)
      const { listing } = state
      return {
        ...state,
        items: { ...(state.items || {}), ...items },
        listing: {
          ...listing,
          isFetching: false,
          ids: [...ids],
          totalCount: data.length,
        },
      }
    }

    case getType(actions.FETCH_BASKETS_FOR_ADDRESS_AND_DATE.request): {
      const { listingForAddressAndDate } = state
      return {
        ...state,
        listingForAddressAndDate: {
          ...listingForAddressAndDate,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_BASKETS_FOR_ADDRESS_AND_DATE.success): {
      const data = action.payload
      const ids = data.map((p) => p.id)
      const items = zipObject(ids, data)
      const { listingForAddressAndDate } = state
      return {
        ...state,
        items: { ...(state.items || {}), ...items },
        listingForAddressAndDate: {
          ...listingForAddressAndDate,
          isFetching: false,
          ids: [...ids],
          totalCount: data.length,
        },
      }
    }

    case getType(actions.FETCH_BASKET.request): {
      return {
        ...state,
        detail: {
          id: action.payload,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_BASKET.success): {
      const item = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
        detail: {
          id: item.id,
          isFetching: false,
        },
      }
    }

    case getType(actions.DELETE_BASKET.request): {
      return {
        ...state,
        delete: {
          id: action.payload,
          isFetching: true,
        },
      }
    }

    case getType(actions.DELETE_BASKET.success): {
      const id = action.payload
      const { listing } = state
      const ids = listing?.ids?.filter((i) => i !== id)
      const items = { ...state.items }
      delete items[id]
      return {
        ...state,
        items,
        listing: {
          ...listing,
          ids,
        },
        delete: {
          ...state.delete,
          error: undefined,
          isFetching: false,
        },
      }
    }

    case getType(actions.DELETE_BASKET.failure): {
      return {
        ...state,
        delete: {
          ...state.delete,
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.CREATE_BASKET.request): {
      return {
        ...state,
        create: {
          isFetching: true,
        },
      }
    }

    case getType(actions.CREATE_BASKET.success): {
      const item = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
        create: {
          id: item.id,
          isFetching: false,
        },
      }
    }

    case getType(actions.CREATE_BASKET.failure): {
      return {
        ...state,
        create: {
          ...state.create,
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.COPY_MENU_TO_BASKET.request):
    case getType(actions.COPY_ORDER_TO_BASKET.request):
    case getType(actions.UPDATE_BASKET.request): {
      const id =
        action.type === getType(actions.UPDATE_BASKET.request)
          ? action.payload.id
          : action.payload.basketId
      return {
        ...state,
        update: {
          id,
          isFetching: true,
        },
      }
    }

    case getType(actions.COPY_MENU_TO_BASKET.success):
    case getType(actions.COPY_ORDER_TO_BASKET.success):
    case getType(actions.UPDATE_BASKET.success): {
      const item = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
        update: {
          id: item.id,
          isFetching: false,
        },
      }
    }

    case getType(actions.UPDATE_BASKET.failure): {
      return {
        ...state,
        update: {
          ...state.update,
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.DELETE_BASKET_ITEM.request):
    case getType(actions.UPDATE_BASKET_ITEM.request): {
      const { id } = action.payload
      return {
        ...state,
        updateItems: {
          ...state.updateItems,
          [id]: {
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.CREATE_BASKET_ITEM.request): {
      const { productId } = action.payload
      return {
        ...state,
        createItems: {
          ...state.createItems,
          [productId]: {
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.CREATE_BASKET_ITEM.success):
    case getType(actions.UPDATE_BASKET_ITEM.success): {
      const isCreate = action.type === getType(actions.CREATE_BASKET_ITEM.success)
      const basketItem = action.payload
      const items = { ...state.items }
      const basket = items[basketItem.basket.id]
      if (basket) {
        // create the updated basket
        const updatedItems = basket.items.map((item) => {
          if (item.id === basketItem.id) {
            return {
              ...basketItem,
            }
          }
          return {
            ...item,
          }
        })
        if (isCreate) {
          updatedItems.push(basketItem)
        }
        const updatedBasket = {
          ...basket,
          items: updatedItems,
        }
        // recalculate the total
        updatedBasket.netTotal = calculateItemsTotal(updatedBasket.items)
        items[updatedBasket.id] = updatedBasket
      }
      const updateItems = { ...state.updateItems }
      delete updateItems[basketItem.id]

      const extra = !isCreate
        ? undefined
        : {
            createItems: {
              ...state.createItems,
              [basketItem.productCode]: {
                id: basketItem.id,
                isFetching: false,
              },
            },
          }
      return {
        ...state,
        items: {
          ...items,
        },
        updateItems,
        ...extra,
      }
    }

    case getType(actions.DELETE_BASKET_ITEM.failure):
    case getType(actions.UPDATE_BASKET_ITEM.failure): {
      const { id, error } = action.payload
      return {
        ...state,
        updateItems: {
          ...state.updateItems,
          [id]: {
            isFetching: false,
            error,
          },
        },
      }
    }

    case getType(actions.DELETE_BASKET_ITEM.success): {
      const { id: basketItemId, basketId } = action.payload
      const items = { ...state.items }
      const basket = items[basketId]
      if (basket) {
        // create the updated basket
        const updatedBasket = {
          ...basket,
          items: basket.items.filter((i) => i.id !== basketItemId),
        }
        // recalculate the total
        updatedBasket.netTotal = calculateItemsTotal(updatedBasket.items)
        items[updatedBasket.id] = updatedBasket
      }
      const updateItems = { ...state.updateItems }
      delete updateItems[basketItemId]
      return {
        ...state,
        items: {
          ...items,
        },
        updateItems,
      }
    }

    case getType(actions.CREATE_BASKET_ITEM.failure): {
      const { productId, error } = action.payload
      return {
        ...state,
        createItems: {
          ...state.createItems,
          [productId]: {
            isFetching: false,
            error,
          },
        },
      }
    }

    case getType(actions.CREATE_BASKET_ITEM.cancel): {
      const productId = action.payload
      const createItems = {
        ...state.createItems,
      }
      delete createItems[productId]
      return {
        ...state,
        ...createItems,
      }
    }

    case getType(actions.SELECT_BASKET.success): {
      return {
        ...state,
        selected: action.payload,
      }
    }

    case getType(actions.UNSELECT_BASKET): {
      const s = {
        ...state,
      }
      delete s.selected
      return s
    }

    case getType(OPEN_ORDER_FOR_EDITING.success): {
      const { basket } = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [basket.id]: basket,
        },
      }
    }

    case getType(actions.CONFIRM_BASKET.request): {
      return {
        ...state,
        confirm: {
          id: action.payload,
          isFetching: true,
        },
      }
    }

    case getType(actions.CONFIRM_BASKET.success): {
      const { basketId: id } = action.payload
      const { listing } = state
      const ids = listing?.ids?.filter((i) => i !== id)
      const items = { ...state.items }
      delete items[id]
      return {
        ...state,
        items,
        listing: {
          ...listing,
          ids,
        },
        confirm: {
          ...state.confirm,
          error: undefined,
          isFetching: false,
        },
      }
    }

    case getType(actions.CONFIRM_BASKET.failure): {
      const { error } = action.payload
      return {
        ...state,
        confirm: {
          ...state.confirm,
          error,
          isFetching: false,
        },
      }
    }
  }

  return state
}
