import { memoize, find, sortBy } from 'lodash'
import { createSelector } from 'reselect'

import { TCategoryAny } from '../../../api'
import { IRootState } from '../../root'

export const selectCategoriesState = (state: IRootState) => state.catalog.categories

export const selectCategoriesIsFetching = createSelector(
  selectCategoriesState,
  (state) => state.isFetching,
)

export const selectCategories = createSelector(selectCategoriesState, (state) => state.items)

export const selectHasRoots = createSelector(selectCategoriesState, (state) => state.hasRoots)

export const selectRootCategories = createSelector(
  selectHasRoots,
  selectCategories,
  (hasRoots, categories) =>
    hasRoots ? sortBy(categories?.filter((c) => c.level === 0), 'position') : [],
)

export const categoryChildrenSelector = createSelector(selectCategories, (categories) =>
  memoize((parent?: TCategoryAny) =>
    parent ? sortBy(categories?.filter((c) => c.parent?.id === parent.id), 'position') : [],
  ),
)

export const categoryAncestorsSelector = createSelector(selectCategories, (categories) =>
  memoize((category?: TCategoryAny) => {
    if (!category) {
      return []
    }
    const ancestorIds = category.ancestors.map((a) => a.id)
    return sortBy(categories?.filter((c) => ancestorIds.includes(c.id)), 'level')
  }),
)

export const categorySelector = createSelector(selectCategories, (categories) =>
  memoize((id?: string) => find(categories, ['id', id])),
)
