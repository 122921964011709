import { find } from 'lodash'
import {
  As,
  Box,
  BoxProps,
  SimpleGrid,
  SimpleGridProps,
  forwardRef,
  Heading,
  HeadingProps,
  Stack,
  Badge,
  createIcon,
} from '@chakra-ui/react'

import { AllergenIconBadge } from '../allergen-icon-badge'
import {
  OmAllergenCelery,
  OmAllergenCereal,
  OmAllergenCoeliac,
  OmAllergenCrustacea,
  OmAllergenEggs,
  OmAllergenFish,
  OmAllergenLactose,
  OmAllergenLupin,
  OmAllergenMilk,
  OmAllergenMolluscs,
  OmAllergenMustard,
  OmAllergenNuts,
  OmAllergenPeanuts,
  OmAllergenSesame,
  OmAllergenSoya,
  OmAllergenSulphurDioxide,
  OmDietaryHalal,
  OmDietaryKosher,
  OmDietaryVegan,
  OmDietaryVegetarian,
} from '../icons'

const COLOR_SUITABLE = 'green.500'
const COLOR_CONTAINS = 'coral.500'
const COLOR_MAY_CONTAIN = '#E99F3C'
const COLOR_UNKNOWN = 'gray.300'
const COLOR_UNKNOWN_TEXT = 'gray.500'

export const NotIcon = createIcon({
  displayName: 'NotIcon',
  viewBox: '0 0 246 246',
  d: 'M8.486,0 L246,237.515 L246,246 L237.515,246 L0,8.486 L0,0 L8.486,0 Z',
})

export interface IAllergen {
  field: string
  label: string
  useSuitable?: boolean
  useApproved?: boolean
  addX?: boolean
  icon: As
}

// ordered by their display position
export const ALLERGENS: IAllergen[] = [
  {
    field: 'vegetarian_suitable',
    label: 'a vegetarian diet',
    icon: OmDietaryVegetarian,
    useSuitable: true,
  },
  { field: 'vegan_suitable', label: 'a vegan diet', icon: OmDietaryVegan, useSuitable: true },
  { field: 'kosher_approved', label: 'kosher', icon: OmDietaryKosher, useApproved: true },
  { field: 'halal_approved', label: 'halal', icon: OmDietaryHalal, useApproved: true },
  {
    field: 'coeliac_suitable',
    label: 'coeliacs',
    icon: OmAllergenCoeliac,
    useSuitable: true,
    addX: true,
  },
  {
    field: 'lactose_intolerance_suitable',
    label: 'sufferers of lactose intolerance',
    icon: OmAllergenLactose,
    useSuitable: true,
    addX: true,
  },

  { field: 'contains_cereal', label: 'cereal / gluten', icon: OmAllergenCereal },
  { field: 'contains_milk', label: 'milk', icon: OmAllergenMilk },
  { field: 'contains_eggs', label: 'eggs', icon: OmAllergenEggs },
  { field: 'contains_peanuts', label: 'peanuts', icon: OmAllergenPeanuts },
  { field: 'contains_tree_nuts', label: 'nuts', icon: OmAllergenNuts },
  { field: 'contains_crustacea', label: 'crustaceans', icon: OmAllergenCrustacea },
  { field: 'contains_mustard', label: 'mustard', icon: OmAllergenMustard },
  { field: 'contains_fish', label: 'fish', icon: OmAllergenFish },
  { field: 'contains_lupin', label: 'lupin', icon: OmAllergenLupin },
  { field: 'contains_sesame', label: 'sesame', icon: OmAllergenSesame },
  { field: 'contains_celery', label: 'celery', icon: OmAllergenCelery },
  { field: 'contains_soya', label: 'soya', icon: OmAllergenSoya },
  { field: 'contains_molluscs', label: 'molluscs', icon: OmAllergenMolluscs },
  { field: 'contains_sulphur_dioxide', label: 'sulphur dioxide', icon: OmAllergenSulphurDioxide },
]

export interface IAllergenData {
  label: string
  field: string
  value: string
}

export interface IAllergenBoxProps extends BoxProps {
  allergenData: IAllergenData
}

export const AllergenBox = forwardRef<IAllergenBoxProps, 'div'>((props, ref) => {
  const { allergenData, ...rest } = props
  const allergen = find(ALLERGENS, ['field', allergenData.field])
  if (!allergen) {
    return null
  }

  let color = ''
  let copy = ''
  if (allergen.useSuitable) {
    if (allergenData.value === 'yes') {
      color = COLOR_SUITABLE
      copy = `Suitable for ${allergen.label}`
    } else {
      copy = `May not be suitable for ${allergen.label}`
    }
  } else if (allergen.useApproved) {
    if (allergenData.value === 'yes') {
      color = COLOR_SUITABLE
      copy = `Approved for a ${allergen.label} diet`
    } else {
      copy = `May not be approved for a ${allergen.label} diet`
    }
  } else {
    if (allergenData.value === 'yes') {
      color = COLOR_CONTAINS
      copy = `Contains ${allergen.label}`
    } else if (allergenData.value === 'no') {
      copy = `Does not contain ${allergen.label}`
    } else {
      color = COLOR_MAY_CONTAIN
      copy = `May contain ${allergen.label}`
    }
  }

  return (
    <Box ref={ref} {...rest} display="flex" flexDir="column" alignItems="center" textAlign="center">
      <AllergenIconBadge
        key={allergen.field}
        icon={allergen.icon}
        overlayIcon={allergen.addX ? NotIcon : undefined}
        size="full"
        color={color || COLOR_UNKNOWN}
      />
      <Box as="span" mt={1} color={color || COLOR_UNKNOWN_TEXT}>
        {copy}
      </Box>
    </Box>
  )
})

export interface IAllergenDataGridProps extends SimpleGridProps {
  allergenData: IAllergenData[]
}

export const DietaryDataTable = forwardRef<IAllergenDataGridProps, 'div'>((props, ref) => {
  const { allergenData, ...rest } = props
  const dietaryKeys = ALLERGENS.filter((a) => a.useApproved || a.useSuitable).map((a) => a.field)
  const filteredData: IAllergenData[] = []
  allergenData.forEach((a) => {
    if (dietaryKeys.includes(a.field)) {
      filteredData.push(a)
    }
  })

  return (
    filteredData && (
      <SimpleGrid ref={ref} {...rest}>
        {filteredData.map((allergen) => (
          <AllergenBox key={allergen.field} allergenData={allergen} />
        ))}
      </SimpleGrid>
    )
  )
})

export const AllergensDataTable = forwardRef<IAllergenDataGridProps, 'div'>((props, ref) => {
  const { allergenData, ...rest } = props

  return (
    <SimpleGrid ref={ref} {...rest}>
      {allergenData.map((allergen) => (
        <AllergenBox key={allergen.field} allergenData={allergen} />
      ))}
    </SimpleGrid>
  )
})

export interface IProductAllergensProps extends BoxProps {
  allergenData: IAllergenData[]
}

export const ProductAllergens = forwardRef<IProductAllergensProps, 'div'>((props, ref) => {
  const { allergenData, ...rest } = props
  const dietary: IAllergenData[] = []
  const allergens: IAllergenData[] = []
  ALLERGENS.forEach((a) => {
    allergenData.forEach((ad) => {
      if (a.field !== ad.field) {
        return
      }
      if (a.useApproved || a.useSuitable) {
        dietary.push(ad)
      } else {
        allergens.push(ad)
      }
    })
  })

  if (!dietary.length || !allergens.length) {
    return null
  }

  const headingProps: HeadingProps = {
    fontFamily: 'body',
    as: 'h5',
    fontSize: 'lg',
  }

  return (
    <Box {...rest}>
      {dietary.length && (
        <>
          <Heading {...headingProps}>Dietary Information</Heading>
          <Stack direction="row" alignItems="baseline" mt={3} mb={4}>
            <Box as="span" fontSize="xs" fontWeight="bold" color={COLOR_UNKNOWN_TEXT}>
              Key:{' '}
            </Box>
            <Badge bg={COLOR_SUITABLE} color="white" py={1} px={2}>
              Suitable for
            </Badge>
          </Stack>
          <AllergensDataTable
            allergenData={dietary}
            columns={[3, 4, 6, 4, 5]}
            spacingX={4}
            spacingY={8}
            fontSize={['md', null, null, 'sm']}
            mb={8}
          />
        </>
      )}
      {allergens.length && (
        <>
          <Heading {...headingProps}>Allergen Information</Heading>
          <Stack direction="row" alignItems="baseline" mt={3} mb={4}>
            <Box as="span" fontSize="xs" fontWeight="bold" color={COLOR_UNKNOWN_TEXT}>
              Key:{' '}
            </Box>
            <Badge bg={COLOR_CONTAINS} color="white" py={1} px={2}>
              Contains
            </Badge>
            <Badge bg={COLOR_MAY_CONTAIN} color="white" py={1} px={2}>
              May contain
            </Badge>
          </Stack>
          <AllergensDataTable
            allergenData={allergens}
            columns={[3, 4, 6, 4, 5]}
            spacingX={4}
            spacingY={8}
            fontSize={['md', null, null, 'sm']}
            mb={8}
          />
        </>
      )}
    </Box>
  )
})
