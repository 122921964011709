import { Helmet } from 'react-helmet-async'
import { Navigate, useParams } from 'react-router-dom'

import { Breadcrumb } from '../components/breadcrumb'
import { OrderPageContents } from '../components/order-page-contents'
import { PageLayout } from '../components/page-layout'
import { ResponsiveContainer } from '../components/responsive-container'

import { DEFAULT_PAGE_TITLE, basketName, basketPath, useBasketDetail } from '../lib'
import { BASKETS_ROUTE } from './baskets'

export const BASKET_ROUTE = '/orders/unconfirmed/:basketId'

export interface IBasketRoute {
  basketId: string
}

export const BasketPage: React.FC = () => {
  const { basketId = '' } = useParams<keyof IBasketRoute>()
  const { item, isCancelled } = useBasketDetail(basketId)

  const breadcrumbs = [
    {
      name: 'Unconfirmed Orders',
      to: BASKETS_ROUTE,
    },
    {
      name: basketName(item),
      to: item ? basketPath(item) : '#',
    },
  ]

  if (isCancelled) {
    return <Navigate to={BASKETS_ROUTE} />
  }

  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          <Breadcrumb items={breadcrumbs} as="h2" />
          {item && <OrderPageContents order={item} />}
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
