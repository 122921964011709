export interface IFacet {
  label: string
  field: string
  value: string
  type: 'allergen' | 'promotion'
}

export interface IAvailableFacet extends IFacet {
  count: number
  isSelected: boolean
}

export const FACETS: IFacet[] = [
  { type: 'allergen', label: 'Halal', field: 'is_halal', value: 'true' },
  { type: 'allergen', label: 'Kosher', field: 'is_kosher', value: 'true' },
  { type: 'allergen', label: 'Vegan', field: 'is_vegan', value: 'true' },
  { type: 'allergen', label: 'Vegetarian', field: 'is_vegetarian', value: 'true' },
  { type: 'allergen', label: 'Suitable for Coeliacs', field: 'is_coeliac_suitable', value: 'true' },
  {
    type: 'allergen',
    label: 'Suitable for Lactose Intolerance',
    field: 'is_lactose_intolerance_suitable',
    value: 'true',
  },
  { type: 'allergen', label: 'Cereal free', field: 'contains_cereal', value: 'false' },
  { type: 'allergen', label: 'Celery free', field: 'contains_celery', value: 'false' },
  { type: 'allergen', label: 'Crustacea free', field: 'contains_crustacea', value: 'false' },
  { type: 'allergen', label: 'Egg free', field: 'contains_egg', value: 'false' },
  { type: 'allergen', label: 'Fish free', field: 'contains_fish', value: 'false' },
  { type: 'allergen', label: 'Gluten free', field: 'contains_gluten', value: 'false' },
  { type: 'allergen', label: 'Lupin free', field: 'contains_lupin', value: 'false' },
  { type: 'allergen', label: 'Milk free', field: 'contains_milk', value: 'false' },
  { type: 'allergen', label: 'Mollusc free', field: 'contains_molluscs', value: 'false' },
  { type: 'allergen', label: 'Mustard free', field: 'contains_mustard', value: 'false' },
  { type: 'allergen', label: 'Peanut free', field: 'contains_peanuts', value: 'false' },
  { type: 'allergen', label: 'Sesame free', field: 'contains_sesame', value: 'false' },
  { type: 'allergen', label: 'Soya free', field: 'contains_soya', value: 'false' },
  { type: 'allergen', label: 'Tree nut free', field: 'contains_tree_nuts', value: 'false' },
  { type: 'promotion', label: 'On promotion', field: 'is_on_promotion', value: 'true' },
]
