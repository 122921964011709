import { createAsyncAction, createAction } from 'typesafe-actions'
import { IAuthTokenWithUser, IUserWithAccount, IAccount, IAddress } from '../../api'

export interface ILoginRequest {
  username: string
  password: string
}

export const LOGIN = createAsyncAction(
  'account/REQ_LOGIN',
  'account/REQ_LOGIN_SUCCESS',
  'account/REQ_LOGIN_FAILURE',
  'account/REQ_LOGIN_CANCEL',
)<ILoginRequest, IAuthTokenWithUser, string, undefined>()

export const LOGOUT = createAction('account/LOGOUT')()

export const CHECK_TOKEN = createAction('account/CHECK_TOKEN')()

export const FETCH_USER = createAsyncAction(
  'account/FETCH_USER',
  'account/FETCH_USER_SUCCESS',
  'account/FETCH_USER_FAILURE',
  'account/FETCH_USER_CANCEL',
)<string, IUserWithAccount, Error, undefined>()

export const FETCH_ACCOUNT = createAsyncAction(
  'account/FETCH_ACCOUNT',
  'account/FETCH_ACCOUNT_SUCCESS',
  'account/FETCH_ACCOUNT_FAILURE',
  'account/FETCH_ACCOUNT_CANCEL',
)<undefined, IAccount, Error, undefined>()

export const CLEAR_STATE = createAction('account/CLEAR_STATE')()

export const FETCH_ADDRESSES = createAsyncAction(
  'account/FETCH_ADDRESSES',
  'account/FETCH_ADDRESSES_SUCCESS',
  'account/FETCH_ADDRESSES_FAILURE',
  'account/FETCH_ADDRESSES_CANCEL',
)<undefined, IAddress[], Error, undefined>()

export const PASSWORD_RESET_REQUEST = createAsyncAction(
  'account/PASSWORD_RESET_REQUEST',
  'account/PASSWORD_RESET_REQUEST_SUCCESS',
  'account/PASSWORD_RESET_REQUEST_FAILURE',
)<string, string, string>()

export interface IPasswordResetRequest {
  email: string
  verificationCode: string
  newPassword: string
  confirmPassword: string
}

export const PASSWORD_RESET = createAsyncAction(
  'account/PASSWORD_RESET',
  'account/PASSWORD_RESET_SUCCESS',
  'account/PASSWORD_RESET_FAILURE',
)<IPasswordResetRequest, undefined, string>()

export const CLEAR_PASSWORD_RESET = createAction('account/CLEAR_PASSWORD_RESET')()

export const SET_LOGIN_NEXT_URL = createAction('account/SET_LOGIN_NEXT')<string>()

export const GOTO_LOGIN = createAction('account/GOTO_LOGIN')<boolean | undefined>()
