export const Accordion = {
  variants: {
    productDetails: {
      button: {
        py: 3,
        px: 2,
      },
      panel: {
        pt: 3,
        pb: 8,
        px: 2,
      },
    },
    productDetailsMobile: {
      button: {
        py: 3,
        px: 0,
      },
      panel: {
        pt: 3,
        pb: 8,
        px: 0,
      },
    },
  },
}
