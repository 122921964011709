import { memoize } from 'lodash'
import { createSelector } from 'reselect'

import { IRootState } from '../../root'
import { TProduct } from '../../../api'

export const selectProductsState = (state: IRootState) => state.catalog.products

export const selectProductsItems = createSelector(selectProductsState, (state) => state.items)

export const selectProductsListing = createSelector(selectProductsState, (state) => state.listing)

export const selectProductsDetail = createSelector(selectProductsState, (state) => state.detail)

export const selectProductsSubstitutes = createSelector(
  selectProductsState,
  (state) => state.substitutes,
)

export const selectItemSubstitutes = createSelector(
  selectProductsState,
  (state) => state.itemSubstitutes,
)

export const productsListingSelector = createSelector(
  selectProductsItems,
  selectProductsListing,
  (items, listing) =>
    memoize((key: string) => {
      const request = listing && listing[key]
      return {
        items: request && request.ids && items ? request.ids.map((id) => items[id]) : [],
        query: request && request.query,
        sort: request && request.query && request.query.sort,
        isFetching: (request && request.isFetching) || false,
        ids: (request && request.ids) || [],
        extra: request && request.extra,
        error: request && request.error,
        moreUrl: request && request.moreUrl,
        hasMore: !!(request && request.moreUrl),
        totalCount: request && request.totalCount,
      }
    }),
)

export const productDetailSelector = createSelector(
  selectProductsItems,
  selectProductsDetail,
  (items, detail) =>
    memoize((id: string) => {
      return {
        item: items && items[id],
        isFetching: detail && detail.isFetching,
        error: detail && detail.error,
      }
    }),
)

export const productSubstitutesSelector = createSelector(
  selectProductsItems,
  selectProductsSubstitutes,
  (items, substitutes) =>
    memoize((key: string) => {
      const request = substitutes && substitutes[key]
      return {
        items: request && request.ids && items ? request.ids.map((id) => items[id]) : [],
        isFetching: (request && request.isFetching) || false,
      }
    }),
)

type ItemSubstitutesSelectorReturn = {
  isFetching: boolean
  items: {
    [key: string]: TProduct[]
  }
}

export const itemSubstitutesSelector = createSelector(
  selectProductsItems,
  selectItemSubstitutes,
  (products, itemSubstitutes) =>
    memoize((key: string): ItemSubstitutesSelectorReturn => {
      const request = itemSubstitutes && itemSubstitutes[key]
      const itemsAndProductIds = (request && request.items) ?? {}
      const items = Object.keys(itemsAndProductIds).reduce<ItemSubstitutesSelectorReturn['items']>(
        (acc, itemProductId) => {
          const substitutes = products
            ? itemsAndProductIds[itemProductId].map((id) => products[id])
            : []
          return {
            ...acc,
            [itemProductId]: substitutes,
          }
        },
        {},
      )
      return {
        isFetching: (request && request.isFetching) || false,
        items,
      }
    }),
)

// export const itemSubstitutesSelector = createSelector(
//   selectProductsItems,
//   selectItemSubstitutes,
//   (products, itemSubstitutes) =>
//     memoize((key: string, itemProductId: string) => {
//       const request = itemSubstitutes && itemSubstitutes[key]
//       const substituteIds = (request && request.items && request.items[itemProductId]) || []
//       const substitutes = products ? substituteIds.map((id) => products[id]) : []
//       return {
//         isFetching: (request && request.isFetching) || false,
//         substitutes,
//       }
//     }),
// )
