import { mode, transparentize } from '@chakra-ui/theme-tools'

type Dict = Record<string, any>

function variantGhost(props: Dict) {
  const { colorScheme: c, theme } = props

  if (c === 'gray') {
    return {
      color: mode(`inherit`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode(`gray.100`, `whiteAlpha.200`)(props),
      },
      _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
    }
  }

  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme)
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme)

  return {
    color: mode(`${c}.500`, `${c}.200`)(props), // change from c.600 to c.500
    bg: 'transparent',
    _hover: {
      bg: mode(`${c}.50`, darkHoverBg)(props),
    },
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props),
    },
  }
}

function variantOutline(props: Dict) {
  const { colorScheme: c } = props
  const borderColor = mode(`gray.200`, `whiteAlpha.300`)(props)
  return {
    border: '1px solid',
    borderColor: c === 'gray' ? borderColor : 'currentColor',
    ...variantGhost(props),
  }
}

export const Button = {
  baseStyle: {
    fontFamily: 'heading',
    fontWeight: 'medium',
  },
  defaultProps: {
    colorScheme: 'coral',
  },
  variants: {
    outline: variantOutline,
    link: {
      fontFamily: 'body',
    },
    filteritem: {
      justifyContent: 'space-between',
      textDecoration: 'none',
      color: 'inherit',
      userSelect: 'none',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      textAlign: 'left',
      flex: '0 0 auto',
      outline: 0,
      py: '0.4rem',
      px: '0.8rem',
      transition: 'background 50ms ease-in 0s',
      _hover: {
        bg: 'gray.100',
      },
      _focus: {
        bg: 'gray.100',
      },
      _active: {
        bg: 'gray.200',
      },
      _expanded: {
        bg: 'gray.100',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  },
}
