import { ActionType, getType } from 'typesafe-actions'

import { IRequestDetail } from '../types'

import * as actions from './actions'
import { IShowOrderPickerProps } from './actions'

interface IState {
  orderPicker?: {
    picker?: {
      isOpen: boolean
      props?: IShowOrderPickerProps
    }
    create?: IRequestDetail<Error>
  }
  menuPicker?: {
    picker?: {
      isOpen: boolean
      props?: actions.IShowMenuPicker
    }
    create?: IRequestDetail<Error>
  }
}

const initialState: IState = {}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions>,
): IState => {
  switch (action.type) {
    case getType(actions.SHOW_ORDER_PICKER): {
      const { payload } = action
      return {
        ...state,
        orderPicker: {
          picker: {
            isOpen: true,
            props: {
              ...payload,
            },
          },
        },
      }
    }

    // case getType(actions.SHOW_ORDER_PICKER): {
    //   const { payload } = action
    //   return {
    //     ...state,
    //     orderPicker: {
    //       picker: {
    //         isOpen: true,
    //         props: payload
    //           ? { type: PICKER_TYPE_ADD_PRODUCT, ...payload }
    //           : { type: PICKER_TYPE_ },
    //       },
    //     },
    //   }
    // }

    // case getType(actions.SHOW_ORDER_PICKER_PICK_EXISTING.success): {
    //   const { payload } = action
    //   return {
    //     ...state,
    //     orderPicker: {
    //       picker: {
    //         isOpen: true,
    //         props: {
    //           ...payload,
    //         },
    //       },
    //     },
    //   }
    // }

    case getType(actions.HIDE_ORDER_PICKER): {
      return {
        ...state,
        orderPicker: {
          ...state.orderPicker,
          picker: {
            ...state.orderPicker?.picker,
            isOpen: false,
          },
        },
      }
    }

    case getType(actions.SELECT_ORDER.request):
    case getType(actions.SELECT_BASKET_AND_CREATE_ITEM.request):
    case getType(actions.SELECT_ORDER_AND_CREATE_ITEM.request): {
      return {
        ...state,
        orderPicker: {
          ...state.orderPicker,
          create: {
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.SELECT_ORDER.success):
    case getType(actions.SELECT_BASKET_AND_CREATE_ITEM.success):
    case getType(actions.SELECT_ORDER_AND_CREATE_ITEM.success): {
      return {
        ...state,
        orderPicker: {
          ...state.orderPicker,
          create: {
            isFetching: false,
          },
        },
      }
    }

    case getType(actions.SELECT_BASKET_AND_CREATE_ITEM.cancel): {
      return {
        ...state,
        orderPicker: {
          ...state.orderPicker,
          create: {
            isFetching: false,
          },
        },
      }
    }

    case getType(actions.SELECT_ORDER.failure):
    case getType(actions.SELECT_BASKET_AND_CREATE_ITEM.failure):
    case getType(actions.SELECT_ORDER_AND_CREATE_ITEM.failure): {
      return {
        ...state,
        orderPicker: {
          ...state.orderPicker,
          create: {
            isFetching: false,
            error: action.payload,
          },
        },
      }
    }

    case getType(actions.SHOW_MENU_PICKER): {
      return {
        ...state,
        menuPicker: {
          picker: {
            isOpen: true,
            props: action.payload,
          },
        },
      }
    }

    case getType(actions.HIDE_MENU_PICKER): {
      return {
        ...state,
        menuPicker: {
          ...state.orderPicker,
          picker: undefined,
        },
      }
    }
  }
  return state
}
