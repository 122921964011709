import {
  forwardRef,
  AspectRatio,
  Image,
  HTMLChakraProps,
  useBreakpointValue,
  Link,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { checkExternalLink } from '../../../lib'
import { Carousel } from '../../carousel'
import { ResponsiveContainer } from '../../responsive-container'
import { IBaseBlock, IImageValue } from '../types'

export interface ICarouselBlock extends IBaseBlock {
  type: 'carousel'
  value: {
    items: {
      image: IImageValue
      mobile_image: IImageValue
      url: string
      text: string
    }[]
  }
}

export interface ICarouselBlockProps extends HTMLChakraProps<'div'> {
  block: ICarouselBlock
}

export const CarouselBlock = forwardRef<ICarouselBlockProps, 'div'>(({ block, ...rest }, ref) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })

  return (
    <ResponsiveContainer p={0} ref={ref} {...rest}>
      <Carousel>
        {block.value.items.map((item, index) => {
          const image = (
            <AspectRatio ratio={[1, null, 16 / 6]} key={index}>
              <Image
                draggable={false}
                src={isMobile ? item.mobile_image.src : item.image.src}
                alt={item.text}
                objectFit="cover"
              />
            </AspectRatio>
          )
          const { url, isPageLink } = checkExternalLink(item.url)
          if (isPageLink) {
            return (
              <Link key={index} as={RouterLink} to={url}>
                {image}
              </Link>
            )
          }
          return (
            <Link key={index} href={url}>
              {image}
            </Link>
          )
        })}
      </Carousel>
    </ResponsiveContainer>
  )
})
