import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
  Textarea,
  forwardRef,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { TMenuAny } from '../../api'
import { CREATE_MENU, UPDATE_MENU, selectMenuCreate, selectMenuUpdate } from '../../stores/menu'

const Schema = Yup.object().shape({
  title: Yup.string().trim().required('Title required'),
})

interface IFormValues {
  title: string
  description: string
}

export interface IMenuFormProps extends Omit<ModalProps, 'children'> {
  menu?: TMenuAny
  onSuccess?: (id: string) => void
  asModal?: boolean
}

export const MenuForm = forwardRef<IMenuFormProps, 'div'>((props, ref) => {
  const { menu, onSuccess, asModal = true, ...modalProps } = props
  const { onClose } = modalProps
  const dispatch = useDispatch()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const {
    isFetching: isCreating,
    error: creationError,
    id: createdId,
  } = useSelector(selectMenuCreate)
  const { isFetching: isUpdating, error: updationError } = useSelector(selectMenuUpdate)

  const isLoading = hasSubmitted && (isCreating || isUpdating)
  const apiError = hasSubmitted && (creationError || updationError)

  const initialValues: IFormValues = useMemo(() => {
    return {
      title: menu?.title ?? '',
      description: menu?.description ?? '',
    }
  }, [menu])

  useEffect(() => {
    if (hasSubmitted && !isLoading && !apiError) {
      if (onSuccess) {
        onSuccess(menu ? menu.id : createdId!)
      } else {
        onClose()
      }
    }
  }, [onClose, hasSubmitted, isLoading, apiError, onSuccess, menu, createdId])

  const handleSubmit = async (values: IFormValues) => {
    if (menu) {
      dispatch(
        UPDATE_MENU.request({
          id: menu.id,
          ...values,
        }),
      )
    } else {
      dispatch(CREATE_MENU.request(values))
    }
    setHasSubmitted(true)
  }

  // const handleSubmit = async (values: IFormValues) => {
  //   if (!isFetching) {
  //     onSubmit(values)
  //   }
  // }

  const formBody = (
    <>
      <Stack spacing={4}>
        <Box>
          <Field name="title">
            {({ field, form }: FieldProps) => {
              const { name } = field
              const { touched, errors } = form
              const errorMessage = touched[name] ? errors[name] : ''
              return (
                <FormControl isInvalid={!!errorMessage}>
                  <FormLabel htmlFor={name}>List Name</FormLabel>
                  <InputGroup>
                    <Input {...field} id={name} placeholder="Name" />
                  </InputGroup>
                  <FormHelperText>Required</FormHelperText>
                  {errorMessage && <FormErrorMessage>{`${errorMessage}`}</FormErrorMessage>}
                </FormControl>
              )
            }}
          </Field>
        </Box>
        <Box>
          <Field name="description">
            {({ field, form }: FieldProps) => {
              const { name } = field
              const { touched, errors } = form
              const errorMessage = touched[name] ? errors[name] : ''
              return (
                <FormControl isInvalid={!!errorMessage}>
                  <FormLabel htmlFor={name}>List Description</FormLabel>
                  <InputGroup>
                    <Textarea {...field} id={name} placeholder="Description" />
                  </InputGroup>
                  <FormHelperText>Optional</FormHelperText>
                  {errorMessage && <FormErrorMessage>{`${errorMessage}`}</FormErrorMessage>}
                </FormControl>
              )
            }}
          </Field>
        </Box>
      </Stack>
      {apiError && (
        <Alert status="error" mt={4} p={4} borderRadius="sm">
          <AlertIcon />
          <Box>
            <Text fontWeight="bold">{apiError.message}</Text>
            {/* <Text fontWeight="light">Please check the form and try again.</Text> */}
          </Box>
        </Alert>
      )}
    </>
  )
  if (!asModal) {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        validateOnMount={!!menu}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            {formBody}
            <Box display="flex" py={4} justifyContent="flex-end">
              <Button variant="ghost" mr={4} onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button isDisabled={!isValid} type="submit" isLoading={isLoading}>
                {menu ? 'Update' : 'Submit'}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    )
  }
  return (
    <Modal size="3xl" {...modalProps}>
      <ModalOverlay />
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        validateOnMount={!!menu}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <ModalContent>
            <Form>
              <ModalHeader>{menu ? `Edit List` : 'Create List'}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>{formBody}</ModalBody>
              <ModalFooter>
                <Button variant="ghost" mr={4} onClick={onClose} colorScheme="gray">
                  Cancel
                </Button>
                <Button isDisabled={!isValid} type="submit" isLoading={isLoading}>
                  {menu ? 'Update' : 'Submit'}
                </Button>
              </ModalFooter>
            </Form>
          </ModalContent>
        )}
      </Formik>
    </Modal>
  )
})
