const baseStyle = {
  h: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'coral.500',
  color: 'white',
  padding: '1.5rem',
  display: 'inline-flex',
  _hover: {
    textDecoration: 'underline',
  },
}

export const IconLinkBox = {
  baseStyle,
}
