import { forwardRef, Box, BoxProps, SystemProps } from '@chakra-ui/react'

export interface ICmsStackProps extends BoxProps {
  spacing: SystemProps['margin']
}

const selector = '& > *:not(style) ~ *:not(style)'

export const CmsStack = forwardRef<ICmsStackProps, 'div'>(({ spacing, ...rest }, ref) => {
  return <Box __css={{ [selector]: { mt: spacing } }} ref={ref} {...rest} />
})
