import { BoxProps, forwardRef } from '@chakra-ui/react'

import { OrderItemBox, TSubstitutes } from '../order-item-box'
import {
  TBasketItemAny,
  TBasketWithAddressAndItems,
  TOrderItemAny,
  TOrderWithAddressAndItems,
} from '../../api'
import { useOrderOrBasketItemDetail } from '../../lib'

export interface IItemBoxProps extends BoxProps {
  order: TBasketWithAddressAndItems | TOrderWithAddressAndItems
  item: TBasketItemAny | TOrderItemAny
  substitutes: TSubstitutes
}

export const ItemBox = forwardRef<IItemBoxProps, 'button'>((props, ref) => {
  const { order, item, ...rest } = props
  const {
    isBasket,
    canRemove,
    canEdit,
    splitsSubtotal,
    packsSubtotal,
    onRemove,
    onUpdateSplitQuantity,
    onUpdatePackQuantity,
  } = useOrderOrBasketItemDetail(order, item)

  return (
    <OrderItemBox
      ref={ref}
      item={item}
      canRemove={canRemove}
      canEdit={canEdit}
      splitsSubtotal={splitsSubtotal}
      packsSubtotal={packsSubtotal}
      onRemove={onRemove}
      onUpdateSplitQuantity={onUpdateSplitQuantity}
      onUpdatePackQuantity={onUpdatePackQuantity}
      hideOutOfStockMessage={!isBasket && order.canCancel}
      {...rest}
    />
  )
})
