import { createAsyncAction } from 'typesafe-actions'
import { TCategoryAny, TCategoryWithAncestorsAndChildren } from '../../../api'

export const FETCH_ROOT_CATEGORIES = createAsyncAction(
  'catalog/FETCH_ROOT_CATEGORIES',
  'catalog/FETCH_ROOT_CATEGORIES_SUCCESS',
  'catalog/FETCH_ROOT_CATEGORIES_FAILURE',
  'catalog/FETCH_ROOT_CATEGORIES_CANCEL',
)<undefined, TCategoryAny[], Error, undefined>()

export const FETCH_CATEGORY = createAsyncAction(
  'catalog/FETCH_CATEGORY',
  'catalog/FETCH_CATEGORY_SUCCESS',
  'catalog/FETCH_CATEGORY_FAILURE',
  'catalog/FETCH_CATEGORY_CANCEL',
)<string, TCategoryWithAncestorsAndChildren, Error, undefined>()
