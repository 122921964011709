import { forwardRef, SimpleGridProps, SimpleGrid } from '@chakra-ui/react'

import { TProduct } from '../../api'
import { ProductBox } from './product-box'

export interface ISearchResultsGridProps extends SimpleGridProps {
  items: TProduct[]
  categoryId?: string
}

export const SearchResultsGrid = forwardRef<ISearchResultsGridProps, 'div'>((props, ref) => {
  const { items, categoryId, ...rest } = props
  return (
    <SimpleGrid columns={[2, 2, 3, 4, 5]} spacing={4} mt={4} ref={ref} {...rest}>
      {items.map((p) => (
        <ProductBox key={p.id} product={p} categoryId={categoryId} />
      ))}
    </SimpleGrid>
  )
})
