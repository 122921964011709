import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import * as Yup from 'yup'

import { LOGIN_ROUTE } from '../../pages'
import { PASSWORD_RESET, selectPasswordReset } from '../../stores/account'
import { OmChevronLeft } from '../icons'
import { FormikPinField } from './fomik-pin-field'

const Schema = Yup.object().shape({
  code: Yup.string().trim().min(6, 'Enter all 6 digits').required('Verification code required'),
  password: Yup.string()
    .trim()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .trim()
    .required('Password is required')
    .oneOf([Yup.ref('password'), ''], 'Both passwords must be the same'),
})

interface IFormValues {
  code: string
  password: string
  confirmPassword: string
}

function usePasswordReset(email: string) {
  const dispatch = useDispatch()
  const state = useSelector(selectPasswordReset)

  const onSubmit = useCallback(
    (values: IFormValues) => {
      dispatch(
        PASSWORD_RESET.request({
          email,
          verificationCode: values.code,
          newPassword: values.password,
          confirmPassword: values.confirmPassword,
        }),
      )
    },
    [dispatch, email],
  )

  return {
    ...state,
    onSubmit,
  }
}

export interface IPasswordResetFormProps {
  email: string
}

export function PasswordResetForm({ email }: IPasswordResetFormProps) {
  const { isFetching, error, onSubmit } = usePasswordReset(email)

  const initialValues: IFormValues = {
    code: '',
    password: '',
    confirmPassword: '',
  }

  const handleSubmit = async (values: IFormValues) => {
    if (!isFetching) {
      onSubmit(values)
    }
  }

  return (
    <Box>
      <Text mb={8} fontWeight="light">
        Use the code sent to {email} to create a new password.
      </Text>
      {error && (
        <Box borderWidth={1} borderColor="red.500" borderRadius="sm" p={4} mb={4}>
          <Box color="red.500">
            <strong>{error}</strong>
          </Box>
        </Box>
      )}
      <Formik initialValues={initialValues} validationSchema={Schema} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Stack spacing={4}>
              <Box>
                <FormikPinField name="code" />
              </Box>
              <Box>
                <Field name="password">
                  {({ field, form }: FieldProps) => {
                    const { name } = field
                    const { touched, errors } = form
                    const errorMessage = touched[name] ? errors[name] : ''
                    return (
                      <FormControl isInvalid={!!errorMessage}>
                        <FormLabel htmlFor={name}>Password</FormLabel>
                        <InputGroup>
                          <Input {...field} id={name} placeholder="" type="password" />
                        </InputGroup>
                        <FormErrorMessage>{`${errorMessage}`}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
              </Box>
              <Box>
                <Field name="confirmPassword">
                  {({ field, form }: FieldProps) => {
                    const { name } = field
                    const { touched, errors } = form
                    const errorMessage = touched[name] ? errors[name] : ''
                    return (
                      <FormControl isInvalid={!!errorMessage}>
                        <FormLabel htmlFor={name}>Re-enter new password</FormLabel>
                        <InputGroup>
                          <Input {...field} id={name} placeholder="" type="password" />
                        </InputGroup>
                        <FormErrorMessage>{`${errorMessage}`}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
              </Box>

              <Box>
                <Button type="submit" w="100%" isLoading={isFetching} isDisabled={!props.isValid}>
                  Update Password
                </Button>
              </Box>
              <Box>
                <Button
                  variant="link"
                  colorScheme="green"
                  as={RouterLink}
                  to={LOGIN_ROUTE}
                  leftIcon={<OmChevronLeft />}
                  iconSpacing={0}
                >
                  Back to login
                </Button>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
