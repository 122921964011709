import { APIService } from '../service'
import { IJsonApiModelWithId, IFetchOneQueryOptions } from '../types'
import { deserializeTransformer } from '../serializer'

declare module '../service' {
  interface APIService {
    fetchCutoffTime(id: string, requestOpts?: IFetchOneQueryOptions): Promise<ICutoffTime>
  }
}

export const ENTITY_TYPE_CUTOFF_TIME = 'cutoff-times'

export interface ICutoffTime extends IJsonApiModelWithId {
  id: string
  requestedDeliveryDate: Date
  cutoff: Date
}

APIService.prototype.fetchCutoffTime = async function (
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  return this.fetchOne<ICutoffTime>(ENTITY_TYPE_CUTOFF_TIME + '/' + id, requestOpts)
}

deserializeTransformer.register(ENTITY_TYPE_CUTOFF_TIME, {
  requestedDeliveryDate: (v: string) => new Date(v),
  cutoff: (v: string) => new Date(v),
})
