import { ActionType, getType } from 'typesafe-actions'
import { zipObject } from 'lodash'

import { IKeyedPaginatedRequest, IRequestDetail } from '../../types'
import { TProduct } from '../../../api'
import { IAvailableFacet } from '../../../lib'
import { getFacetsFromQuery } from './utils'

import * as actions from './actions'
import { CREATE_FAVOURITE, DELETE_FAVOURITE } from '../../favourite'

interface IState {
  items?: { [key: string]: TProduct }
  listing?: IKeyedPaginatedRequest<
    actions.IFetchProductsRequestQuery,
    {
      available: IAvailableFacet[]
      selected: IAvailableFacet[]
    }
  >
  detail?: IRequestDetail<Error>
  substitutes?: {
    [key: string]: {
      ids?: string[]
      isFetching?: boolean
    }
  }
  itemSubstitutes?: {
    [key: string]: {
      isFetching?: boolean
      items?: {
        [itemProductId: string]: string[]
      }
    }
  }
}

const initialState: IState = {}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions | typeof CREATE_FAVOURITE | typeof DELETE_FAVOURITE>,
): IState => {
  switch (action.type) {
    case getType(actions.FETCH_PRODUCTS.request): {
      const { key, query } = action.payload
      const { listing } = state
      const request = listing && listing[key]
      return {
        ...state,
        listing: {
          ...listing,
          [key]: {
            ...request,
            query,
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.FETCH_PRODUCTS.cancel): {
      const key = action.payload
      const { listing } = state
      const request = listing && listing[key]
      if (!request) {
        return state
      }
      return {
        ...state,
        listing: {
          ...listing,
          [key]: {
            ...request,
            isFetching: false,
          },
        },
      }
    }

    case getType(actions.FETCH_PRODUCT_SUBSTITUTES.request): {
      const { productId } = action.payload
      const { substitutes } = state
      const current = substitutes && substitutes[productId]
      return {
        ...state,
        substitutes: {
          ...substitutes,
          [productId]: {
            ...current,
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.FETCH_PRODUCT_SUBSTITUTES.success): {
      const {
        productId,
        response: { data },
      } = action.payload
      const ids = data.map((p) => p.id)
      const items = zipObject(ids, data)
      const { substitutes } = state
      const current = substitutes && substitutes[productId]
      return {
        ...state,
        items: { ...(state.items || {}), ...items },
        substitutes: {
          ...substitutes,
          [productId]: {
            ...current,
            isFetching: false,
            ids: [...ids],
          },
        },
      }
    }

    case getType(actions.FETCH_ITEM_SUBSTITUTES.request): {
      const { parentKey } = action.payload
      const { itemSubstitutes } = state
      const current = itemSubstitutes && itemSubstitutes[parentKey]
      return {
        ...state,
        itemSubstitutes: {
          ...itemSubstitutes,
          [parentKey]: {
            ...current,
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.FETCH_ITEM_SUBSTITUTES.success): {
      const { parentKey, items } = action.payload

      // get all the products from for the substitutes
      const products = items.reduce<TProduct[]>((acc, { substitutes }) => {
        for (const sub of substitutes) {
          if (!acc.some((p) => p.id === sub.id)) {
            acc.push(sub)
          }
        }
        return acc
      }, [])

      // get the products to add to the products list state
      const productIds = products.map((p) => p.id)
      const productData = zipObject(productIds, products)

      const { itemSubstitutes } = state
      const current = itemSubstitutes && itemSubstitutes[parentKey]
      const itemData = items.reduce<{ [key: string]: string[] }>(
        (acc, { productId, substitutes }) => {
          acc[productId] = substitutes.map((s) => s.id)
          return acc
        },
        {},
      )
      return {
        ...state,
        items: { ...(state.items || {}), ...productData },
        itemSubstitutes: {
          ...itemSubstitutes,
          [parentKey]: {
            ...current,
            isFetching: false,
            items: itemData,
          },
        },
      }
    }

    case getType(actions.FETCH_MORE_PRODUCTS.success):
    case getType(actions.FETCH_PRODUCTS.success): {
      const {
        key,
        response: {
          data,
          links,
          meta: {
            facets,
            pagination: { count },
          },
        },
      } = action.payload
      const ids = data.map((p) => p.id)
      const items = zipObject(ids, data)
      const { listing } = state
      const request = listing && listing[key]
      return {
        ...state,
        items: { ...(state.items || {}), ...items },
        listing: {
          ...listing,
          [key]: {
            ...request,
            isFetching: false,
            ids: [...((request && request.ids) || []), ...ids],
            moreUrl: links.next ?? undefined,
            extra: getFacetsFromQuery(request?.query!, facets),
            totalCount: count,
          },
        },
      }
    }

    case getType(actions.FETCH_PRODUCT.request): {
      return {
        ...state,
        detail: {
          id: action.payload,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_PRODUCT.success): {
      const item = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
        detail: {
          id: item.id,
          isFetching: false,
        },
      }
    }

    case getType(CREATE_FAVOURITE.success): {
      const favourite = action.payload
      const productId = favourite.product.id
      // update the relevant product with the favourite
      const product = state.items && state.items[productId]
      if (!product) {
        return {
          ...state,
        }
      }
      return {
        ...state,
        items: {
          ...state.items,
          [productId]: {
            ...product,
            favourite,
          },
        },
      }
    }

    case getType(DELETE_FAVOURITE.success): {
      const { productId } = action.payload
      // delete the favourite from the product
      const product = state.items && state.items[productId]
      if (!product) {
        return {
          ...state,
        }
      }
      return {
        ...state,
        items: {
          ...state.items,
          [productId]: {
            ...product,
            favourite: null,
          },
        },
      }
    }
  }

  return state
}
