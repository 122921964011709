import { useLocation } from 'react-router-dom'

import { PageLayout } from '../components/page-layout'
import { CmsPageContent } from '../components/cms-page-content'
import { Error404PageContent } from '../components/error-404-page-content'
import { PageLoadingSpinner } from '../components/page-loading-spinner'

export const FallbackPage: React.FC = () => {
  const path = useLocation().pathname
  return (
    <PageLayout>
      <CmsPageContent
        path={path}
        error404={<Error404PageContent />}
        loader={<PageLoadingSpinner />}
      />
    </PageLayout>
  )
}
