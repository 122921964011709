import useSWR, { SWRConfiguration } from 'swr'

import { ICmsMultipleResponse } from './types'
import { BASE_URL, CmsApiError, fetcher } from './fetcher'

export interface ICmsFlatMenu {
  id: string
  meta: {
    handle: string
    type: string
    detail_url: string
  }
  items: {
    id: number
    text: string
    url: string
  }[]
}

export function useCmsFlatMenu(handle: string, options?: SWRConfiguration) {
  const url = BASE_URL + 'flatmenus/?handle=' + handle
  const { data, error } = useSWR<ICmsMultipleResponse<ICmsFlatMenu>, CmsApiError>(
    url,
    fetcher,
    options,
  )
  const menu = data && data.items.length > 0 ? data.items[0] : undefined
  return { data: menu, error }
}
