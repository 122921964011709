import { combineReducers } from 'redux'
import { all, fork } from 'redux-saga/effects'

import { reducer as categories } from './categories/reducer'
import { reducer as products } from './products/reducer'

import { saga as categoriesSaga } from './categories/sagas'
import { saga as productsSaga } from './products/sagas'

export const reducer = combineReducers({
  categories,
  products,
})

export const saga = function* () {
  yield all([categoriesSaga, productsSaga].map(fork))
}

export * from './categories/actions'
export * from './categories/selectors'

export * from './products/actions'
export * from './products/selectors'
export * from './products/utils'
