import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { orderDetailSelector, FETCH_ORDER, selectOrdersDelete } from '../../stores/order'

export function useOrderDetail(id: string) {
  const dispatch = useDispatch()
  const [requested, setRequested] = useState(false)
  const detail = useSelector(orderDetailSelector)(id)
  const deleteState = useSelector(selectOrdersDelete)
  const isCancelled =
    deleteState.id === id && deleteState.isFetching === false && !deleteState.error

  useEffect(() => {
    if (!requested) {
      setRequested(true)
      dispatch(FETCH_ORDER.request(id))
    }
  }, [dispatch, id, requested, setRequested])

  return {
    ...detail,
    isCancelled,
  }
}
