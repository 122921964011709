import { useMemo, memo } from 'react'
import { useFormikContext } from 'formik'
import { addDays, formatISO } from 'date-fns'
import { find } from 'lodash'
import { FormControl, FormLabel, FormHelperText, FormErrorMessage } from '@chakra-ui/react'

import { addressEnabledDaysOfTheWeek } from '../../lib'
import { DeliveryDatePicker } from '../delivery-date-picker'
import { IBasketFormValues } from './basket-form'
import { IAddress } from '../../api'

export interface IDeliveryDateFieldProps {
  name: string
  value?: Date
  enabledDates: Date[]
  onChange: (value?: Date) => void
  onCalendarClose: () => void
  errorMessage?: string
  isDisabled?: boolean
}

const DeliveryDateField: React.FC<IDeliveryDateFieldProps> = (props) => {
  const { enabledDates, errorMessage, ...rest } = props

  const isInvalid = !!errorMessage
  const minDate = enabledDates && enabledDates.length ? enabledDates[0] : undefined
  const maxDate =
    enabledDates && enabledDates.length ? enabledDates[enabledDates.length - 1] : undefined
  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor="requestedDeliveryDate">Preferred Delivery Date</FormLabel>
      <DeliveryDatePicker
        {...rest}
        minDate={minDate}
        maxDate={maxDate}
        enabledDates={enabledDates}
      />
      {!errorMessage && <FormHelperText>Required</FormHelperText>}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
}

const DeliveryDateFieldMemo = memo(DeliveryDateField)

export interface IFormikDeliveryDateFieldProps {
  hideErrorUntilTouched: boolean
  maxDeliveryDays: number
  addresses: IAddress[]
  tomorrowsCutoff?: Date
  nonDeliveryDates?: Date[]
}

export const FormikDeliveryDateField: React.FC<IFormikDeliveryDateFieldProps> = ({
  hideErrorUntilTouched,
  maxDeliveryDays,
  addresses,
  tomorrowsCutoff,
  nonDeliveryDates,
}) => {
  const name = 'date'
  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext<IBasketFormValues>()
  const value = values[name]
  const address = find(addresses, ['id', values.address])
  const handleChange = useMemo(
    () => (value?: Date) => {
      setFieldValue(name, value)
    },
    [setFieldValue],
  )
  const handleClose = useMemo(
    () => () => {
      // really shitty but a timeout needs to be used as the picker sends the
      // onClose event before the onChange event, yet formik needs them the
      // other way round
      setTimeout(() => {
        setFieldTouched(name, true)
      }, 200)
    },
    [setFieldTouched],
  )
  const enabledDates = useMemo(
    () => getEnabledDates(maxDeliveryDays, address, tomorrowsCutoff, nonDeliveryDates),
    [maxDeliveryDays, address, tomorrowsCutoff, nonDeliveryDates],
  )

  const errorMessage = hideErrorUntilTouched ? (touched[name] ? errors[name] : '') : errors[name]

  return (
    <DeliveryDateFieldMemo
      name={name}
      value={value}
      enabledDates={enabledDates.dates}
      onChange={handleChange}
      onCalendarClose={handleClose}
      errorMessage={errorMessage}
    />
  )
}

export function getEnabledDates(
  maxDeliveryDays: number,
  address?: IAddress,
  tomorrowsCutoff?: Date,
  nonDeliveryDates?: Date[],
) {
  const nonDeliveryDaysIso = nonDeliveryDates?.map((d) => formatISO(d, { representation: 'date' }))
  const enabledDays = address ? addressEnabledDaysOfTheWeek(address) : []
  const enabledDates: Date[] = []
  const enabledDatesIso: string[] = []
  const todayIso = formatISO(new Date(), { representation: 'date' })
  const today = new Date(todayIso)
  for (let i = 1; i <= maxDeliveryDays; i++) {
    const date = addDays(today, i)
    const dateIso = formatISO(date, { representation: 'date' })
    if (enabledDays.includes(date.getDay()) && !nonDeliveryDaysIso?.includes(dateIso)) {
      if (i === 1 && tomorrowsCutoff && new Date() > tomorrowsCutoff) {
        // after the cutoff for tomorrow
        continue
      }
      enabledDates.push(date)
      enabledDatesIso.push(dateIso)
    }
  }

  return {
    dates: enabledDates,
    isoDates: enabledDatesIso,
  }
}
