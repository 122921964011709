import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TCategoryAny } from '../../api'
import { FETCH_CATEGORY, categoryAncestorsSelector } from '../../stores/catalog'

export function useCategoryAncestors(category?: TCategoryAny) {
  const dispatch = useDispatch()
  const ancestors = useSelector(categoryAncestorsSelector)(category)

  useEffect(() => {
    if (category && ancestors.length !== category.ancestors.length) {
      dispatch(FETCH_CATEGORY.request(category.id))
    }
  }, [dispatch, category, ancestors])

  if (category && ancestors && ancestors.length === category.ancestors.length) {
    return ancestors
  }
  return []
}
