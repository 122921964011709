import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ticketDetailSelector,
  selectTicketCommentListingResults,
  FETCH_TICKET,
  FETCH_TICKET_COMMENTS,
} from '../../stores/ticket'

export function useTicketDetail(id: string) {
  const dispatch = useDispatch()
  const [requested, setRequested] = useState(false)
  const detail = useSelector(ticketDetailSelector)(id)
  const comments = useSelector(selectTicketCommentListingResults)(id)

  useEffect(() => {
    if (!requested) {
      setRequested(true)
      dispatch(FETCH_TICKET.request(id))
      dispatch(FETCH_TICKET_COMMENTS.request(id))
    }
  }, [dispatch, id, requested, setRequested])

  return {
    ...detail,
    comments,
  }
}
