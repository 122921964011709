import { combineReducers } from 'redux'
import { all, fork } from 'redux-saga/effects'

import { reducer as baskets } from './baskets/reducer'
import { reducer as cutoffs } from './cutoffs/reducer'
import { reducer as discounts } from './discounts/reducer'
import { reducer as duplicateOrderChecks } from './duplicate-order-checks/reducer'
import { reducer as orderSettings } from './order-settings/reducer'
import { reducer as orders } from './orders/reducer'
import { reducer as nonDeliveryDays } from './non-delivery-days/reducer'

import { saga as basketsSaga } from './baskets/sagas'
import { saga as cutoffSaga } from './cutoffs/sagas'
import { saga as discountsSaga } from './discounts/sagas'
import { saga as duplicateOrderChecksSaga } from './duplicate-order-checks/sagas'
import { saga as orderSettingsSaga } from './order-settings/sagas'
import { saga as ordersSaga } from './orders/sagas'
import { saga as nonDeliveryDaysSaga } from './non-delivery-days/sagas'

export const reducer = combineReducers({
  baskets,
  cutoffs,
  discounts,
  duplicateOrderChecks,
  orderSettings,
  orders,
  nonDeliveryDays,
})

export const saga = function* () {
  yield all(
    [
      basketsSaga,
      cutoffSaga,
      discountsSaga,
      duplicateOrderChecksSaga,
      nonDeliveryDaysSaga,
      orderSettingsSaga,
      ordersSaga,
    ].map(fork),
  )
}

export * from './baskets/actions'
export * from './baskets/selectors'

export * from './cutoffs/actions'
export * from './cutoffs/selectors'

export * from './discounts/actions'
export * from './discounts/selectors'

export * from './duplicate-order-checks/actions'
export * from './duplicate-order-checks/selectors'

export * from './order-settings/actions'
export * from './order-settings/selectors'

export * from './orders/actions'
export * from './orders/selectors'

export * from './non-delivery-days/actions'
export * from './non-delivery-days/selectors'
