import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { FETCH_BASKET_DISCOUNTS, discountDetailSelector } from '../../stores/order'

export function useDiscounts(basketId: string) {
  const dispatch = useDispatch()
  const [requested, setRequested] = useState(false)
  const discount = useSelector(discountDetailSelector)(basketId)

  useEffect(() => {
    if (!requested) {
      setRequested(true)
      dispatch(FETCH_BASKET_DISCOUNTS.request(basketId))
    }
  }, [basketId, dispatch, requested, setRequested])

  return discount
}
