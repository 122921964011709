import { generatePath } from 'react-router'
import queryString from 'query-string'
import { format as formatDate, isPast } from 'date-fns'
import { has, isString } from 'lodash'

import {
  TCategoryAny,
  IAddress,
  TBasketAny,
  TOrderAny,
  TBasketItemAny,
  TOrderItemAny,
  IJsonApiModelWithId,
  TMenuItemAny,
  TMenuAny,
  ITicket,
  TMenuWithItems,
} from '../api'
import {
  CATEGORY_ROUTE,
  PRODUCT_ROUTE,
  BASKET_ROUTE,
  ORDER_ROUTE,
  MENU_ROUTE,
  TICKET_ROUTE,
  SEARCH_ROUTE,
} from '../pages'

export function getCategoryPathSlugs(category: TCategoryAny, categories?: TCategoryAny[]) {
  const slugs = []
  if (category.parent) {
    const parent = categories!.find((c) => c.id === category.parent!.id)
    if (parent) {
      getCategoryPathSlugs(parent, categories).forEach((parentSlug) => {
        slugs.push(parentSlug)
      })
    }
  }
  slugs.push(category.slug)
  return slugs
}

export function categoryPath(category: TCategoryAny, categories?: TCategoryAny[]) {
  const slugs = getCategoryPathSlugs(category, categories)
  return generatePath(CATEGORY_ROUTE, {
    slugs: slugs.join('/'),
    categoryId: category.id,
  })
}

export function searchPathForCodes(codes: string[]) {
  // return `${SEARCH_ROUTE}?${queryString.stringify({ codes: codes.join(',') })}`
  return `${SEARCH_ROUTE}?codes=${codes.join(',')}`
}

export function productPath(product: IJsonApiModelWithId, categoryId?: string) {
  const path = generatePath(PRODUCT_ROUTE, {
    // @ts-ignore
    productId: product.id,
  })

  return categoryId && product.categories.length > 1
    ? `${path}?${queryString.stringify({ c: categoryId })}`
    : path
}

export function basketPath(basket: TBasketAny | string) {
  const basketId = isString(basket) ? basket : basket.id
  return generatePath(BASKET_ROUTE, {
    basketId,
  })
}

export function menuPath(menu: TMenuAny | string) {
  const menuId = isString(menu) ? menu : menu.id
  return generatePath(MENU_ROUTE, {
    menuId,
  })
}

export function formatDeliveryDate(date: Date) {
  return formatDate(date, 'iiii dd/MM/yyyy')
}

export function deliveryAddressDisplay(address: IAddress) {
  const keys = ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'county', 'postcode']
  const parts: string[] = []
  keys.forEach((k) => {
    if (has(address, k) && address[k]) {
      parts.push(address[k])
    }
  })
  return parts.join(', ')
}

export function formatCutoffDate(date: Date) {
  const minutes = date.getMinutes() > 0 ? `:${date.getMinutes()}` : ''
  return formatDate(date, `iii dd/MM/yyyy 'at' h${minutes}bbb`)
}

export function basketErrorMessage(basket: TBasketAny) {
  if (!basket.order && !basketIsBeforeCutoff(basket)) {
    return 'The cutoff time has now passed. You must edit the order and change the requested delivery date.'
  }
  const areAllItemsValid =
    basket.items.filter((i) => (i.product && i.productIsAvailable) || i.hasOrderItem || (i.isFreeDiscount && i.product)).length ===
    basket.items.length
  if (!areAllItemsValid) {
    return 'The order cannot be confirmed as some items are no longer available. You will be able to proceed when they are removed.'
  }
  return ''
}

export function basketIsBeforeCutoff(basket: TBasketAny) {
  return !isPast(basket.cutoff)
}

export function basketOrderMessage(basket: TBasketAny) {
  if (basket.order) {
    if (isPast(basket.cutoff)) {
      return [
        'Order has been opened for editing but the cutoff time has now passed. Please remove this from Unconfirmed Orders.',
        false,
      ]
    }
    if (basket.hasOrderStateChanged) {
      return [
        'Order has been opened for editing but has been amended in our backend system. Please remove this from Unconfirmed Orders.',
        true,
      ]
    }
    return ['Order has been opened for editing.', true]
  }
  return ['', false]
}

export function orderBasketMessage(order: TOrderAny) {
  if (order.basket) {
    return ['Order has been opened for editing.', true]
  }
  return ['', false]
}

export function basketName(basket?: TBasketAny) {
  if (!basket) {
    return 'Unconfirmed Order'
  }

  return basket.title || basket.poNumber || formatDeliveryDate(basket.requestedDeliveryDate)
}

export function orderIsBeforeCutoff(order: TOrderAny) {
  return order.status === 'open' && !isPast(order.cutoff)
}

export function orderPath(order: TOrderAny) {
  return generatePath(ORDER_ROUTE, {
    orderId: order.id,
  })
}

export function orderName(order?: TOrderAny) {
  if (!order) {
    return 'Confirmed Order'
  }

  const title = order.title || order.poNumber || formatDeliveryDate(order.requestedDeliveryDate)
  return `${order.orderNumber} ${title}`
}

export function getItemSubtotals(item: TBasketItemAny | TOrderItemAny | TMenuItemAny) {
  const unitPrice = parseFloat(item.unitPrice) * 100
  const splitsSubtotal = (item.splitQuantity * unitPrice) / 100
  const packsSubtotal = (item.packQuantity * item.productPackQuantity * unitPrice) / 100
  return {
    splitsSubtotal: splitsSubtotal.toFixed(2),
    packsSubtotal: packsSubtotal.toFixed(2),
  }
}

export function addressEnabledDaysOfTheWeek(address: IAddress) {
  const { deliveryDays } = address
  const enabledDays: number[] = [] // sunday == 0

  if (deliveryDays.sunday) {
    enabledDays.push(0)
  }
  if (deliveryDays.monday) {
    enabledDays.push(1)
  }
  if (deliveryDays.tuesday) {
    enabledDays.push(2)
  }
  if (deliveryDays.wednesday) {
    enabledDays.push(3)
  }
  if (deliveryDays.thursday) {
    enabledDays.push(4)
  }
  if (deliveryDays.friday) {
    enabledDays.push(5)
  }
  if (deliveryDays.satuday) {
    enabledDays.push(6)
  }

  return enabledDays
}

export function canCreateOrderFromMenu(menu: TMenuWithItems) {
  // check if any of the items have products
  return menu.items.filter((item) => item.product && item.productIsAvailable).length > 0
}

export function ticketPath(ticket: ITicket | string) {
  const ticketId = isString(ticket) ? ticket : ticket.id
  return generatePath(TICKET_ROUTE, {
    ticketId,
  })
}

export function ticketStatus(ticket: ITicket) {
  return ticketStatusTitle(ticket) + ' ' + formatTicketDate(ticket.updatedAt)
}

export function ticketStatusTitle(ticket: ITicket) {
  if (ticket.isOrderTicket && ticket.orderTicketStatus) {
    if (ticket.orderTicketStatus === 'new') {
      return 'New order'
    }
    return 'Order ' + ticket.orderTicketStatus.toLowerCase()
  }
  return ticket.status.trim().replace(/^\w/, (c) => c.toUpperCase()) // capitalize
}

export function formatTicketDate(date: Date) {
  return formatDate(date, `dd/MM/yyyy h:mmaaa`)
}

export function isTicketOpen(ticket: ITicket) {
  const openStatuses = ['new', 'open', 'pending', 'hold']
  return openStatuses.includes(ticket.status.toLowerCase())
}

export function ticketStatusColor(ticket: ITicket) {
  if (['solved', 'closed'].includes(ticket.status.toLowerCase())) {
    return 'gray.500'
  }
  return 'green.500'
}

export function ensureError(error: unknown, defaultMessage?: string) {
  if (error instanceof Error) {
    return error
  }
  return new Error(defaultMessage ?? 'An unknown error occurred')
}

export function checkExternalLink(url: string): { url: string; isPageLink: boolean } {
  const currentDomain = window.location.origin
  if (url.startsWith(currentDomain)) {
    return {
      url: url.replace(currentDomain, ''),
      isPageLink: true,
    }
  }

  if (url[0] === '/') {
    return {
      url,
      isPageLink: true,
    }
  }

  return {
    url,
    isPageLink: false,
  }
}
