import React from 'react'
import { chakra, forwardRef, Box, BoxProps } from '@chakra-ui/react'

const ImageLinkBoxGroupItem = chakra('li', {
  baseStyle: {
    listStyle: 'none',
  },
})

export const ImageLinkBoxGroup = forwardRef<BoxProps, 'div'>(({ children, ...rest }, ref) => {
  const margin = 2
  const _children = React.Children.toArray(children).map((child, index) => (
    <ImageLinkBoxGroupItem
      key={index}
      width={[
        'calc(50% - 0.5rem)',
        null,
        'calc(calc(100% - 2rem) / 3)',
        'calc(calc(100% - 4rem) / 5)',
      ]}
      mx={margin}
      mb={4}
      __css={{
        ':nth-of-type(odd)': {
          ml: [0, null, margin],
        },
        ':nth-of-type(even)': {
          mr: [0, null, margin],
        },
        ':nth-of-type(3n)': {
          mr: [null, null, 0, margin],
        },
        ':nth-of-type(4n)': {
          ml: [null, null, 0, margin],
        },
        ':nth-of-type(5n)': {
          mr: [null, null, null, 0],
        },
        ':nth-of-type(6n)': {
          ml: [null, null, null, 0],
        },
        ':nth-of-type(1)': {
          ml: 0,
        },
        ':last-of-type': {
          mr: 0,
        },
      }}
    >
      {child}
    </ImageLinkBoxGroupItem>
  ))
  return (
    <Box
      ref={ref}
      as="ul"
      display="flex"
      flexDirection={'row'}
      justifyContent="center"
      alignItems="stretch"
      flexWrap="wrap"
      mb={-4}
      {...rest}
    >
      {_children}
    </Box>
  )
})
