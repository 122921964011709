import { createSelector } from 'reselect'
import { memoize } from 'lodash'

import { IRootState } from '../../root'

export const selectBasketsState = (state: IRootState) => state.order.baskets

const selectBasketsItems = createSelector(selectBasketsState, (state) => state.items)

const selectBasketsListing = createSelector(selectBasketsState, (state) => state.listing)

const selectBasketsListingForAddressAndDate = createSelector(
  selectBasketsState,
  (state) => state.listingForAddressAndDate,
)

const selectBasketsDetail = createSelector(selectBasketsState, (state) => state.detail)

export const selectBasketsListingResults = createSelector(
  selectBasketsItems,
  selectBasketsListing,
  (items, listing) => {
    return {
      ...listing,
      items: items && listing && listing.ids ? listing.ids.map((id) => items[id]) : [],
      ids: (listing && listing.ids) ?? [],
    }
  },
)

export const selectBasketsListingForAddressAndDateResults = createSelector(
  selectBasketsItems,
  selectBasketsListingForAddressAndDate,
  (items, listing) => {
    return {
      ...listing,
      items: items && listing && listing.ids ? listing.ids.map((id) => items[id]) : [],
      ids: (listing && listing.ids) ?? [],
    }
  },
)

export const basketDetailSelector = createSelector(
  selectBasketsItems,
  selectBasketsDetail,
  (items, detail) =>
    memoize((id: string) => {
      return {
        item: items && items[id],
        isFetching: detail && detail.isFetching,
        error: detail && detail.error,
      }
    }),
)

const selectBasketsDeleteState = createSelector(selectBasketsState, (state) => state.delete)

export const selectBasketsDelete = createSelector(selectBasketsDeleteState, (state) => {
  return {
    ...state,
    isFetching: state && state.isFetching,
    error: state && state.error,
  }
})

const selectBasketsCreateState = createSelector(selectBasketsState, (state) => state.create)

export const selectBasketsCreate = createSelector(selectBasketsCreateState, (state) => {
  return {
    ...state,
    isFetching: state && state.isFetching,
    error: state && state.error,
  }
})

const selectBasketsUpdateState = createSelector(selectBasketsState, (state) => state.update)

export const selectBasketsUpdate = createSelector(selectBasketsUpdateState, (state) => {
  return {
    ...state,
    isFetching: state && state.isFetching,
    error: state && state.error,
  }
})

export const selectBasketsSelectedState = createSelector(
  selectBasketsState,
  (state) => state.selected,
)

export const selectBasketsSelectedBasket = createSelector(
  selectBasketsSelectedState,
  selectBasketsItems,
  (selected, items) => {
    if (!selected || !items) {
      return
    }
    return {
      basket: items[selected],
    }
  },
)

const selectBasketsConfirmState = createSelector(selectBasketsState, (state) => state.confirm)

export const selectBasketsConfirm = createSelector(selectBasketsConfirmState, (state) => {
  return {
    ...state,
    isFetching: state && state.isFetching,
    error: state && state.error,
  }
})
