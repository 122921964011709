import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { menuDetailSelector, FETCH_MENU, selectMenuDelete } from '../../stores/menu'

export function useMenuDetail(id: string) {
  const dispatch = useDispatch()
  const [requested, setRequested] = useState(false)
  const detail = useSelector(menuDetailSelector)(id)
  const deleteState = useSelector(selectMenuDelete)
  const isDeleted = deleteState.id === id && deleteState.isFetching === false && !deleteState.error

  useEffect(() => {
    if (!requested) {
      setRequested(true)
      dispatch(FETCH_MENU.request(id))
    }
  }, [dispatch, id, requested, setRequested])

  return {
    ...detail,
    isDeleted,
  }
}
