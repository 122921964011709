import { PageLayout } from '../components/page-layout'
import { CmsPageContent } from '../components/cms-page-content'
import { Error404PageContent } from '../components/error-404-page-content'
import { PageLoadingSpinner } from '../components/page-loading-spinner'

export const HOME_ROUTE = '/'

export const HomePage: React.FC = () => {
  return (
    <PageLayout>
      <CmsPageContent path="/" error404={<Error404PageContent />} loader={<PageLoadingSpinner />} />
    </PageLayout>
  )
}
