import { Box, BoxProps, forwardRef } from '@chakra-ui/react'

interface INutritionalDataRow {
  label: string
  perPortion: string
  per100: string
  additional: {
    label: string
    perPortion: string
    per100: string
  }[]
}

interface INutritionalData {
  unit: 'ml' | 'g'
  servingSize?: string
  data: INutritionalDataRow[]
}

export interface INutritionalDataProps extends BoxProps {
  nutritionalData: INutritionalData
}

export interface IRowProps {
  nutritionalData: INutritionalDataRow
  hasPerPortion: boolean
}

const Th = (props: BoxProps) => (
  <Box
    as="th"
    fontWeight="bold"
    borderBottom="1px"
    borderColor="gray.300"
    whiteSpace="nowrap"
    p={0}
    py={1.5}
    {...props}
  />
)
const Td = (props: BoxProps) => <Th as="td" fontWeight="normal" {...props} />

const DataRow = ({ hasPerPortion, nutritionalData }: IRowProps) => {
  const tdProps = nutritionalData.additional ? { borderBottom: 0 } : {}
  return (
    <>
      <tr>
        <Td {...tdProps}>{nutritionalData.label}</Td>
        {hasPerPortion && <Td {...tdProps}>{nutritionalData.perPortion}</Td>}
        <Td {...tdProps}>{nutritionalData.per100}</Td>
      </tr>
      {nutritionalData.additional &&
        nutritionalData.additional.map((additional, index) => {
          const isLast = index === nutritionalData.additional.length - 1
          const tdProps = !isLast ? { borderBottom: 0 } : {}
          return (
            <tr key={index}>
              <Td pt={0} {...tdProps}>
                {additional.label}
              </Td>
              {hasPerPortion && (
                <Td pt={0} {...tdProps}>
                  {additional.perPortion}
                </Td>
              )}
              <Td pt={0} {...tdProps}>
                {additional.per100}
              </Td>
            </tr>
          )
        })}
    </>
  )
}

export const NutritionDataTable = forwardRef<INutritionalDataProps, 'table'>((props, ref) => {
  const { nutritionalData, ...rest } = props
  const hasPerPortion = nutritionalData.data.filter((d) => d.perPortion).length > 0
  const servingSize =
    hasPerPortion && nutritionalData.servingSize ? ` (${nutritionalData.servingSize})` : ''

  return (
    <Box
      as="table"
      ref={ref}
      __css={{ borderSpacing: '1rem 0', borderCollapse: 'separate' }}
      width="calc(100% + 2rem)"
      mx="-1rem"
      {...rest}
    >
      <thead>
        <Box as="tr" textAlign="left">
          <Th>Typical Values</Th>
          {hasPerPortion && (
            <Th width="20%" whiteSpace="normal">
              Per Portion{servingSize}
            </Th>
          )}
          <Th width="20%">Per 100{nutritionalData.unit}</Th>
        </Box>
      </thead>
      <tbody>
        {nutritionalData.data.map((d) => (
          <DataRow key={d.label} nutritionalData={d} hasPerPortion={hasPerPortion} />
        ))}
      </tbody>
    </Box>
  )
})
