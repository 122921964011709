import { createSelector } from 'reselect'

import { IRootState } from '../root'

export const selectUiState = (state: IRootState) => state.ui

export const selectOrderPickerState = createSelector(selectUiState, (state) => state.orderPicker)

export const selectOrderPickerPickerState = createSelector(
  selectOrderPickerState,
  (state) => state?.picker,
)

export const selectOrderPickerIsOpen = createSelector(
  selectOrderPickerPickerState,
  (state) => state?.isOpen ?? false,
)

export const selectOrderPickerCreateState = createSelector(
  selectOrderPickerState,
  (state) => state?.create,
)

export const selectOrderPickerProps = createSelector(
  selectOrderPickerPickerState,
  (state) => state?.props,
)

export const selectMenuPickerState = createSelector(selectUiState, (state) => state.menuPicker)
