import { createIcon } from '@chakra-ui/react'

export const OmAllergenCereal = createIcon({
  displayName: 'Cereal',
  viewBox: '0 0 35 67',
  d: 'M 18.146 19.771 C 18.209 20.806 18.248 21.829 18.26 22.843 C 17.357 21.787 16.367 20.802 15.925 19.474 C 16.729 19.68 17.471 19.775 18.146 19.771 Z M 16.208 52.03 C 17.469 49.324 18.574 46.562 19.483 43.728 C 20.171 44.425 21.235 44.833 22.58 44.807 C 23.993 44.779 25.227 44.283 26.417 43.561 C 31.711 40.352 33.935 35.382 34.364 29.209 C 31.4 32.751 27.209 33.908 23.232 35.483 C 22.517 35.767 21.874 36.146 21.306 36.608 C 21.654 34.784 21.916 32.931 22.082 31.044 C 23.867 32.527 25.687 32.485 27.501 30.893 C 32.025 26.92 32.147 14.754 27.34 10.094 C 26.977 14.913 24.18 18.447 21.936 22.299 C 21.777 21.063 21.558 19.838 21.281 18.626 C 22.417 17.483 22.739 15.541 21.981 13.037 C 20.688 8.766 17.391 5.409 16.805 0.168 C 12.936 5.983 12.728 11.52 15.537 19.072 C 13.191 18.176 12.457 15.553 10.215 13.871 C 9.347 19.007 9.348 23.627 11.479 28.051 C 13.162 31.542 15.519 32.595 17.804 31.176 C 17.477 33.812 16.98 36.36 16.336 38.812 C 16.209 38.141 15.985 37.441 15.651 36.733 C 15.473 36.355 15.292 35.959 15.028 35.643 C 12.572 32.7 9.909 29.931 7.498 26.1 C 6.228 31.396 5.815 36.006 7.669 40.522 C 8.946 43.633 11.758 44.938 14.207 43.892 C 14.445 43.789 14.66 43.662 14.861 43.521 C 14.037 45.61 12.645 48.329 11.091 51.143 C 10.742 49.64 9.948 48.249 8.696 46.971 C 6.787 45.023 5.015 42.983 3.854 40.46 C 3.371 39.408 2.461 38.426 1.836 40.341 C 0.251 45.198 -0.664 50.095 1.96 54.939 C 3.376 57.556 4.95 58.684 6.919 58.393 C 5.979 59.981 5.113 61.431 4.419 62.608 C 3.699 63.832 2.891 65.518 4.628 66.545 C 6.441 67.618 7.874 66.522 8.873 64.998 C 9.667 63.786 10.44 62.565 11.189 61.335 C 12.207 62.978 14.184 63.191 17.575 62.171 C 25.22 59.869 29.454 54.173 32.36 46.12 C 28.306 48.251 24.979 50.008 21.227 50.674 C 19.492 50.982 17.798 51.396 16.208 52.03 L 16.208 52.03 Z',
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 35 67"
//     id="vector">
//     <path
//         id="path"
//         d="M 18.146 19.771 C 18.209 20.806 18.248 21.829 18.26 22.843 C 17.357 21.787 16.367 20.802 15.925 19.474 C 16.729 19.68 17.471 19.775 18.146 19.771 Z M 16.208 52.03 C 17.469 49.324 18.574 46.562 19.483 43.728 C 20.171 44.425 21.235 44.833 22.58 44.807 C 23.993 44.779 25.227 44.283 26.417 43.561 C 31.711 40.352 33.935 35.382 34.364 29.209 C 31.4 32.751 27.209 33.908 23.232 35.483 C 22.517 35.767 21.874 36.146 21.306 36.608 C 21.654 34.784 21.916 32.931 22.082 31.044 C 23.867 32.527 25.687 32.485 27.501 30.893 C 32.025 26.92 32.147 14.754 27.34 10.094 C 26.977 14.913 24.18 18.447 21.936 22.299 C 21.777 21.063 21.558 19.838 21.281 18.626 C 22.417 17.483 22.739 15.541 21.981 13.037 C 20.688 8.766 17.391 5.409 16.805 0.168 C 12.936 5.983 12.728 11.52 15.537 19.072 C 13.191 18.176 12.457 15.553 10.215 13.871 C 9.347 19.007 9.348 23.627 11.479 28.051 C 13.162 31.542 15.519 32.595 17.804 31.176 C 17.477 33.812 16.98 36.36 16.336 38.812 C 16.209 38.141 15.985 37.441 15.651 36.733 C 15.473 36.355 15.292 35.959 15.028 35.643 C 12.572 32.7 9.909 29.931 7.498 26.1 C 6.228 31.396 5.815 36.006 7.669 40.522 C 8.946 43.633 11.758 44.938 14.207 43.892 C 14.445 43.789 14.66 43.662 14.861 43.521 C 14.037 45.61 12.645 48.329 11.091 51.143 C 10.742 49.64 9.948 48.249 8.696 46.971 C 6.787 45.023 5.015 42.983 3.854 40.46 C 3.371 39.408 2.461 38.426 1.836 40.341 C 0.251 45.198 -0.664 50.095 1.96 54.939 C 3.376 57.556 4.95 58.684 6.919 58.393 C 5.979 59.981 5.113 61.431 4.419 62.608 C 3.699 63.832 2.891 65.518 4.628 66.545 C 6.441 67.618 7.874 66.522 8.873 64.998 C 9.667 63.786 10.44 62.565 11.189 61.335 C 12.207 62.978 14.184 63.191 17.575 62.171 C 25.22 59.869 29.454 54.173 32.36 46.12 C 28.306 48.251 24.979 50.008 21.227 50.674 C 19.492 50.982 17.798 51.396 16.208 52.03 L 16.208 52.03 Z"
//         fill="#ff4f4f"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
