import React from 'react'
import queryString from 'query-string'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Heading,
  Image,
  Stack,
  Text,
  forwardRef,
  BoxProps,
  useBreakpointValue,
  SimpleGrid,
  TextProps,
  Link,
  LayoutProps,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { ResponsiveContainer } from '../responsive-container'
import { Breadcrumb } from '../breadcrumb'
import { IconBadge } from '../icon-badge'
import { OmMenu, OmHeart, OmHeartSolid, OmFrozen, OmPromotion, OmClock } from '../icons'
import {
  history,
  useCategory,
  useCategoryAncestors,
  categoryPath,
  productPath,
  useMenuPicker,
  useFavourite,
  searchPathForCodes,
} from '../../lib'
import { TProduct } from '../../api'
import { QuantityForm } from './quantity-form'
import { NutritionDataTable } from './nutrition-data-table'
import { ProductAllergens } from './product-allergens'
import { ProductSubstitutes } from './product-substitutes'
import { SectionHeading } from './section-heading'

const OUT_OF_STOCK_MESSAGE = 'Sorry but this item is temporarily out of stock.'

const Disclaimer = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box ref={ref} {...props}>
    <Text mt={3}>
      Please note that this is an estimated price. For items sold by weight, the exact price may
      vary depending on the weight of items supplied. All items are subject to stock availability
      and price fluctuations. Your invoice will accurately record these variances.
    </Text>
    <Text mt={3}>
      Whilst the owners of OrderMate take steps to ensure the information is regularly updated, they
      give no warranty and no guarantee that the information is accurate. Product information and
      ingredients may change, please always read product labels carefully in addition to using the
      information provided by OrderMate.
    </Text>
    <Text mt={3}>
      We do not accept liability for any inaccuracies or incorrect information contained on this
      site.
    </Text>
  </Box>
))

const BgxyLabel: React.FC<{ product: TProduct; display?: LayoutProps['display'] }> = ({
  product,
  display,
}) => {
  const { bxgy } = product

  if (!bxgy) {
    return null
  }
  const { isY, xLabel, yLabel, xCodes, yCodes } = bxgy
  const isX = !isY

  if (isX && xLabel && yCodes.length) {
    const productUrl =
      yCodes.length === 1
        ? productPath({
            type: product.type,
            id: yCodes[0],
          })
        : searchPathForCodes(yCodes)
    return (
      <Link
        as={RouterLink}
        to={productUrl}
        my={2}
        p={2}
        display={display ?? 'inline-block'}
        fontWeight="normal"
        fontSize="xs"
        bg="green.500"
        color="white">
        {xLabel}
      </Link>
    )
  }

  if (isY && yLabel && xCodes.length) {
    const productUrl =
      xCodes.length === 1
        ? productPath({
            type: product.type,
            id: xCodes[0],
          })
        : searchPathForCodes(xCodes)

    return (
      <Link
        as={RouterLink}
        to={productUrl}
        my={2}
        p={2}
        display={display ?? 'inline-block'}
        fontWeight="normal"
        fontSize="xs"
        bg="green.500"
        color="white">
        {yLabel}
      </Link>
    )
  }

  return null
}

interface IProductBoxProps extends BoxProps {
  product: TProduct
}

interface ICopyTextProps extends TextProps {
  copy: string
}

const CopyTextParagraph = ({ copy, ...textProps }: ICopyTextProps) => {
  const lines = copy
    .split(/\n/)
    .map((s) => s.trim())
    .filter((s) => !!s)

  if (!lines.length) {
    return null
  }

  const [first, ...rest] = lines

  return (
    <Text {...textProps}>
      {first}
      {rest.map((line, index) => (
        <React.Fragment key={index}>
          <br />
          {line}
        </React.Fragment>
      ))}
    </Text>
  )
}

const CopyText = ({ copy, ...textProps }: ICopyTextProps) => {
  const paragraphs = copy
    .replaceAll('\r', '')
    .split(/\n{2,}/)
    .map((s) => s.trim())
    .filter((s) => !!s)

  if (!paragraphs.length) {
    return null
  }

  return (
    <>
      {paragraphs.map((paragraph, index) => (
        <CopyTextParagraph key={index} copy={paragraph} {...textProps} />
      ))}
    </>
  )
}

const Description = forwardRef<IProductBoxProps, 'div'>((props, ref) => {
  const { product, ...rest } = props
  const hasDescription = product.description || product.cutoffTime || product.isPreOrder
  if (!hasDescription) {
    return null
  }

  return (
    <Box ref={ref} {...rest}>
      <SectionHeading>Description</SectionHeading>
      <CopyText copy={product.description} mt={3} />
      {product.cutoffTime && (
        <Text mt={3}>Order by {product.cutoffTime} on the working day prior to your delivery.</Text>
      )}
      {product.isPreOrder && (
        <Text mt={3}>This is a pre-ordered product. Lead times may vary.</Text>
      )}
    </Box>
  )
})

interface IProductHeaderProps extends BoxProps {
  product: TProduct
  handleOpenMenuPicker: () => void
}

const SmallSizeProductHeader = forwardRef<IProductHeaderProps, 'div'>((props, ref) => {
  const { product, handleOpenMenuPicker, ...rest } = props
  const { isAvailable, isPurchasable } = product
  const {
    isFavourite,
    onToggle: onToggleFavourite,
    isFetching: isFavouriteFetching,
  } = useFavourite(product)

  const images = product.images.length ? product.images.filter((image) => image.medium) : []
  const image = images.length ? product.images[0].medium : '/images/product-placeholder.png'
  const hasIcons = product.isFrozen || product.isOnPromotion || product.isPreOrder

  return (
    <Box ref={ref} {...rest}>
      <Heading as="h3" color="gray.500" fontFamily="body" fontWeight="bold" fontSize="lg" mb={1}>
        {product.id}
      </Heading>
      <Heading as="h1" fontFamily="body" fontWeight="bold" fontSize="3xl">
        {product.name}
      </Heading>
      <Box>
        <Box my={4} position="relative">
          <Image src={image} mx="auto" alt={product.name} />
          {hasIcons && (
            <Box top={1} left={1} position="absolute">
              {product.isFrozen && (
                <IconBadge icon={OmFrozen} size="sm" color="#82D0F6" marginRight={2} />
              )}
              {product.isOnPromotion && (
                <IconBadge icon={OmPromotion} size="sm" color="coral.500" marginRight={2} />
              )}
              {product.isPreOrder && (
                <IconBadge icon={OmClock} size="sm" color="birchallBlue.500" marginRight={2} />
              )}
            </Box>
          )}
        </Box>

        <BgxyLabel product={product} display="block" />

        {product.type === 'products' && (
          <QuantityForm product={product} borderWidth={1} borderX="none" py={4} />
        )}

        {!isAvailable && (
          <Text
            borderWidth={1}
            borderX="none"
            borderTop={0}
            py={4}
            textAlign="center"
            fontSize="large"
            fontWeight="bold">
            {OUT_OF_STOCK_MESSAGE}
          </Text>
        )}

        {isPurchasable && (
          <Box my={4} borderBottomWidth={1} pb={4} display="flex" justifyContent="center">
            <Stack direction={['column', 'row']} spacing={4}>
              <Button variant="outline" leftIcon={<OmMenu />} onClick={handleOpenMenuPicker}>
                Add to List
              </Button>
              <Button
                variant="outline"
                leftIcon={isFavourite ? <OmHeartSolid /> : <OmHeart />}
                onClick={onToggleFavourite}
                isLoading={isFavouriteFetching}>
                {isFavourite ? 'Remove from Favourites' : 'Add to Favourites'}
              </Button>
            </Stack>
          </Box>
        )}

        <ProductSubstitutes product={product} mt={8} />
        <Description product={product} mt={8} />
      </Box>
    </Box>
  )
})

const MediumSizeProductHeader = forwardRef<IProductHeaderProps, 'div'>((props, ref) => {
  const { product, handleOpenMenuPicker, ...rest } = props
  const { isAvailable, isPurchasable } = product
  const {
    isFavourite,
    onToggle: onToggleFavourite,
    isFetching: isFavouriteFetching,
  } = useFavourite(product)
  const images = product.images.length ? product.images.filter((image) => image.medium) : []
  const image = images.length ? product.images[0].medium : '/images/product-placeholder.png'
  const hasIcons = product.isFrozen || product.isOnPromotion || product.isPreOrder

  return (
    <Box {...rest} ref={ref}>
      <Box display="flex">
        <Box width={[null, null, 350, 500, null]} flexShrink={0} mr={4}>
          <Box position="relative">
            <Image
              src={image}
              mx="auto"
              alt={product.name}
              objectFit="contain"
              maxHeight={[null, null, 350, 500, null]}
            />
            {hasIcons && (
              <Box top={1} left={1} position="absolute">
                {product.isFrozen && (
                  <IconBadge icon={OmFrozen} size="sm" color="#82D0F6" marginRight={2} />
                )}
                {product.isOnPromotion && (
                  <IconBadge icon={OmPromotion} size="sm" color="coral.500" marginRight={2} />
                )}
                {product.isPreOrder && (
                  <IconBadge icon={OmClock} size="sm" color="birchallBlue.500" marginRight={2} />
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Box flexGrow={1}>
          <Heading
            as="h3"
            color="gray.500"
            fontFamily="body"
            fontWeight="bold"
            fontSize="lg"
            mb={1}>
            {product.id}
          </Heading>
          <Heading as="h1" fontFamily="body" fontWeight="bold" fontSize="3xl">
            {product.name}
          </Heading>

          <BgxyLabel product={product} />

          {product.type === 'products' && <QuantityForm product={product} mt={8} />}

          {!isAvailable && (
            <Text mt={8} fontSize="large" fontWeight="bold">
              {OUT_OF_STOCK_MESSAGE}
            </Text>
          )}

          {isPurchasable && (
            <Stack direction="row" spacing={4} my={8}>
              <Button variant="outline" leftIcon={<OmMenu />} onClick={handleOpenMenuPicker}>
                Add to List
              </Button>
              <Button
                variant="outline"
                leftIcon={isFavourite ? <OmHeartSolid /> : <OmHeart />}
                onClick={onToggleFavourite}
                isLoading={isFavouriteFetching}>
                {isFavourite ? 'Remove from Favourites' : 'Add to Favourites'}
              </Button>
            </Stack>
          )}

          <Description product={product} mt={8} />
        </Box>
      </Box>

      <ProductSubstitutes product={product} mt={8} />
    </Box>
  )
})

const MobileSizeProductDetails = forwardRef<IProductBoxProps, 'div'>((props, ref) => {
  const { product, ...rest } = props

  return (
    <>
      <Box {...rest} ref={ref}>
        <Accordion variant="productDetailsMobile" allowMultiple={true} mt={8}>
          {product.ingredients && (
            <>
              <AccordionItem>
                <AccordionButton>
                  <SectionHeading flex={1} textAlign="left">
                    Ingredients
                  </SectionHeading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <CopyText mt={4} copy={product.ingredients} />
                </AccordionPanel>
              </AccordionItem>
            </>
          )}
          {product.directionsForUse && (
            <>
              <AccordionItem>
                <AccordionButton>
                  <SectionHeading flex={1} textAlign="left">
                    Cooking &amp; Handling
                  </SectionHeading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <CopyText mt={4} copy={product.directionsForUse} />
                </AccordionPanel>
              </AccordionItem>
            </>
          )}
          {product.directionsForUse && (
            <>
              <AccordionItem>
                <AccordionButton>
                  <SectionHeading flex={1} textAlign="left">
                    Storage Instructions
                  </SectionHeading>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <CopyText mt={4} copy={product.storageInstructions} />
                </AccordionPanel>
              </AccordionItem>
            </>
          )}
          <AccordionItem>
            <AccordionButton>
              <SectionHeading flex={1} textAlign="left">
                Nutritional Information
              </SectionHeading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {product.nutritionalData ? (
                <NutritionDataTable nutritionalData={product.nutritionalData} />
              ) : (
                <Text>
                  For further Allergen or Nutritional information please contact{' '}
                  <Link href="mailto:technical@birchallfoodservice.co.uk" color="coral.500">
                    technical@birchallfoodservice.co.uk
                  </Link>
                  .
                </Text>
              )}
            </AccordionPanel>
          </AccordionItem>
          {product.allergenData && (
            <AccordionItem>
              <AccordionButton>
                <SectionHeading flex={1} textAlign="left">
                  Dietary &amp; Allergen Information
                </SectionHeading>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <ProductAllergens allergenData={product.allergenData} />
              </AccordionPanel>
            </AccordionItem>
          )}
        </Accordion>
      </Box>
    </>
  )
})

const LargeSizeProductDetails = forwardRef<IProductBoxProps, 'div'>((props, ref) => {
  const { product, ...rest } = props
  return (
    <Box {...rest} ref={ref}>
      {product.ingredients && (
        <Box mt={3} mb={8}>
          <SectionHeading mt={3} mb={6}>
            Ingredients
          </SectionHeading>
          <Box>
            <CopyText mt={4} copy={product.ingredients} />
          </Box>
        </Box>
      )}

      {product.directionsForUse && (
        <Box mt={3} mb={8}>
          <SectionHeading mt={3} mb={6}>
            Cooking &amp; Handling
          </SectionHeading>
          <Box>
            <CopyText mt={4} copy={product.directionsForUse} />
          </Box>
        </Box>
      )}

      {product.storageInstructions && (
        <Box mt={3} mb={8}>
          <SectionHeading mt={3} mb={6}>
            Storage Instructions
          </SectionHeading>
          <Box>
            <CopyText mt={4} copy={product.storageInstructions} />
          </Box>
        </Box>
      )}

      {product.nutritionalData && product.allergenData && (
        <SimpleGrid columns={2} spacing={12}>
          <Box mt={3} mb={8}>
            <SectionHeading mt={3} mb={6}>
              Nutritional Information
            </SectionHeading>
            <NutritionDataTable nutritionalData={product.nutritionalData} />
          </Box>
          <Box mt={3} mb={8}>
            <SectionHeading mt={3} mb={6}>
              Dietary &amp; Allergen Information
            </SectionHeading>
            <ProductAllergens allergenData={product.allergenData} />
          </Box>
        </SimpleGrid>
      )}
      {!product.nutritionalData && !product.allergenData && (
        <>
          <SectionHeading>Nutritional Information</SectionHeading>
          <Text mt={3}>
            For further Allergen or Nutritional information please contact{' '}
            <Link href="mailto:technical@birchallfoodservice.co.uk" color="coral.500">
              technical@birchallfoodservice.co.uk
            </Link>
            .
          </Text>
        </>
      )}
    </Box>
  )
})

interface IProductPageContentProps {
  product: TProduct
}

export const ProductPageContent: React.FC<IProductPageContentProps> = ({ product }) => {
  const params = queryString.parse(history.location!.search)
  const qsCategoryId = (Array.isArray(params.c) ? params.c[0] : params.c) || undefined
  const categoryIds = product && product.categories?.map((c) => c.id!)
  const categoryId =
    qsCategoryId && categoryIds?.includes(qsCategoryId)
      ? qsCategoryId
      : categoryIds.length > 0
      ? categoryIds[0]
      : undefined

  const category = useCategory(categoryId)
  const ancestors = [...useCategoryAncestors(category), ...(category ? [category] : [])]
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })
  const isLarge = useBreakpointValue({
    base: false,
    lg: true,
  })

  const { onOpen: onOpenMenuPicker } = useMenuPicker()

  const handleOpenMenuPicker = () => {
    onOpenMenuPicker({
      productId: product.id,
    })
  }

  const breadcrumbs = ancestors.map((c) => ({
    name: c.name,
    to: categoryPath(c, ancestors),
  }))
  breadcrumbs.push({
    name: product.name,
    to: productPath(product, category?.id),
  })

  return (
    <ResponsiveContainer>
      <Breadcrumb items={breadcrumbs} as="h2" />
      {isMobile ? (
        <SmallSizeProductHeader product={product} handleOpenMenuPicker={handleOpenMenuPicker} />
      ) : (
        <MediumSizeProductHeader
          product={product}
          handleOpenMenuPicker={handleOpenMenuPicker}
          mb={8}
        />
      )}

      {/* <ProductSubstitutes product={product} /> */}

      {isLarge ? (
        <LargeSizeProductDetails product={product} />
      ) : (
        <MobileSizeProductDetails product={product} />
      )}

      <Box mt={12}>
        <SectionHeading>Disclaimer</SectionHeading>
        <Disclaimer mt={3} />
      </Box>
    </ResponsiveContainer>
  )
}
