import React from 'react'
import { List, ListItem, Button, forwardRef, ListProps } from '@chakra-ui/react'
import { IFacet } from '../../lib'

import { OmX } from '../icons'

export interface IFacetButtonsProps extends ListProps {
  facets: IFacet[]
  onRemoveFacet: (field: string) => void
  onRemoveAll: () => void
  showClearAll?: boolean
  hideFacets?: string[]
}

export const FacetButtons = forwardRef<IFacetButtonsProps, 'div'>((props, ref) => {
  const { facets, onRemoveFacet, onRemoveAll, showClearAll = true, hideFacets, ...rest } = props
  const visibleFacets = facets.filter((facet) => !hideFacets?.includes(facet.field))

  if (!visibleFacets.length) {
    return null
  }

  return (
    <List ref={ref} {...rest}>
      {visibleFacets.map((facet) => (
        <ListItem key={facet.field} mr={4} mb={3} display="inline-block">
          <Button
            onClick={() => onRemoveFacet(facet.field)}
            size="sm"
            colorScheme="gray"
            _hover={{ bg: 'gray.300' }}
            rightIcon={<OmX />}
          >
            {facet.label}
          </Button>
        </ListItem>
      ))}

      {showClearAll && (
        <ListItem display="inline-block" mb={3}>
          <Button
            onClick={() => onRemoveAll()}
            variant="ghost"
            size="sm"
            colorScheme="black"
            rightIcon={<OmX />}
            _hover={{ bg: 'gray.300', color: 'black' }}
            color="gray.800"
          >
            Clear all
          </Button>
        </ListItem>
      )}
    </List>
  )
})
