import { useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectTicketListingResults, FETCH_TICKETS, FETCH_MORE_TICKETS } from '../../stores/ticket'

export function useTicketsListing() {
  const dispatch = useDispatch()
  const listing = useSelector(selectTicketListingResults)

  const handlers = useMemo(
    () => ({
      onLoadMore: () => dispatch(FETCH_MORE_TICKETS.request()),
    }),
    [dispatch],
  )

  const onLoadInitial = useCallback(() => {
    dispatch(FETCH_TICKETS.request())
  }, [dispatch])

  // automatically call load initial
  // the extra pages will be lost but data will be fresh
  useEffect(() => {
    onLoadInitial()
  }, [onLoadInitial])

  return {
    ...listing,
    ...handlers,
    onLoadInitial,
  }
}
