import { StateType } from 'typesafe-actions'
import { combineReducers } from 'redux'
import { all, fork } from 'redux-saga/effects'

import { reducer as account, saga as accountSaga } from './account'
import { reducer as catalog, saga as catalogSaga } from './catalog'
import { reducer as favourite, saga as favouriteSaga } from './favourite'
import { reducer as menu, saga as menuSaga } from './menu'
import { reducer as order, saga as orderSaga } from './order'
import { reducer as ticket, saga as ticketSaga } from './ticket'
import { reducer as ui, saga as uiSaga } from './ui'

export const rootSaga = function* () {
  yield all(
    [accountSaga, catalogSaga, favouriteSaga, menuSaga, orderSaga, ticketSaga, uiSaga].map(fork),
  )
}

export const rootReducer = combineReducers({
  account,
  catalog,
  favourite,
  menu,
  order,
  ticket,
  ui,
})

export type IRootState = StateType<typeof rootReducer>
