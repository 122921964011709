import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { FETCH_ACCOUNT, selectAccount } from '../../stores/account'

export function useAccount() {
  const dispatch = useDispatch()
  const account = useSelector(selectAccount)

  useEffect(() => {
    if (!account) {
      dispatch(FETCH_ACCOUNT.request())
    }
  }, [dispatch, account])

  return account
}
