import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TCategoryAny } from '../../api'
import { FETCH_CATEGORY, categoryChildrenSelector } from '../../stores/catalog'

export function useCategoryChildren(parent?: TCategoryAny) {
  const dispatch = useDispatch()
  const children = useSelector(categoryChildrenSelector)(parent)

  useEffect(() => {
    if (parent && children.length !== parent.children.length) {
      dispatch(FETCH_CATEGORY.request(parent.id))
    }
  }, [dispatch, parent, children])

  if (parent && children && children.length === parent.children.length) {
    return children
  }
  return []
}
