import { Box, BoxProps, forwardRef, chakra, Button } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { ticketPath, ticketStatus, ticketStatusColor } from '../../lib'
import { ITicket } from '../../api'

export interface ITicketBoxProps extends BoxProps {
  ticket: ITicket
  showViewButton?: boolean
}

export const DefinitionList = chakra('dl', {
  baseStyle: {
    '& > dt:not(style) ~ dt:not(style)': {
      mt: 4,
    },
  },
})

export const DefinitionTerm = chakra('dt', {
  baseStyle: {
    fontWeight: 'light',
  },
})

export const Definition = chakra('dd', {
  baseStyle: {
    fontWeight: 'bold',
  },
})

export const TicketBox = forwardRef<ITicketBoxProps, 'div'>((props, ref) => {
  const { ticket, showViewButton = true, ...rest } = props
  return (
    <Box ref={ref} borderWidth={1} p={[3, 4]} borderRadius="sm" {...rest}>
      <DefinitionList>
        <DefinitionTerm m={0}>Subject</DefinitionTerm>
        <Definition>{ticket.subject}</Definition>

        <DefinitionTerm>Status</DefinitionTerm>
        <Definition color={ticketStatusColor(ticket)}>{ticketStatus(ticket)}</Definition>
      </DefinitionList>
      {showViewButton && (
        <Box mt={4} display="flex" flexDirection="row-reverse">
          <Button ml={[3, 4]} as={RouterLink} to={ticketPath(ticket)}>
            View
          </Button>
        </Box>
      )}
    </Box>
  )
})
