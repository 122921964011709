import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import API, { JsonApiError } from '../../api'
import { LOGOUT } from '../../stores/account'

export const useAuthChecker = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const onError = (error: JsonApiError) => {
      if (error.status === 401) {
        dispatch(LOGOUT())
      }
    }
    API.setErrorListener(onError)
  }, [dispatch])
}
