import { all, put, takeLeading, select, call } from 'redux-saga/effects'

import API, { CallReturnType } from '../../api'
import { displayErrorToastSaga, displayErrorToast, DEFAULT_PAGE_SIZE, ensureError } from '../../lib'
import { selectTicketListingResults } from './selectors'
import * as actions from './actions'

function* fetchTickets() {
  try {
    const result: CallReturnType<typeof API.fetchTickets> = yield API.fetchTickets({
      pageSize: DEFAULT_PAGE_SIZE,
    })
    yield put(actions.FETCH_TICKETS.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_TICKETS.failure(error))
  }
}

function* fetchMoreTickets() {
  try {
    const { moreUrl }: ReturnType<typeof selectTicketListingResults> = yield select(
      selectTicketListingResults,
    )
    const response: CallReturnType<typeof API.fetchTickets> = yield API.fetchTickets(moreUrl!)
    yield put(actions.FETCH_MORE_TICKETS.success(response))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_MORE_TICKETS.failure(error))
  }
}

function* fetchTicket(action: ReturnType<typeof actions.FETCH_TICKET.request>) {
  try {
    const ticket: CallReturnType<typeof API.fetchTicket> = yield API.fetchTicket(action.payload)
    yield put(actions.FETCH_TICKET.success(ticket))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_TICKET.failure(error))
  }
}

function* fetchTicketComments(action: ReturnType<typeof actions.FETCH_TICKET_COMMENTS.request>) {
  const ticketId = action.payload
  try {
    const comments: CallReturnType<typeof API.fetchAllTicketComments> =
      yield API.fetchAllTicketComments(action.payload, {
        pageSize: 50,
      })
    yield put(
      actions.FETCH_TICKET_COMMENTS.success({
        ticketId,
        comments,
      }),
    )
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(
      actions.FETCH_TICKET_COMMENTS.failure({
        ticketId,
        error,
      }),
    )
  }
}

function* fetchTicketCommentsFailure(
  action: ReturnType<typeof actions.FETCH_TICKET_COMMENTS.failure>,
) {
  yield call(displayErrorToast, action.payload.error)
}

function* createTicket(action: ReturnType<typeof actions.CREATE_TICKET.request>) {
  try {
    const result: CallReturnType<typeof API.createTicket> = yield API.createTicket(action.payload)
    yield put(actions.CREATE_TICKET.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.CREATE_TICKET.failure(error))
  }
}

function* createTicketComment(action: ReturnType<typeof actions.CREATE_TICKET_COMMENT.request>) {
  const { ticketId, description } = action.payload
  try {
    const result: CallReturnType<typeof API.createTicketComment> = yield API.createTicketComment(
      ticketId,
      {
        plainBody: description,
      },
    )
    yield put(
      actions.CREATE_TICKET_COMMENT.success({
        ticketId,
        comment: result,
      }),
    )
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(
      actions.CREATE_TICKET_COMMENT.failure({
        ticketId,
        error,
      }),
    )
  }
}

export function* saga() {
  yield all([
    takeLeading(actions.FETCH_TICKETS.request, fetchTickets),
    takeLeading(actions.FETCH_TICKETS.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_MORE_TICKETS.request, fetchMoreTickets),
    takeLeading(actions.FETCH_MORE_TICKETS.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_TICKET.request, fetchTicket),
    takeLeading(actions.FETCH_TICKET.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_TICKET_COMMENTS.request, fetchTicketComments),
    takeLeading(actions.FETCH_TICKET_COMMENTS.failure, fetchTicketCommentsFailure),
    takeLeading(actions.CREATE_TICKET.request, createTicket),
    takeLeading(actions.CREATE_TICKET_COMMENT.request, createTicketComment),
  ])
}
