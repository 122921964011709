import { all, call, put, takeLeading } from 'redux-saga/effects'

import API, {
  CallReturnType,
  JsonApiError,
  IUpdateMenuItem,
  INewMenuItem,
  ENTITY_TYPE_MENU,
  ENTITY_TYPE_PRODUCT,
} from '../../api'
import { displayErrorToastSaga, ensureError } from '../../lib'

import * as actions from './actions'

function* fetchMenus() {
  try {
    const result: CallReturnType<typeof API.fetchAllMenus> = yield API.fetchAllMenus()
    yield put(actions.FETCH_MENUS.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_MENUS.failure(error))
  }
}

function* fetchMenu(action: ReturnType<typeof actions.FETCH_MENU.request>) {
  try {
    const menu: CallReturnType<typeof API.fetchMenu> = yield API.fetchMenu(action.payload)
    yield put(actions.FETCH_MENU.success(menu))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_MENU.failure(error))
  }
}

function* deleteMenu(action: ReturnType<typeof actions.DELETE_MENU.request>) {
  const menuId = action.payload
  try {
    yield API.deleteMenu(menuId)
    yield put(actions.DELETE_MENU.success(menuId))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.DELETE_MENU.failure(error))
  }
}

function* createMenu(action: ReturnType<typeof actions.CREATE_MENU.request>) {
  try {
    const result: CallReturnType<typeof API.createMenu> = yield API.createMenu(action.payload)
    yield put(actions.CREATE_MENU.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.CREATE_MENU.failure(error))
  }
}

function* updateMenu(action: ReturnType<typeof actions.UPDATE_MENU.request>) {
  try {
    const result: CallReturnType<typeof API.updateMenu> = yield API.updateMenu(action.payload)
    yield put(actions.UPDATE_MENU.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.UPDATE_MENU.failure(error))
  }
}

function* deleteMenuItem(action: ReturnType<typeof actions.DELETE_MENU_ITEM.request>) {
  const { id } = action.payload
  try {
    yield API.deleteMenuItem(id)
    yield put(actions.DELETE_MENU_ITEM.success(action.payload))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(
      actions.DELETE_MENU_ITEM.failure({
        id,
        error,
      }),
    )
  }
}

function* updateMenuItem(action: ReturnType<typeof actions.UPDATE_MENU_ITEM.request>) {
  const { menuId, ...rest } = action.payload
  const data: IUpdateMenuItem = {
    ...rest,
  }
  try {
    const result: CallReturnType<typeof API.updateMenuItem> = yield API.updateMenuItem(data)
    yield put(actions.UPDATE_MENU_ITEM.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(
      actions.UPDATE_MENU_ITEM.failure({
        id: data.id,
        error,
      }),
    )
  }
}

function* createMenuItem(action: ReturnType<typeof actions.CREATE_MENU_ITEM.request>) {
  const { menuId, productId, ...rest } = action.payload
  const data: INewMenuItem = {
    ...rest,
    menu: {
      id: menuId,
      type: ENTITY_TYPE_MENU,
    },
    product: {
      id: productId,
      type: ENTITY_TYPE_PRODUCT,
    },
  }

  try {
    const result: CallReturnType<typeof API.createMenuItem> = yield API.createMenuItem(data)
    yield put(actions.CREATE_MENU_ITEM.success(result))
  } catch (e) {
    if (e instanceof JsonApiError && e.errors.length === 1 && e.errors[0].code === 'unique') {
      // deliberately ignore unique errors
      yield put(actions.CREATE_MENU_ITEM.cancel(productId))
      return
    }
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(
      actions.CREATE_MENU_ITEM.failure({
        productId,
        error,
      }),
    )
  }
}

export function* saga() {
  yield all([
    takeLeading(actions.FETCH_MENUS.request, fetchMenus),
    takeLeading(actions.FETCH_MENUS.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_MENU.request, fetchMenu),
    takeLeading(actions.FETCH_MENU.failure, displayErrorToastSaga),
    takeLeading(actions.DELETE_MENU.request, deleteMenu),
    takeLeading(actions.DELETE_MENU.failure, displayErrorToastSaga),
    takeLeading(actions.CREATE_MENU.request, createMenu),
    takeLeading(actions.UPDATE_MENU.request, updateMenu),
    takeLeading(actions.DELETE_MENU_ITEM.request, deleteMenuItem),
    takeLeading(actions.UPDATE_MENU_ITEM.request, updateMenuItem),
    takeLeading(actions.CREATE_MENU_ITEM.request, createMenuItem),
  ])
}
