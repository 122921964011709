import { forwardRef, Button, ButtonProps, useDisclosure } from '@chakra-ui/react'

import { TMenuAny } from '../../api'
import { MenuForm } from './menu-form'

export interface IMenuFormModalButtonProps extends ButtonProps {
  menu?: TMenuAny
  onSuccess?: (id: string) => void
}

export const MenuFormModalButton = forwardRef<IMenuFormModalButtonProps, 'button'>((props, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { menu, onSuccess, children, ...rest } = props
  const _children = children ? children : menu ? 'Edit List' : 'Create List'
  return (
    <>
      <Button onClick={onOpen} ref={ref} {...rest}>
        {_children}
      </Button>
      {isOpen && <MenuForm isOpen={isOpen} onClose={onClose} menu={menu} onSuccess={onSuccess} />}
    </>
  )
})
