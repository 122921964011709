import React from 'react'
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'

import { useBasketsConfirm } from '../../lib'

export const OrderConfirmationSpinner: React.FC = () => {
  const { isFetching } = useBasketsConfirm()

  if (!isFetching) {
    return null
  }
  return (
    <Modal
      isOpen={true}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => null}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirming Order</ModalHeader>
        <ModalBody>
          <Box as="p">Please wait while your order is confirmed.</Box>
          <Box display="flex" justifyContent="center" p={8}>
            <Spinner
              thickness="4px"
              speed="0.8s"
              emptyColor="gray.100"
              color="green.500"
              size="xl"
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
