import { forwardRef, BoxProps, Box } from '@chakra-ui/react'

import { IAvailableFacet, useHasAuth } from '../../lib'
import { SearchFilters } from './search-filters'
import { FacetButtons } from './facet-buttons'
import { SortMenu } from './sort-menu'

export interface ISearchResultsFiltersProps extends BoxProps {
  sort?: string
  onSort: (field?: string) => void
  facets: {
    available: IAvailableFacet[]
    selected: IAvailableFacet[]
  }
  onAddFacet: (field: string) => void
  onRemoveFacet: (field: string) => void
  onSetFacets: (fields: string[]) => void
  onRemoveAllFacets: () => void
  hideFacets?: string[]
}

export const SearchResultsFilters = forwardRef<ISearchResultsFiltersProps, 'div'>((props, ref) => {
  const {
    sort,
    onSort,
    facets,
    onAddFacet,
    onRemoveFacet,
    onSetFacets,
    onRemoveAllFacets,
    hideFacets,
    ...rest
  } = props

  const { hasAuth } = useHasAuth()
  return (
    <Box ref={ref} {...rest}>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <SearchFilters
          facets={facets.available}
          onAddFacet={onAddFacet}
          onRemoveFacet={onRemoveFacet}
          onSetFacets={onSetFacets}
          onRemoveAllFacets={onRemoveAllFacets}
          hideFacets={hideFacets}
        />
        {hasAuth && <SortMenu sort={sort} onClick={onSort} />}
      </Box>
      <FacetButtons
        facets={facets.selected}
        hideFacets={hideFacets}
        onRemoveFacet={onRemoveFacet}
        onRemoveAll={onRemoveAllFacets}
        mt={-1}
        mb={-1}
        // display={['none', null, 'block']}
      />
    </Box>
  )
})
