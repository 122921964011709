import { forwardRef, BoxProps, Box, Button } from '@chakra-ui/react'

export interface ILoadMoreButtonProps extends BoxProps {
  isLoading?: boolean
  hasMore: boolean
  onClick: () => void
}

export const LoadMoreButton = forwardRef<ILoadMoreButtonProps, 'div'>((props, ref) => {
  const { hasMore, onClick, ...rest } = props

  if (!hasMore) {
    return null
  }

  return (
    <Box my={10} display="flex" justifyContent="center" ref={ref} {...rest}>
      <Button size="lg" onClick={onClick}>
        Load more
      </Button>
    </Box>
  )
})
