import { formatISO } from 'date-fns'
import { APIService } from '../service'
import { IJsonApiModelWithId, IFetchOneQueryOptions } from '../types'

declare module '../service' {
  interface APIService {
    fetchDuplicateOrderCheck(
      addressId: string,
      requestedDeliveryDate: Date,
      requestOpts?: IFetchOneQueryOptions,
    ): Promise<IDuplicateOrderCheck>
    fetchDuplicateOrderCheckById(
      id: string,
      requestOpts?: IFetchOneQueryOptions,
    ): Promise<IDuplicateOrderCheck>
  }
}

export const ENTITY_TYPE_DUPLICATE_ORDER_CHECK = 'duplicate-order-checks'

export interface IDuplicateOrderCheck extends IJsonApiModelWithId {
  id: string
  orderIds: string[]
  basketIds: string[]
}

APIService.prototype.fetchDuplicateOrderCheck = async function (
  addressId: string,
  requestedDeliveryDate: Date,
  requestOpts?: IFetchOneQueryOptions,
) {
  const id = `${formatISO(requestedDeliveryDate, { representation: 'date' })}:${addressId}`
  return this.fetchDuplicateOrderCheckById(id, {
    ...requestOpts,
  })
}

APIService.prototype.fetchDuplicateOrderCheckById = async function (
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  return this.fetchOne<IDuplicateOrderCheck>(ENTITY_TYPE_DUPLICATE_ORDER_CHECK + '/' + id, {
    ...requestOpts,
  })
}
