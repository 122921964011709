import { createStore, applyMiddleware, compose, StoreEnhancer } from 'redux'
import createSagaMiddleware from 'redux-saga'
import * as Sentry from '@sentry/react'

import { IRootState, rootReducer, rootSaga } from './root'

export function configureStore(initialState?: IRootState) {
  const saga = createSagaMiddleware()

  const middleware = [saga]

  const enhancers = [applyMiddleware(...middleware), Sentry.createReduxEnhancer()]

  const enhancer: StoreEnhancer = compose(...enhancers)
  const store = createStore(rootReducer, initialState, enhancer)
  saga.run(rootSaga)

  return { store }
}
