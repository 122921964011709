const parts = ['badge', 'icon']

const baseStyle = {
  badge: {
    bg: 'white',
    verticalAlign: 'top',
    borderColor: 'currentColor',
  },
  icon: {},
}

function getSize(badgeSize: string, iconSize: string) {
  return {
    badge: {
      width: badgeSize,
      height: badgeSize,
    },
    icon: {
      width: iconSize,
      height: iconSize,
    },
  }
}

const sizes = {
  '2xs': getSize('6', '4'),
  xs: getSize('7', '5'),
  sm: getSize('8', '6'),
  md: getSize('12', '9'),
  lg: getSize('16', '12'),
  xl: getSize('24', '20'),
  '2xl': getSize('32', '24'),
  full: getSize('100%', '80%'),
}

const defaultProps = {
  size: 'md',
  borderColor: 'black',
}

export const IconBadge = {
  parts,
  baseStyle,
  sizes,
  defaultProps,
}
