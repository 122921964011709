import { useCallback, useEffect } from 'react'
import { isBefore, addDays } from 'date-fns'
import {
  useDisclosure,
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Image,
  Stack,
} from '@chakra-ui/react'

const APPLINK_BASE_URL = process.env.REACT_APP_APPLINK_URL!
const LOCAL_STORAGE_KEY = 'om:android-app-banner'
const SHOW_AGAIN_AFTER_DAYS = 28
const isAndroid = /Android/i.test(navigator.userAgent)

function shouldOpen() {
  if (!isAndroid) {
    return false
  }

  // check the last closed time
  const lastClosedValue = window.localStorage.getItem(LOCAL_STORAGE_KEY)
  if (!lastClosedValue) {
    return true
  }
  try {
    const lastClosedAt = new Date(lastClosedValue)
    const showAgainAt = addDays(lastClosedAt, SHOW_AGAIN_AFTER_DAYS)
    if (isBefore(new Date(), showAgainAt)) {
      return false
    }
  } catch (error) {
    // could not parse date
  }
  return true
}

function setClosedAt() {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, `${new Date()}`)
}

export const AndroidAppBanner: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    onOpen()
  }, [onOpen])

  const handleClose = useCallback(() => {
    setClosedAt()
    onClose()
  }, [onClose])

  if (!shouldOpen()) {
    return null
  }

  return (
    <Drawer
      placement="bottom"
      onClose={onClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      autoFocus={false}
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerBody py={4} px={6}>
            <Stack direction="row" spacing={4}>
              <Image
                src="/images/app-icon.svg"
                alt="Birchall OrderMate"
                width="52px"
                height="52px"
              />
              <Box>
                <Box as="span" display="block" fontSize="xl">
                  OrderMate
                </Box>{' '}
                <Box as="span" display="block" fontSize="0.8125rem" color="gray.600">
                  Birchall Foodservice
                </Box>
              </Box>
            </Stack>
            <Stack direction="column" spacing={4} mt={4}>
              <Button colorScheme="green" as="a" href={APPLINK_BASE_URL}>
                Open in the App
              </Button>
              <Button colorScheme="green" variant="outline" onClick={handleClose}>
                Continue in Browser
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
