export const Popover = {
  variants: {
    filter: {
      popper: {
        maxW: 'fit-content',
        minW: '3xs',
      },
      body: {
        p: 0,
        // bg: 'gray.100',
      },
    },
  },
}
