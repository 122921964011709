import React, { useState, useMemo } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { formatISO } from 'date-fns'
import { isArray } from 'lodash'
import enGb from 'date-fns/locale/en-GB'
import { InputGroup, Input, InputRightElement, InputProps, forwardRef } from '@chakra-ui/react'

import { OmCalendar } from '../icons'

import 'react-datepicker/dist/react-datepicker.css'
import './date-picker.css'

registerLocale('en-GB', enGb)
setDefaultLocale('en-GB')

export interface IDeliveryDatePickerProps {
  minDate?: Date
  maxDate?: Date
  name?: string
  value?: Date
  enabledDates?: Date[]
  isDisabled?: boolean
  onChange?: (v: Date | undefined) => void
  onCalendarClose?: () => void
}

export const DeliveryDatePicker: React.FC<IDeliveryDatePickerProps> = (props) => {
  const { value, enabledDates, isDisabled, onChange, ...rest } = props
  const [startDate, setStartDate] = useState<Date | null>(value || null)
  const enabledDatesIso = useMemo(
    () => enabledDates?.map((d) => formatISO(d, { representation: 'date' })),
    [enabledDates],
  )

  const isDeliveryDate = (date: Date) => {
    const d = formatISO(date, { representation: 'date' })
    return enabledDatesIso?.includes(d) || false
  }
  return (
    <DatePicker
      {...rest}
      disabled={isDisabled}
      dateFormat="dd/MM/yyyy"
      useWeekdaysShort={false}
      selected={startDate}
      formatWeekDay={(day: string) => day.substr(0, 3)}
      filterDate={isDeliveryDate}
      onChange={(date) => {
        if (!isArray(date)) {
          setStartDate(date)
          if (props.onChange) {
            props.onChange(date ? date : undefined)
          }
        }
      }}
      customInput={<ChakraInput isDisabled={isDisabled} />}
    />
  )
}

export const ChakraInput = forwardRef<InputProps, 'input'>((props, ref) => {
  const { isDisabled } = props
  return (
    <InputGroup>
      <InputRightElement
        pointerEvents="none"
        children={<OmCalendar />}
        opacity={isDisabled ? 0.4 : 1}
      />
      <Input placeholder="Basic usage" ref={ref} {...props} />
    </InputGroup>
  )
})
