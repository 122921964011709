import { forwardRef, Button, ButtonProps, useDisclosure } from '@chakra-ui/react'

import { TicketForm } from './ticket-form'

export interface ITicketFormModalButtonProps extends ButtonProps {
  onSuccess?: (id: string) => void
}

export const TicketFormModalButton = forwardRef<ITicketFormModalButtonProps, 'button'>(
  (props, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { onSuccess, children, ...rest } = props
    const _children = children ? children : 'Create Message'
    return (
      <>
        <Button onClick={onOpen} ref={ref} {...rest}>
          {_children}
        </Button>
        {isOpen && <TicketForm isOpen={isOpen} onClose={onClose} onSuccess={onSuccess} />}
      </>
    )
  },
)
