import { createIcon } from '@chakra-ui/react'

export const OmAllergenEggs = createIcon({
  displayName: 'Eggs',
  viewBox: '0 0 45 62',
  d: 'M 44.227 35.468 C 44.227 50.076 34.326 61.919 22.114 61.919 C 9.9 61.919 0 50.076 0 35.468 C 0 20.859 9.9 0 22.114 0 C 26.694 0 30.948 2.932 34.478 7.369 C 34.554 7.467 32.883 9.412 32.883 9.412 L 33.247 10.915 L 31.75 10.638 L 31.485 11.219 L 30.174 11.113 L 30.58 12.6 L 30.062 13.069 L 30.396 14.207 L 29.375 14.028 L 28.07 16.1 C 28.07 16.1 28.64 15.895 29.343 15.357 C 29.568 15.183 30.079 14.774 30.079 14.774 L 31.458 15.255 L 31.006 14.027 L 31.447 13.677 L 31.549 12.378 L 32.691 12.695 L 33.046 12.417 L 34.541 12.734 L 34.42 11.27 C 34.42 11.27 36.255 9.821 36.333 9.938 C 41.159 17.267 44.227 27.336 44.227 35.468',
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 45 62"
//     id="vector">
//     <path
//         id="path_1"
//         d="M 44.227 35.468 C 44.227 50.076 34.326 61.919 22.114 61.919 C 9.9 61.919 0 50.076 0 35.468 C 0 20.859 9.9 0 22.114 0 C 26.694 0 30.948 2.932 34.478 7.369 C 34.554 7.467 32.883 9.412 32.883 9.412 L 33.247 10.915 L 31.75 10.638 L 31.485 11.219 L 30.174 11.113 L 30.58 12.6 L 30.062 13.069 L 30.396 14.207 L 29.375 14.028 L 28.07 16.1 C 28.07 16.1 28.64 15.895 29.343 15.357 C 29.568 15.183 30.079 14.774 30.079 14.774 L 31.458 15.255 L 31.006 14.027 L 31.447 13.677 L 31.549 12.378 L 32.691 12.695 L 33.046 12.417 L 34.541 12.734 L 34.42 11.27 C 34.42 11.27 36.255 9.821 36.333 9.938 C 41.159 17.267 44.227 27.336 44.227 35.468"
//         fill="#eeae4c"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
