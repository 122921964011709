import {
  Alert,
  AlertIcon,
  Box,
  BoxProps,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  Stack,
  Text,
  Textarea,
  forwardRef,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { CREATE_TICKET_COMMENT, selectTicketCommentCreate } from '../../stores/ticket'

const Schema = Yup.object().shape({
  description: Yup.string().trim().required('Reply required'),
})

export interface ITicketCommentFormValues {
  description: string
}

export interface ITicketCommentFormProps extends Omit<BoxProps, 'children'> {
  ticketId: string
  onSuccess?: (id: string) => void
}

export const TicketCommentForm = forwardRef<ITicketCommentFormProps, 'div'>((props, ref) => {
  const { ticketId, onSuccess, ...boxProps } = props
  const dispatch = useDispatch()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [helpers, setHelpers] = useState<FormikHelpers<ITicketCommentFormValues>>()
  const {
    isFetching: isCreating,
    error: creationError,
    id: createdId,
  } = useSelector(selectTicketCommentCreate)(ticketId)

  const isLoading = hasSubmitted && isCreating
  const apiError = hasSubmitted && creationError

  useEffect(() => {
    if (hasSubmitted && !isLoading && !apiError) {
      if (onSuccess) {
        onSuccess(createdId!)
      }

      // reset the form
      helpers?.resetForm()
    }
  }, [hasSubmitted, isLoading, apiError, onSuccess, createdId, helpers])

  const initialValues: ITicketCommentFormValues = {
    description: '',
  }

  const handleSubmit = (
    { description }: ITicketCommentFormValues,
    helpers: FormikHelpers<ITicketCommentFormValues>,
  ) => {
    setHelpers(helpers)
    dispatch(
      CREATE_TICKET_COMMENT.request({
        ticketId,
        description,
      }),
    )
    setHasSubmitted(true)
  }

  const formBody = (
    <>
      <Stack spacing={4}>
        <Box>
          <Field name="description">
            {({ field, form }: FieldProps) => {
              const { name } = field
              const { touched, errors } = form
              const errorMessage = touched[name] ? errors[name] : ''
              return (
                <FormControl isInvalid={!!errorMessage}>
                  <FormLabel htmlFor={name}>Reply</FormLabel>
                  <InputGroup>
                    <Textarea {...field} id={name} placeholder="Reply" />
                  </InputGroup>
                  <FormHelperText>Required</FormHelperText>
                  {errorMessage && <FormErrorMessage>{`${errorMessage}`}</FormErrorMessage>}
                </FormControl>
              )
            }}
          </Field>
        </Box>
      </Stack>
      {apiError && (
        <Alert status="error" mt={4} p={4} borderRadius="sm">
          <AlertIcon />
          <Box>
            <Text fontWeight="bold">{apiError.message}</Text>
            {/* <Text fontWeight="light">Please check the form and try again.</Text> */}
          </Box>
        </Alert>
      )}
    </>
  )

  return (
    <Box ref={ref} {...boxProps}>
      <Formik initialValues={initialValues} validationSchema={Schema} onSubmit={handleSubmit}>
        {({ isValid }) => (
          <Form>
            {formBody}
            <Box mt={4} display="flex" flexDirection="row-reverse">
              <Button isDisabled={!isValid} type="submit" isLoading={isLoading}>
                Reply
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
})
