export const BASE_URL = process.env.REACT_APP_CMS_API_URL!

export class CmsApiError extends Error {
  status: number

  constructor(status: number, message: string) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
    this.status = status
  }
}

export const fetcher = async (url: string) => {
  const res = await fetch(url)

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    throw new CmsApiError(res.status, res.statusText)
  }

  return res.json()
}
