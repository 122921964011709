import { createSelector } from 'reselect'
import { memoize } from 'lodash'
import { isFuture } from 'date-fns'

import { IRootState } from '../../root'

export const selectOrdersState = (state: IRootState) => state.order.orders

const selectOrdersItems = createSelector(selectOrdersState, (state) => state.items)

const selectOrdersListing = createSelector(selectOrdersState, (state) => state.listing)

const selectOrdersOpenListing = createSelector(selectOrdersState, (state) => state.openListing)

const selectOrdersDetail = createSelector(selectOrdersState, (state) => state.detail)

export const selectOrdersListingResults = createSelector(
  selectOrdersItems,
  selectOrdersListing,
  (items, listing) => {
    return {
      ...listing,
      items: items && listing && listing.ids ? listing.ids.map((id) => items[id]) : [],
      ids: (listing && listing.ids) ?? [],
      hasMore: !!(listing && listing.moreUrl),
    }
  },
)

export const orderDetailSelector = createSelector(
  selectOrdersItems,
  selectOrdersDetail,
  (items, detail) =>
    memoize((id: string) => {
      return {
        item: items && items[id],
        isFetching: detail && detail.isFetching,
        error: detail && detail.error,
      }
    }),
)

export const selectOrdersOpenListingResults = createSelector(
  selectOrdersItems,
  selectOrdersOpenListing,
  (items, listing) => {
    // filter the items by those where the cutoff has not expired
    // and is not already opened for editing
    const ids = ((listing && listing.ids) ?? []).filter((id) => {
      const item = items && items[id]
      if (!item) {
        return false
      }
      if (item.basket) {
        return false
      }
      return isFuture(item.cutoff)
    })
    return {
      ...listing,
      items: items && listing ? ids.map((id) => items[id]) : [],
      ids,
    }
  },
)

const selectOrdersDeleteState = createSelector(selectOrdersState, (state) => state.delete)

export const selectOrdersDelete = createSelector(selectOrdersDeleteState, (state) => {
  return {
    ...state,
    isFetching: state && state.isFetching,
    error: state && state.error,
  }
})

const selectOrdersOpenForEditState = createSelector(selectOrdersState, (state) => state.openForEdit)

export const selectOrdersOpenForEdit = createSelector(selectOrdersOpenForEditState, (state) => {
  return {
    ...state,
    isFetching: state && state.isFetching,
    error: state && state.error,
  }
})
