import { Box, Button, Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IAddress } from '../../api'
import { formatDeliveryDate, useDuplicateOrderCheck } from '../../lib'
import { addressDetailSelector } from '../../stores/account'
import { ConfirmDialog, useConfirmDialog } from '../confirm-dialog'
import { IBasketFormValues } from './basket-form'

interface IBasketFormSubmitButtonsDefaultButtonSetProps {
  isEditing: boolean
  isDisabled: boolean
  isLoading: boolean
  onClose?: () => void
}

const DefaultButtonSet: React.FC<IBasketFormSubmitButtonsDefaultButtonSetProps> = ({
  isDisabled,
  isLoading,
  isEditing,
  onClose,
}) => {
  return (
    <>
      <Button variant="ghost" mr={4} onClick={onClose} colorScheme="gray">
        Cancel
      </Button>
      <Button isDisabled={isDisabled} type="submit" isLoading={isLoading}>
        {isEditing ? 'Update' : 'Submit'}
      </Button>
    </>
  )
}

export interface IOnSelectExistingProps extends Omit<IBasketFormValues, 'date' | 'address'> {
  address: IAddress
  deliveryDate: Date
}

export interface IBasketFormSubmitButtonsProps {
  isEditing: boolean
  asModal?: boolean
  isFormLoading?: boolean // used when the form is submitting
  onClose?: () => void
  onSelectExisting?: (values: IOnSelectExistingProps) => void
}

export const BasketFormSubmitButtons: React.FC<IBasketFormSubmitButtonsProps> = ({
  isEditing,
  isFormLoading,
  onClose,
  onSelectExisting,
}) => {
  const { values, isValid, handleSubmit } = useFormikContext<IBasketFormValues>()

  const { address: addressId, date: deliveryDate } = values
  const address = useSelector(addressDetailSelector)(addressId)
  const {
    isFetching,
    error,
    item: duplicateOrderCheck,
    onCancel,
  } = useDuplicateOrderCheck(addressId, deliveryDate, isEditing)

  const handleSelectExisting = useCallback(() => {
    if (onSelectExisting) {
      if (address && deliveryDate) {
        onSelectExisting({
          ...values,
          address,
          deliveryDate,
        })
      }
    }
  }, [onSelectExisting, address, deliveryDate, values])

  const confirmCreateNewDialog = useConfirmDialog({
    type: 'create-basket-for-same-date',
    onConfirm: handleSubmit,
  })

  const handleCreateNew = useCallback(() => {
    confirmCreateNewDialog.onOpen()
  }, [confirmCreateNewDialog])

  useEffect(() => {
    return () => {
      onCancel()
    }
  }, [onCancel])

  const hasExistingOrder = useMemo(() => {
    if (!duplicateOrderCheck) {
      return false
    }

    return duplicateOrderCheck.orderIds.length > 0 || duplicateOrderCheck.basketIds.length > 0
  }, [duplicateOrderCheck])

  if (isEditing) {
    // when editing we don't care about the duplicate order check
    return (
      <DefaultButtonSet
        isEditing={isEditing}
        isDisabled={!isValid}
        isLoading={!!isFormLoading}
        onClose={onClose}
      />
    )
  }

  if (error || isFetching || !duplicateOrderCheck) {
    // show the default buttons but disabled while fetching the duplicate order check
    return (
      <DefaultButtonSet
        isEditing={isEditing}
        isDisabled={true}
        isLoading={!!isFormLoading || !!isFetching}
        onClose={onClose}
      />
    )
  }

  if (hasExistingOrder && deliveryDate) {
    // when the check has been performed and there's a duplicate
    // show the warning message and associated buttons
    return (
      <Box justifyContent="center" width="100%">
        {confirmCreateNewDialog.isOpen && <ConfirmDialog {...confirmCreateNewDialog} />}
        <Box borderRadius="sm" bg="gray.100" p={4} my={4}>
          <Text>
            You already have an existing order on {formatDeliveryDate(deliveryDate)} to the selected
            address.
          </Text>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button mr={4} variant="ghost" onClick={onClose} colorScheme="gray">
            Cancel
          </Button>
          <Button
            mr={4}
            variant="outline"
            isDisabled={!isValid}
            type="button"
            isLoading={isFormLoading}
            onClick={handleCreateNew}
          >
            Create New
          </Button>
          <Button
            isDisabled={!isValid}
            type="button"
            isLoading={isFormLoading}
            onClick={handleSelectExisting}
          >
            Select Existing
          </Button>
        </Box>
      </Box>
    )
  }

  // when the check has been performed and there's no duplicate
  // show the default buttons
  return (
    <DefaultButtonSet
      isEditing={isEditing}
      isDisabled={!isValid}
      isLoading={!!isFormLoading}
      onClose={onClose}
    />
  )
}
