import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { CHECK_TOKEN, selectUser } from '../../stores/account'

export function useUser() {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!user) {
      dispatch(CHECK_TOKEN())
    }
  }, [dispatch, user])

  return user
}
