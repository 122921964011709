import { isString } from 'lodash'
import { parseISO } from 'date-fns'
import { APIService } from '../service'
import {
  IJsonApiModelWithId,
  IFetchMultipleQueryOptions,
  IFetchMultipleResponse,
  IFetchMultipleResponseMeta,
  IFetchOneQueryOptions,
  IJsonApiModel,
} from '../types'
import { deserializeTransformer } from '../serializer'
import { IAddress } from './address'
import { TOrderItemAny } from './order-item'
import { TBasketWithAddressAndItems } from './basket'

declare module '../service' {
  interface APIService {
    fetchOrders(
      url: string,
    ): Promise<IFetchMultipleResponse<TOrderWithAddressAndItems, IFetchMultipleResponseMeta>>
    fetchOrders(
      requestOpts?: IFetchMultipleQueryOptions,
    ): Promise<IFetchMultipleResponse<TOrderWithAddressAndItems, IFetchMultipleResponseMeta>>
    fetchAllOrders(requestOpts?: IFetchMultipleQueryOptions): Promise<TOrderWithAddressAndItems[]>
    fetchOrder(id: string, requestOpts?: IFetchOneQueryOptions): Promise<TOrderWithAddressAndItems>
    openOrderForEditing(
      id: string,
      requestOpts?: IFetchOneQueryOptions,
    ): Promise<TBasketWithAddressAndItems>
    deleteOrder(id: string): Promise<unknown>
    reorder(data: IReorder): Promise<TBasketWithAddressAndItems>
  }
}

export const ENTITY_TYPE_ORDER = 'orders'
export const ENTITY_TYPE_REORDER = 'order-reorders'

export type TOrderAny = IOrder<IJsonApiModelWithId, IJsonApiModelWithId, IJsonApiModelWithId>
export type TOrderWithAddressAndItems = IOrder<IJsonApiModelWithId, IAddress, TOrderItemAny>

export interface IOrder<
  B extends IJsonApiModelWithId,
  A extends IJsonApiModelWithId,
  I extends IJsonApiModelWithId,
> extends IJsonApiModelWithId {
  id: string
  title: string
  orderNumber: string
  poNumber: string
  status: string
  canEdit: boolean
  canCancel: boolean
  orderDate: Date
  deliverToAddress: string
  requestedDeliveryDate: Date
  cutoff: Date
  netTotal: string
  basket?: B
  deliverTo: A
  items: I[]
}

export interface IReorder extends Omit<IJsonApiModel, 'type'> {
  order: IJsonApiModelWithId
  basket: IJsonApiModelWithId
}

APIService.prototype.fetchOrders = async function (
  requestOptsOrUrl?: IFetchMultipleQueryOptions | string,
) {
  if (isString(requestOptsOrUrl)) {
    return this.fetchMultiple<TOrderWithAddressAndItems, IFetchMultipleResponseMeta>(
      requestOptsOrUrl,
    )
  }
  return this.fetchMultiple<TOrderWithAddressAndItems, IFetchMultipleResponseMeta>(
    ENTITY_TYPE_ORDER,
    {
      ...requestOptsOrUrl,
      include: [...(requestOptsOrUrl?.include || []), 'deliver_to', 'items'],
    },
  )
}

APIService.prototype.fetchAllOrders = async function (requestOpts?: IFetchMultipleQueryOptions) {
  return this.fetchAll<TOrderWithAddressAndItems>(ENTITY_TYPE_ORDER, {
    ...requestOpts,
    include: [...(requestOpts?.include || []), 'deliver_to', 'items'],
  })
}

APIService.prototype.fetchOrder = async function (id: string, requestOpts?: IFetchOneQueryOptions) {
  return this.fetchOne<TOrderWithAddressAndItems>(ENTITY_TYPE_ORDER + '/' + id, {
    ...requestOpts,
    include: [...(requestOpts?.include || []), 'deliver_to', 'items'],
  })
}

APIService.prototype.openOrderForEditing = async function (
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  return this.create<TBasketWithAddressAndItems>(
    ENTITY_TYPE_ORDER + '/' + id + '/edit',
    undefined,
    {
      ...requestOpts,
      include: [...(requestOpts?.include || []), 'deliver_to', 'items'],
    },
  )
}

APIService.prototype.deleteOrder = async function (id: string) {
  return this.delete(`${ENTITY_TYPE_ORDER}/${id}`)
}

APIService.prototype.reorder = async function (data: IReorder) {
  return this.create<TBasketWithAddressAndItems>(
    ENTITY_TYPE_REORDER,
    {
      type: ENTITY_TYPE_REORDER,
      ...data,
    },
    {
      include: ['deliver_to', 'items'],
    },
  )
}

deserializeTransformer.register(ENTITY_TYPE_ORDER, {
  orderDate: (v: string) => parseISO(v),
  requestedDeliveryDate: (v: string) => parseISO(v),
  cutoff: (v: string) => parseISO(v),
})
