import { useMemo } from 'react'
import { Box, forwardRef, BoxProps } from '@chakra-ui/react'
import { TProduct } from '../../api'
import { useProductSubstitutes } from '../../lib'
import { SearchResultsGrid } from '../search-results/search-results-grid'
import { SectionHeading } from './section-heading'
import { SearchResultsSkeleton } from '../search-results'

interface IProductSubstitutesBoxProps extends BoxProps {
  product: TProduct
}

export const ProductSubstitutes = forwardRef<IProductSubstitutesBoxProps, 'div'>((props, ref) => {
  const { product, ...rest } = props
  const { isAvailable, substitutes } = product
  const { items, isFetching } = useProductSubstitutes(product)
  const headingCopy = useMemo(
    () => (count: number) => {
      switch (count) {
        case 0:
          return 'Sorry but this item is temporarily out of stock.'
        case 1:
          return 'Sorry but this item is temporarily out of stock, we have this substitute available'
        default:
          return 'Sorry but this item is temporarily out of stock, we have these substitutes available'
      }
    },
    [],
  )

  if (isAvailable || !substitutes.length || (!isFetching && !items.length)) {
    return null
  }

  return (
    <Box {...rest} ref={ref}>
      <SectionHeading>{headingCopy(items.length)}</SectionHeading>
      {isFetching ? (
        <SearchResultsSkeleton count={substitutes.length} />
      ) : (
        <SearchResultsGrid items={items} />
      )}
    </Box>
  )
})
