import React, { PropsWithChildren } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import API from '../api'
import { PageLoadingSpinner } from './page-loading-spinner'
import { LOGIN_ROUTE } from '../pages'
import { FETCH_USER, selectAccount, selectUser } from '../stores/account'

export const ProtectedWrappedComponent: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const auth = API.getAuth()
  const user = useSelector(selectUser)
  const account = useSelector(selectAccount)

  React.useEffect(() => {
    if (!auth) {
      return
    }
    if (!user || !account) {
      dispatch(FETCH_USER.request(auth.user))
    }
  }, [dispatch, auth, user, account])

  if (!auth) {
    return <Navigate to={LOGIN_ROUTE} />
  }

  if (!user || !account) {
    return <PageLoadingSpinner />
  }
  return <>{children}</>
}

type ProtectedRouteProps = {
  element: React.ReactNode
}

export const ProtectedPage: React.FC<ProtectedRouteProps> = ({ element }) => {
  return <ProtectedWrappedComponent>{element}</ProtectedWrappedComponent>
}
