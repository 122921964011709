import { forwardRef, HTMLChakraProps } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { checkExternalLink } from '../../../lib'
import { ImageLinkBox, ImageLinkBoxGroup } from '../../image-link-box'
import { ResponsiveContainer } from '../../responsive-container'
import { IBaseBlock, IImageValue } from '../types'

export interface IImageLinkBoxesBlock extends IBaseBlock {
  type: 'image_link_boxes'
  value: {
    items: {
      image: IImageValue
      url: string
      text: string
    }[]
  }
}

export interface IImageLinkBoxesBlockProp extends HTMLChakraProps<'div'> {
  block: IImageLinkBoxesBlock
}

export const ImageLinkBoxesBlock = forwardRef<IImageLinkBoxesBlockProp, 'div'>(
  ({ block, ...rest }, ref) => {
    return (
      <ResponsiveContainer ref={ref} {...rest}>
        <ImageLinkBoxGroup>
          {block.value.items.map((item, index) => {
            const { url, isPageLink } = checkExternalLink(item.url)

            if (isPageLink) {
              return (
                <ImageLinkBox key={index} image={item.image.src} as={RouterLink} to={url}>
                  {item.text}
                </ImageLinkBox>
              )
            }

            return (
              <ImageLinkBox key={index} image={item.image.src} href={url}>
                {item.text}
              </ImageLinkBox>
            )
          })}
        </ImageLinkBoxGroup>
      </ResponsiveContainer>
    )
  },
)
