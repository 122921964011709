import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Heading, Image, Link } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'

import { DEFAULT_PAGE_TITLE } from '../lib'
import { LoginForm } from '../components/login-form'
import { HOME_ROUTE } from './home'

export const LOGIN_ROUTE = '/login'

export const LoginPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <Box maxW="28rem" mx="auto" my={8} px={4}>
        <Link as={RouterLink} to={HOME_ROUTE} display="block" my={8} mx="auto" w="16rem">
          <Image src="/images/logo-splash.png" alt="Birchall OrderMate" />
        </Link>
        <Heading as="h1" size="lg" my={8}>
          Sign In
        </Heading>
        <LoginForm />
      </Box>
    </>
  )
}
