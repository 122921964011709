import {
  Box,
  BoxProps,
  forwardRef,
  Button,
  Image,
  Grid,
  Link,
  AspectRatio,
  LinkProps,
} from '@chakra-ui/react'

import { Link as RouterLink } from 'react-router-dom'
import { TBasketItemAny, TOrderItemAny, TMenuItemAny, TProduct } from '../../api'
import { productPath } from '../../lib'
import { IconBadge } from '../icon-badge'
import { OmClock, OmFrozen, OmPromotion } from '../icons'
import { QuantityButton } from '../quantity-button'
import { useMemo } from 'react'

type Item = TBasketItemAny | TOrderItemAny | TMenuItemAny

export type TSubstitutes = {
  substitute: TProduct
  isAlreadyIncluded: boolean
}[]

export interface IOrderItemBoxProps extends BoxProps {
  item: Item
  substitutes: TSubstitutes
  splitsSubtotal: string
  packsSubtotal: string
  canRemove: boolean
  onRemove?: () => void
  canEdit: boolean
  onUpdateSplitQuantity?: (value: number) => void
  onUpdatePackQuantity?: (value: number) => void
  hideOutOfStockMessage?: boolean
}

export const OrderItemBox = forwardRef<IOrderItemBoxProps, 'button'>((props, ref) => {
  const {
    item,
    substitutes,
    splitsSubtotal,
    packsSubtotal,
    canRemove,
    onRemove,
    canEdit,
    onUpdateSplitQuantity,
    onUpdatePackQuantity,
    hideOutOfStockMessage,
    ...rest
  } = props

  const { product, productIsAvailable, hasOrderItem, isFreeDiscount } = item

  const isNotAvailable =
    (!isFreeDiscount && !productIsAvailable && !hasOrderItem && !hideOutOfStockMessage) ||
    (isFreeDiscount && !product)

  const outOfStockMessage = useMemo(() => {
    if (hasOrderItem || hideOutOfStockMessage) {
      return ''
    }
    if (!product) {
      return 'Product not available on OrderMate'
    }

    switch (substitutes.length) {
      case 0:
        return 'Product temporarily unavailable'
      case 1:
        return 'Product temporarily unavailable, we have this substitute available'
      default:
        return 'Product temporarily unavailable, we have these substitutes available'
    }
  }, [hasOrderItem, product, substitutes.length, hideOutOfStockMessage])

  const imageOverlayMessage = useMemo(() => {
    if (
      hasOrderItem ||
      productIsAvailable ||
      hideOutOfStockMessage ||
      (isFreeDiscount && product)
    ) {
      return ''
    }
    if (!product) {
      return 'Product not available'
    }
    return 'Temporarily out of stock'
  }, [hasOrderItem, productIsAvailable, hideOutOfStockMessage, isFreeDiscount, product])

  const handleRemove = () => onRemove && onRemove()

  const images = item.productImages.length ? item.productImages.filter((image) => image.medium) : []
  const image = images.length ? item.productImages[0].medium : '/images/product-placeholder.png'
  const productUrl = product && productPath(product)

  const imageSize = ['4rem', '10rem']
  const hasIcons = item.productIsFrozen || item.productIsOnPromotion || item.productIsPreOrder

  return (
    <Box ref={ref} {...rest}>
      <Box display="flex">
        <Box width={imageSize} height={imageSize} minWidth={imageSize}>
          <WithProductLink productUrl={productUrl} display="block">
            <AspectRatio maxW={imageSize} ratio={1}>
              <Box
                position="absolute"
                top={0}
                left={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%">
                <Image
                  src={image}
                  width="100%"
                  height="100%"
                  objectFit="cover"
                  alt={item.productName}
                  borderRadius="sm"
                />
                {hasIcons && (
                  <Box top={1} left={1} position="absolute">
                    {item.productIsFrozen && (
                      <IconBadge icon={OmFrozen} size="xs" color="#82D0F6" marginRight={1} />
                    )}
                    {item.productIsOnPromotion && (
                      <IconBadge icon={OmPromotion} size="xs" color="coral.500" marginRight={1} />
                    )}
                    {item.productIsPreOrder && (
                      <IconBadge
                        icon={OmClock}
                        size="xs"
                        color="birchallBlue.500"
                        marginRight={1}
                      />
                    )}
                  </Box>
                )}
                {imageOverlayMessage && (
                  <Box
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    backgroundColor="blackAlpha.500"
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textTransform="uppercase"
                    textAlign="center"
                    color="white"
                    fontWeight="bold"
                    fontSize={[8, 'lg', 'xl']}>
                    <Box>{imageOverlayMessage}</Box>
                  </Box>
                )}
              </Box>
            </AspectRatio>
          </WithProductLink>
        </Box>
        <Box
          ml={[2, 4]}
          flexGrow={1}
          minHeight={imageSize}
          display="flex"
          justifyContent="space-between"
          flexDirection="column">
          <Box fontWeight="bold" fontSize="sm">
            <WithProductLink productUrl={productUrl} display="inline-block">
              <Box as="span" display="block" color="gray.500">
                {item.productCode}
              </Box>
              <Box as="span" display="block">
                {item.productName}
              </Box>
            </WithProductLink>
          </Box>
          <Box>
            <Box display="flex" flexDirection="row-reverse">
              <Grid
                display="inline-grid"
                mt={2}
                gap={4}
                textAlign="right"
                alignItems="center"
                gridTemplateColumns={['auto 7rem', 'auto 7rem 5rem']}>
                {item.productCanSplit && (canEdit || item.splitQuantity > 0) && (
                  <>
                    <Box color="gray.600">{item.productSplitSize}</Box>
                    <Box>
                      {canEdit && !isFreeDiscount ? (
                        <QuantityButton
                          value={item.splitQuantity}
                          emptyLabel={`Add ${item.productSplitSize}`}
                          onValueChange={onUpdateSplitQuantity}
                          maxValue={item.productPackQuantity - 1}
                        />
                      ) : (
                        <Box as="span" color="gray.600">
                          x {item.splitQuantity}
                        </Box>
                      )}
                    </Box>
                    <Box color="gray.600" display={['none', 'block']}>
                      £{splitsSubtotal}
                    </Box>
                  </>
                )}

                <Box color="gray.600">{item.productPackSize || 'Case'}</Box>
                <Box>
                  {canEdit && !isFreeDiscount ? (
                    <QuantityButton
                      value={item.packQuantity}
                      emptyLabel={`Add ${item.productPackSize}`}
                      onValueChange={onUpdatePackQuantity}
                      maxValue={999}
                    />
                  ) : (
                    <Box as="span" color="gray.600">
                      x {item.packQuantity}
                    </Box>
                  )}
                </Box>
                <Box color="gray.600" display={['none', 'block']}>
                  £{packsSubtotal}
                </Box>
              </Grid>
            </Box>
            {isNotAvailable && (
              <Box my={4} color="coral.500" fontWeight="bold">
                <Box>{outOfStockMessage}</Box>
                {substitutes.length > 0 && (
                  <Box as="ul" listStyleType="none">
                    {substitutes.map(({ substitute, isAlreadyIncluded }) => (
                      <Box as="li" key={substitute.id} fontWeight="normal">
                        <WithProductLink productUrl={productPath(substitute)}>
                          {substitute.name} {isAlreadyIncluded && '(already added)'}
                        </WithProductLink>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )}
            <Box display="flex" flexDirection="row-reverse" mt={4}>
              <Grid
                display="inline-grid"
                mt={2}
                gap={4}
                textAlign="right"
                alignItems="center"
                gridTemplateColumns={['auto 7rem', 'auto 5rem']}>
                {canRemove && (
                  <Box>
                    <Button
                      variant="link"
                      colorScheme={!isNotAvailable ? 'black' : 'coral'}
                      fontWeight={!isNotAvailable ? '' : '700'}
                      onClick={handleRemove}>
                      Remove
                    </Button>
                  </Box>
                )}
                <Box fontSize="lg" fontWeight="bold">
                  £{item.subtotal}
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
})

interface IWithProductLinkProps extends Omit<LinkProps, 'to' | 'as'> {
  productUrl?: string
}

const WithProductLink: React.FC<IWithProductLinkProps> = ({ productUrl, children, ...rest }) => {
  const _children = productUrl ? (
    <Link as={RouterLink} to={productUrl} {...rest}>
      {children}
    </Link>
  ) : (
    children
  )
  return <>{_children}</>
}
