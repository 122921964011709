import { ActionType, getType } from 'typesafe-actions'
import { zipObject } from 'lodash'

import { IAllRequest, IRequestDetail } from '../types'
import { TMenuWithItems } from '../../api'

import * as actions from './actions'
import { calculateItemsTotal } from './utils'

interface IState {
  items?: { [key: string]: TMenuWithItems }
  listing?: IAllRequest<TMenuWithItems>
  detail?: IRequestDetail<Error>
  delete?: IRequestDetail<Error>
  create?: IRequestDetail<Error>
  update?: IRequestDetail<Error>
  updateItems?: { [key: string]: IRequestDetail<Error> } // keyed by item id
  createItems?: { [key: string]: IRequestDetail<Error> } // keyed by product id
  selected?: string
}

const initialState: IState = {}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions>,
): IState => {
  switch (action.type) {
    case getType(actions.FETCH_MENUS.request): {
      const { listing } = state
      return {
        ...state,
        listing: {
          ...listing,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_MENUS.success): {
      const data = action.payload
      const ids = data.map((p) => p.id)
      const items = zipObject(ids, data)
      const { listing } = state
      return {
        ...state,
        items: { ...(state.items || {}), ...items },
        listing: {
          ...listing,
          isFetching: false,
          ids: [...ids],
          // moreUrl: links.next ?? undefined,
          totalCount: data.length,
        },
      }
    }

    case getType(actions.FETCH_MENU.request): {
      return {
        ...state,
        detail: {
          id: action.payload,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_MENU.success): {
      const item = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
        detail: {
          id: item.id,
          isFetching: false,
        },
      }
    }

    case getType(actions.DELETE_MENU.request): {
      return {
        ...state,
        delete: {
          id: action.payload,
          isFetching: true,
        },
      }
    }

    case getType(actions.DELETE_MENU.success): {
      const id = action.payload
      const { listing } = state
      const ids = listing?.ids?.filter((i) => i !== id)
      const items = { ...state.items }
      delete items[id]
      return {
        ...state,
        items,
        listing: {
          ...listing,
          ids,
        },
        delete: {
          ...state.delete,
          error: undefined,
          isFetching: false,
        },
      }
    }

    case getType(actions.DELETE_MENU.failure): {
      return {
        ...state,
        delete: {
          ...state.delete,
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.CREATE_MENU.request): {
      return {
        ...state,
        create: {
          isFetching: true,
        },
      }
    }

    case getType(actions.CREATE_MENU.success): {
      const item = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
        create: {
          id: item.id,
          isFetching: false,
        },
      }
    }

    case getType(actions.CREATE_MENU.failure): {
      return {
        ...state,
        create: {
          ...state.create,
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.UPDATE_MENU.request): {
      const id = action.payload.id
      return {
        ...state,
        update: {
          id,
          isFetching: true,
        },
      }
    }

    case getType(actions.UPDATE_MENU.success): {
      const item = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
        update: {
          id: item.id,
          isFetching: false,
        },
      }
    }

    case getType(actions.UPDATE_MENU.failure): {
      return {
        ...state,
        update: {
          ...state.update,
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.DELETE_MENU_ITEM.request):
    case getType(actions.UPDATE_MENU_ITEM.request): {
      const { id } = action.payload
      return {
        ...state,
        updateItems: {
          ...state.updateItems,
          [id]: {
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.CREATE_MENU_ITEM.request): {
      const { productId } = action.payload
      return {
        ...state,
        createItems: {
          ...state.createItems,
          [productId]: {
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.CREATE_MENU_ITEM.success):
    case getType(actions.UPDATE_MENU_ITEM.success): {
      const isCreate = action.type === getType(actions.CREATE_MENU_ITEM.success)
      const menuItem = action.payload
      const items = { ...state.items }
      const menu = items[menuItem.menu.id]
      if (menu) {
        // create the updated menu
        const updatedItems = menu.items.map((item) => {
          if (item.id === menuItem.id) {
            return {
              ...menuItem,
            }
          }
          return {
            ...item,
          }
        })
        if (isCreate) {
          updatedItems.push(menuItem)
        }
        const updatedMenu = {
          ...menu,
          items: updatedItems,
        }
        // recalculate the total
        updatedMenu.netTotal = calculateItemsTotal(updatedMenu.items)
        items[updatedMenu.id] = updatedMenu
      }
      const updateItems = { ...state.updateItems }
      delete updateItems[menuItem.id]

      const extra = !isCreate
        ? undefined
        : {
            createItems: {
              ...state.createItems,
              [menuItem.productCode]: {
                id: menuItem.id,
                isFetching: false,
              },
            },
          }
      return {
        ...state,
        items: {
          ...items,
        },
        updateItems,
        ...extra,
      }
    }

    case getType(actions.DELETE_MENU_ITEM.failure):
    case getType(actions.UPDATE_MENU_ITEM.failure): {
      const { id, error } = action.payload
      return {
        ...state,
        updateItems: {
          ...state.updateItems,
          [id]: {
            isFetching: false,
            error,
          },
        },
      }
    }

    case getType(actions.DELETE_MENU_ITEM.success): {
      const { id: menuItemId, menuId } = action.payload
      const items = { ...state.items }
      const menu = items[menuId]
      if (menu) {
        // create the updated menu
        const updatedMenu = {
          ...menu,
          items: menu.items.filter((i) => i.id !== menuItemId),
        }
        // recalculate the total
        updatedMenu.netTotal = calculateItemsTotal(updatedMenu.items)
        items[updatedMenu.id] = updatedMenu
      }
      const updateItems = { ...state.updateItems }
      delete updateItems[menuItemId]
      return {
        ...state,
        items: {
          ...items,
        },
        updateItems,
      }
    }

    case getType(actions.CREATE_MENU_ITEM.failure): {
      const { productId, error } = action.payload
      return {
        ...state,
        createItems: {
          ...state.createItems,
          [productId]: {
            isFetching: false,
            error,
          },
        },
      }
    }

    case getType(actions.CREATE_MENU_ITEM.cancel): {
      const productId = action.payload
      const createItems = {
        ...state.createItems,
      }
      delete createItems[productId]
      return {
        ...state,
        ...createItems,
      }
    }

    case getType(actions.SELECT_MENU): {
      return {
        ...state,
        selected: action.payload,
      }
    }

    case getType(actions.UNSELECT_MENU): {
      const s = {
        ...state,
      }
      delete s.selected
      return s
    }
  }

  return state
}
