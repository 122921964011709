import { useParams } from 'react-router-dom'
import { VStack } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'

import { PageLayout } from '../components/page-layout'
import { ResponsiveContainer } from '../components/responsive-container'
import { Breadcrumb } from '../components/breadcrumb'
import { TicketBox } from '../components/ticket-box'
import { TicketCommentBox } from '../components/ticket-comment-box'
import { TicketCommentForm } from '../components/ticket-comment-form'
import { PageLoadingSpinner } from '../components/page-loading-spinner'

import { TICKETS_ROUTE } from './tickets'
import { ticketPath, useTicketDetail, DEFAULT_PAGE_TITLE, isTicketOpen } from '../lib'

export const TICKET_ROUTE = '/messages/:ticketId'

export interface ITicketRoute {
  ticketId: string
}

export const TicketPage: React.FC = () => {
  const { ticketId = '' } = useParams<keyof ITicketRoute>()
  const { item, comments, isFetching } = useTicketDetail(ticketId)

  const breadcrumbs = [
    {
      name: 'Messages',
      to: TICKETS_ROUTE,
    },
    {
      name: item ? item.subject : '',
      to: item ? ticketPath(item) : '#',
    },
  ]

  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          <Breadcrumb items={breadcrumbs} as="h2" />
          {isFetching || comments.isFetching ? (
            <PageLoadingSpinner />
          ) : (
            <VStack spacing={4} align="stretch">
              {item && <TicketBox ticket={item} showViewButton={false} />}
              {comments.items.map((comment) => (
                <TicketCommentBox key={comment.id} comment={comment} />
              ))}
              {item && isTicketOpen(item) && (
                <TicketCommentForm
                  borderWidth={1}
                  p={[3, 4]}
                  borderRadius="sm"
                  ticketId={item.id}
                />
              )}
            </VStack>
          )}
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
