import { Box, BoxProps, forwardRef, Button, Stack, StackDivider } from '@chakra-ui/react'

import { TMenuItemAny, TMenuWithItems } from '../../api'

import { DefinitionList, DefinitionTerm, Definition } from '../order-box'
import { MenuFormModalButton } from '../menu-form'
import { useConfirmDialog, ConfirmDialog } from '../confirm-dialog'
import { BasketFormModalButton } from '../basket-form'

import { ItemBox } from './item-box'
import { useItemSubstitutes, useMenu } from '../../lib'

export interface IOrderPageContentsProps extends BoxProps {
  menu: TMenuWithItems
}

export const MenuPageContents = forwardRef<IOrderPageContentsProps, 'button'>((props, ref) => {
  const { menu, ...rest } = props

  const { onDelete, handleCreateBasketSuccess, handleSelectExisting, canCreateOrder } =
    useMenu(menu)

  const { substitutes } = useItemSubstitutes(menu)

  const deleteDialog = useConfirmDialog({
    type: 'delete-menu',
    menuName: menu.title,
    onConfirm: onDelete,
  })

  const handleDelete = () => {
    deleteDialog.onOpen()
  }

  return (
    <Box ref={ref} {...rest}>
      {deleteDialog.isOpen && <ConfirmDialog {...deleteDialog} />}
      <Box display={[null, null, 'flex']} flexDirection="row-reverse" mt={4}>
        <Box my={4} width={['auto', null, 72]} minWidth={['auto', null, 72]} ml={[0, null, 4]}>
          <Box borderWidth={1} borderRadius="sm" p={4}>
            <DefinitionList>
              <DefinitionTerm m={0}>Name</DefinitionTerm>
              <Definition>{menu.title}</Definition>

              <DefinitionTerm>Description</DefinitionTerm>
              <Definition whiteSpace="pre-wrap">{menu.description || '—'}</Definition>
            </DefinitionList>

            <MenuFormModalButton menu={menu} variant="outline" mt={4}>
              Change
            </MenuFormModalButton>
          </Box>
        </Box>

        <Box flexGrow={1}>
          <Stack direction="column" divider={<StackDivider borderColor="gray.200" />} spacing={4}>
            {(menu.items as TMenuItemAny[]).map((item, index) => (
              <ItemBox
                key={index}
                menu={menu}
                item={item}
                substitutes={substitutes[item.productCode] ?? []}
              />
            ))}
          </Stack>

          <Box bg="gray.100" borderRadius="sm" p={4} my={4}>
            <Box as="dl" display="grid" gridTemplateColumns="auto auto" gridGap={4} fontSize="xl">
              <DefinitionTerm>Lines</DefinitionTerm>
              <Definition textAlign="right">{menu.items.length}</Definition>

              <DefinitionTerm mt={0}>Total</DefinitionTerm>
              <Definition textAlign="right">£{menu.netTotal}</Definition>
            </Box>

            <Box
              mt={4}
              display="flex"
              flexDirection={['column', null, 'row']}
              justifyContent="space-between"
              alignItems={['stretch']}
            >
              <Button variant="outline" onClick={handleDelete}>
                Delete List
              </Button>

              <BasketFormModalButton
                menu={menu}
                mt={[4, null, 0]}
                onCreated={handleCreateBasketSuccess}
                onSelectExisting={handleSelectExisting}
                isDisabled={!canCreateOrder}
              >
                Create Order
              </BasketFormModalButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
})
