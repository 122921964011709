import { useSelector } from 'react-redux'

import { selectBasketsSelectedBasket } from '../../stores/order'

export function useSelectedBasket() {
  const selected = useSelector(selectBasketsSelectedBasket)

  return {
    basket: selected?.basket,
  }
}
