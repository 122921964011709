import { all, put, takeLeading, select, take, call } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import { formatISO } from 'date-fns'

import API, { CallReturnType, IFetchMultipleQueryOptions } from '../../../api'
import { displayErrorToastSaga, DEFAULT_PAGE_SIZE, ensureError } from '../../../lib'
import { selectBasketsSelectedBasket, UNSELECT_BASKET, SELECT_BASKET } from '..'
import { selectOrdersListingResults } from './selectors'
import * as actions from './actions'

function* fetchOrders() {
  try {
    const result: CallReturnType<typeof API.fetchOrders> = yield API.fetchOrders({
      pageSize: DEFAULT_PAGE_SIZE,
    })
    yield put(actions.FETCH_ORDERS.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_ORDERS.failure(error))
  }
}

function* fetchMoreOrders() {
  try {
    const { moreUrl }: ReturnType<typeof selectOrdersListingResults> = yield select(
      selectOrdersListingResults,
    )
    const response: CallReturnType<typeof API.fetchOrders> = yield API.fetchOrders(moreUrl!)
    yield put(actions.FETCH_MORE_ORDERS.success(response))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_MORE_ORDERS.failure(error))
  }
}

function* fetchOrder(action: ReturnType<typeof actions.FETCH_ORDER.request>) {
  try {
    const order: CallReturnType<typeof API.fetchOrder> = yield API.fetchOrder(action.payload)
    yield put(actions.FETCH_ORDER.success(order))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_ORDER.failure(error))
  }
}

function* fetchOpenOrders(action: ReturnType<typeof actions.FETCH_OPEN_ORDERS.request>) {
  const { payload } = action
  const filters: { [key: string]: string | number } = payload
    ? {
        requested_delivery_date: formatISO(payload.requestedDeliveryDate, {
          representation: 'date',
        }),
        deliver_to: payload.addressId,
        is_editable: 'true',
      }
    : {
        'requested_delivery_date.gt': formatISO(new Date(), { representation: 'date' }),
      }
  const requestOpts: IFetchMultipleQueryOptions = {
    filters,
  }
  try {
    const result: CallReturnType<typeof API.fetchAllOrders> = yield API.fetchAllOrders(requestOpts)
    yield put(actions.FETCH_OPEN_ORDERS.success(result))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_OPEN_ORDERS.failure(error))
  }
}

function* openOrderForEditing(action: ReturnType<typeof actions.OPEN_ORDER_FOR_EDITING.request>) {
  try {
    const basket: CallReturnType<typeof API.openOrderForEditing> = yield API.openOrderForEditing(
      action.payload,
    )
    const order: CallReturnType<typeof API.fetchOrder> = yield API.fetchOrder(action.payload)
    yield put(
      actions.OPEN_ORDER_FOR_EDITING.success({
        basket,
        order,
      }),
    )
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.OPEN_ORDER_FOR_EDITING.failure(error))
  }
}

function* deleteOrder(action: ReturnType<typeof actions.DELETE_ORDER.request>) {
  const orderId = action.payload
  try {
    yield API.deleteOrder(orderId)
    yield put(actions.DELETE_ORDER.success(orderId))

    // check if the selected basket was related to the cancelled order
    // if so unselect it
    const selected: ReturnType<typeof selectBasketsSelectedBasket> = yield select(
      selectBasketsSelectedBasket,
    )
    if (selected?.basket?.order?.id === orderId) {
      yield put(UNSELECT_BASKET())
    }
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.DELETE_ORDER.failure(error))
  }
}

function* selectOrder(action: ReturnType<typeof actions.SELECT_ORDER.request>) {
  const { orderId, redirectAfterSelect } = action.payload
  yield put(actions.OPEN_ORDER_FOR_EDITING.request(orderId))

  // wait for the basket to be created
  // @ts-ignore
  const resultAction: ActionType<typeof actions.OPEN_ORDER_FOR_EDITING> = yield take([
    actions.OPEN_ORDER_FOR_EDITING.success,
    actions.OPEN_ORDER_FOR_EDITING.failure,
  ])

  if (resultAction.type === getType(actions.OPEN_ORDER_FOR_EDITING.success)) {
    const { basket } = resultAction.payload
    yield put(SELECT_BASKET.request({ basketId: basket.id, redirectAfterSelect }))
    yield put(actions.SELECT_ORDER.success())
  } else {
    const failureActionError = resultAction.payload
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, failureActionError)
    yield put(actions.SELECT_ORDER.failure(error))
  }
}

export function* saga() {
  yield all([
    takeLeading(actions.FETCH_ORDERS.request, fetchOrders),
    takeLeading(actions.FETCH_ORDERS.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_MORE_ORDERS.request, fetchMoreOrders),
    takeLeading(actions.FETCH_MORE_ORDERS.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_ORDER.request, fetchOrder),
    takeLeading(actions.FETCH_ORDER.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_OPEN_ORDERS.request, fetchOpenOrders),
    takeLeading(actions.FETCH_OPEN_ORDERS.failure, displayErrorToastSaga),
    takeLeading(actions.OPEN_ORDER_FOR_EDITING.request, openOrderForEditing),
    takeLeading(actions.DELETE_ORDER.request, deleteOrder),
    takeLeading(actions.DELETE_ORDER.failure, displayErrorToastSaga),
    takeLeading(actions.SELECT_ORDER.request, selectOrder),
    takeLeading(actions.SELECT_ORDER.failure, displayErrorToastSaga),
  ])
}
