import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { TMenuAny, TMenuItemAny } from '../../api'
import { getItemSubtotals } from '../utils'
import { DELETE_MENU_ITEM, UPDATE_MENU_ITEM, CREATE_MENU_ITEM } from '../../stores/menu'

export function useMenuItemDetail(productId: string, menu?: TMenuAny, item?: TMenuItemAny) {
  const dispatch = useDispatch()

  const { hasProduct, canEdit, canRemove, splitsSubtotal, packsSubtotal } = useMemo(() => {
    const hasProduct = !!item?.product
    const canEdit = menu && (!item || hasProduct) ? true : false

    const canRemove = menu && item ? true : false
    const subtotals = item
      ? getItemSubtotals(item)
      : {
          splitsSubtotal: '0.00',
          packsSubtotal: '0.00',
        }
    return {
      hasProduct,
      canEdit,
      canRemove,
      ...subtotals,
    }
  }, [menu, item])

  const onRemove = useCallback(() => {
    if (!menu || !item) {
      return
    }
    dispatch(
      DELETE_MENU_ITEM.request({
        id: item.id,
        menuId: menu.id,
      }),
    )
  }, [menu, item, dispatch])

  const onUpdate = useCallback(
    (packQuantity: number, splitQuantity: number) => {
      if (!menu) {
        return
      }
      if (item) {
        if (splitQuantity < 1 && packQuantity < 1) {
          onRemove()
        } else {
          dispatch(
            UPDATE_MENU_ITEM.request({
              id: item.id,
              menuId: menu.id,
              packQuantity,
              splitQuantity,
            }),
          )
        }
      } else if (splitQuantity > 0 || packQuantity > 0) {
        dispatch(
          CREATE_MENU_ITEM.request({
            menuId: menu.id,
            productId,
            packQuantity,
            splitQuantity,
          }),
        )
      }
    },
    [menu, item, productId, dispatch, onRemove],
  )

  const onUpdateSplitQuantity = useCallback(
    (quantity: number) => {
      onUpdate(item?.packQuantity ?? 0, quantity)
    },
    [item, onUpdate],
  )

  const onUpdatePackQuantity = useCallback(
    (quantity: number) => {
      onUpdate(quantity, item?.splitQuantity ?? 0)
    },
    [item, onUpdate],
  )

  return {
    hasProduct,
    canEdit,
    canRemove,
    splitsSubtotal,
    packsSubtotal,
    onRemove,
    onUpdateSplitQuantity,
    onUpdatePackQuantity,
  }
}
