import { all, call, cancel, fork, put, take, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import API, { CallReturnType } from '../../../api'
import { displayErrorToastSaga, ensureError } from '../../../lib'
import * as actions from './actions'

function* fetchOrderSettings(dateToday: Date) {
  try {
    const cutoff: CallReturnType<typeof API.fetchOrderSettings> = yield call(
      [API, API.fetchOrderSettings],
      dateToday,
    )
    yield put(actions.FETCH_ORDER_SETTINGS.success(cutoff))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_ORDER_SETTINGS.failure(error))
  }
}

function* orderSettingsRequest(action: ReturnType<typeof actions.FETCH_ORDER_SETTINGS.request>) {
  const dateToday = new Date()

  // @ts-ignore
  const task: any = yield fork(fetchOrderSettings, dateToday)

  // wait for the check to be complete or cancelled
  // @ts-ignore
  const resultAction: ActionType<typeof actions.FETCH_ORDER_SETTINGS> = yield take([
    actions.FETCH_ORDER_SETTINGS.success,
    actions.FETCH_ORDER_SETTINGS.failure,
    actions.FETCH_ORDER_SETTINGS.cancel,
  ])

  if (resultAction.type === getType(actions.FETCH_ORDER_SETTINGS.cancel)) {
    yield cancel(task)
  }
}

export function* saga() {
  yield all([takeEvery(actions.FETCH_ORDER_SETTINGS.request, orderSettingsRequest)])
  yield all([takeEvery(actions.FETCH_ORDER_SETTINGS.failure, displayErrorToastSaga)])
}
