import { createStandaloneToast } from '@chakra-ui/react'
import { PayloadAction } from 'typesafe-actions'
import { call } from 'redux-saga/effects'
import * as Sentry from '@sentry/react'
import { theme } from '../theme'
import { JsonApiError } from '../api'
import { AccountOnHoldError } from '.'

const { toast } = createStandaloneToast({
  theme,
})

export const displayErrorToast = (error: string | Error) => {
  var message = 'An unknown error occurred'
  if (typeof error === 'string') {
    message = error
  } else if (error instanceof JsonApiError) {
    message = error.message
  } else if (error instanceof AccountOnHoldError) {
    message = error.message
  }

  toast({
    title: 'An error occurred.',
    description: message,
    status: 'error',
    duration: 3000,
    isClosable: true,
  })

  if (!(error instanceof JsonApiError) && !(error instanceof AccountOnHoldError)) {
    console.log(error)
    Sentry.captureException(error)
  }
}

export function* displayErrorToastSaga(action: PayloadAction<any, Error | string>) {
  yield call(displayErrorToast, action.payload)
}
