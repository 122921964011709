import { ERROR_MESSAGE_ACCOUNT_ON_HOLD } from '.'

export class AccountOnHoldError extends Error {
  constructor() {
    const message = ERROR_MESSAGE_ACCOUNT_ON_HOLD
    super(message)
    this.name = this.constructor.name
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
  }
}
