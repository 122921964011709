import { ActionType, getType } from 'typesafe-actions'
import { uniqBy } from 'lodash'

import { IFetchedResources } from '../../types'
import { TCategoryAny } from '../../../api'

import * as actions from './actions'

interface IState extends IFetchedResources<TCategoryAny> {
  hasRoots: boolean
}

const initialState: IState = {
  isFetching: false,
  hasRoots: false,
}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions>,
): IState => {
  switch (action.type) {
    case getType(actions.FETCH_ROOT_CATEGORIES.request): {
      return {
        ...state,
        hasRoots: false,
        isFetching: true,
      }
    }

    case getType(actions.FETCH_ROOT_CATEGORIES.success): {
      return {
        ...state,
        items: action.payload,
        hasRoots: true,
        isFetching: false,
      }
    }

    case getType(actions.FETCH_CATEGORY.request): {
      return {
        ...state,
        isFetching: true,
      }
    }

    case getType(actions.FETCH_CATEGORY.success): {
      const category = action.payload
      const { ancestors, children } = category
      const items = uniqBy([...ancestors, ...children, category, ...(state.items || [])], 'id')
      return {
        ...state,
        items,
        isFetching: false,
      }
    }
  }

  return state
}
