import {
  Box,
  BoxProps,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  forwardRef,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { HOME_ROUTE, RESET_PASSWORD_ROUTE } from '../pages'
import { ILoginRequest, LOGIN, selectLogin, selectNextUrl } from '../stores/account'
import { OmChevronLeft } from './icons'

const Schema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .required('Email address is required')
    .email('Invalid email address'),
  password: Yup.string()
    .trim()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
})

export const LoginForm = forwardRef<BoxProps, 'button'>((props, ref) => {
  const login = useSelector(selectLogin)
  const error = login && login.error
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const nextUrl = useSelector(selectNextUrl)

  const handleSubmit = async (values: ILoginRequest) => {
    dispatch(LOGIN.request(values))
  }

  return (
    <Box ref={ref} {...props}>
      {error && (
        <Box borderWidth={1} borderColor="red.500" borderRadius="sm" p={4} mb={4}>
          <Box color="red.500">
            <strong>{error}</strong>
          </Box>
          <Box>Please check your email and password</Box>
        </Box>
      )}
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validateOnMount={true}
        validationSchema={Schema}
        onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Stack spacing={4}>
              <Box>
                <Field name="username">
                  {({ field, form }: FieldProps) => {
                    const isFieldInvalid =
                      form.errors.username !== undefined && form.touched.username !== undefined
                    return (
                      <FormControl isInvalid={isFieldInvalid}>
                        <FormLabel htmlFor="username">Email address</FormLabel>
                        <InputGroup>
                          <Input
                            {...field}
                            id="username"
                            placeholder="Email address"
                            autoFocus={true}
                          />
                        </InputGroup>
                        <FormErrorMessage>{`${form.errors.username}`}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
              </Box>
              <Box>
                <Field name="password">
                  {({ field, form }: FieldProps) => {
                    const isFieldInvalid =
                      form.errors.password !== undefined && form.touched.password !== undefined
                    return (
                      <FormControl isInvalid={isFieldInvalid}>
                        <FormLabel htmlFor="password">Password</FormLabel>
                        <InputGroup>
                          <Input {...field} id="password" placeholder="Password" type="password" />
                        </InputGroup>
                        <FormErrorMessage>{`${form.errors.password}`}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
              </Box>

              <Box>
                <Button
                  type="submit"
                  w="100%"
                  isLoading={props.isSubmitting}
                  isDisabled={!props.isValid}>
                  Login
                </Button>
              </Box>
              <Box display="flex" justifyContent="space-between">
                {nextUrl ? (
                  <Button
                    variant="link"
                    colorScheme="green"
                    as={RouterLink}
                    to={nextUrl ?? HOME_ROUTE}
                    leftIcon={<OmChevronLeft />}
                    iconSpacing={0}>
                    Back
                  </Button>
                ) : (
                  <Button
                    variant="link"
                    colorScheme="green"
                    onClick={() => navigate(-1)}
                    leftIcon={<OmChevronLeft />}
                    iconSpacing={0}>
                    Back
                  </Button>
                )}
                <Button
                  variant="link"
                  colorScheme="green"
                  as={RouterLink}
                  to={RESET_PASSWORD_ROUTE}>
                  Forgot Password?
                </Button>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
})
