import { APIService } from '../service'
import { IJsonApiModelWithId, IFetchMultipleQueryOptions } from '../types'

declare module '../service' {
  interface APIService {
    fetchAllAddresses(requestOpts?: IFetchMultipleQueryOptions): Promise<IAddress[]>
  }
}

export const ENTITY_TYPE_ADDRESS = 'addresses'

export interface IDeliveryDays {
  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  satuday: boolean
  sunday: boolean
}

export interface IAddress extends IJsonApiModelWithId {
  code: string
  addressLine1: string
  addressLine2: string
  addressLine3: string
  city: string
  county: string
  postcode: string
  country: string
  deliveryDays: IDeliveryDays
}

APIService.prototype.fetchAllAddresses = async function (requestOpts?: IFetchMultipleQueryOptions) {
  return this.fetchAll<IAddress>(ENTITY_TYPE_ADDRESS, requestOpts)
}
