import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { FETCH_NON_DELIVERY_DAYS, selectNonDeliveryDays } from '../../stores/order'

export function useNonDeliveryDays() {
  const dispatch = useDispatch()
  const nonDeliveryDays = useSelector(selectNonDeliveryDays)

  useEffect(() => {
    dispatch(FETCH_NON_DELIVERY_DAYS.request())
  }, [dispatch])

  return nonDeliveryDays
}
