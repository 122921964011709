import { createAsyncAction, createAction } from 'typesafe-actions'
import { TMenuWithItems, TMenuItemAny } from '../../api'
import { IBasketItemData } from '../types'

export const FETCH_MENUS = createAsyncAction(
  'menu/FETCH_MENUS',
  'menu/FETCH_MENUS_SUCCESS',
  'menu/FETCH_MENUS_FAILURE',
  'menu/FETCH_MENUS_CANCEL',
)<undefined, TMenuWithItems[], Error, undefined>()

export const FETCH_MENU = createAsyncAction(
  'menu/FETCH_MENU',
  'menu/FETCH_MENU_SUCCESS',
  'menu/FETCH_MENU_FAILURE',
  'menu/FETCH_MENU_CANCEL',
)<string, TMenuWithItems, Error, undefined>()

export const DELETE_MENU = createAsyncAction(
  'menu/DELETE_MENU',
  'menu/DELETE_MENU_SUCCESS',
  'menu/DELETE_MENU_FAILURE',
  'menu/DELETE_MENU_CANCEL',
)<string, string, Error, undefined>()

export interface ICreateMenuPayload {
  title: string
  description: string
}

export const CREATE_MENU = createAsyncAction(
  'menu/CREATE_MENU',
  'menu/CREATE_MENU_SUCCESS',
  'menu/CREATE_MENU_FAILURE',
  'menu/CREATE_MENU_CANCEL',
)<ICreateMenuPayload, TMenuWithItems, Error, undefined>()

export interface IUpdateMenuPayload {
  id: string
  title: string
  description: string
}

export const UPDATE_MENU = createAsyncAction(
  'menu/UPDATE_MENU',
  'menu/UPDATE_MENU_SUCCESS',
  'menu/UPDATE_MENU_FAILURE',
  'menu/UPDATE_MENU_CANCEL',
)<IUpdateMenuPayload, TMenuWithItems, Error, undefined>()

export interface IUpdateMenuItemPayload {
  id: string
  menuId: string
  packQuantity: number
  splitQuantity: number
}

export const UPDATE_MENU_ITEM = createAsyncAction(
  'menu/UPDATE_MENU_ITEM',
  'menu/UPDATE_MENU_ITEM_SUCCESS',
  'menu/UPDATE_MENU_ITEM_FAILURE',
)<IUpdateMenuItemPayload, TMenuItemAny, { id: string; error: Error }>()

export interface IDeleteMenuItemPayload {
  id: string
  menuId: string
}

export const DELETE_MENU_ITEM = createAsyncAction(
  'menu/DELETE_MENU_ITEM',
  'menu/DELETE_MENU_ITEM_SUCCESS',
  'menu/DELETE_MENU_ITEM_FAILURE',
)<IDeleteMenuItemPayload, IDeleteMenuItemPayload, { id: string; error: Error }>()

export interface ICreateMenuItemPayload extends IBasketItemData {
  menuId: string
}

export const CREATE_MENU_ITEM = createAsyncAction(
  'menu/CREATE_MENU_ITEM',
  'menu/CREATE_MENU_ITEM_SUCCESS',
  'menu/CREATE_MENU_ITEM_FAILURE',
  'menu/CREATE_MENU_ITEM_CANCEL',
)<ICreateMenuItemPayload, TMenuItemAny, { productId: string; error: Error }, string>()

export const SELECT_MENU = createAction('menu/SELECT_MENU')<string>()
export const UNSELECT_MENU = createAction('menu/UNSELECT_MENU')()
