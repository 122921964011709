const parts = ['badge', 'inner', 'icon', 'overlayicon']

const baseStyle = {
  badge: {
    verticalAlign: 'top',
    borderWidth: '3px',
    borderColor: 'currentColor',
    display: 'inline-block',
    overflow: 'hidden',
  },
  inner: {
    display: 'block',
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
  },
  icon: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    width: '80%',
    height: '80%',
  },
  overlayicon: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}

function getSize(badgeSize: string) {
  const extra = badgeSize === '100%' ? {} : { height: badgeSize }
  return {
    badge: {
      width: badgeSize,
      ...extra,
    },
  }
}

const sizes = {
  '2xs': getSize('8'),
  xs: getSize('12'),
  sm: getSize('16'),
  md: getSize('24'),
  lg: getSize('32'),
  xl: getSize('48'),
  '2xl': getSize('60'),
  full: getSize('100%'),
}

const defaultProps = {
  size: 'lg',
}

export const AllergenIconBadge = {
  parts,
  baseStyle,
  sizes,
  defaultProps,
}
