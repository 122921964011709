import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { FETCH_TOMORROWS_CUTOFF, selectTomorrowsCutoff } from '../../stores/order'

export function useTomorrowsCutoff() {
  const dispatch = useDispatch()
  const cutoff = useSelector(selectTomorrowsCutoff)

  useEffect(() => {
    dispatch(FETCH_TOMORROWS_CUTOFF.request())
  }, [dispatch])

  return cutoff
}
