import { forwardRef, Box, BoxProps, Spinner } from '@chakra-ui/react'

export const PageLoadingSpinner = forwardRef<BoxProps, 'div'>((props, ref) => {
  return (
    <Box ref={ref} display="flex" justifyContent="center" p={8} {...props}>
      <Spinner
        thickness="4px"
        speed="0.8s"
        emptyColor="gray.100"
        color="green.500"
        width={40}
        height={40}
      />
    </Box>
  )
})
