import {
  forwardRef,
  HTMLChakraProps,
  ThemingProps,
  SystemProps,
  useMultiStyleConfig,
  omitThemingProps,
  SystemStyleObject,
  chakra,
  Icon,
  As,
} from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'

export interface IIconBadgeProps extends HTMLChakraProps<'span'>, ThemingProps {
  icon: As
  size?: string
  color?: SystemProps['color']
}

export const baseStyle: SystemStyleObject = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  flexShrink: 0,
}

export const IconBadge = forwardRef<IIconBadgeProps, 'span'>((props, ref) => {
  const styles = useMultiStyleConfig('IconBadge', props)

  const { borderRadius = 'full', children, color, icon, ...rest } = omitThemingProps(props)

  const badgeStyles: SystemStyleObject = {
    borderRadius,
    borderWidth: '1px',
    color,
    ...baseStyle,
    ...styles.badge,
  }

  const iconStyles: SystemStyleObject = {
    color,
    ...styles.icon,
  }

  return (
    <chakra.span
      ref={ref}
      {...rest}
      className={cx('om--icon-badge', props.className)}
      __css={badgeStyles}
    >
      <Icon as={icon} className={cx('om--icon-badge__icon', props.className)} __css={iconStyles} />
    </chakra.span>
  )
})
