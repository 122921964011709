import { isString } from 'lodash'
import { APIService } from '../service'
import {
  IJsonApiModel,
  IJsonApiModelWithId,
  IFetchMultipleQueryOptions,
  IFetchMultipleResponse,
  IFetchMultipleResponseMeta,
  IFetchOneQueryOptions,
} from '../types'
import { TMenuItemAny } from './menu-item'
import { TBasketWithAddressAndItems } from './basket'

declare module '../service' {
  interface APIService {
    fetchMenus(
      url: string,
    ): Promise<IFetchMultipleResponse<TMenuWithItems, IFetchMultipleResponseMeta>>
    fetchMenus(
      requestOpts?: IFetchMultipleQueryOptions,
    ): Promise<IFetchMultipleResponse<TMenuWithItems, IFetchMultipleResponseMeta>>
    fetchMenu(id: string, requestOpts?: IFetchOneQueryOptions): Promise<TMenuWithItems>
    fetchAllMenus(requestOpts?: IFetchMultipleQueryOptions): Promise<TMenuWithItems[]>
    deleteMenu(id: string): Promise<unknown>
    createMenu(data: Omit<INewMenu, 'type'>): Promise<TMenuWithItems>
    updateMenu(data: Omit<IUpdateMenu, 'type'>): Promise<TMenuWithItems>
    copyMenuToBasket(data: IMenuToBasket): Promise<TBasketWithAddressAndItems>
  }
}

export const ENTITY_TYPE_MENU = 'menus'
export const ENTITY_TYPE_MENU_BASKET = 'menu-baskets'

export type TMenuAny = IMenu<IJsonApiModelWithId>
export type TMenuWithItems = IMenu<TMenuItemAny>

export interface IMenu<I extends IJsonApiModelWithId> extends IJsonApiModelWithId {
  id: string
  title: string
  description: string
  netTotal: string
  items: I[]
}

export interface INewMenu extends Omit<IJsonApiModel, 'type'> {
  title: string
  description: string
}

export interface IUpdateMenu extends Omit<IJsonApiModelWithId, 'type'> {
  title: string
  description: string
}

export interface IMenuToBasket extends Omit<IJsonApiModel, 'type'> {
  menu: IJsonApiModelWithId
  basket: IJsonApiModelWithId
}

APIService.prototype.fetchMenus = async function (
  requestOptsOrUrl?: IFetchMultipleQueryOptions | string,
) {
  if (isString(requestOptsOrUrl)) {
    return this.fetchMultiple<TMenuWithItems, IFetchMultipleResponseMeta>(requestOptsOrUrl)
  }
  return this.fetchMultiple<TMenuWithItems, IFetchMultipleResponseMeta>(ENTITY_TYPE_MENU, {
    ...requestOptsOrUrl,
    include: [...(requestOptsOrUrl?.include || []), 'items'],
  })
}

APIService.prototype.fetchAllMenus = async function (requestOpts?: IFetchMultipleQueryOptions) {
  return this.fetchAll<TMenuWithItems>(ENTITY_TYPE_MENU, {
    ...requestOpts,
    include: [...(requestOpts?.include || []), 'items'],
  })
}

APIService.prototype.fetchMenu = async function (id: string, requestOpts?: IFetchOneQueryOptions) {
  return this.fetchOne<TMenuWithItems>(ENTITY_TYPE_MENU + '/' + id, {
    ...requestOpts,
    include: [...(requestOpts?.include || []), 'items'],
  })
}

APIService.prototype.deleteMenu = async function (id: string) {
  return this.delete(`${ENTITY_TYPE_MENU}/${id}`)
}

APIService.prototype.createMenu = async function (data: INewMenu) {
  return this.create<TMenuWithItems>(
    ENTITY_TYPE_MENU,
    {
      type: ENTITY_TYPE_MENU,
      ...data,
    },
    {
      include: ['items'],
    },
  )
}

APIService.prototype.updateMenu = async function (data: IUpdateMenu) {
  const { id } = data
  return this.update<TMenuWithItems>(
    ENTITY_TYPE_MENU + '/' + id,
    {
      type: ENTITY_TYPE_MENU,
      ...data,
    },
    {
      include: ['items'],
    },
  )
}

APIService.prototype.copyMenuToBasket = async function (data: IMenuToBasket) {
  return this.create<TBasketWithAddressAndItems>(
    ENTITY_TYPE_MENU_BASKET,
    {
      type: ENTITY_TYPE_MENU_BASKET,
      ...data,
    },
    {
      include: ['deliver_to', 'items'],
    },
  )
}
