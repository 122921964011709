import React from 'react'
import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from '@chakra-ui/react'

import { OmFilter, OmCircle, OmCheck } from '../../icons'
import { IAvailableFacet } from '../../../lib'

export interface IPromotionFilterMenuProps {
  facets: IAvailableFacet[]
  onAddFacet: (field: string) => void
  onRemoveFacet: (field: string) => void
  hideFacets?: string[]
}

export const PromotionFilterMenu: React.FC<IPromotionFilterMenuProps> = ({
  facets: allFacets,
  onAddFacet,
  onRemoveFacet,
  hideFacets,
}) => {
  const facets = allFacets.filter((f) => f.type === 'promotion' && !hideFacets?.includes(f.field))
  if (!facets.length) {
    return null
  }

  if (!facets.length) {
    return null
  }

  const anySelected = !!facets.filter((f) => f.isSelected).length

  return (
    <Popover variant="filter">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              variant="outline"
              colorScheme="birchallBlue"
              rightIcon={<OmFilter />}
              leftIcon={anySelected ? <OmCircle /> : <></>}
            >
              Promotion
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Box maxH="50vh" overflowY="auto">
                {facets.map((facet) => (
                  <Button
                    key={facet.field}
                    variant="filteritem"
                    onClick={() => {
                      onClose()
                      if (facet.isSelected) {
                        onRemoveFacet(facet.field)
                      } else {
                        onAddFacet(facet.field)
                      }
                    }}
                    rightIcon={facet.isSelected ? <OmCheck /> : undefined}
                  >
                    {facet.label} ({facet.count})
                  </Button>
                ))}
              </Box>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
