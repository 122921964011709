import { APIService } from '../service'
import { IJsonApiModel, IJsonApiModelWithId } from '../types'
import { IUserWithAccount } from './user'
import { deserializeTransformer } from '../serializer'

declare module '../service' {
  interface APIService {
    createAuthToken(data: INewAuthToken): Promise<IAuthTokenWithUser>
    deleteAuthToken(id: string, key: string): Promise<unknown>
  }
}

export const ENTITY_TYPE_AUTH_TOKEN = 'authtokens'

export interface INewAuthToken extends Omit<IJsonApiModel, 'type'> {
  username: string
  password: string
  deviceId: string
  deviceName: string
}

export interface IAuthToken extends IJsonApiModelWithId {
  key: string
  deviceId: string
  deviceName: string
  createdAt: Date
  lastUsedAt: Date
}

export interface IAuthTokenWithUser extends IJsonApiModelWithId {
  user: IUserWithAccount
}

APIService.prototype.createAuthToken = async function (data: INewAuthToken) {
  return this.create<IAuthTokenWithUser>(
    ENTITY_TYPE_AUTH_TOKEN,
    {
      type: ENTITY_TYPE_AUTH_TOKEN,
      ...data,
    },
    {
      include: ['user', 'user.account'],
    },
  )
}

APIService.prototype.deleteAuthToken = async function (id: string, key: string) {
  const headers = this.getAuthorizationHeader(key)
  return this.delete(`${ENTITY_TYPE_AUTH_TOKEN}/${id}`, headers)
}

deserializeTransformer.register(ENTITY_TYPE_AUTH_TOKEN, {
  createdAt: (v: string) => new Date(v),
})
