import { memo, useMemo } from 'react'
import { FormControl, FormLabel, FormHelperText, FormErrorMessage } from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import { IAddress } from '../../api'
import { deliveryAddressDisplay } from '../../lib'
import { SelectMenu } from '../select-menu'
import { IBasketFormValues } from './basket-form'

export interface IAddressFieldProps {
  name: string
  value: string
  addresses: IAddress[]
  onChange?: (value: string) => void
  onClose?: () => void
  errorMessage?: string
  isDisabled?: boolean
}

export const AddressField: React.FC<IAddressFieldProps> = ({
  name,
  value,
  addresses,
  onChange,
  onClose,
  errorMessage,
  isDisabled,
}) => {
  const isInvalid = !!errorMessage
  return (
    <FormControl isInvalid={isInvalid} isDisabled={isDisabled}>
      <FormLabel htmlFor={name}>Delivery Address</FormLabel>
      <SelectMenu
        name={name}
        value={value}
        isDisabled={isDisabled}
        onChange={onChange}
        onClose={onClose}
        options={addresses.map((address) => ({
          value: address.id,
          label: deliveryAddressDisplay(address),
        }))}
        placeholder="Select Address"
      />
      {!errorMessage && <FormHelperText>Required</FormHelperText>}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
}

export const AddressFieldMemo = memo(AddressField)

export interface IFormikFormikAddressFieldProps {
  hideErrorUntilTouched: boolean
  addresses: IAddress[]
  isDisabled?: boolean
}

export const FormikAddressField: React.FC<IFormikFormikAddressFieldProps> = ({
  hideErrorUntilTouched,
  addresses,
  isDisabled,
}) => {
  const name = 'address'

  // use useFormikContext instead of useField to memoize and reduce re-renders
  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext<IBasketFormValues>()
  const value = values[name]
  const handleChange = useMemo(
    () => (value: string) => {
      setFieldValue(name, value)
    },
    [setFieldValue],
  )
  const handleClose = useMemo(
    () => () => {
      // really shitty but a timeout needs to be used as chakra sends the
      // onClose event before the onChange event, yet formik needs them the
      // other way round
      setTimeout(() => {
        setFieldTouched(name, true)
      }, 200)
    },
    [setFieldTouched],
  )

  const errorMessage = hideErrorUntilTouched ? (touched[name] ? errors[name] : '') : errors[name]

  return (
    <AddressFieldMemo
      name={name}
      value={value}
      addresses={addresses}
      errorMessage={errorMessage}
      onChange={handleChange}
      onClose={handleClose}
      isDisabled={isDisabled}
    />
  )
}
