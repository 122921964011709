import { all, call, put, takeLeading } from 'redux-saga/effects'

import API, { CallReturnType } from '../../../api'
import { displayErrorToastSaga, ensureError } from '../../../lib'
import * as actions from './actions'

function* fetchNonDeliveryDays() {
  try {
    const nonDeliveryDays: CallReturnType<typeof API.fetchAllNonDeliveryDays> =
      yield API.fetchAllNonDeliveryDays()
    yield put(actions.FETCH_NON_DELIVERY_DAYS.success(nonDeliveryDays))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_NON_DELIVERY_DAYS.failure(error))
  }
}

export function* saga() {
  yield all([
    takeLeading(actions.FETCH_NON_DELIVERY_DAYS.request, fetchNonDeliveryDays),
    takeLeading(actions.FETCH_NON_DELIVERY_DAYS.failure, displayErrorToastSaga),
  ])
}
