import { ActionType, getType } from 'typesafe-actions'

import { IAccount, IAddress, IUser } from '../../api'
import { IFetchedResource, IFetchedResources } from '../types'

import * as actions from './actions'

interface IState {
  account?: IFetchedResource<IAccount, Error>
  addresses?: IFetchedResources<IAddress, Error>
  login?: IFetchedResource<string>
  user?: IFetchedResource<IUser, Error>
  passwordResetRequest?: IFetchedResource<string> // item will be the email
  passwordReset?: IFetchedResource<boolean> // item will be true on success
  nextUrl?: string
}

const initialState: IState = {}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions>,
): IState => {
  switch (action.type) {
    case getType(actions.LOGIN.request): {
      return {
        ...state,
        login: {
          isFetching: true,
        },
      }
    }

    case getType(actions.LOGIN.success): {
      const {
        id,
        user: { account, ...user },
      } = action.payload
      return {
        ...state,
        account: {
          isFetching: false,
          item: account,
        },
        login: {
          isFetching: false,
          item: id,
        },
        user: {
          isFetching: false,
          item: user,
        },
      }
    }

    case getType(actions.LOGIN.failure): {
      return {
        ...state,
        login: {
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.LOGOUT): {
      return {
        ...initialState,
      }
    }

    case getType(actions.FETCH_USER.request): {
      return {
        ...state,
        user: {
          ...state.user,
          error: undefined,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_USER.success): {
      const { account, ...user } = action.payload
      return {
        ...state,
        account: {
          ...state.account,
          error: undefined,
          isFetching: false,
          item: account,
        },
        user: {
          ...state.user,
          error: undefined,
          isFetching: false,
          item: user,
        },
      }
    }

    case getType(actions.FETCH_USER.failure): {
      return {
        ...state,
        user: {
          ...state.user,
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.FETCH_ACCOUNT.request): {
      return {
        ...state,
        account: {
          ...state.account,
          error: undefined,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_ACCOUNT.success): {
      return {
        ...state,
        account: {
          ...state.account,
          error: undefined,
          isFetching: false,
          item: action.payload,
        },
      }
    }

    case getType(actions.FETCH_ACCOUNT.failure): {
      return {
        ...state,
        account: {
          ...state.account,
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.FETCH_ADDRESSES.request): {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          error: undefined,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_ADDRESSES.success): {
      return {
        ...state,
        addresses: {
          items: action.payload,
          error: undefined,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_ADDRESSES.failure): {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.PASSWORD_RESET_REQUEST.request): {
      return {
        ...state,
        passwordResetRequest: {
          isFetching: true,
        },
      }
    }

    case getType(actions.PASSWORD_RESET_REQUEST.success): {
      return {
        ...state,
        passwordResetRequest: {
          item: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.PASSWORD_RESET_REQUEST.failure): {
      return {
        ...state,
        passwordResetRequest: {
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.PASSWORD_RESET.request): {
      return {
        ...state,
        passwordReset: {
          isFetching: true,
        },
      }
    }

    case getType(actions.PASSWORD_RESET.success): {
      return {
        ...state,
        passwordReset: {
          item: true,
          isFetching: false,
        },
      }
    }

    case getType(actions.PASSWORD_RESET.failure): {
      return {
        ...state,
        passwordReset: {
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.CLEAR_PASSWORD_RESET): {
      return {
        ...state,
        passwordResetRequest: undefined,
        passwordReset: undefined,
      }
    }

    case getType(actions.SET_LOGIN_NEXT_URL): {
      return {
        ...state,
        nextUrl: action.payload,
      }
    }
  }

  return state
}
