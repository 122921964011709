import { createIcon } from '@chakra-ui/react'

export const OmAllergenMilk = createIcon({
  displayName: 'Milk',
  viewBox: '0 0 48 62',
  path: (
    <g>
      <path
        fill="currentColor"
        d="M 14.852 17.384 C 9.775 21.083 2.587 17.384 2.587 17.384 C 2.587 17.384 6.323 9.376 9.489 5.738 L 20.643 5.738 C 23.805 9.376 27.544 17.384 27.544 17.384 C 27.544 17.384 19.925 13.687 14.852 17.384 Z M 29.471 16.534 C 29.317 16.206 26.2 9.562 23.118 5.482 C 23.805 5.063 24.276 4.164 24.276 3.116 C 24.276 1.67 23.372 0.497 22.257 0.497 L 7.873 0.497 C 6.758 0.497 5.857 1.67 5.857 3.116 C 5.857 4.164 6.325 5.063 7.012 5.482 C 3.929 9.562 0.814 16.206 0.659 16.534 L 0.471 16.941 L 0.471 54.513 C 0.471 58.363 3.698 61.503 7.659 61.503 L 22.47 61.503 C 26.436 61.503 29.664 58.363 29.664 54.513 L 29.664 16.938 L 29.471 16.534 L 29.471 16.534 Z"
      />
      <path
        fill="currentColor"
        d="M 38.673 42.47 C 36.559 43.861 31.305 42.437 31.305 42.437 L 30.895 37.241 L 46.169 37.241 L 45.762 42.498 C 45.762 42.498 40.788 41.072 38.673 42.47 Z M 29.529 36.005 L 31.316 58.886 L 45.755 58.886 L 47.539 36.005 L 29.529 36.005 L 29.529 36.005 Z"
      />
    </g>
  ),
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 48 62"
//     id="vector">
//     <path
//         id="path"
//         d="M 14.852 17.384 C 9.775 21.083 2.587 17.384 2.587 17.384 C 2.587 17.384 6.323 9.376 9.489 5.738 L 20.643 5.738 C 23.805 9.376 27.544 17.384 27.544 17.384 C 27.544 17.384 19.925 13.687 14.852 17.384 Z M 29.471 16.534 C 29.317 16.206 26.2 9.562 23.118 5.482 C 23.805 5.063 24.276 4.164 24.276 3.116 C 24.276 1.67 23.372 0.497 22.257 0.497 L 7.873 0.497 C 6.758 0.497 5.857 1.67 5.857 3.116 C 5.857 4.164 6.325 5.063 7.012 5.482 C 3.929 9.562 0.814 16.206 0.659 16.534 L 0.471 16.941 L 0.471 54.513 C 0.471 58.363 3.698 61.503 7.659 61.503 L 22.47 61.503 C 26.436 61.503 29.664 58.363 29.664 54.513 L 29.664 16.938 L 29.471 16.534 L 29.471 16.534 Z"
//         fill="#eeae4c"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_1"
//         d="M 38.673 42.47 C 36.559 43.861 31.305 42.437 31.305 42.437 L 30.895 37.241 L 46.169 37.241 L 45.762 42.498 C 45.762 42.498 40.788 41.072 38.673 42.47 Z M 29.529 36.005 L 31.316 58.886 L 45.755 58.886 L 47.539 36.005 L 29.529 36.005 L 29.529 36.005 Z"
//         fill="#eeae4c"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
