import { APIService } from '../service'
import { IJsonApiModelWithId } from '../types'
import { IAccount } from './account'

declare module '../service' {
  interface APIService {
    fetchUser(id: string): Promise<IUserWithAccount>
  }
}

export const ENTITY_TYPE_USER = 'users'

export interface IUser extends IJsonApiModelWithId {
  email: string
  firstName: string
  lastName: string
  phone: string
  image: string
  canAccessPaymentPortal: boolean
}

export interface IUserWithAccount extends IUser {
  account: IAccount
}

APIService.prototype.fetchUser = async function (id: string) {
  return this.fetchOne<IUserWithAccount>(ENTITY_TYPE_USER + '/' + id, {
    include: ['account'],
  })
}
