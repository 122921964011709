import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ENTITY_TYPE_BASKET,
  TBasketWithAddressAndItems,
  TOrderWithAddressAndItems,
} from '../../api'
import {
  DELETE_BASKET,
  SELECT_BASKET,
  DELETE_ORDER,
  CONFIRM_BASKET,
  SELECT_ORDER,
  COPY_ORDER_TO_BASKET,
  selectBasketsDelete,
  selectOrdersDelete,
  selectOrdersOpenForEdit,
} from '../../stores/order'
import { IBasketFormData, SHOW_ORDER_PICKER } from '../../stores/ui'
import {
  basketErrorMessage as getBasketErrorMessage,
  basketOrderMessage,
  orderBasketMessage,
  basketPath,
  orderPath,
  basketName,
  orderName,
  deliveryAddressDisplay,
  formatDeliveryDate,
  formatCutoffDate,
} from '../utils'

export function useOrderOrBasketDetail(
  order: TBasketWithAddressAndItems | TOrderWithAddressAndItems,
) {
  const dispatch = useDispatch()

  const isBasket = order.type === ENTITY_TYPE_BASKET

  const basketDeleteState = useSelector(selectBasketsDelete)
  const orderDeleteState = useSelector(selectOrdersDelete)
  const deleteState = useMemo(() => {
    if (isBasket && basketDeleteState.id === order.id) {
      return basketDeleteState
    } else if (!isBasket && orderDeleteState.id === order.id) {
      return orderDeleteState
    }
  }, [order, basketDeleteState, orderDeleteState, isBasket])

  const openForEditState = useSelector(selectOrdersOpenForEdit)
  const openForEdit = useMemo(() => {
    if (!isBasket && order.id === openForEditState.id) {
      return openForEditState
    }
  }, [order, isBasket, openForEditState])

  const basketErrorMessage = isBasket
    ? getBasketErrorMessage(order as TBasketWithAddressAndItems)
    : ''
  const [orderMessage, showReciprocalLink] = isBasket
    ? basketOrderMessage(order as TBasketWithAddressAndItems)
    : orderBasketMessage(order as TOrderWithAddressAndItems)
  const canSubmit = isBasket ? order.canSubmit : false
  const canCancel = isBasket ? true : order.canCancel
  const href = isBasket
    ? basketPath(order as TBasketWithAddressAndItems)
    : orderPath(order as TOrderWithAddressAndItems)
  const name = isBasket
    ? basketName(order as TBasketWithAddressAndItems)
    : orderName(order as TOrderWithAddressAndItems)

  const onConfirm = useCallback(() => {
    if (isBasket) {
      dispatch(CONFIRM_BASKET.request(order.id))
    }
  }, [dispatch, order, isBasket])

  const onCancel = useCallback(() => {
    if (isBasket) {
      dispatch(DELETE_BASKET.request(order.id))
    } else {
      dispatch(DELETE_ORDER.request(order.id))
    }
  }, [dispatch, order, isBasket])

  const onSelect = useCallback(() => {
    if (isBasket) {
      dispatch(SELECT_BASKET.request({ basketId: order.id, redirectAfterSelect: true }))
    } else {
      dispatch(SELECT_ORDER.request({ orderId: order.id, redirectAfterSelect: true }))
    }
  }, [dispatch, order, isBasket])

  const handleReorderSuccess = useCallback(
    (basketId: string) => {
      !isBasket &&
        dispatch(
          COPY_ORDER_TO_BASKET.request({
            basketId,
            orderId: order.id,
          }),
        )
    },
    [dispatch, order, isBasket],
  )

  const handleSelectExisting = useCallback(
    (formData: IBasketFormData) => {
      if (!isBasket) {
        dispatch(
          SHOW_ORDER_PICKER({
            type: 'REORDER_MATCH_EXISTING',
            formData,
            order: order as TOrderWithAddressAndItems,
          }),
        )
      }
    },
    [dispatch, order, isBasket],
  )

  return {
    isBasket,
    basketErrorMessage,
    orderMessage,
    showReciprocalLink,
    canEdit: order.canEdit,
    canSubmit,
    canCancel,
    href,
    name,
    showCutoff: isBasket || (!isBasket && order.canEdit),
    addressDisplay: deliveryAddressDisplay(order.deliverTo),
    deliveryDateDisplay: formatDeliveryDate(order.requestedDeliveryDate),
    cutoffDisplay: formatCutoffDate(order.cutoff),
    onConfirm,
    onCancel,
    onSelect,
    handleReorderSuccess,
    handleSelectExisting: isBasket ? undefined : handleSelectExisting,
    isDeleting: deleteState?.isFetching,
    isOpeningForEdit: openForEdit?.isFetching,
  }
}
