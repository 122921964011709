import { createAction, createAsyncAction } from 'typesafe-actions'
import {
  TProduct,
  IFetchMultipleResponse,
  IFetchMultipleQueryOptionsWithSearch,
  IFetchMultipleProductResponseMeta,
  IFetchMultiplePublicProductResponseMeta,
} from '../../../api'

// We need to store the filters (facets) as an array so that the order in which they
// added by the user can be preserved
export interface IFilter {
  key: string
  value: string
}

export interface IFetchProductsRequestQuery
  extends Omit<IFetchMultipleQueryOptionsWithSearch, 'filters'> {
  filters?: IFilter[]
}

export interface IFetchProductsRequest {
  key: string
  query: IFetchProductsRequestQuery
}

export interface IFetchProductsResponse {
  key: string
  response: IFetchMultipleResponse<
    TProduct,
    IFetchMultipleProductResponseMeta | IFetchMultiplePublicProductResponseMeta
  >
}

export const FETCH_PRODUCTS = createAsyncAction(
  'catalog/FETCH_PRODUCTS',
  'catalog/FETCH_PRODUCTS_SUCCESS',
  'catalog/FETCH_PRODUCTS_FAILURE',
  'catalog/FETCH_PRODUCTS_CANCEL',
)<IFetchProductsRequest, IFetchProductsResponse, Error, string>()

export const FETCH_MORE_PRODUCTS = createAsyncAction(
  'catalog/FETCH_MORE_PRODUCTS',
  'catalog/FETCH_MORE_PRODUCTS_SUCCESS',
  'catalog/FETCH_MORE_PRODUCTS_FAILURE',
  'catalog/FETCH_MORE_PRODUCTS_CANCEL',
)<string, IFetchProductsResponse, Error, undefined>()

export interface IFetchProductSubstitutesRequest {
  productId: string
  substituteIds: string[]
}

export interface IFetchProductSubstitutesResponse {
  productId: string
  response: IFetchMultipleResponse<
    TProduct,
    IFetchMultipleProductResponseMeta | IFetchMultiplePublicProductResponseMeta
  >
}

export const FETCH_PRODUCT_SUBSTITUTES = createAsyncAction(
  'catalog/FETCH_PRODUCT_SUBSTITUTES',
  'catalog/FETCH_PRODUCT_SUBSTITUTES_SUCCESS',
  'catalog/FETCH_PRODUCT_SUBSTITUTES_FAILURE',
  'catalog/FETCH_PRODUCT_SUBSTITUTES_CANCEL',
)<IFetchProductSubstitutesRequest, IFetchProductSubstitutesResponse, Error, string>()

export interface IFetchItemSubstitutesRequest {
  parentKey: string
  items: {
    productId: string
    substituteIds: string[]
  }[]
}

export interface IFetchItemSubstitutesResponse {
  parentKey: string
  items: {
    productId: string
    substitutes: TProduct[]
  }[]
}

export const FETCH_ITEM_SUBSTITUTES = createAsyncAction(
  'catalog/FETCH_ITEM_SUBSTITUTES',
  'catalog/FETCH_ITEM_SUBSTITUTES_SUCCESS',
  'catalog/FETCH_ITEM_SUBSTITUTES_FAILURE',
  'catalog/FETCH_ITEM_SUBSTITUTES_CANCEL',
)<IFetchItemSubstitutesRequest, IFetchItemSubstitutesResponse, Error, string>()

export const SORT_PRODUCTS = createAction('catalog/SORT_PRODUCTS')<{
  request: string
  field?: string
}>()

export const ADD_FACET = createAction('catalog/ADD_FACET')<{
  request: string
  field: string
}>()

export const REMOVE_FACET = createAction('catalog/REMOVE_FACET')<{
  request: string
  field: string
}>()

export const SET_FACETS = createAction('catalog/SET_FACETS')<{
  request: string
  fields: string[]
}>()

export const REMOVE_ALL_FACETS = createAction('catalog/REMOVE_ALL_FACETS')<string>()

export const FETCH_PRODUCT = createAsyncAction(
  'catalog/FETCH_PRODUCT',
  'catalog/FETCH_PRODUCT_SUCCESS',
  'catalog/FETCH_PRODUCT_FAILURE',
  'catalog/FETCH_PRODUCT_CANCEL',
)<string, TProduct, Error, undefined>()
