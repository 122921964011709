import { JsonPropertiesMapper, ModelPropertiesMapper } from 'jsona'
import { camelCase } from 'lodash'

interface IObj {
  [key: string]: any
}
interface IRelationships {
  [relationName: string]: IObj | IObj[]
}

const RELATIONSHIP_NAMES_PROP = 'relationshipNames'

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export class SnakeToCamelJsonMapper extends JsonPropertiesMapper {
  // The included SwitchCaseJsonMapper only does not translate nested
  // properties or relationship names, so here's a converter which does

  setAttributes(model: IObj, attributes: IObj) {
    this.covertObject(model, attributes)
  }

  setMeta(model: IObj, meta: IObj) {
    model.meta = {}
    this.covertObject(model.meta, meta)
  }

  covertObject(dst: IObj, src: IObj) {
    // applies transformed attributes from the source to the destination
    Object.keys(src).forEach((propName) => {
      const camelName = camelCase(propName)

      if (src[propName] && src[propName].constructor.name === 'Object') {
        dst[camelName] = {}
        this.covertObject(dst[camelName], src[propName])
      } else if (src[propName] && src[propName].constructor.name === 'Array') {
        dst[camelName] = []
        this.covertObject(dst[camelName], src[propName])
      } else {
        dst[camelName] = src[propName]
      }
    })
  }

  setRelationships(model: IObj, relationships: IRelationships) {
    Object.keys(relationships).forEach((propName) => {
      model[camelCase(propName)] = relationships[propName]
    })

    const newNames = Object.keys(relationships).map((k) => camelCase(k))
    const currentNames = model[RELATIONSHIP_NAMES_PROP]

    if (currentNames && currentNames.length) {
      model[RELATIONSHIP_NAMES_PROP] = [...currentNames, ...newNames].filter(
        (value, i, self) => self.indexOf(value) === i,
      )
    } else {
      model[RELATIONSHIP_NAMES_PROP] = newNames
    }
  }
}

export class CamelToSnakeModelMapper extends ModelPropertiesMapper {
  // The included SwitchCaseMoMapper only does not translate nested
  // properties, so here's a converter which does

  getAttributes(model: IObj) {
    let exceptProps = ['id', 'type', RELATIONSHIP_NAMES_PROP]

    if (Array.isArray(model[RELATIONSHIP_NAMES_PROP])) {
      exceptProps.push(...model[RELATIONSHIP_NAMES_PROP])
    } else if (model[RELATIONSHIP_NAMES_PROP]) {
      console.warn(
        `Can't getAttributes correctly, '${RELATIONSHIP_NAMES_PROP}' property of ${model.type}-${model.id} model
            isn't array of relationship names`,
        model[RELATIONSHIP_NAMES_PROP],
      )
    }

    const attributes: IObj = {}
    Object.keys(model).forEach((attrName) => {
      if (exceptProps.indexOf(attrName) === -1) {
        attributes[attrName] = model[attrName]
      }
    })

    // do the deep conversion
    const convertedAttrs = {}
    this.covertObject(convertedAttrs, attributes)
    return convertedAttrs
  }

  covertObject(dst: IObj, src: IObj) {
    // applies transformed attributes from the source to the destination
    Object.keys(src).forEach((propName) => {
      // can't use lodash's snakeCase as it converts address1 to address_1
      const snakeName = camelToSnakeCase(propName)

      if (src[propName] && src[propName].constructor.name === 'Object') {
        dst[snakeName] = {}
        this.covertObject(dst[snakeName], src[propName])
      } else if (src[propName] && src[propName].constructor.name === 'Array') {
        dst[snakeName] = []
        this.covertObject(dst[snakeName], src[propName])
      } else if (src[propName] && src[propName].constructor.name === 'Date') {
        dst[snakeName] = src[propName].toISOString()
      } else {
        dst[snakeName] = src[propName]
      }
    })
  }
}
