import { createIcon } from '@chakra-ui/react'

// https://thenounproject.com/term/vegan/2187982/
export const OmVegan = createIcon({
  displayName: 'Vegan',
  viewBox: '0 0 100 100',
  path: (
    <g>
      <g>
        <path
          fill="currentColor"
          d="M49.3,0C21.8,0.4-0.4,23.1,0,50.7c0.4,27.6,23.1,49.7,50.7,49.3c27.6-0.4,49.7-23.1,49.3-50.7C99.6,21.8,76.9-0.4,49.3,0z     M50.6,97.6C24.4,98,2.8,76.9,2.4,50.6C2,24.4,23.1,2.8,49.4,2.4c26.2-0.4,47.9,20.7,48.2,47C98,75.6,76.9,97.2,50.6,97.6z"
        />
      </g>
      <g>
        <path
          fill="currentColor"
          d="M53.1,75.4l-2.5-0.2c0-0.1,0-0.3,0-0.6C51.5,62,37.1,54.8,36.5,54.4l1.1-2.2c0.6,0.3,12,6.1,14.9,16.5    c1.9-3.7,5.6-8.1,12.8-11.4l1,2.3C55,64.7,53.4,73,53.1,74.9C53.1,75.1,53.1,75.2,53.1,75.4z"
        />
      </g>
      <g>
        <path
          fill="currentColor"
          d="M65.4,70.4c-0.2,0-0.4,0-0.6,0c-4.8-0.3-7.3-2.7-8.6-4.8c-2.2-3.5-2.4-8-1.4-10.6c2.4-6.1,12.3-12.5,26.9-7.5l1,0.3    l-0.1,1C81.2,60.9,73.6,70.4,65.4,70.4z M71.7,48.3c-7.9,0-13.2,3.9-14.6,7.7c-0.7,1.7-0.7,5.4,1.2,8.3c1.5,2.3,3.7,3.5,6.7,3.6    c6.9,0.4,13.5-7.8,15-18.3C77,48.6,74.2,48.3,71.7,48.3z"
        />
      </g>
      <g>
        <path
          fill="currentColor"
          d="M38,69.5c-1.2,0-2.5-0.1-3.9-0.5c-11.4-2.6-18.2-18.7-15.3-35.9l0.2-1l1,0c11.8-0.5,19.5,3.1,23.9,6.2    c5.4,3.8,8.9,9.2,9.3,14.3c0.3,3.7-1.6,9.7-5.9,13.4C45.3,67.8,42.3,69.5,38,69.5z M21.1,34.6c-2.3,15.5,3.6,29.7,13.5,32    c4.3,1,8,0.2,11-2.4c3.9-3.4,5.3-8.7,5.1-11.3C50.1,45.1,40.2,34.2,21.1,34.6z"
        />
      </g>
    </g>
  ),
})
