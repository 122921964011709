import { createIcon } from '@chakra-ui/react'

export const OmAllergenLactose = createIcon({
  displayName: 'Lactose',
  viewBox: '0 0 97 201',
  d: 'M47.3819,55.639 C30.6544,67.826 6.9717,55.639 6.9717,55.639 C6.9717,55.639 19.2809,29.254 29.7121,17.268 L66.4619,17.268 C76.8799,29.254 89.199,55.639 89.199,55.639 C89.199,55.639 64.0962,43.458 47.3819,55.639 Z M95.548,52.838 C95.0406,51.757 84.7708,29.867 74.6164,16.424 C76.8799,15.044 78.4317,12.082 78.4317,8.629 C78.4317,3.865 75.4533,0 71.7796,0 L24.3878,0 C20.7141,0 17.7456,3.865 17.7456,8.629 C17.7456,12.082 19.2875,15.044 21.551,16.424 C11.3933,29.867 1.1301,51.757 0.6194,52.838 L0,54.179 L0,177.9696 C0,190.6545 10.6322,201 23.6827,201 L72.4814,201 C85.5484,201 96.1839,190.6545 96.1839,177.9696 L96.1839,54.169 L95.548,52.838 Z',
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg width="97px" height="201px" viewBox="0 0 97 201" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
//     <title>lactose</title>
//     <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//         <path d="M47.3819,55.639 C30.6544,67.826 6.9717,55.639 6.9717,55.639 C6.9717,55.639 19.2809,29.254 29.7121,17.268 L66.4619,17.268 C76.8799,29.254 89.199,55.639 89.199,55.639 C89.199,55.639 64.0962,43.458 47.3819,55.639 Z M95.548,52.838 C95.0406,51.757 84.7708,29.867 74.6164,16.424 C76.8799,15.044 78.4317,12.082 78.4317,8.629 C78.4317,3.865 75.4533,0 71.7796,0 L24.3878,0 C20.7141,0 17.7456,3.865 17.7456,8.629 C17.7456,12.082 19.2875,15.044 21.551,16.424 C11.3933,29.867 1.1301,51.757 0.6194,52.838 L0,54.179 L0,177.9696 C0,190.6545 10.6322,201 23.6827,201 L72.4814,201 C85.5484,201 96.1839,190.6545 96.1839,177.9696 L96.1839,54.169 L95.548,52.838 Z" id="lactose" fill="#FF4F4F"></path>
//     </g>
// </svg>
