import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FETCH_PRODUCT, productDetailSelector } from '../../stores/catalog'

export function useProductDetail(id: string) {
  const dispatch = useDispatch()
  const [requested, setRequested] = useState(false)
  const detail = useSelector(productDetailSelector)(id)

  // reset reqested flag when id changes
  useEffect(() => {
    setRequested(false)
  }, [id])

  useEffect(() => {
    if (!requested) {
      setRequested(true)
      dispatch(FETCH_PRODUCT.request(id))
    }
  }, [dispatch, id, requested, setRequested])

  return {
    ...detail,
  }
}
