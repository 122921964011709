import { Helmet } from 'react-helmet-async'
import { Navigate, useParams } from 'react-router-dom'

import { Breadcrumb } from '../components/breadcrumb'
import { PageLayout } from '../components/page-layout'
import { ResponsiveContainer } from '../components/responsive-container'

import { MenuPageContents } from '../components/menu-page-contents'
import { DEFAULT_PAGE_TITLE, menuPath, useMenuDetail } from '../lib'
import { MENUS_ROUTE } from './menus'

export const MENU_ROUTE = '/lists/:menuId'

export interface IMenuRoute {
  menuId: string
}

export const MenuPage: React.FC = () => {
  const { menuId = '' } = useParams<keyof IMenuRoute>()
  const { item, isDeleted } = useMenuDetail(menuId)

  const breadcrumbs = [
    {
      name: 'Lists',
      to: MENUS_ROUTE,
    },
    {
      name: item?.title ?? '',
      to: item ? menuPath(item) : '#',
    },
  ]

  if (isDeleted) {
    return <Navigate to={MENUS_ROUTE} />
  }

  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          <Breadcrumb items={breadcrumbs} as="h2" />
          {item && <MenuPageContents menu={item} />}
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
