import React, { HTMLAttributes } from 'react'
import {
  forwardRef,
  RadioGroup,
  RadioGroupProps,
  useRadioGroupContext,
  SystemProps,
  HTMLChakraProps,
  SystemStyleObject,
  chakra,
  useMultiStyleConfig,
  omitThemingProps,
  useMenu,
  MenuProvider,
  useMenuContext,
  useMenuList,
  useMenuPositioner,
  useMenuButton,
  useMenuItem,
  useRadio,
  UseRadioProps,
  PropsOf,
  MenuDescendantsProvider,
  createStylesContext,
} from '@chakra-ui/react'
import { cx, callAll } from '@chakra-ui/utils'
import { CustomDomComponent, motion, Variants } from 'framer-motion'

import { OmCheck, OmChevronDown } from '../icons'

const [StylesProvider, useStyles] = createStylesContext('SelectMenu')

export interface ISelectOption {
  value: string
  label: string | number
}

export interface ISelectProps extends Omit<RadioGroupProps, 'children'>, ISelectMenuContentProps {
  isDisabled?: boolean
}

export const SelectMenu = forwardRef<ISelectProps, 'div'>((props, ref) => {
  const { options, placeholder, isDisabled, ...rest } = props
  const styles = useMultiStyleConfig('SelectMenu', props)
  const ownProps = omitThemingProps(props)
  const { descendants, ...ctx } = useMenu({
    ...ownProps,
    autoSelect: false,
    matchWidth: true,
    placement: 'auto',
  })
  const context = React.useMemo(() => ctx, [ctx])

  const content = React.useMemo(
    () => <SelectMenuContent options={options} placeholder={placeholder} isDisabled={isDisabled} />,
    [options, placeholder, isDisabled],
  )

  return (
    <RadioGroup ref={ref} {...rest}>
      <MenuDescendantsProvider value={descendants}>
        <MenuProvider value={context}>
          <StylesProvider value={styles}>{content}</StylesProvider>
        </MenuProvider>
      </MenuDescendantsProvider>
    </RadioGroup>
  )
})

interface ISelectMenuContentProps {
  placeholder?: string
  options: ISelectOption[]
  isDisabled?: boolean
  onClose?: () => void
}

const SelectMenuContent: React.FC<ISelectMenuContentProps> = ({
  options,
  placeholder,
  isDisabled,
}) => {
  return (
    <>
      <SelectMenuButton options={options} placeholder={placeholder} disabled={isDisabled} />
      {options.length > 0 && (
        <SelectMenuOptionsList>
          {options.map((option, index) => (
            <SelectMenuOption key={index} value={option.value}>
              {option.label}
            </SelectMenuOption>
          ))}
        </SelectMenuOptionsList>
      )}
    </>
  )
}

const motionVariants: Variants = {
  enter: {
    visibility: 'visible',
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: {
    transitionEnd: {
      visibility: 'hidden',
    },
    opacity: 0,
    scale: 0.8,
    transition: {
      duration: 0.1,
      easings: 'easeOut',
    },
  },
}

export interface ISelectMenuOptionsListProps extends HTMLChakraProps<'div'> {
  rootProps?: HTMLChakraProps<'div'>
}

const MotionDiv: CustomDomComponent<PropsOf<typeof chakra.div>> =
  'custom' in motion ? (motion as any).custom(chakra.div) : (motion as any)(chakra.div)

export const SelectMenuOptionsList = forwardRef<ISelectMenuOptionsListProps, 'div'>(
  (props, ref) => {
    const { rootProps, ...rest } = props
    const { isOpen, onTransitionEnd } = useMenuContext()

    const listProps = useMenuList(rest, ref)
    const { style: positionerStyle, ...positionerProps } = useMenuPositioner(rootProps)

    const styles = useStyles()

    return (
      <chakra.div
        {...positionerProps}
        style={{ ...positionerStyle, minWidth: 'auto' }}
        __css={{ zIndex: props.zIndex ?? styles.list?.zIndex }}
      >
        <MotionDiv
          {...listProps}
          /**
           * We could call this on either `onAnimationComplete` or `onUpdate`.
           * It seems the re-focusing works better with the `onUpdate`
           */
          onUpdate={onTransitionEnd}
          className={cx('om-select-menu__options-list', listProps.className)}
          variants={motionVariants}
          initial={false}
          animate={isOpen ? 'enter' : 'exit'}
          __css={{
            outline: 0,
            ...styles.list,
          }}
        />
      </chakra.div>
    )
  },
)

interface ISelectMenuButtonProps extends HTMLChakraProps<'button'> {
  options: ISelectOption[]
  iconSpacing?: SystemProps['marginRight']
}

interface IStyledSelectMenuButtonProps extends HTMLChakraProps<'button'> {}

const StyledSelectMenuButton = forwardRef<IStyledSelectMenuButtonProps, 'button'>((props, ref) => {
  const styles = useStyles()
  return (
    <chakra.button
      ref={ref}
      {...props}
      __css={{
        display: 'inline-flex',
        appearance: 'none',
        alignItems: 'center',
        outline: 0,
        transition: 'all 250ms',
        ...styles.button,
      }}
    />
  )
})

const SelectMenuButton = forwardRef<ISelectMenuButtonProps, 'button'>((props, ref) => {
  const { options, placeholder, iconSpacing = '0.5rem', ...rest } = props
  const buttonProps = useMenuButton(rest, ref)
  const group = useRadioGroupContext()

  const { value } = group
  const labels = options.filter((option) => option.value === value)
  const label = labels.length ? labels[0].label : placeholder

  return (
    <StyledSelectMenuButton
      type="button"
      {...buttonProps}
      className={cx('om-select-menu__button', props.className)}
    >
      <chakra.span __css={{ pointerEvents: 'none', flex: '1 1 auto' }}>{label}</chakra.span>
      <SelectMenuButtonIcon marginStart={iconSpacing} fontSize="1.25em">
        <OmChevronDown />
      </SelectMenuButtonIcon>
    </StyledSelectMenuButton>
  )
})

const SelectMenuButtonIcon: React.FC<HTMLChakraProps<'span'>> = (props) => {
  const { children, className, ...rest } = props

  const _children = React.isValidElement(children)
    ? React.cloneElement(children, {
        'aria-hidden': true,
        focusable: false,
      } as HTMLAttributes<'span'>)
    : children

  const _className = cx('om-select-menu__button', className)

  return (
    <chakra.span {...rest} className={_className}>
      {_children}
    </chakra.span>
  )
}

interface IStyledSelectMenuOptionProps extends HTMLChakraProps<'button'> {}

const StyledSelectMenuOption = forwardRef<IStyledSelectMenuOptionProps, 'button'>((props, ref) => {
  const { type, ...rest } = props
  const styles = useStyles()

  /**
   * Given another component, use its type if present
   * Else, use no type to avoid invalid html, e.g. <a type="button" />
   * Else, fall back to "button"
   */
  const btnType = rest.as ? type ?? undefined : 'button'

  const buttonStyles: SystemStyleObject = {
    textDecoration: 'none',
    color: 'inherit',
    userSelect: 'none',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    textAlign: 'left',
    flex: '0 0 auto',
    outline: 0,
    ...styles.option,
  }

  return <chakra.button ref={ref} type={btnType} {...rest} __css={buttonStyles} />
})

type Omitted = 'onChange' | 'defaultChecked' | 'checked'
interface BaseControlProps extends Omit<HTMLChakraProps<'div'>, Omitted> {}

interface ISelectMenuOptionProps extends UseRadioProps, BaseControlProps {
  value: string
  spacing?: SystemProps['marginLeft']
}

const SelectMenuOption = forwardRef<ISelectMenuOptionProps, 'input'>((props, ref) => {
  const { onChange: onChangeProp, value: valueProp } = props

  const group = useRadioGroupContext()
  const styles = useStyles()

  const { spacing = '0.5rem', children, ...rest } = props

  let isChecked = props.isChecked
  if (group?.value && valueProp) {
    isChecked = group.value === valueProp
  }

  let onChange = onChangeProp
  if (group?.onChange && valueProp) {
    onChange = callAll(group.onChange, onChangeProp)
  }

  const name = props?.name ?? group?.name

  const { getInputProps, getLabelProps } = useRadio({
    ...rest,
    isChecked,
    onChange,
    name,
  })

  const inputProps = getInputProps({}, ref)
  const labelProps = getLabelProps()
  const menuItemProps = useMenuItem(labelProps)

  return (
    <StyledSelectMenuOption
      {...menuItemProps}
      as="label"
      className={cx('om-select-menu__option', menuItemProps.className)}
    >
      <input className="om-select-menu__input" {...inputProps} />
      <SelectMenuOptionIcon marginEnd={spacing} opacity={isChecked ? 1 : 0}>
        <OmCheck />
      </SelectMenuOptionIcon>
      <chakra.span className="om-select-menu__label" __css={styles.label}>
        {children}
      </chakra.span>
    </StyledSelectMenuOption>
  )
})

const SelectMenuOptionIcon: React.FC<HTMLChakraProps<'span'>> = (props) => {
  const { className, children, ...rest } = props

  const styles = useStyles()
  const child = React.Children.only(children)

  const clone = React.isValidElement(child)
    ? React.cloneElement(child, {
        focusable: 'false',
        'aria-hidden': true,
        className: cx('om-select-menu__optionicon', child.props.className),
      } as HTMLAttributes<'span'>)
    : null

  const _className = cx('om-select-menu__optionicon--wrapper', className)

  return (
    <chakra.span className={_className} {...rest} __css={styles.icon}>
      {clone}
    </chakra.span>
  )
}
