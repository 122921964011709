import { ActionType, getType } from 'typesafe-actions'
import { zipObject } from 'lodash'

import { IPaginatedRequest, IRequestDetail, IAllRequest } from '../../types'
import { TOrderWithAddressAndItems } from '../../../api'

import * as actions from './actions'

// interface IState extends IKeyedFetchedResources<TOrderWithAddressAndItems>, IPaginatedResources {}
interface IState {
  items?: { [key: string]: TOrderWithAddressAndItems }
  listing?: IPaginatedRequest<TOrderWithAddressAndItems>
  openListing?: IAllRequest<TOrderWithAddressAndItems>
  detail?: IRequestDetail<Error>
  openForEdit?: IRequestDetail<Error>
  delete?: IRequestDetail<Error>
}

const initialState: IState = {}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions>,
): IState => {
  switch (action.type) {
    case getType(actions.FETCH_MORE_ORDERS.request):
    case getType(actions.FETCH_ORDERS.request): {
      const { listing } = state
      return {
        ...state,
        listing: {
          ...listing,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_MORE_ORDERS.success):
    case getType(actions.FETCH_ORDERS.success): {
      const {
        data,
        links,
        meta: {
          pagination: { count },
        },
      } = action.payload
      const ids = data.map((p) => p.id)
      const items = zipObject(ids, data)
      const { listing } = state
      const isMore = action.type === getType(actions.FETCH_MORE_ORDERS.success)
      const existingIds = isMore && listing && listing.ids ? listing.ids : []
      return {
        ...state,
        items: { ...(state.items || {}), ...items },
        listing: {
          ...listing,
          isFetching: false,
          ids: [...existingIds, ...ids],
          moreUrl: links.next ?? undefined,
          totalCount: count,
        },
      }
    }

    case getType(actions.FETCH_ORDER.request): {
      return {
        ...state,
        detail: {
          id: action.payload,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_ORDER.success): {
      const item = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
        detail: {
          id: item.id,
          isFetching: false,
        },
      }
    }

    case getType(actions.FETCH_OPEN_ORDERS.request): {
      const { openListing } = state
      return {
        ...state,
        openListing: {
          ...openListing,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_OPEN_ORDERS.success): {
      const data = action.payload
      const ids = data.map((p) => p.id)
      const items = zipObject(ids, data)
      const { openListing } = state
      return {
        ...state,
        items: { ...(state.items || {}), ...items },
        openListing: {
          ...openListing,
          isFetching: false,
          ids: [...ids],
          totalCount: data.length,
        },
      }
    }

    case getType(actions.OPEN_ORDER_FOR_EDITING.request): {
      return {
        ...state,
        openForEdit: {
          id: action.payload,
          isFetching: true,
        },
      }
    }

    case getType(actions.OPEN_ORDER_FOR_EDITING.success): {
      const { order } = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [order.id]: order,
        },
        openForEdit: {
          id: order.id,
          isFetching: false,
        },
      }
    }

    case getType(actions.OPEN_ORDER_FOR_EDITING.failure): {
      return {
        ...state,
        openForEdit: {
          ...state.openForEdit,
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.DELETE_ORDER.request): {
      return {
        ...state,
        delete: {
          id: action.payload,
          isFetching: true,
        },
      }
    }

    case getType(actions.DELETE_ORDER.success): {
      const id = action.payload
      const { listing } = state
      const ids = listing?.ids?.filter((i) => i !== id)
      const items = { ...state.items }
      delete items[id]
      return {
        ...state,
        items,
        listing: {
          ...listing,
          ids,
        },
        delete: {
          ...state.delete,
          error: undefined,
          isFetching: false,
        },
      }
    }

    case getType(actions.DELETE_ORDER.failure): {
      return {
        ...state,
        delete: {
          ...state.delete,
          error: action.payload,
          isFetching: false,
        },
      }
    }
  }

  return state
}
