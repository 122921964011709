import { Helmet } from 'react-helmet-async'
import { Navigate, useParams } from 'react-router-dom'

import { Breadcrumb } from '../components/breadcrumb'
import { OrderPageContents } from '../components/order-page-contents'
import { PageLayout } from '../components/page-layout'
import { ResponsiveContainer } from '../components/responsive-container'

import { DEFAULT_PAGE_TITLE, orderName, orderPath, useOrderDetail } from '../lib'
import { ORDERS_ROUTE } from './orders'

export const ORDER_ROUTE = '/orders/confirmed/:orderId'

export interface IOrderRoute {
  orderId: string
}

export const OrderPage: React.FC = () => {
  const { orderId = '' } = useParams<keyof IOrderRoute>()
  const { item, isCancelled } = useOrderDetail(orderId)

  const breadcrumbs = [
    {
      name: 'Confirmed Orders',
      to: ORDERS_ROUTE,
    },
    {
      name: orderName(item),
      to: item ? orderPath(item) : '#',
    },
  ]

  if (isCancelled) {
    return <Navigate to={ORDERS_ROUTE} />
  }

  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          <Breadcrumb items={breadcrumbs} as="h2" />
          {item && <OrderPageContents order={item} />}
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
