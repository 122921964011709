import { createIcon } from '@chakra-ui/react'

export const OmAllergenNuts = createIcon({
  displayName: 'Nuts',
  viewBox: '0 0 54 63',
  path: (
    <g>
      <path
        fill="currentColor"
        d="M 38.477 41.408 C 38.377 42.579 38.266 43.898 37.663 44.856 C 33.128 52.072 25.809 55.394 17.693 58.569 C 18.124 56.359 19.518 55.85 20.489 55.033 C 24.998 51.243 29.111 47.175 31.471 41.715 C 31.842 40.854 32.47 40.063 33.114 39.354 C 34.05 38.326 35.198 37.626 36.71 38.187 C 38.248 38.756 38.591 40.049 38.477 41.408 Z M 42.473 37.911 C 37.035 35.611 31.673 33.146 26.279 30.749 C 20.774 28.3 15.239 25.911 9.779 23.373 C 7.654 22.386 6.555 22.812 5.375 24.823 C -1.687 36.86 -0.083 48.104 7.934 59.144 C 10.101 62.127 11.625 63.005 15.894 62.89 C 30.766 62.486 38.803 54.398 44.288 42.356 C 45.334 40.059 44.773 38.884 42.473 37.911 L 42.473 37.911 Z"
      />
      <path
        fill="currentColor"
        d="M 50.111 27.449 C 50.032 27.516 49.707 28.014 49.328 28.058 C 48.622 28.138 48.237 27.511 48.088 26.94 C 47.063 23.03 44.077 20.807 40.804 18.778 C 38.914 17.606 37.953 15.933 39.389 13.949 C 40.764 12.049 42.643 12.741 44.34 13.646 C 49.127 16.195 52.199 23.197 50.111 27.449 Z M 51.931 17.546 C 50.214 14.321 47.34 12.275 44.493 10.191 C 42.222 8.528 40.998 6.545 41.688 3.714 C 41.968 2.568 42.474 1.354 40.836 0.662 C 39.348 0.032 38.523 0.829 37.76 1.864 C 36.111 4.098 33.787 4.517 31.182 4.126 C 28.379 3.704 25.59 3.188 22.778 2.841 C 18.568 2.321 14.915 3.501 12.193 6.726 C 10.484 8.751 9.037 10.9 8.373 13.589 C 7.18 18.422 7.022 18.392 11.762 20.507 C 16.919 22.809 22.083 25.099 27.244 27.393 C 32.742 29.837 38.239 32.282 43.736 34.727 C 43.848 34.776 43.969 34.813 44.072 34.877 C 46.561 36.417 48.393 35.523 50.151 33.553 C 54.271 28.935 54.905 23.133 51.931 17.546 L 51.931 17.546 Z"
      />
    </g>
  ),
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 54 63"
//     id="vector">
//     <path
//         id="path"
//         d="M 38.477 41.408 C 38.377 42.579 38.266 43.898 37.663 44.856 C 33.128 52.072 25.809 55.394 17.693 58.569 C 18.124 56.359 19.518 55.85 20.489 55.033 C 24.998 51.243 29.111 47.175 31.471 41.715 C 31.842 40.854 32.47 40.063 33.114 39.354 C 34.05 38.326 35.198 37.626 36.71 38.187 C 38.248 38.756 38.591 40.049 38.477 41.408 Z M 42.473 37.911 C 37.035 35.611 31.673 33.146 26.279 30.749 C 20.774 28.3 15.239 25.911 9.779 23.373 C 7.654 22.386 6.555 22.812 5.375 24.823 C -1.687 36.86 -0.083 48.104 7.934 59.144 C 10.101 62.127 11.625 63.005 15.894 62.89 C 30.766 62.486 38.803 54.398 44.288 42.356 C 45.334 40.059 44.773 38.884 42.473 37.911 L 42.473 37.911 Z"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_2"
//         d="M 50.111 27.449 C 50.032 27.516 49.707 28.014 49.328 28.058 C 48.622 28.138 48.237 27.511 48.088 26.94 C 47.063 23.03 44.077 20.807 40.804 18.778 C 38.914 17.606 37.953 15.933 39.389 13.949 C 40.764 12.049 42.643 12.741 44.34 13.646 C 49.127 16.195 52.199 23.197 50.111 27.449 Z M 51.931 17.546 C 50.214 14.321 47.34 12.275 44.493 10.191 C 42.222 8.528 40.998 6.545 41.688 3.714 C 41.968 2.568 42.474 1.354 40.836 0.662 C 39.348 0.032 38.523 0.829 37.76 1.864 C 36.111 4.098 33.787 4.517 31.182 4.126 C 28.379 3.704 25.59 3.188 22.778 2.841 C 18.568 2.321 14.915 3.501 12.193 6.726 C 10.484 8.751 9.037 10.9 8.373 13.589 C 7.18 18.422 7.022 18.392 11.762 20.507 C 16.919 22.809 22.083 25.099 27.244 27.393 C 32.742 29.837 38.239 32.282 43.736 34.727 C 43.848 34.776 43.969 34.813 44.072 34.877 C 46.561 36.417 48.393 35.523 50.151 33.553 C 54.271 28.935 54.905 23.133 51.931 17.546 L 51.931 17.546 Z"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
