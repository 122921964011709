import Jsona from 'jsona'

import { TJsonaModel } from 'jsona/lib/JsonaTypes'
import { CamelToSnakeModelMapper, SnakeToCamelJsonMapper } from './propertyMappers'
import { DeserializeTransformer } from './propertyTransformers'
import { IJsonApiData, IJsonApiModel } from './types'

export const deserializeTransformer = new DeserializeTransformer()

export const formatter = new Jsona({
  // json to model
  jsonPropertiesMapper: new SnakeToCamelJsonMapper(),
  // model to json
  modelPropertiesMapper: new CamelToSnakeModelMapper(),
})

interface IJsonaNormalizedIncludeNamesTree {
  [relationName: string]: null | IJsonaNormalizedIncludeNamesTree
}

export function serialize<T extends TJsonaModel | TJsonaModel[]>(data: {
  stuff: T
  includeNames?: string[] | IJsonaNormalizedIncludeNamesTree
}) {
  return formatter.serialize(data)
}

export function deserialize(response: {
  data: IJsonApiData | IJsonApiData[]
  included?: IJsonApiData[]
}) {
  // @ts-ignore — links property differ
  const deserialized = formatter.deserialize(response)

  if (Array.isArray(deserialized)) {
    return deserialized.map((d) => deserializeTransformer.transform(d as IJsonApiModel))
  }

  return deserializeTransformer.transform(deserialized as IJsonApiModel)
}
