import { Box, ChakraProvider, Heading, Link, Text, createStandaloneToast } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import React, { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { Store } from 'redux'

import { NavigateSetter, useAuthChecker } from '../../lib'
import * as pages from '../../pages'
import { theme } from '../../theme'
import { AndroidAppBanner } from '../android-app-banner'
import { ProtectedPage } from '../protected-page'
import { UiVersionChecker } from '../ui-version-checker'
import { StoredBasketChecker } from '../../components/stored-basket-checker'

const { ToastContainer } = createStandaloneToast({
  theme,
})

export interface AppProps {
  store: Store<any>
}

const ScrollToTop: React.FC = () => {
  // https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const ErrorFallback = () => {
  const handleReload = () => {
    window.location.reload()
  }
  return (
    <Box maxW="28rem" mx="auto" my={8} px={4}>
      <Heading as="h1" fontSize="5xl">
        Oops, an error occurred.
      </Heading>
      <Text mt={8}>
        We're very sorry about this, please try{' '}
        <Link color="coral.500" onClick={handleReload}>
          refreshing the page
        </Link>
        .
      </Text>
    </Box>
  )
}

const sentryErrorBoundaryFallback = <ErrorFallback />

const AuthChecker = () => {
  useAuthChecker()
  return null
}

export const App: React.FC<AppProps> = ({ store }) => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <HelmetProvider>
          <ChakraProvider theme={theme}>
            <Sentry.ErrorBoundary fallback={sentryErrorBoundaryFallback}>
              <NavigateSetter />
              <ScrollToTop />
              <AuthChecker />
              <AndroidAppBanner />
              <UiVersionChecker />
              <StoredBasketChecker />
              <Routes>
                <Route path={pages.HOME_ROUTE} element={<pages.HomePage />} />
                <Route path={pages.LOGIN_ROUTE} element={<pages.LoginPage />} />
                <Route path={pages.RESET_PASSWORD_ROUTE} element={<pages.ResetPasswordPage />} />
                <Route path={pages.PRODUCT_ROUTE} element={<pages.ProductPage />} />
                <Route path={pages.CATEGORY_ROUTE_DEPTH1} element={<pages.CategoryPage />} />
                <Route path={pages.CATEGORY_ROUTE_DEPTH2} element={<pages.CategoryPage />} />
                <Route path={pages.CATEGORY_ROUTE_DEPTH3} element={<pages.CategoryPage />} />
                <Route path={pages.CATEGORY_ROUTE_DEPTH4} element={<pages.CategoryPage />} />
                <Route path={pages.CATEGORY_ROUTE_DEPTH5} element={<pages.CategoryPage />} />
                <Route path={pages.CATEGORY_ROUTE_DEPTH6} element={<pages.CategoryPage />} />
                <Route path={pages.CATEGORY_ROUTE_DEPTH7} element={<pages.CategoryPage />} />
                <Route path={pages.CATEGORY_ROUTE_DEPTH8} element={<pages.CategoryPage />} />
                <Route path={pages.CATEGORY_ROUTE_DEPTH9} element={<pages.CategoryPage />} />
                <Route path={pages.SEARCH_ROUTE} element={<pages.SearchPage />} />
                <Route
                  path={pages.PROMOTIONS_ROUTE}
                  element={<ProtectedPage element={<pages.PromotionsPage />} />}
                />
                <Route
                  path={pages.ORDERS_ROOT_ROUTE}
                  element={<ProtectedPage element={<pages.OrdersRootPage />} />}
                />
                <Route
                  path={pages.BASKET_ROUTE}
                  element={<ProtectedPage element={<pages.BasketPage />} />}
                />
                <Route
                  path={pages.ORDER_ROUTE}
                  element={<ProtectedPage element={<pages.OrderPage />} />}
                />
                <Route
                  path={pages.BASKETS_ROUTE}
                  element={<ProtectedPage element={<pages.BasketsPage />} />}
                />
                <Route
                  path={pages.ORDERS_ROUTE}
                  element={<ProtectedPage element={<pages.OrdersPage />} />}
                />
                <Route
                  path={pages.ACCOUNT_ROUTE}
                  element={<ProtectedPage element={<pages.AccountPage />} />}
                />
                <Route
                  path={pages.FAVOURITES_ROUTE}
                  element={<ProtectedPage element={<pages.FavouritesPage />} />}
                />
                <Route
                  path={pages.MENU_ROUTE}
                  element={<ProtectedPage element={<pages.MenuPage />} />}
                />
                <Route
                  path={pages.MENUS_ROUTE}
                  element={<ProtectedPage element={<pages.MenusPage />} />}
                />
                <Route
                  path={pages.TICKET_ROUTE}
                  element={<ProtectedPage element={<pages.TicketPage />} />}
                />
                <Route
                  path={pages.TICKETS_ROUTE}
                  element={<ProtectedPage element={<pages.TicketsPage />} />}
                />
                <Route path="*" element={<pages.FallbackPage />} />
              </Routes>
              <ToastContainer />
            </Sentry.ErrorBoundary>
          </ChakraProvider>
        </HelmetProvider>
      </Provider>
    </BrowserRouter>
  )
}
