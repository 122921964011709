import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { TMenuWithItems } from '../../api'
import { DELETE_MENU } from '../../stores/menu'
import { COPY_MENU_TO_BASKET } from '../../stores/order'
import { IBasketFormData, SHOW_ORDER_PICKER } from '../../stores/ui'
import { menuPath, canCreateOrderFromMenu } from '../utils'

export function useMenu(menu: TMenuWithItems) {
  const dispatch = useDispatch()

  const href = menuPath(menu)

  const onDelete = useCallback(() => {
    dispatch(DELETE_MENU.request(menu.id))
  }, [dispatch, menu])

  const handleCreateBasketSuccess = useCallback(
    (basketId: string) => {
      dispatch(
        COPY_MENU_TO_BASKET.request({
          basketId,
          menuId: menu.id,
        }),
      )
    },
    [dispatch, menu],
  )

  const handleSelectExisting = useCallback(
    (formData: IBasketFormData) => {
      dispatch(
        SHOW_ORDER_PICKER({
          type: 'FROM_MENU_MATCH_EXISTING',
          formData,
          menu,
        }),
      )
    },
    [dispatch, menu],
  )

  return {
    href,
    canCreateOrder: canCreateOrderFromMenu(menu),
    onDelete,
    handleCreateBasketSuccess,
    handleSelectExisting,
  }
}
