import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { SELECT_STORED_BASKET } from '../../stores/order'

export const BASKET_STORAGE_KEY = 'om:selected-basket-id'

export const getBasket = (): string | null => {
  return localStorage.getItem(BASKET_STORAGE_KEY)
}

export const setBasket = (value: string): void => {
  localStorage.setItem(BASKET_STORAGE_KEY, value)
}

export const removeBasket = (): void => {
  localStorage.removeItem(BASKET_STORAGE_KEY)
}

export const StoredBasketChecker = () => {
  const dispatch = useDispatch()
  const basketId = getBasket()

  useEffect(() => {
    if (basketId != null) {
      dispatch(SELECT_STORED_BASKET.request(basketId))
    }
  }, [dispatch, basketId])

  return null
}
