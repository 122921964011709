import { createAsyncAction, createAction } from 'typesafe-actions'
import { TBasketWithAddressAndItems, TBasketItemAny, TOrderWithAddressAndItems } from '../../../api'
import { IBasketItemData } from '../../types'

export interface IFetchBasketsForAddressAndDatePayload {
  requestedDeliveryDate: Date
  addressId: string
}

export const SELECT_STORED_BASKET = createAsyncAction(
  'order/SELECT_STORED_BASKET',
  'order/SELECT_STORED_BASKET_SUCCESS',
  'order/SELECT_STORED_BASKET_FAILURE', // fail silently
)<string, TBasketWithAddressAndItems, undefined>()

export const FETCH_BASKETS = createAsyncAction(
  'order/FETCH_BASKETS',
  'order/FETCH_BASKETS_SUCCESS',
  'order/FETCH_BASKETS_FAILURE',
  'order/FETCH_BASKETS_CANCEL',
)<undefined, TBasketWithAddressAndItems[], Error, undefined>()

export const FETCH_BASKETS_FOR_ADDRESS_AND_DATE = createAsyncAction(
  'order/FETCH_BASKETS_FOR_ADDRESS_AND_DATE',
  'order/FETCH_BASKETS_FOR_ADDRESS_AND_DATE_SUCCESS',
  'order/FETCH_BASKETS_FOR_ADDRESS_AND_DATE_FAILURE',
  'order/FETCH_BASKETS_FOR_ADDRESS_AND_DATE_CANCEL',
)<IFetchBasketsForAddressAndDatePayload, TBasketWithAddressAndItems[], Error, undefined>()

export const FETCH_BASKET = createAsyncAction(
  'order/FETCH_BASKET',
  'order/FETCH_BASKET_SUCCESS',
  'order/FETCH_BASKET_FAILURE',
  'order/FETCH_BASKET_CANCEL',
)<string, TBasketWithAddressAndItems, Error, undefined>()

export const DELETE_BASKET = createAsyncAction(
  'order/DELETE_BASKET',
  'order/DELETE_BASKET_SUCCESS',
  'order/DELETE_BASKET_FAILURE',
  'order/DELETE_BASKET_CANCEL',
)<string, string, Error, undefined>()

export interface ICreateBasketPayload {
  title: string
  poNumber: string
  requestedDeliveryDate: Date
  addressId: string
}

export const CREATE_BASKET = createAsyncAction(
  'order/CREATE_BASKET',
  'order/CREATE_BASKET_SUCCESS',
  'order/CREATE_BASKET_FAILURE',
  'order/CREATE_BASKET_CANCEL',
)<ICreateBasketPayload, TBasketWithAddressAndItems, Error, undefined>()

export interface IUpdateBasketPayload {
  id: string
  title: string
  poNumber: string
  requestedDeliveryDate: Date
  addressId: string
}

export const UPDATE_BASKET = createAsyncAction(
  'order/UPDATE_BASKET',
  'order/UPDATE_BASKET_SUCCESS',
  'order/UPDATE_BASKET_FAILURE',
  'order/UPDATE_BASKET_CANCEL',
)<IUpdateBasketPayload, TBasketWithAddressAndItems, Error, undefined>()

export interface IUpdateBasketItemPayload {
  id: string
  basketId: string
  packQuantity: number
  splitQuantity: number
}

export const UPDATE_BASKET_ITEM = createAsyncAction(
  'order/UPDATE_BASKET_ITEM',
  'order/UPDATE_BASKET_ITEM_SUCCESS',
  'order/UPDATE_BASKET_ITEM_FAILURE',
)<IUpdateBasketItemPayload, TBasketItemAny, { id: string; error: Error }>()

export interface IDeleteBasketItemPayload {
  id: string
  basketId: string
}

export const DELETE_BASKET_ITEM = createAsyncAction(
  'order/DELETE_BASKET_ITEM',
  'order/DELETE_BASKET_ITEM_SUCCESS',
  'order/DELETE_BASKET_ITEM_FAILURE',
)<IDeleteBasketItemPayload, IDeleteBasketItemPayload, { id: string; error: Error }>()

export interface ICreateBasketItemPayload extends IBasketItemData {
  basketId: string
  asBasketSelectionFlow?: true
}

export interface ICreateBasketItemSuccessPayload extends TBasketItemAny {
  asBasketSelectionFlow?: true
}

export const CREATE_BASKET_ITEM = createAsyncAction(
  'order/CREATE_BASKET_ITEM',
  'order/CREATE_BASKET_ITEM_SUCCESS',
  'order/CREATE_BASKET_ITEM_FAILURE',
  'order/CREATE_BASKET_ITEM_CANCEL',
)<
  ICreateBasketItemPayload,
  TBasketItemAny,
  { productId: string; error: Error; asBasketSelectionFlow?: true },
  string
>()

export interface ISelectBasketPayload {
  basketId: string
  redirectAfterSelect?: boolean
  onSuccess?: () => void
}

export const SELECT_BASKET = createAsyncAction(
  'order/SELECT_BASKET',
  'order/SELECT_BASKET_SUCCESS',
  'order/SELECT_BASKET_FAILURE',
)<ISelectBasketPayload, string, undefined>()
export const UNSELECT_BASKET = createAction('order/UNSELECT_BASKET')()

export const CONFIRM_BASKET = createAsyncAction(
  'order/CONFIRM_BASKET',
  'order/CONFIRM_BASKET_SUCCESS',
  'order/CONFIRM_BASKET_FAILURE',
)<
  string,
  { basketId: string; order: TOrderWithAddressAndItems },
  { basketId: string; error: Error }
>()

export interface ICopyOrderToBasketRequest {
  basketId: string
  orderId: string
}

export const COPY_ORDER_TO_BASKET = createAsyncAction(
  'order/COPY_ORDER_TO_BASKET',
  'order/COPY_ORDER_TO_BASKET_SUCCESS',
  'order/COPY_ORDER_TO_BASKET_FAILURE',
)<ICopyOrderToBasketRequest, TBasketWithAddressAndItems, Error>()

export interface ICopyMenuToBasketRequest {
  basketId: string
  menuId: string
}

export const COPY_MENU_TO_BASKET = createAsyncAction(
  'order/COPY_MENU_TO_BASKET',
  'order/COPY_MENU_TO_BASKET_SUCCESS',
  'order/COPY_MENU_TO_BASKET_FAILURE',
)<ICopyMenuToBasketRequest, TBasketWithAddressAndItems, Error>()
