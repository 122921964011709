import { createAsyncAction } from 'typesafe-actions'
import {
  TOrderWithAddressAndItems,
  IFetchMultipleResponse,
  IFetchMultipleResponseMeta,
  TBasketWithAddressAndItems,
} from '../../../api'

export const FETCH_ORDERS = createAsyncAction(
  'order/FETCH_ORDERS',
  'order/FETCH_ORDERS_SUCCESS',
  'order/FETCH_ORDERS_FAILURE',
  'order/FETCH_ORDERS_CANCEL',
)<
  undefined,
  IFetchMultipleResponse<TOrderWithAddressAndItems, IFetchMultipleResponseMeta>,
  Error,
  undefined
>()

export const FETCH_MORE_ORDERS = createAsyncAction(
  'order/FETCH_MORE_ORDERS',
  'order/FETCH_MORE_ORDERS_SUCCESS',
  'order/FETCH_MORE_ORDERS_FAILURE',
  'order/FETCH_MORE_ORDERS_CANCEL',
)<
  undefined,
  IFetchMultipleResponse<TOrderWithAddressAndItems, IFetchMultipleResponseMeta>,
  Error,
  undefined
>()

export const FETCH_ORDER = createAsyncAction(
  'order/FETCH_ORDER',
  'order/FETCH_ORDER_SUCCESS',
  'order/FETCH_ORDER_FAILURE',
  'order/FETCH_ORDER_CANCEL',
)<string, TOrderWithAddressAndItems, Error, undefined>()

export interface IFetchOpenOrdersPayload {
  requestedDeliveryDate: Date
  addressId: string
}

export const FETCH_OPEN_ORDERS = createAsyncAction(
  'order/FETCH_OPEN_ORDERS',
  'order/FETCH_OPEN_ORDERS_SUCCESS',
  'order/FETCH_OPEN_ORDERS_FAILURE',
  'order/FETCH_OPEN_ORDERS_CANCEL',
)<IFetchOpenOrdersPayload | undefined, TOrderWithAddressAndItems[], Error, undefined>()

export const OPEN_ORDER_FOR_EDITING = createAsyncAction(
  'order/OPEN_ORDER_FOR_EDITING',
  'order/OPEN_ORDER_FOR_EDITING_SUCCESS',
  'order/OPEN_ORDER_FOR_EDITING_FAILURE',
)<string, { order: TOrderWithAddressAndItems; basket: TBasketWithAddressAndItems }, Error>()

export const DELETE_ORDER = createAsyncAction(
  'order/DELETE_ORDER',
  'order/DELETE_ORDER_SUCCESS',
  'order/DELETE_ORDER_FAILURE',
  'order/DELETE_ORDER_CANCEL',
)<string, string, Error, undefined>()

export interface ISelectOrderPayload {
  orderId: string
  redirectAfterSelect?: boolean
}

export const SELECT_ORDER = createAsyncAction(
  'order/SELECT_ORDER',
  'order/SELECT_ORDER_SUCCESS',
  'order/SELECT_ORDER_FAILURE',
)<ISelectOrderPayload, undefined, Error>()
