import React, { PropsWithChildren } from 'react'
import { chakra, useDisclosure } from '@chakra-ui/react'
import { createContext } from '@chakra-ui/react-utils'

const [ContentOverlayProvider, useContentOverlayContext] = createContext<UseContentOverlayReturn>({
  strict: false,
  name: 'ContentOverlayContainerContext',
})

export { ContentOverlayProvider, useContentOverlayContext }

export function useContentOverlay() {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure({
    defaultIsOpen: false,
  })

  return {
    isOverlayVisible: isOpen,
    showOverlay: onOpen,
    hideOverlay: onClose,
    toggleOverlay: () => {
      onToggle()
    },
  }
}

export interface UseContentOverlayReturn extends ReturnType<typeof useContentOverlay> {}

function Overlay() {
  const { isOverlayVisible } = useContentOverlayContext()

  return (
    <chakra.div
      style={{
        visibility: isOverlayVisible ? 'visible' : 'hidden',
      }}
      __css={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'fixed',
        zIndex: 'docked',
        bg: 'blackAlpha.600',
      }}
    />
  )
}

export const ContentOverlay: React.FC<PropsWithChildren> = ({ children }) => {
  const ctx = useContentOverlay()
  const context = React.useMemo(() => ctx, [ctx])

  return (
    <ContentOverlayProvider value={context}>
      <Overlay />
      {children}
    </ContentOverlayProvider>
  )
}
