import { parseISO } from 'date-fns'

import { APIService } from '../service'
import {
  IJsonApiModel,
  IJsonApiModelWithId,
  IFetchMultipleQueryOptions,
  IFetchOneQueryOptions,
} from '../types'
import { deserializeTransformer } from '../serializer'

import { ENTITY_TYPE_TICKET } from './ticket'

declare module '../service' {
  interface APIService {
    fetchAllTicketComments(
      ticketId: string,
      requestOpts?: IFetchMultipleQueryOptions,
    ): Promise<ITicketComment[]>
    fetchTicketComment(
      ticketId: string,
      id: string,
      requestOpts?: IFetchOneQueryOptions,
    ): Promise<ITicketComment>
    createTicketComment(ticketId: string, data: INewTicketComment): Promise<ITicketComment>
  }
}

export const ENTITY_TYPE_TICKET_COMMENT = 'ticket-comments'

const TICKET_COMMENT_RELATIONSHIP = 'comments'

export interface ITicketComment extends IJsonApiModelWithId {
  id: string
  author: string
  createdAt: Date
  htmlBody: string
  plainBody: string
}

export interface INewTicketComment extends Omit<IJsonApiModel, 'type'> {
  plainBody: string
}

APIService.prototype.fetchAllTicketComments = async function (
  ticketId: string,
  requestOpts?: IFetchMultipleQueryOptions,
) {
  return this.fetchAll<ITicketComment>(
    ENTITY_TYPE_TICKET + '/' + ticketId + '/' + TICKET_COMMENT_RELATIONSHIP,
    requestOpts,
  )
}

APIService.prototype.fetchTicketComment = async function (
  ticketId: string,
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  return this.fetchOne<ITicketComment>(
    ENTITY_TYPE_TICKET + '/' + ticketId + '/' + TICKET_COMMENT_RELATIONSHIP + '/' + id,
    requestOpts,
  )
}

APIService.prototype.createTicketComment = async function (
  ticketId: string,
  data: INewTicketComment,
) {
  return this.create<ITicketComment>(
    ENTITY_TYPE_TICKET + '/' + ticketId + '/' + TICKET_COMMENT_RELATIONSHIP,
    {
      type: ENTITY_TYPE_TICKET_COMMENT,
      ...data,
    },
  )
}

deserializeTransformer.register(ENTITY_TYPE_TICKET_COMMENT, {
  createdAt: (v: string) => parseISO(v),
})
