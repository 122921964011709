import { Box, Button, Grid, forwardRef, BoxProps } from '@chakra-ui/react'
import { IProduct } from '../../api'
import { useSelectedBasketItem } from '../../lib'
import { QuantityButton } from '../quantity-button'

export interface IQuantityFormProps extends BoxProps {
  product: IProduct
}
export const QuantityForm = forwardRef<IQuantityFormProps, 'div'>((props, ref) => {
  const { product, ...rest } = props
  const {
    canSplit,
    casePrice,
    isAvailable,
    isPurchasable,
    isFreeProductPromotion,
    packQuantity,
    packSize,
    splitPrice,
    splitSize,
  } = product
  const { basket, item, onSelectBasket, onUpdatePackQuantity, onUpdateSplitQuantity } =
    useSelectedBasketItem(product.id, { isFreeProductPromotion, isPurchasable })

  return (
    <Box ref={ref} color="gray.600" fontWeight="bold" fontSize="xl" textAlign="right" {...rest}>
      <Box display={[null, 'none']}>
        {canSplit && (
          <Box mb={2}>
            £{splitPrice} per {splitSize}
          </Box>
        )}
        <Box mb={2}>
          £{casePrice} per {packSize}
        </Box>
      </Box>
      <Grid
        alignItems="center"
        templateColumns={isAvailable ? 'auto 1fr auto' : 'auto 1fr'}
        columnGap={[2, 5]}
        rowGap={4}
        marginRight="auto"
        marginLeft={['auto', null, 0]}
        width={['auto', 'fit-content']}>
        {canSplit && (
          <>
            <Box as="span" color="gray.500" fontWeight="normal">
              {splitSize}
            </Box>
            <Box as="span" visibility={['hidden', 'visible']}>
              £{splitPrice}
            </Box>
            {isAvailable && (
              <Box as="span">
                {basket ? (
                  <QuantityButton
                    size="md"
                    width={36}
                    value={item ? item.splitQuantity : 0}
                    emptyLabel={`Add ${splitSize}`}
                    onValueChange={onUpdateSplitQuantity}
                    maxValue={packQuantity - 1}
                    isAddDisabled={!isPurchasable}
                    isChangeDisabled={!isPurchasable}
                  />
                ) : (
                  <Button
                    width={36}
                    onClick={() => onSelectBasket(1, 0)}
                    isDisabled={!isPurchasable}>
                    Add {splitSize}
                  </Button>
                )}
              </Box>
            )}
          </>
        )}
        <Box as="span" color="gray.500" fontWeight="normal">
          {packSize}
        </Box>
        <Box as="span" visibility={['hidden', 'visible']}>
          £{casePrice}
        </Box>
        {isAvailable && (
          <Box as="span">
            {basket ? (
              <QuantityButton
                size="md"
                width={36}
                value={item ? item.packQuantity : 0}
                emptyLabel={`Add ${packSize}`}
                onValueChange={onUpdatePackQuantity}
                maxValue={999}
                isAddDisabled={!isPurchasable}
                isChangeDisabled={!isPurchasable}
              />
            ) : (
              <Button width={36} onClick={() => onSelectBasket(0, 1)} isDisabled={!isPurchasable}>
                Add {packSize}
              </Button>
            )}
          </Box>
        )}
      </Grid>
    </Box>
  )
})
