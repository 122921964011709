import { APIService } from '../service'
import { IJsonApiModelWithId, IJsonApiModel } from '../types'

import { IProductImage } from './public-product'

declare module '../service' {
  interface APIService {
    createBasketItem(data: INewBasketItem): Promise<TBasketItemAny>
    deleteBasketItem(id: string): Promise<unknown>
    updateBasketItem(data: IUpdateBasketItem): Promise<TBasketItemAny>
  }
}

export const ENTITY_TYPE_BASKET_ITEM = 'basket-items'

export interface IBasketItem<B extends IJsonApiModelWithId, P extends IJsonApiModelWithId>
  extends IJsonApiModelWithId {
  id: string
  hasOrderItem: boolean
  productCode: string
  productName: string
  productDescription: string
  productImages: IProductImage[]
  productCanSplit: boolean
  productPackQuantity: number
  productPackSize: string
  productSplitSize: string
  packQuantity: number
  splitQuantity: number
  unitPrice: string
  subtotal: string
  basket: B
  product?: P
  productIsAvailable?: boolean
  productIsOnPromotion?: boolean
  productIsPreOrder?: boolean
  productIsFrozen?: boolean
  substitutes: IJsonApiModelWithId[]
  isFreeDiscount: boolean
}

export type TBasketItemAny = IBasketItem<IJsonApiModelWithId, IJsonApiModelWithId>

export interface INewBasketItem extends Omit<IJsonApiModel, 'type'> {
  packQuantity: number
  splitQuantity: number
  basket: IJsonApiModelWithId
}

export interface IUpdateBasketItem extends Omit<IJsonApiModelWithId, 'type'> {
  packQuantity: number
  splitQuantity: number
}

APIService.prototype.createBasketItem = async function (data: INewBasketItem) {
  return this.create<TBasketItemAny>(ENTITY_TYPE_BASKET_ITEM, {
    type: ENTITY_TYPE_BASKET_ITEM,
    ...data,
  })
}

APIService.prototype.deleteBasketItem = async function (id: string) {
  return this.delete(`${ENTITY_TYPE_BASKET_ITEM}/${id}`)
}

APIService.prototype.updateBasketItem = async function (data: IUpdateBasketItem) {
  const { id } = data
  return this.update<TBasketItemAny>(ENTITY_TYPE_BASKET_ITEM + '/' + id, {
    type: ENTITY_TYPE_BASKET_ITEM,
    ...data,
  })
}
