import { createIcon } from '@chakra-ui/react'

export const OmAllergenSoya = createIcon({
  displayName: 'Soya',
  viewBox: '0 0 62 62',
  d: 'M 51.353 61.59 C 52.648 61.59 54.262 61.691 55.857 61.572 C 61.824 61.125 62.727 59.512 60.259 54.26 C 57.005 47.338 51.778 42.703 44.162 40.856 C 40.339 39.928 37.994 37.827 36.854 34.071 C 35.477 29.535 32.743 25.877 27.898 24.378 C 23.943 23.155 21.677 20.663 20.499 16.852 C 17.851 8.283 10.567 4.511 2.991 1.11 C 1.714 0.535 0.285 0.317 0.491 2.316 C 1.408 11.214 0.469 20.741 8.89 27.233 C 11.103 28.94 12.264 31.204 12.817 33.864 C 14.452 41.709 19.453 46.364 27.332 48.161 C 30.985 48.993 34.306 50.557 35.779 53.972 C 38.74 60.836 44.232 62.388 51.353 61.59',
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 62 62"
//     id="vector">
//     <path
//         id="path_1"
//         d="M 51.353 61.59 C 52.648 61.59 54.262 61.691 55.857 61.572 C 61.824 61.125 62.727 59.512 60.259 54.26 C 57.005 47.338 51.778 42.703 44.162 40.856 C 40.339 39.928 37.994 37.827 36.854 34.071 C 35.477 29.535 32.743 25.877 27.898 24.378 C 23.943 23.155 21.677 20.663 20.499 16.852 C 17.851 8.283 10.567 4.511 2.991 1.11 C 1.714 0.535 0.285 0.317 0.491 2.316 C 1.408 11.214 0.469 20.741 8.89 27.233 C 11.103 28.94 12.264 31.204 12.817 33.864 C 14.452 41.709 19.453 46.364 27.332 48.161 C 30.985 48.993 34.306 50.557 35.779 53.972 C 38.74 60.836 44.232 62.388 51.353 61.59"
//         fill="#eeae4c"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
