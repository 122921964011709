import { ActionType, getType } from 'typesafe-actions'

import { IFetchedResources } from '../../types'
import { INonDeliveryDay } from '../../../api'

import * as actions from './actions'

interface IState extends IFetchedResources<INonDeliveryDay> {}

const initialState: IState = {
  isFetching: false,
}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions>,
): IState => {
  switch (action.type) {
    case getType(actions.FETCH_NON_DELIVERY_DAYS.request): {
      return {
        ...initialState,
      }
    }

    case getType(actions.FETCH_NON_DELIVERY_DAYS.success): {
      return {
        ...state,
        items: action.payload,
        isFetching: false,
      }
    }
  }

  return state
}
