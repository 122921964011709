import { Box, BoxProps, forwardRef } from '@chakra-ui/react'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Navigate, Link as RouterLink } from 'react-router-dom'

import { IMenuFormModalButtonProps, MenuFormModalButton } from '../components/menu-form'
import { PageHeading, PageHeadingProps } from '../components/page-heading'
import { PageLayout } from '../components/page-layout'
import { ResponsiveContainer } from '../components/responsive-container'
import { SearchResults, SearchResultsSkeleton } from '../components/search-results'
import { TabButton, TabList } from '../components/tab-list'

import { DEFAULT_PAGE_TITLE, menuPath, useSearchResults } from '../lib'
import { MENUS_ROUTE } from './menus'

export const FAVOURITES_ROUTE = '/favourites'

const FAVOURITE_FILTERS = [
  {
    key: 'is_favourite',
    value: 'true',
  },
]
const FAVOURITE_INITIAL_SORT = ['-favourite_created_at']

const FavouritesPageContent = forwardRef<BoxProps, 'div'>((props, ref) => {
  const { totalCount, isLoadingInitial, ...searchProps } = useSearchResults(
    FAVOURITE_FILTERS,
    FAVOURITE_INITIAL_SORT,
  )

  return (
    <Box ref={ref} {...props}>
      <TabList>
        <TabButton as={RouterLink} to={MENUS_ROUTE}>
          Lists
        </TabButton>
        <TabButton as={RouterLink} to={FAVOURITES_ROUTE} isActive={true}>
          Favourites
        </TabButton>
      </TabList>

      {isLoadingInitial && (
        <>
          <SearchResultsSkeleton mt={28} />
        </>
      )}
      {!isLoadingInitial && (
        <>
          {totalCount > 0 && <SearchResults {...searchProps} />}
          {!totalCount && (
            <Box textAlign="center" p={16}>
              You have not yet added any favourites.
            </Box>
          )}
        </>
      )}
    </Box>
  )
})

interface IFavouritesPageHeaderProps extends PageHeadingProps {
  buttonProps: IMenuFormModalButtonProps
}

const FavouritesPageHeader = forwardRef<IFavouritesPageHeaderProps, 'div'>((props, ref) => {
  const { buttonProps, ...rest } = props

  return (
    <PageHeading ref={ref} {...rest} as="div">
      <Box display="flex" justifyContent="space-between">
        <Box as="h1" mr={4}>
          Favourites
        </Box>
        <MenuFormModalButton {...buttonProps} />
      </Box>
    </PageHeading>
  )
})

export const FavouritesPage: React.FC = () => {
  const [createdMenuId, setCreatedMenuId] = useState<string | undefined>()
  const onSuccess = (menuId: string) => {
    setCreatedMenuId(menuId)
  }

  if (createdMenuId) {
    return (
      <Navigate
        to={{
          pathname: menuPath(createdMenuId),
        }}
      />
    )
  }

  const buttonProps: IMenuFormModalButtonProps = {
    onSuccess,
  }

  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          <FavouritesPageHeader buttonProps={buttonProps} />
          <FavouritesPageContent />
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
