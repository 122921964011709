import { ActionType, getType } from 'typesafe-actions'
import { IOrderSettings } from '../../../api'

import { IFetchedResource } from '../../types'

import * as actions from './actions'

interface IState extends IFetchedResource<IOrderSettings, Error> {}

const initialState: IState = {
  isFetching: false,
}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions>,
): IState => {
  switch (action.type) {
    case getType(actions.FETCH_ORDER_SETTINGS.request): {
      return {
        isFetching: true,
      }
    }

    case getType(actions.FETCH_ORDER_SETTINGS.failure): {
      return {
        isFetching: false,
        error: action.payload,
      }
    }

    case getType(actions.FETCH_ORDER_SETTINGS.success): {
      return {
        ...state,
        item: action.payload,
        isFetching: false,
      }
    }

    case getType(actions.FETCH_ORDER_SETTINGS.cancel): {
      return {
        isFetching: false,
      }
    }
  }

  return state
}
