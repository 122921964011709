import {
  forwardRef,
  HTMLChakraProps,
  ThemingProps,
  SystemProps,
  useMultiStyleConfig,
  omitThemingProps,
  SystemStyleObject,
  chakra,
  Icon,
  As,
} from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'

export interface IAllergenIconBadgeProps extends HTMLChakraProps<'span'>, ThemingProps {
  icon: As
  overlayIcon?: As
  size?: string
  color?: SystemProps['color']
}

export const AllergenIconBadge = forwardRef<IAllergenIconBadgeProps, 'span'>((props, ref) => {
  const styles = useMultiStyleConfig('AllergenIconBadge', props)

  const {
    borderRadius = 'sm',
    children,
    color,
    icon,
    overlayIcon,
    ...rest
  } = omitThemingProps(props)

  const badgeStyles: SystemStyleObject = {
    borderRadius,
    color,
    ...styles.badge,
  }

  const innerStyles: SystemStyleObject = {
    color,
    ...styles.inner,
  }

  return (
    <chakra.span
      ref={ref}
      {...rest}
      className={cx('om--allergen-icon-badge', props.className)}
      __css={badgeStyles}
    >
      <chakra.span
        className={cx('om--allergen-icon-badge__inner', props.className)}
        __css={innerStyles}
      >
        <Icon
          as={icon}
          className={cx('om--allergen-icon-badge__icon', props.className)}
          __css={styles.icon}
        />
        {overlayIcon && (
          <Icon
            as={overlayIcon}
            className={cx('om--allergen-icon-badge__overlayicon', props.className)}
            __css={styles.overlayicon}
          />
        )}
      </chakra.span>
    </chakra.span>
  )
})
