import { APIService } from '../service'
import { IJsonApiModelWithId, IJsonApiModel } from '../types'

import { IProductImage } from './public-product'

declare module '../service' {
  interface APIService {
    createMenuItem(data: INewMenuItem): Promise<TMenuItemAny>
    deleteMenuItem(id: string): Promise<unknown>
    updateMenuItem(data: IUpdateMenuItem): Promise<TMenuItemAny>
  }
}

export const ENTITY_TYPE_MENU_ITEM = 'menu-items'

export interface IMenuItem<M extends IJsonApiModelWithId, P extends IJsonApiModelWithId>
  extends IJsonApiModelWithId {
  id: string
  productCode: string
  productName: string
  productDescription: string
  productImages: IProductImage[]
  productCanSplit: boolean
  productPackQuantity: number
  productPackSize: string
  productSplitSize: string
  packQuantity: number
  splitQuantity: number
  unitPrice: string
  subtotal: string
  menu: M
  product?: P
  productIsAvailable?: boolean
  productIsOnPromotion?: boolean
  productIsPreOrder?: boolean
  productIsFrozen?: boolean
  substitutes: IJsonApiModelWithId[]
}

export type TMenuItemAny = IMenuItem<IJsonApiModelWithId, IJsonApiModelWithId>

export interface INewMenuItem extends Omit<IJsonApiModel, 'type'> {
  packQuantity: number
  splitQuantity: number
  menu: IJsonApiModelWithId
}

export interface IUpdateMenuItem extends Omit<IJsonApiModelWithId, 'type'> {
  packQuantity: number
  splitQuantity: number
}

APIService.prototype.createMenuItem = async function (data: INewMenuItem) {
  return this.create<TMenuItemAny>(ENTITY_TYPE_MENU_ITEM, {
    type: ENTITY_TYPE_MENU_ITEM,
    ...data,
  })
}

APIService.prototype.deleteMenuItem = async function (id: string) {
  return this.delete(`${ENTITY_TYPE_MENU_ITEM}/${id}`)
}

APIService.prototype.updateMenuItem = async function (data: IUpdateMenuItem) {
  const { id } = data
  return this.update<TMenuItemAny>(ENTITY_TYPE_MENU_ITEM + '/' + id, {
    type: ENTITY_TYPE_MENU_ITEM,
    ...data,
  })
}
