import { Box, Button, List, ListItem } from '@chakra-ui/react'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { Breadcrumb } from '../components/breadcrumb'
import { PageLayout } from '../components/page-layout'
import { ResponsiveContainer } from '../components/responsive-container'
import { SearchResults, SearchResultsSkeleton } from '../components/search-results'
import {
  DEFAULT_PAGE_TITLE,
  categoryPath,
  useCategory,
  useCategoryAncestors,
  useCategoryChildren,
  useSearchResults,
} from '../lib'

export const CATEGORY_ROUTE = '/catalog/:slugs/c/:categoryId'

// React Router v6 does not support RexExp path matching so define a bunch of
// routes to be used in the <Route> component.
// The slugs have no bearing on the page as only the categoryId is used.
export const CATEGORY_ROUTE_DEPTH1 = '/catalog/:slug1/c/:categoryId'
export const CATEGORY_ROUTE_DEPTH2 = '/catalog/:slug1/:slug2/c/:categoryId'
export const CATEGORY_ROUTE_DEPTH3 = '/catalog/:slug1/:slug2/:slug3/c/:categoryId'
export const CATEGORY_ROUTE_DEPTH4 = '/catalog/:slug1/:slug2/:slug3/:slug4/c/:categoryId'
export const CATEGORY_ROUTE_DEPTH5 = '/catalog/:slug1/:slug2/:slug3/:slug4/:slug5/c/:categoryId'
export const CATEGORY_ROUTE_DEPTH6 =
  '/catalog/:slug1/:slug2/:slug3/:slug4/:slug5/:slug6/c/:categoryId'
export const CATEGORY_ROUTE_DEPTH7 =
  '/catalog/:slug1/:slug2/:slug3/:slug4/:slug5/:slug6/:slug7/c/:categoryId'
export const CATEGORY_ROUTE_DEPTH8 =
  '/catalog/:slug1/:slug2/:slug3/:slug4/:slug5/:slug6/:slug7/:slug8/c/:categoryId'
export const CATEGORY_ROUTE_DEPTH9 =
  '/catalog/:slug1/:slug2/:slug3/:slug4/:slug5/:slug6/:slug7/:slug8/:slug9/c/:categoryId'

const CategoryPageContent: React.FC = () => {
  const { categoryId = '' } = useParams<'slugs' | 'categoryId'>()

  const category = useCategory(categoryId)
  const children = useCategoryChildren(category)
  const ancestors = [...useCategoryAncestors(category), ...(category ? [category] : [])]

  const filters = useMemo(() => {
    return [
      {
        key: 'category',
        value: categoryId,
      },
    ]
  }, [categoryId])
  const { totalCount, isLoadingInitial, ...searchProps } = useSearchResults(filters)

  if (!category) {
    return null
  }

  const breadcrumbs = ancestors.map((c) => ({
    name: c.name,
    to: categoryPath(c, ancestors),
  }))

  return (
    <ResponsiveContainer>
      <Breadcrumb items={breadcrumbs} />

      {children && children.length > 1 && (
        <List>
          {children.map((c) => (
            <ListItem key={c.id} display="inline">
              <Button
                variant="outline"
                colorScheme="birchallBlue"
                size="sm"
                mr={4}
                mb={4}
                as={RouterLink}
                to={categoryPath(c, ancestors)}
              >
                {c.name}
              </Button>
            </ListItem>
          ))}
        </List>
      )}

      {isLoadingInitial && (
        <>
          <SearchResultsSkeleton mt={28} />
        </>
      )}
      {!isLoadingInitial && (
        <>
          {totalCount > 0 && <SearchResults {...searchProps} />}

          {!totalCount && (
            <Box textAlign="center" p={16}>
              Whoops, there does not appear to be any products in this category.
            </Box>
          )}
        </>
      )}
    </ResponsiveContainer>
  )
}

export const CategoryPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <CategoryPageContent />
      </PageLayout>
    </>
  )
}
