import React from 'react'
import {
  Box,
  VStack,
  Icon,
  StackDivider,
  useBreakpointValue,
  HTMLChakraProps,
  forwardRef,
  MenuListProps,
  HStack,
  chakra,
  Skeleton,
} from '@chakra-ui/react'
import { min } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'

import { OmChevronDown, OmChevronUp } from '../icons'
import { TCategoryAny } from '../../api'
import { useRootCategories, useCategoryChildren } from '../../lib'
import { categoryPath } from '../../lib'
import { ResponsiveContainer } from '../responsive-container'
import { Nav, NavLink, NavList, NavItem, NavLinkProps } from '../nav'
import { useContentOverlayContext } from '../content-overlay'

interface IDesktopPrimaryNavMenuListProps extends MenuListProps {
  category: TCategoryAny
}

const DesktopPrimaryNavMenuList = forwardRef<IDesktopPrimaryNavMenuListProps, 'div'>(
  ({ category, ...props }, ref) => {
    return (
      <NavList {...props} ref={ref}>
        <ResponsiveContainer py={4}>
          <NavItem to={categoryPath(category)} fontWeight="bold" as={RouterLink}>
            All in {category.name}
          </NavItem>
          <CategoryChildrenContainer category={category} />
        </ResponsiveContainer>
      </NavList>
    )
  },
)

function chunk(list: any[], size: number) {
  const chunks = Array(size)
  var position = 0

  for (var i = 0; i < size; i++) {
    const chunkSize = Math.ceil((list.length - position) / (size - i))
    chunks[i] = list.slice(position, position + chunkSize)
    position += chunkSize
  }

  return chunks
}

interface NavColumnsProps extends HTMLChakraProps<'div'> {
  category: TCategoryAny
}

function CategoryChildrenContainer(props: NavColumnsProps) {
  const { category } = props
  const categories = useCategoryChildren(category)

  if (!categories.length) {
    return (
      <NavColumns mt={4}>
        {[...Array(category.children.length)].map((_, index) => (
          <Skeleton
            key={index}
            height="1.5rem"
            width="100%"
            startColor="gray.50"
            endColor="gray.200"
          />
        ))}
      </NavColumns>
    )
  }

  return (
    <NavColumns mt={4}>
      {categories.map((child) => (
        <NavItem
          key={child.id}
          p={0}
          to={categoryPath(child, [category])}
          fontWeight="light"
          as={RouterLink}
        >
          {child.name}
        </NavItem>
      ))}
    </NavColumns>
  )
}

const NavColumns = forwardRef<HTMLChakraProps<'div'>, 'div'>(({ children, ...props }, ref) => {
  const columnCount = useBreakpointValue({ base: 3, lg: 4, xl: 5 })

  if (!columnCount) {
    return null
  }

  const childrenArray = React.Children.toArray(children)
  const columns = chunk(childrenArray, min([columnCount, childrenArray.length])!)

  return (
    <chakra.div {...props}>
      <HStack align="top" divider={<StackDivider />} spacing={4}>
        {columns.map((columnChildren, columnIndex) => (
          <VStack key={columnIndex} width={{ base: 219, lg: 216, xl: 224 }} align="flex-start">
            {columnChildren}
          </VStack>
        ))}
      </HStack>
    </chakra.div>
  )
})

export const DesktopPrimaryNav = forwardRef<HTMLChakraProps<'div'>, 'div'>(
  ({ children, ...props }, ref) => {
    const categories = useRootCategories()
    const padding = '1rem'
    const navLinkProps: NavLinkProps = {
      padding,
    }
    const { showOverlay, hideOverlay } = useContentOverlayContext()

    return (
      <Box bg="gray.100" ref={ref} {...props} height="56px">
        <ResponsiveContainer p={0}>
          <nav aria-label="Categories">
            <HStack divider={<StackDivider h={6} alignSelf="auto" />} spacing={0} overflowX="auto">
              {categories?.map((category) => {
                const hasMultipleChildren = category.children.length > 1
                return (
                  <Nav
                    key={category.id}
                    placement="bottom"
                    isLazy={true}
                    onOpen={hasMultipleChildren ? showOverlay : undefined}
                    onClose={hasMultipleChildren ? hideOverlay : undefined}
                  >
                    {({ isOpen }) => {
                      if (!hasMultipleChildren) {
                        return (
                          <NavLink
                            {...navLinkProps}
                            to={categoryPath(category)}
                            as={RouterLink}
                            preventDefault={false}
                            style={{
                              padding,
                            }}
                          >
                            {category.name}
                          </NavLink>
                        )
                      }
                      return (
                        <>
                          <NavLink
                            {...navLinkProps}
                            href={categoryPath(category)}
                            whiteSpace="nowrap"
                          >
                            {category.name}
                            <Icon as={isOpen ? OmChevronUp : OmChevronDown} ml={1} />
                          </NavLink>
                          <DesktopPrimaryNavMenuList category={category} />
                        </>
                      )
                    }}
                  </Nav>
                )
              })}
            </HStack>
          </nav>
        </ResponsiveContainer>
      </Box>
    )
  },
)
