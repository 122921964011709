import { all, put, takeLeading, select, call } from 'redux-saga/effects'

import { HOME_ROUTE, LOGIN_ROUTE } from '../../pages'
import { displayErrorToast, displayErrorToastSaga, ensureError, history } from '../../lib'
import API, { CallReturnType, JsonApiError } from '../../api'

import * as actions from './actions'
import { selectNextUrl } from './selectors'
import { UNSELECT_BASKET } from '../order'

function* login(action: ReturnType<typeof actions.LOGIN.request>) {
  try {
    API.clearAuth()
    const result: CallReturnType<typeof API.createAuthToken> = yield API.createAuthToken({
      ...action.payload,
      deviceId: window.navigator.userAgent,
      deviceName: window.navigator.userAgent,
    })

    API.setAuth(result.user.id, { id: result.id, key: result.key })

    const nextUrl: ReturnType<typeof selectNextUrl> = yield select(selectNextUrl)
    yield put(actions.LOGIN.success(result))
    history.push(nextUrl ?? HOME_ROUTE)
  } catch (e) {
    if (e instanceof JsonApiError && e.errors[0].code === 'authorization') {
      yield put(actions.LOGIN.failure(e.errors[0].detail))
    } else {
      const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
      yield displayErrorToast(error)
    }
  }
}

function* fetchUser(action: ReturnType<typeof actions.FETCH_USER.request>) {
  try {
    const result: CallReturnType<typeof API.fetchUser> = yield API.fetchUser(action.payload)
    yield put(actions.FETCH_USER.success(result))
    yield put(actions.FETCH_ACCOUNT.success(result.account))
  } catch (e) {
    if (e instanceof JsonApiError && e.status === 401) {
      API.clearAuth() // clear the auth to ensure it is reset
      history.push(HOME_ROUTE) // redirect to HOME
    }
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_USER.failure(error))
  }
}

function* logout() {
  const auth: CallReturnType<typeof API.getAuth> = yield API.getAuth()
  API.clearAuth()

  history.push(HOME_ROUTE)
  yield put(UNSELECT_BASKET())

  if (auth) {
    try {
      yield API.deleteAuthToken(auth.token.id, auth.token.key)
    } catch (error) {}
  }
}

function* checkToken() {
  const auth: CallReturnType<typeof API.getAuth> = yield API.getAuth()
  if (!auth) {
    return
  }

  yield put(actions.FETCH_USER.request(auth.user))
}

function* fetchAddresses() {
  try {
    const addresses: CallReturnType<typeof API.fetchAllAddresses> = yield API.fetchAllAddresses()
    yield put(actions.FETCH_ADDRESSES.success(addresses))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_ADDRESSES.failure(error))
  }
}

function* passwordResetRequest(action: ReturnType<typeof actions.PASSWORD_RESET_REQUEST.request>) {
  const email = action.payload
  try {
    yield API.createPasswordResetRequest({
      email,
    })
    yield put(actions.PASSWORD_RESET_REQUEST.success(email))
  } catch (e) {
    if (e instanceof JsonApiError && e.errors[0].code === 'invalid') {
      yield put(actions.PASSWORD_RESET_REQUEST.failure(e.errors[0].detail))
    } else {
      const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
      yield displayErrorToast(error)
    }
  }
}

function* passwordReset(action: ReturnType<typeof actions.PASSWORD_RESET.request>) {
  try {
    yield API.createPasswordReset(action.payload)
    yield put(actions.PASSWORD_RESET.success())
  } catch (e) {
    if (e instanceof JsonApiError && e.errors[0].code === 'invalid') {
      const errorMessage = e.errors[0].detail
      yield put(actions.PASSWORD_RESET.failure(errorMessage.replace('verification_code', 'code')))
    } else {
      const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
      yield displayErrorToast(error)
    }
  }
}

function* gotoLogin(action: ReturnType<typeof actions.GOTO_LOGIN>) {
  // set the next URL and go to the login page
  const includeNextUrl = action.payload ?? true
  const pathname = includeNextUrl ? history.location!.pathname : ''
  yield put(actions.SET_LOGIN_NEXT_URL(pathname))
  history.push(LOGIN_ROUTE)
}

export function* saga() {
  yield all([
    takeLeading(actions.LOGIN.request, login),
    takeLeading(actions.LOGOUT, logout),
    takeLeading(actions.CHECK_TOKEN, checkToken),
    takeLeading(actions.FETCH_USER.request, fetchUser),
    takeLeading(actions.FETCH_USER.failure, displayErrorToastSaga),
    takeLeading(actions.FETCH_ADDRESSES.request, fetchAddresses),
    takeLeading(actions.FETCH_ADDRESSES.failure, displayErrorToastSaga),
    takeLeading(actions.PASSWORD_RESET_REQUEST.request, passwordResetRequest),
    takeLeading(actions.PASSWORD_RESET.request, passwordReset),
    takeLeading(actions.GOTO_LOGIN, gotoLogin),
  ])
}
