import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { FETCH_ADDRESSES, addressesSelector } from '../../stores/account'

export function useAddresses(withDeliveryDays: boolean = true) {
  const dispatch = useDispatch()
  const [requested, setRequested] = useState(false)
  const addresses = useSelector(addressesSelector)(withDeliveryDays)

  useEffect(() => {
    if (!requested) {
      setRequested(true)
      dispatch(FETCH_ADDRESSES.request())
    }
  }, [dispatch, requested, setRequested])

  return addresses
}
