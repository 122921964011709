import { Heading, forwardRef, HeadingProps } from '@chakra-ui/react'

export const SectionHeading = forwardRef<HeadingProps, 'h3'>((props, ref) => {
  return (
    <Heading
      ref={ref}
      as="h3"
      fontSize={['xl', null, null, '2xl']}
      fontFamily="body"
      fontWeight="bold"
      {...props}
    />
  )
})
