import { Button, Menu, MenuList, MenuItem, MenuButton } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { OmChevronDown, OmChevronUp } from '../icons'
import { LOGOUT } from '../../stores/account'
import { ORDERS_ROOT_ROUTE, ACCOUNT_ROUTE, MENUS_ROUTE, TICKETS_ROUTE } from '../../pages'
import { useUser } from '../../lib'

const REACT_APP_PAYMENT_PORTAL_URL = process.env.REACT_APP_PAYMENT_PORTAL_URL as string

export function AccountMenu() {
  const user = useUser()
  const dispatch = useDispatch()
  const handleLogout = () => {
    dispatch(LOGOUT())
  }

  return (
    <Menu autoSelect={false} placement="bottom-end">
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            variant="ghost"
            colorScheme="gray"
            fontFamily="body"
            rightIcon={isOpen ? <OmChevronUp /> : <OmChevronDown />}
          >
            My Account
          </MenuButton>
          <MenuList minWidth="10rem">
            <MenuItem as={RouterLink} to={ORDERS_ROOT_ROUTE}>
              Orders
            </MenuItem>
            <MenuItem as={RouterLink} to={MENUS_ROUTE}>
              Lists &amp; Favourites
            </MenuItem>
            <MenuItem as={RouterLink} to={TICKETS_ROUTE}>
              Messages
            </MenuItem>
            {user && user.canAccessPaymentPortal && (
              <MenuItem as="a" href={REACT_APP_PAYMENT_PORTAL_URL}>
                Payment Portal
              </MenuItem>
            )}
            <MenuItem as={RouterLink} to={ACCOUNT_ROUTE}>
              Profile
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
