import { forwardRef, HTMLChakraProps } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { checkExternalLink } from '../../../lib'
import { IconLinkBox, IconLinkBoxGroup } from '../../icon-link-box'
import { ResponsiveContainer } from '../../responsive-container'
import { IBaseBlock } from '../types'

export interface IIconLinkBoxesBlock extends IBaseBlock {
  type: 'icon_link_boxes'
  value: {
    items: {
      icon: string
      url: string
      text: string
    }[]
  }
}

export interface IIconLinkBoxesBlockProp extends HTMLChakraProps<'div'> {
  block: IIconLinkBoxesBlock
}

export const IconLinkBoxesBlock = forwardRef<IIconLinkBoxesBlockProp, 'div'>(
  ({ block, ...rest }, ref) => {
    return (
      <ResponsiveContainer ref={ref} {...rest}>
        <IconLinkBoxGroup>
          {block.value.items.map((item, index) => {
            const { url, isPageLink } = checkExternalLink(item.url)

            if (isPageLink) {
              return (
                <IconLinkBox key={index} icon={item.icon} as={RouterLink} to={url}>
                  {item.text}
                </IconLinkBox>
              )
            }

            return (
              <IconLinkBox key={index} icon={item.icon} href={url}>
                {item.text}
              </IconLinkBox>
            )
          })}
        </IconLinkBoxGroup>
      </ResponsiveContainer>
    )
  },
)
