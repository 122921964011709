import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FETCH_ROOT_CATEGORIES, selectHasRoots, selectRootCategories } from '../../stores/catalog'

export function useRootCategories() {
  const dispatch = useDispatch()
  const hasRoots = useSelector(selectHasRoots)
  const categories = useSelector(selectRootCategories)

  useEffect(() => {
    if (!hasRoots) {
      dispatch(FETCH_ROOT_CATEGORIES.request())
    }
  }, [dispatch, hasRoots])

  return hasRoots ? categories : []
}
