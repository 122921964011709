import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser'
import { Element } from 'domhandler'
import {
  chakra,
  Box,
  BoxProps,
  forwardRef,
  Text,
  UnorderedList as ChakraUnorderedList,
  OrderedList as ChakraOrderedList,
  ListItem as ChackraListItem,
} from '@chakra-ui/react'

import { formatTicketDate } from '../../lib'
import { ITicketComment } from '../../api'

export interface ITicketBoxProps extends BoxProps {
  comment: ITicketComment
}

const UnorderedList = chakra(ChakraUnorderedList, {
  baseStyle: {
    mt: 4,
    pl: 4,
  },
})

const OrderedList = chakra(ChakraOrderedList, {
  baseStyle: {
    mt: 4,
    pl: 4,
  },
})

const ListItem = chakra(ChackraListItem, {})

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.attribs) {
      switch (domNode.tagName) {
        case 'p': {
          if (!domNode.children.length) {
            // strip empty paragraphs
            return <></>
          }
          return <Text>{domToReact(domNode.children, options)}</Text>
        }
        case 'ul': {
          return <UnorderedList>{domToReact(domNode.children, options)}</UnorderedList>
        }
        case 'ol': {
          return <OrderedList>{domToReact(domNode.children, options)}</OrderedList>
        }
        case 'li': {
          return <ListItem>{domToReact(domNode.children, options)}</ListItem>
        }
      }
    }
  },
}

export const TicketCommentBox = forwardRef<ITicketBoxProps, 'div'>((props, ref) => {
  const { comment, ...rest } = props
  const htmlContent = parse(comment.htmlBody, options)
  return (
    <Box ref={ref} borderWidth={1} p={[3, 4]} borderRadius="sm" {...rest}>
      <Text fontWeight="bold">
        {comment.author}{' '}
        <Text as="span" fontWeight="normal" color="gray.600">
          {formatTicketDate(comment.createdAt)}
        </Text>
      </Text>
      <Box mt={4}>{htmlContent}</Box>
    </Box>
  )
})
