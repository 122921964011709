import { APIService } from '../service'
import { IJsonApiModel, IJsonApiModelWithId } from '../types'
import { IProduct } from './product'

declare module '../service' {
  interface APIService {
    applyBasketDiscountBxgy(data: IApplyBasketDiscountBxgy): Promise<IAppliedBasketDiscountBxgy>
  }
}

export const ENTITY_TYPE_BASKET_DISCOUNT_BXGY = 'basket-discount-bxgys'
export const ENTITY_TYPE_APPLIED_BASKET_DISCOUNT_BXGY = 'applied-basket-discount-bxgys'

export interface IBasketDiscountBxgy<B extends IJsonApiModelWithId, P extends IJsonApiModelWithId>
  extends IJsonApiModelWithId {
  id: string
  title: string
  description: string
  basket: B
  products: P[]
}

export type IBasketDiscountBxgyAny = IBasketDiscountBxgy<IJsonApiModelWithId, IJsonApiModelWithId>
export type IBasketDiscountBxgyWithProduct = IBasketDiscountBxgy<IJsonApiModelWithId, IProduct>

export interface IAppliedBasketDiscountBxgy extends IJsonApiModelWithId {
  basket: IJsonApiModelWithId
  bxgy: IJsonApiModelWithId
  product: IJsonApiModelWithId
}

export interface IApplyBasketDiscountBxgy extends Omit<IJsonApiModel, 'type'> {
  bxgy: IJsonApiModelWithId
  product: IJsonApiModelWithId
}

APIService.prototype.applyBasketDiscountBxgy = async function (data: IApplyBasketDiscountBxgy) {
  return this.create<IAppliedBasketDiscountBxgy>(ENTITY_TYPE_APPLIED_BASKET_DISCOUNT_BXGY, {
    type: ENTITY_TYPE_APPLIED_BASKET_DISCOUNT_BXGY,
    ...data,
  })
}
