import { Box } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'

import { ResponsiveContainer } from '../components/responsive-container'
import { PageLayout } from '../components/page-layout'
import { PageHeading } from '../components/page-heading'
import { SearchResults, SearchResultsSkeleton } from '../components/search-results'
import { useSearchResults, DEFAULT_PAGE_TITLE } from '../lib'

export const PROMOTIONS_ROUTE = '/catalog/promotions'

const PROMOTION_FILTERS = [
  {
    key: 'is_on_promotion',
    value: 'true',
  },
]

export const PromotionsPage: React.FC = () => {
  const { totalCount, isLoadingInitial, ...searchProps } = useSearchResults(PROMOTION_FILTERS)

  return (
    <>
      <Helmet>
        <title>{DEFAULT_PAGE_TITLE}</title>
      </Helmet>
      <PageLayout>
        <ResponsiveContainer>
          <PageHeading>Promotions</PageHeading>
          {isLoadingInitial && (
            <>
              <SearchResultsSkeleton mt={28} />
            </>
          )}
          {!isLoadingInitial && (
            <>
              {totalCount > 0 && (
                <SearchResults {...searchProps} hideFacets={['is_on_promotion']} />
              )}
              {!totalCount && (
                <Box textAlign="center" p={16}>
                  Sorry, we currently do not have any promotions.
                </Box>
              )}
            </>
          )}
        </ResponsiveContainer>
      </PageLayout>
    </>
  )
}
