import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IProduct, IPublicProduct } from '../../api'
import {
  selectFavouriteIsFetchingUpdate,
  CREATE_FAVOURITE,
  DELETE_FAVOURITE,
} from '../../stores/favourite'
import { useHasAuth } from './use-has-auth'

export function useFavourite(product: IProduct | IPublicProduct) {
  const dispatch = useDispatch()
  const isFetching = useSelector(selectFavouriteIsFetchingUpdate)(product.id)
  const isFavourite = !!product.favourite
  const { hasAuth, onRedirectToLogin } = useHasAuth()

  const onToggle = useCallback(() => {
    if (!hasAuth) {
      onRedirectToLogin()
      return
    }

    if (isFetching) {
      return
    }

    const favouriteId = product.favourite?.id
    if (!favouriteId) {
      dispatch(CREATE_FAVOURITE.request(product.id))
    } else {
      dispatch(
        DELETE_FAVOURITE.request({
          id: favouriteId,
          productId: product.id,
        }),
      )
    }
  }, [dispatch, isFetching, product, hasAuth, onRedirectToLogin])

  return {
    isFavourite,
    isFetching,
    onToggle,
  }
}
