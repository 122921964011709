import { all, put, takeLeading, call, take, select } from 'redux-saga/effects'

import API, { CallReturnType, ENTITY_TYPE_BASKET_DISCOUNT_BXGY, ENTITY_TYPE_BASKET_DISCOUNT_FREE_PRODUCT_PROMOTION, ENTITY_TYPE_PRODUCT, IApplyBasketDiscountBxgy, IApplyBasketDiscountFreeProductPromotion } from '../../../api'

import * as actions from './actions'
import { displayErrorToastSaga, ensureError } from '../../../lib'
import { FETCH_BASKET } from '../baskets/actions'
import { ActionType, getType } from 'typesafe-actions'
import { freeProductPromotionForProductSelector } from './selectors'

function* fetchDiscounts(action: ReturnType<typeof actions.FETCH_BASKET_DISCOUNTS.request>) {
  try {
    const discount: CallReturnType<typeof API.fetchBasketDiscount> = yield API.fetchBasketDiscount(action.payload)
    yield put(actions.FETCH_BASKET_DISCOUNTS.success(discount))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_BASKET_DISCOUNTS.failure(error))
  }
}

function* applyBxgy(action: ReturnType<typeof actions.APPLY_BXGY.request>) {
  const { bxgyId, productId } = action.payload
  const data: IApplyBasketDiscountBxgy = {
    bxgy: { type: ENTITY_TYPE_BASKET_DISCOUNT_BXGY, id: bxgyId },
    product: { type: ENTITY_TYPE_PRODUCT, id: productId },
    relationshipNames: ['bxgy', 'product'],
  }

  try {
    const result: CallReturnType<typeof API.applyBasketDiscountBxgy> = yield API.applyBasketDiscountBxgy(data)
    yield put(
      FETCH_BASKET.request(result.basket.id)
    )

  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.APPLY_BXGY.failure(error))
  }
}

function* applyFreeProductPromotion(action: ReturnType<typeof actions.APPLY_FREE_PRODUCT_PROMOTION.request>) {
  const { freeProductPromotionId } = action.payload
  const data: IApplyBasketDiscountFreeProductPromotion = {
    freeProductPromotion: { type: ENTITY_TYPE_BASKET_DISCOUNT_FREE_PRODUCT_PROMOTION, id: freeProductPromotionId },
    relationshipNames: ['free_product_promotion'],
  }

  try {
    const result: CallReturnType<typeof API.applyBasketDiscountFreeProductPromotion> = yield API.applyBasketDiscountFreeProductPromotion(data)
    yield put(
      FETCH_BASKET.request(result.basket.id)
    )

  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.APPLY_FREE_PRODUCT_PROMOTION.failure(error))
  }
}

function* addFreeProductToBasket(action: ReturnType<typeof actions.ADD_FREE_PRODUCT_TO_BASKET.request>) {
  const { productId, basketId } = action.payload

  // fetch the basket discounts
  yield put(actions.FETCH_BASKET_DISCOUNTS.request(basketId))

  // wait for the discounts to be fetched
  // @ts-ignore
  const fetchDiscountsResult: ActionType<any> = yield take([
    actions.FETCH_BASKET_DISCOUNTS.success,
    actions.FETCH_BASKET_DISCOUNTS.failure,
  ])

  if (fetchDiscountsResult.type === getType(actions.FETCH_BASKET_DISCOUNTS.failure)) {
    // failed, ignore
    yield put(actions.ADD_FREE_PRODUCT_TO_BASKET.failure(fetchDiscountsResult.payload))
    return
  }

  const selectFreeProductPromotion: ReturnType<typeof freeProductPromotionForProductSelector> =
    yield select(freeProductPromotionForProductSelector)

  const freeProductPromotion = selectFreeProductPromotion(basketId, productId)

  if (!freeProductPromotion) {
    yield put(actions.ADD_FREE_PRODUCT_TO_BASKET.failure(new Error('Free product promotion not found')))
    return
  }

  yield put(
    actions.APPLY_FREE_PRODUCT_PROMOTION.request({
      freeProductPromotionId: freeProductPromotion.id,
    }),
  )
}

export function* saga() {
  yield all([
    takeLeading(actions.FETCH_BASKET_DISCOUNTS.request, fetchDiscounts),
    takeLeading(actions.FETCH_BASKET_DISCOUNTS.failure, displayErrorToastSaga),
    takeLeading(actions.APPLY_BXGY.request, applyBxgy),
    takeLeading(actions.APPLY_BXGY.failure, displayErrorToastSaga),
    takeLeading(actions.APPLY_FREE_PRODUCT_PROMOTION.request, applyFreeProductPromotion),
    takeLeading(actions.APPLY_FREE_PRODUCT_PROMOTION.failure, displayErrorToastSaga),
    takeLeading(actions.ADD_FREE_PRODUCT_TO_BASKET.request, addFreeProductToBasket),
    takeLeading(actions.ADD_FREE_PRODUCT_TO_BASKET.failure, displayErrorToastSaga),
  ])
}
