import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectMenuListingResults, FETCH_MENUS } from '../../stores/menu'

export function useMenusListing() {
  const dispatch = useDispatch()
  const listing = useSelector(selectMenuListingResults)

  const onLoadInitial = useCallback(() => {
    dispatch(FETCH_MENUS.request())
  }, [dispatch])

  // automatically call load initial
  useEffect(() => {
    onLoadInitial()
  }, [onLoadInitial])

  return {
    ...listing,
    onLoadInitial,
  }
}
