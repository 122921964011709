import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { ENTITY_TYPE_BASKET, TBasketAny, TBasketItemAny, TOrderAny, TOrderItemAny } from '../../api'
import { DELETE_BASKET_ITEM, UPDATE_BASKET_ITEM } from '../../stores/order'
import { getItemSubtotals } from '../utils'

export function useOrderOrBasketItemDetail(
  order: TBasketAny | TOrderAny,
  item: TBasketItemAny | TOrderItemAny,
) {
  const dispatch = useDispatch()

  const { isBasket, canRemove, canEdit, splitsSubtotal, packsSubtotal } = useMemo(() => {
    const isBasket = order.type === ENTITY_TYPE_BASKET
    const hasProduct = !(!item.product || !item.productIsAvailable)
    const canRemove = isBasket && order.canEdit
    const canEdit = canRemove && hasProduct

    return {
      isBasket,
      canRemove,
      canEdit,
      ...getItemSubtotals(item),
    }
  }, [order, item])

  const onRemove = useCallback(() => {
    if (!canRemove) {
      return
    }
    dispatch(
      DELETE_BASKET_ITEM.request({
        id: item.id,
        basketId: order.id,
      }),
    )
  }, [canRemove, order, item, dispatch])

  const onUpdate = useCallback(
    (packQuantity: number, splitQuantity: number) => {
      if (!canEdit) {
        return
      }
      if (splitQuantity < 1 && packQuantity < 1) {
        onRemove()
      } else {
        dispatch(
          UPDATE_BASKET_ITEM.request({
            id: item.id,
            basketId: order.id,
            packQuantity,
            splitQuantity,
          }),
        )
      }
    },
    [canEdit, order, item, dispatch, onRemove],
  )

  const onUpdateSplitQuantity = useCallback(
    (quantity: number) => {
      onUpdate(item.packQuantity, quantity)
    },
    [item, onUpdate],
  )

  const onUpdatePackQuantity = useCallback(
    (quantity: number) => {
      onUpdate(quantity, item.splitQuantity)
    },
    [item, onUpdate],
  )

  return {
    isBasket,
    canRemove,
    canEdit,
    splitsSubtotal,
    packsSubtotal,
    onRemove,
    onUpdateSplitQuantity,
    onUpdatePackQuantity,
  }
}
