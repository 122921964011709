import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import API from '../../api'
import { GOTO_LOGIN } from '../../stores/account'

export function useHasAuth() {
  const hasAuth = !!API.getAuth()
  const dispatch = useDispatch()
  const onRedirectToLogin = useCallback(
    (useLocationAsNextUrl?: boolean) => {
      dispatch(GOTO_LOGIN(useLocationAsNextUrl))
    },
    [dispatch],
  )
  return {
    hasAuth,
    onRedirectToLogin,
  }
}
