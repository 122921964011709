import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { createRoot } from 'react-dom/client'
import { App } from './components/app'
import reportWebVitals from './reportWebVitals'

import { StrictMode } from 'react'
import './polyfills'
import { store } from './stores/configure-store'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  normalizeDepth: 10, // for capturing Redux actions

  environment: process.env.REACT_APP_SENTRY_ENV,

  enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
})

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <StrictMode>
    <App store={store} />
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
