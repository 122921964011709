import {
  Icon,
  chakra,
  HTMLChakraProps,
  ThemingProps,
  forwardRef,
  useStyleConfig,
  omitThemingProps,
  SystemProps,
  IconProps,
  ComponentWithAs,
} from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import { IconType } from 'react-icons'
import * as icons from '../icons'

type ILinkBoxIcons = {
  [key: string]: IconType | ComponentWithAs<'svg', IconProps>
}

const LinkBoxIconMap: ILinkBoxIcons = {
  bread: icons.OmBread,
  heart: icons.OmHeart,
  menu: icons.OmMenu,
  promotion: icons.OmPromotion,
  new: icons.OmNew,
  vegan: icons.OmVegan,
  takeaway: icons.OmTakeaway,
}

function linkIcon(iconType: string | IconType, props: IconProps) {
  if (typeof iconType === 'string') {
    const icon = LinkBoxIconMap[iconType]
    if (!icon) {
      return null
    }
    return <Icon as={icon} {...props} />
  }
  return <Icon as={iconType} {...props} />
}

export interface IIconLinkBoxProps extends HTMLChakraProps<'a'>, ThemingProps {
  icon?: string | IconType
  iconSpacing?: SystemProps['marginRight']
  iconSize?: SystemProps['height']
}

export const IconLinkBox = forwardRef<IIconLinkBoxProps, 'a'>((props, ref) => {
  const styles = useStyleConfig('IconLinkBox', props)
  const {
    className,
    icon,
    children,
    iconSpacing = '0.5rem',
    iconSize = '1.5rem',
    ...rest
  } = omitThemingProps(props)
  return (
    <chakra.a ref={ref} className={cx('om-iconlinkbox', className)} {...rest} __css={styles}>
      {children}
      {icon ? linkIcon(icon, { marginStart: iconSpacing, h: iconSize, w: iconSize }) : null}
    </chakra.a>
  )
})
