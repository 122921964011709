import { useCallback, useState } from 'react'
import { Box, List, ListItem, Text, Link, useTheme } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { useCmsFlatMenu } from '../cms-page-content'
import { ResponsiveContainer } from '../responsive-container'
import { OmPhone } from '../icons'

export function Footer() {
  const theme = useTheme()
  const [isMenuFetched, setIsMenuFetched] = useState(false)
  const isPaused = useCallback(() => {
    return isMenuFetched
  }, [isMenuFetched])
  const { data: menu } = useCmsFlatMenu('footer-menu', { isPaused })
  if (menu && !isPaused()) {
    setIsMenuFetched(true)
  }

  return (
    <Box bg="gray.100" mt={4} boxShadow={`0 50vh 0 50vh ${theme.colors.gray[50]}`}>
      <ResponsiveContainer py={4}>
        <Box display={[null, null, 'flex']}>
          {menu && menu.items.length > 0 && (
            <List width={[null, null, '50%']} spacing={3}>
              {menu.items.map((item) => {
                const isPage = item.url[0] === '/'
                if (isPage) {
                  return (
                    <ListItem key={item.id}>
                      <Link as={RouterLink} to={item.url}>
                        {item.text}
                      </Link>
                    </ListItem>
                  )
                }
                return (
                  <ListItem key={item.id}>
                    <Link href={item.url}>{item.text}</Link>
                  </ListItem>
                )
              })}
            </List>
          )}
          <Box my={[4, null, 0]} width={[null, null, '50%']} textAlign={[null, null, 'right']}>
            <Box as="p" mb={3}>
              <Link href="tel:01282 429446">
                <OmPhone mr={1} mb="2px" />
                01282 429446
              </Link>
            </Box>
            <Text fontWeight="light">
              Cobalt House
              <br />
              Magnesium Way
              <br />
              Burnley Bridge Business Park
              <br />
              Hapton, Burnley
              <br />
              BB12 7BF
            </Text>
          </Box>
        </Box>
        <Text mt={[null, null, 3]} fontWeight="light">
          © GC Birchall Limited 2021
        </Text>
      </ResponsiveContainer>
    </Box>
  )
}
