import { createIcon } from '@chakra-ui/react'

export const OmDietaryKosher = createIcon({
  displayName: 'Kosher',
  viewBox: '0 0 42 62',
  d: 'M 37.444 62 C 32.106 62 27.128 59.308 24.205 54.84 L 12.365 36.742 L 12.365 36.742 L 12.365 55.818 C 12.365 59.232 9.597 62 6.182 62 C 2.768 62 0 59.232 0 55.818 L 0 6.182 C 0 2.768 2.768 0 6.182 0 C 9.597 0 12.365 2.768 12.365 6.182 L 12.365 25.259 L 12.365 25.259 L 24.205 7.16 C 27.128 2.692 32.106 0 37.444 0 C 39.79 0 41.691 1.901 41.691 4.246 C 41.691 5.174 41.387 6.075 40.827 6.814 L 22.447 31.021 L 22.447 31.021 L 40.822 55.189 C 42.24 57.055 41.878 59.717 40.012 61.135 C 39.274 61.696 38.372 62 37.444 62 Z',
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 42 62"
//     id="vector">
//     <path
//         id="path"
//         d="M 37.444 62 C 32.106 62 27.128 59.308 24.205 54.84 L 12.365 36.742 L 12.365 36.742 L 12.365 55.818 C 12.365 59.232 9.597 62 6.182 62 C 2.768 62 0 59.232 0 55.818 L 0 6.182 C 0 2.768 2.768 0 6.182 0 C 9.597 0 12.365 2.768 12.365 6.182 L 12.365 25.259 L 12.365 25.259 L 24.205 7.16 C 27.128 2.692 32.106 0 37.444 0 C 39.79 0 41.691 1.901 41.691 4.246 C 41.691 5.174 41.387 6.075 40.827 6.814 L 22.447 31.021 L 22.447 31.021 L 40.822 55.189 C 42.24 57.055 41.878 59.717 40.012 61.135 C 39.274 61.696 38.372 62 37.444 62 Z"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
