import { isString } from 'lodash'
import { parseISO, formatISO } from 'date-fns'
import { APIService } from '../service'
import {
  IJsonApiModel,
  IJsonApiModelWithId,
  IFetchMultipleQueryOptions,
  IFetchMultipleResponse,
  IFetchMultipleResponseMeta,
  IFetchOneQueryOptions,
} from '../types'
import { deserializeTransformer } from '../serializer'
import { IAddress } from './address'
import { TBasketItemAny } from './basket-item'
import { TOrderWithAddressAndItems } from './order'

declare module '../service' {
  interface APIService {
    fetchBaskets(
      url: string,
    ): Promise<IFetchMultipleResponse<TBasketWithAddressAndItems, IFetchMultipleResponseMeta>>
    fetchBaskets(
      requestOpts?: IFetchMultipleQueryOptions,
    ): Promise<IFetchMultipleResponse<TBasketWithAddressAndItems, IFetchMultipleResponseMeta>>
    fetchBasket(
      id: string,
      requestOpts?: IFetchOneQueryOptions,
    ): Promise<TBasketWithAddressAndItems>
    fetchAllBaskets(requestOpts?: IFetchMultipleQueryOptions): Promise<TBasketWithAddressAndItems[]>
    deleteBasket(id: string): Promise<unknown>
    createBasket(data: Omit<INewBasket, 'type'>): Promise<TBasketWithAddressAndItems>
    updateBasket(data: Omit<IUpdateBasket, 'type'>): Promise<TBasketWithAddressAndItems>
    confirmBasket(
      id: string,
      requestOpts?: IFetchOneQueryOptions,
    ): Promise<TOrderWithAddressAndItems>
  }
}

export const ENTITY_TYPE_BASKET = 'baskets'

export type TBasketAny = IBasket<IJsonApiModelWithId, IJsonApiModelWithId, IJsonApiModelWithId>
export type TBasketWithAddressAndItems = IBasket<IJsonApiModelWithId, IAddress, TBasketItemAny>

export interface IBasket<
  O extends IJsonApiModelWithId,
  A extends IJsonApiModelWithId,
  I extends IJsonApiModelWithId,
> extends IJsonApiModelWithId {
  id: string
  title: string
  canEdit: boolean
  canSubmit: boolean
  poNumber: string
  requestedDeliveryDate: Date
  cutoff: Date
  netTotal: string
  order?: O
  deliverTo: A
  items: I[]
}

export interface INewBasket extends Omit<IJsonApiModel, 'type'> {
  title: string
  poNumber: string
  requestedDeliveryDate: Date
  deliverTo: IJsonApiModelWithId
}

export interface IUpdateBasket extends Omit<IJsonApiModelWithId, 'type'> {
  title: string
  poNumber: string
  requestedDeliveryDate: Date
  deliverTo: IJsonApiModelWithId
}

APIService.prototype.fetchBaskets = async function (
  requestOptsOrUrl?: IFetchMultipleQueryOptions | string,
) {
  if (isString(requestOptsOrUrl)) {
    return this.fetchMultiple<TBasketWithAddressAndItems, IFetchMultipleResponseMeta>(
      requestOptsOrUrl,
    )
  }
  return this.fetchMultiple<TBasketWithAddressAndItems, IFetchMultipleResponseMeta>(
    ENTITY_TYPE_BASKET,
    {
      ...requestOptsOrUrl,
      include: [...(requestOptsOrUrl?.include || []), 'deliver_to', 'items'],
    },
  )
}

APIService.prototype.fetchAllBaskets = async function (requestOpts?: IFetchMultipleQueryOptions) {
  return this.fetchAll<TBasketWithAddressAndItems>(ENTITY_TYPE_BASKET, {
    ...requestOpts,
    include: [...(requestOpts?.include || []), 'deliver_to', 'items'],
  })
}

APIService.prototype.fetchBasket = async function (
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  return this.fetchOne<TBasketWithAddressAndItems>(ENTITY_TYPE_BASKET + '/' + id, {
    ...requestOpts,
    include: [...(requestOpts?.include || []), 'deliver_to', 'items'],
  })
}

APIService.prototype.deleteBasket = async function (id: string) {
  return this.delete(`${ENTITY_TYPE_BASKET}/${id}`)
}

APIService.prototype.createBasket = async function (data: INewBasket) {
  return this.create<TBasketWithAddressAndItems>(
    ENTITY_TYPE_BASKET,
    {
      type: ENTITY_TYPE_BASKET,
      ...data,
      requestedDeliveryDate: formatISO(data.requestedDeliveryDate, { representation: 'date' }),
    },
    {
      include: ['deliver_to', 'items'],
    },
  )
}

APIService.prototype.updateBasket = async function (data: IUpdateBasket) {
  const { id } = data
  return this.update<TBasketWithAddressAndItems>(
    ENTITY_TYPE_BASKET + '/' + id,
    {
      type: ENTITY_TYPE_BASKET,
      ...data,
      requestedDeliveryDate: formatISO(data.requestedDeliveryDate, { representation: 'date' }),
    },
    {
      include: ['deliver_to', 'items'],
    },
  )
}

APIService.prototype.confirmBasket = async function (
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  return this.create<TOrderWithAddressAndItems>(
    ENTITY_TYPE_BASKET + '/' + id + '/order',
    undefined,
    {
      ...requestOpts,
      include: [...(requestOpts?.include || []), 'deliver_to', 'items'],
    },
  )
}

deserializeTransformer.register(ENTITY_TYPE_BASKET, {
  requestedDeliveryDate: (v: string) => parseISO(v),
  cutoff: (v: string) => parseISO(v),
})
