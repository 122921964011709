import { getColor, mode } from '@chakra-ui/theme-tools'

const parts = ['list', 'button', 'option', 'icon', 'label']

function baseStyleList(props: Record<string, any>) {
  return {
    bg: mode(`#fff`, `gray.700`)(props),
    boxShadow: mode(`sm`, `dark-lg`)(props),
    color: 'inherit',
    minW: '3xs',
    py: '2',
    zIndex: 'dropdown',
    borderRadius: 'md',
    borderWidth: '1px',
    maxH: '50vh',
    overflowY: 'auto',
  }
}

function baseStyleOption(props: Record<string, any>) {
  return {
    py: '0.4rem',
    px: '0.8rem',
    transition: 'background 50ms ease-in 0s',
    _focus: {
      bg: mode(`gray.100`, `whiteAlpha.100`)(props),
    },
    _active: {
      bg: mode(`gray.200`, `whiteAlpha.200`)(props),
    },
    _expanded: {
      bg: mode(`gray.100`, `whiteAlpha.100`)(props),
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
  }
}

const baseStyleIcon = {
  flexShrink: 0,
  fontSize: '0.8em',
}

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props
  return {
    focusBorderColor: fc || mode('blue.500', 'blue.300')(props),
    errorBorderColor: ec || mode('red.500', 'red.300')(props),
  }
}

function baseStyleButton(props: Record<string, any>) {
  const { theme } = props
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props)
  return {
    width: '100%',
    textAlign: 'left',
    whiteSpace: 'normal',
    minHeight: 10,
    px: 4,
    pr: 2,
    py: 1,
    borderRadius: 'md',
    // height: 'auto',
    border: '1px solid',
    borderColor: 'inherit',
    bg: 'inherit',
    _hover: {
      borderColor: mode('gray.300', 'whiteAlpha.400')(props),
    },
    _readOnly: {
      boxShadow: 'none !important',
      userSelect: 'all',
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed',
    },
    _invalid: {
      borderColor: getColor(theme, ec),
      boxShadow: `0 0 0 1px ${getColor(theme, ec)}`,
    },
    _focus: {
      zIndex: 1,
      borderColor: getColor(theme, fc),
      boxShadow: `0 0 0 1px ${getColor(theme, fc)}`,
    },
  }
}

const baseStyle = (props: Record<string, any>) => ({
  list: baseStyleList(props),
  button: baseStyleButton(props),
  option: baseStyleOption(props),
  icon: baseStyleIcon,
})

export const SelectMenu = {
  parts,
  baseStyle,
}
