import {
  AspectRatio,
  Box,
  forwardRef,
  LinkBox,
  LinkBoxProps,
  LinkOverlay,
  As,
} from '@chakra-ui/react'

export interface IImageLinkBoxProps extends LinkBoxProps {
  image: string
  href?: string
  as?: As
  to?: string
}

export const ImageLinkBox = forwardRef<IImageLinkBoxProps, 'a'>((props, ref) => {
  const { as, image, href, to, children, ...rest } = props
  return (
    <AspectRatio maxW="100%" ratio={1} as={LinkBox} bg="green.500" ref={ref} {...rest}>
      <Box
        flexDirection="column"
        justifyContent="flex-end !important"
        backgroundImage={`url(${image})`}
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
      >
        <Box
          background="green.500"
          w="100%"
          justifyContent="center"
          textAlign="center"
          p={6}
          color="white"
        >
          <LinkOverlay href={href} as={as} to={to}>
            {children}
          </LinkOverlay>
        </Box>
      </Box>
    </AspectRatio>
  )
})
