import { createSelector } from 'reselect'

import { IRootState } from '../../root'

export const selectCutoffState = (state: IRootState) => state.order.cutoffs

export const selectTomorrowsCutoffState = createSelector(
  selectCutoffState,
  (state) => state.tomorrow,
)

export const selectTomorrowsCutoff = createSelector(
  selectTomorrowsCutoffState,
  (state) => state.item,
)
