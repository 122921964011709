import React, { useMemo } from 'react'
import { SWRConfiguration } from 'swr'
import { forwardRef } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'

import { PageHeading } from '../page-heading'
import { useCmsPageContent } from './use-cms-page-content'
import { CmsStack, ICmsStackProps } from './cms-stack'
import { ICarouselBlock, CarouselBlock } from './blocks/carousel-block'
import { IIconLinkBoxesBlock, IconLinkBoxesBlock } from './blocks/icon-link-boxes-block'
import { IImageLinkBoxesBlock, ImageLinkBoxesBlock } from './blocks/image-link-boxes-block'
import { IRichTextBlock, RichTextBlock } from './blocks/rich-text-block'
import { ResponsiveContainer } from '../responsive-container'

type TBlock = ICarouselBlock | IIconLinkBoxesBlock | IImageLinkBoxesBlock | IRichTextBlock

// const TechDifficultiesMessage: React.FC = () => {
//   return (
//     <ResponsiveContainer bg="gray.100" py={4}>
//       <PageHeading mt={0}>Technical Difficulties</PageHeading>
//       <Text my={2}>Overnight, we experienced some technical issues with our systems, which has led to difficulties with picking and routing orders.</Text>
//       <Text my={2}>If you are expecting an order today and are yet to receive it, then please contact your sales representative, who will be happy to provide more information regarding the status of your order.</Text>
//       <Text my={2}>If you’re looking to place an order today, then you can do so by emailing our sales team at <Link href="mailto:sales@birchallfoodservice.co.uk" color="coral.500">sales@birchallfoodservice.co.uk</Link>. Unfortunately, our phone lines are down.</Text>
//       <Text my={2}>If you would like to place on order via OrderMate, you can do so, however, you will not be able to confirm your order. We will confirm your order for you before your Monday delivery.</Text>
//       <Text my={2}>Our team are working hard to fix these technical issues. We appreciate your patience as we work towards a resolution, and we apologise for any inconvenience caused.</Text>
//     </ResponsiveContainer>
//   )
// }

export interface ICmsPage {
  id: number
  meta: {
    type: string
    slug: string
    show_in_menus: string
    seo_title: string
    search_description: string
  }
  title: string
  body?: TBlock[]
}

export interface ICmsPageContentProps {
  path: string
  loader?: JSX.Element
  error404?: JSX.Element
  swrOptions?: SWRConfiguration
}

export const CmsPageContent: React.FC<ICmsPageContentProps> = (props) => {
  const { path, swrOptions, loader, error404 } = props
  const { data, error } = useCmsPageContent(path, swrOptions)

  if (error) {
    if (error.status === 404 && error404) {
      return error404
    }
    throw error
  }
  if (!data) {
    return loader ?? null
  }
  return (
    <>
      <CmsPageHead page={data} />
      {/* {path === '/' && <TechDifficultiesMessage />} */}
      <CmsPageContentBlocks page={data} />
    </>
  )
}

export interface ICmsPageContentBlocksProps extends Partial<ICmsStackProps> {
  page: ICmsPage
}

export const CmsPageContentBlocks = forwardRef<ICmsPageContentBlocksProps, 'div'>((props, ref) => {
  const { page, spacing = 4, ...rest } = props
  const _children = useMemo(() => {
    return page.body?.map((block) => {
      switch (block.type) {
        case 'carousel':
          return <CarouselBlock block={block} key={block.id} />
        case 'icon_link_boxes':
          return <IconLinkBoxesBlock block={block} key={block.id} />
        case 'image_link_boxes':
          return <ImageLinkBoxesBlock block={block} key={block.id} />
        case 'rich_text':
          return <RichTextBlock block={block} key={block.id} />
      }
      return null
    })
  }, [page])

  const showHeader = page.meta.type !== 'cms.HomePage'

  return (
    <CmsStack ref={ref} spacing={spacing} {...rest}>
      {showHeader && (
        <ResponsiveContainer>
          <PageHeading>{page.title}</PageHeading>
        </ResponsiveContainer>
      )}
      {_children}
    </CmsStack>
  )
})

const CmsPageHead: React.FC<{ page: ICmsPage }> = ({ page }) => {
  const { meta } = page
  if (!meta) {
    return null
  }
  return (
    <Helmet>
      {meta.seo_title && <title>{meta.seo_title}</title>}
      {meta.search_description && <meta name="description" content={meta.search_description} />}
    </Helmet>
  )
}
