import { APIService } from '../service'
import { IFetchOneQueryOptions, IJsonApiModelWithId } from '../types'
import { IBasketDiscountBxgyWithProduct } from './basket-discount-bxgy'
import { IBasketDiscountFreeProductPromotionWithProduct } from './basket-discount-free-product-promotion'

declare module '../service' {
  interface APIService {
    fetchBasketDiscount(
      id: string,
      requestOpts?: IFetchOneQueryOptions,
    ): Promise<IBasketDiscountWithDiscounts>
  }
}

export const ENTITY_TYPE_BASKET_DISCOUNT = 'basket-discounts'

export interface IBasketDiscount<Bxgy extends IJsonApiModelWithId, Fpp extends IJsonApiModelWithId>
  extends IJsonApiModelWithId {
  id: string
  bxgys: Bxgy[]
  freeProductPromotions: Fpp[]
}

export type IBasketDiscountAny = IBasketDiscount<IJsonApiModelWithId, IJsonApiModelWithId>
export type IBasketDiscountWithDiscounts = IBasketDiscount<IBasketDiscountBxgyWithProduct, IBasketDiscountFreeProductPromotionWithProduct>



APIService.prototype.fetchBasketDiscount = async function (
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  return this.fetchOne<IBasketDiscountWithDiscounts>(ENTITY_TYPE_BASKET_DISCOUNT + '/' + id, {
    ...requestOpts,
  })
}
