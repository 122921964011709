import queryString from 'query-string'
import { startsWith } from 'lodash'

import { serialize, deserialize } from './serializer'
import {
  IRequestParams,
  IJsonApiSingleResponse,
  IJsonApiMultipleResponse,
  IError,
  IJsonApiData,
  IJsonApiModel,
  IFetchOneQueryOptions,
  IFetchMultipleQueryOptions,
  IFetchMultipleResponse,
  IFetchMultipleResponseMeta,
  IRequestHeaders,
} from './types'
import Cookies from 'js-cookie'

export class JsonApiError extends Error {
  status: number
  errors: IError[]

  constructor(status: number, errors: IError[]) {
    super(errors && errors.length ? errors[0].detail : `Response ${status}`)
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
    this.status = status
    this.errors = errors
  }
}

const AUTH_COOKIE_NAME = 'OMTOKEN'
const AUTH_COOKIE_MAX_AGE = 35
const cookie = Cookies.withAttributes({
  path: '/',
  secure: window.location.protocol === 'https:',
  sameSite: window.location.protocol === 'https:' ? 'None' : 'Lax',
  expires: AUTH_COOKIE_MAX_AGE,
})

interface IAPIServiceAuth {
  user: string
  token: {
    id: string
    key: string
  }
}

export class APIService {
  baseUrl: string
  private auth?: IAPIServiceAuth
  private errorListener?: (error: JsonApiError) => void

  constructor(baseUrl: string) {
    this.auth = this.getStoredAuth()
    this.baseUrl = baseUrl
  }

  setErrorListener(listener: (error: JsonApiError) => void) {
    this.errorListener = listener
  }

  private onError(error: JsonApiError) {
    this.errorListener && this.errorListener(error)
  }

  getAuth() {
    return this.auth
  }

  setAuth(userId: string, authtoken: { id: string; key: string }) {
    this.auth = {
      user: userId,
      token: authtoken,
    }
    this.setStoredAuth(this.auth)
  }

  hasAuth() {
    return !!this.auth
  }

  clearAuth() {
    this.auth = undefined
    this.setStoredAuth()
  }

  getAuthorizationHeader(token: string) {
    return {
      Authorization: `Token ${token}`,
    }
  }

  private updateAuthExpiry() {
    this.setStoredAuth(this.auth)
  }

  private getStoredAuth() {
    // const stored = window.localStorage.getItem(LOCAL_STORAGE_KEY)
    const stored = cookie.get(AUTH_COOKIE_NAME)
    if (!stored) {
      return
    }
    try {
      const auth = JSON.parse(atob(stored))
      if (auth.user && auth.token && auth.token.key && auth.token.key) {
        return auth as IAPIServiceAuth
      }
    } catch (_error) {
      this.setStoredAuth() // clear the auth
      return
    }
    return
  }

  private setStoredAuth(auth?: IAPIServiceAuth) {
    if (auth) {
      cookie.set(AUTH_COOKIE_NAME, btoa(JSON.stringify(auth)))
    } else {
      cookie.remove(AUTH_COOKIE_NAME)
    }
  }

  private getHeaders(extra?: IRequestHeaders) {
    let headers: IRequestHeaders = {
      'Content-Type': 'application/vnd.api+json',
    }
    if (this.auth) {
      headers = {
        ...headers,
        ...this.getAuthorizationHeader(this.auth.token.key),
      }
    }
    return Object.entries({
      ...headers,
      ...extra,
    })
  }

  private resourceUrl(resourcePath: string) {
    return this.baseUrl + resourcePath
  }

  private async request<T>(
    url: string,
    params?: IRequestParams,
    headers?: IRequestHeaders,
  ): Promise<T> {
    const query: any = {}

    const init = {
      ...params,
      headers: this.getHeaders(headers),
    }

    if (params && params.opts) {
      for (const [key, value] of Object.entries(params.opts)) {
        switch (key) {
          case 'page':
            query['page[number]'] = value
            break
          case 'pageSize':
            query['page[size]'] = value
            break
          case 'limit':
            query.limit = value
            break
          case 'include':
            query.include = value.join(',')
            break
          case 'fields':
            for (const [resource, fields] of Object.entries(value)) {
              query[`fields[${resource}]`] = (fields as string[]).join(',')
            }
            break
          case 'filters':
            for (const [filterKey, filterValue] of Object.entries(value)) {
              query[`filter[${filterKey}]`] = filterValue
            }
            break
          case 'sort':
            query.sort = value.join(',')
            break
          case 'codes':
            query.codes = value
            break
          case 'search':
            query.search = value
            break
        }
      }
    }

    const fetchUrl = Object.keys(query).length ? `${url}?${queryString.stringify(query)}` : url
    const response = await fetch(fetchUrl, init)

    if (response.ok) {
      this.updateAuthExpiry()
      if (response.status === 204) {
        return Promise.resolve<any>(undefined)
      }
      return response.json()
    }

    const { errors } = (await response.json()) as { errors: IError[] }
    const error = new JsonApiError(response.status, errors)
    this.onError(error)
    throw error
  }

  async delete(resourcePath: string, headers?: IRequestHeaders) {
    const url = this.resourceUrl(resourcePath)
    return this.request(
      url,
      {
        method: 'DELETE',
      },
      headers,
    )
  }

  async create<T extends IJsonApiModel | undefined>(
    resourcePath: string,
    data?: Omit<IJsonApiModel, 'id'>,
    requestOpts?: IFetchOneQueryOptions,
    headers?: IRequestHeaders,
  ) {
    const url = this.resourceUrl(resourcePath)
    const body =
      data &&
      serialize({
        stuff: data,
      })
    const params: IRequestParams = {
      method: 'POST',
      opts: requestOpts,
    }
    if (body) {
      params['body'] = JSON.stringify(body)
    }
    const response = await this.request<IJsonApiSingleResponse>(url, params, headers)
    if (response) {
      return deserialize(response) as T
    }
    return undefined as T
  }

  async update<T extends IJsonApiModel>(
    resourcePath: string,
    data: IJsonApiModel,
    requestOpts?: IFetchOneQueryOptions,
    headers?: IRequestHeaders,
  ) {
    const url = this.resourceUrl(resourcePath)
    const body = serialize({
      stuff: data,
    })
    const response = await this.request<IJsonApiSingleResponse>(
      url,
      {
        method: 'PATCH',
        body: JSON.stringify(body),
        opts: requestOpts,
      },
      headers,
    )
    return deserialize(response) as T
  }

  async fetchOne<T extends IJsonApiModel>(
    resourcePath: string,
    requestOpts?: IFetchOneQueryOptions,
    headers?: IRequestHeaders,
  ) {
    const url = this.resourceUrl(resourcePath)
    const response = await this.request<IJsonApiSingleResponse>(url, { opts: requestOpts }, headers)
    return deserialize(response) as T
  }

  async fetchMultiple<T extends IJsonApiModel, M extends IFetchMultipleResponseMeta>(
    resourcePathOrUrl: string,
    requestOpts?: IFetchMultipleQueryOptions,
    headers?: IRequestHeaders,
  ): Promise<IFetchMultipleResponse<T, M>> {
    const isUrl = startsWith(resourcePathOrUrl, 'http')
    const url = isUrl ? resourcePathOrUrl : this.resourceUrl(resourcePathOrUrl)
    const params = isUrl
      ? {}
      : {
        opts: requestOpts,
      }
    const response = await this.request<IJsonApiMultipleResponse<M>>(url, params, headers)

    return {
      links: response.links,
      data: deserialize(response) as T[],
      meta: response.meta,
    }
  }

  async fetchAll<T extends IJsonApiModel>(
    resourcePath: string,
    requestOpts?: IFetchMultipleQueryOptions,
    headers?: IRequestHeaders,
  ): Promise<T[]> {
    const url = this.resourceUrl(resourcePath)
    const response = await this.request<IJsonApiMultipleResponse<IFetchMultipleResponseMeta>>(
      url,
      {
        opts: requestOpts,
      },
      headers,
    )

    const data = {
      data: response.data as IJsonApiData[],
      included: (response.included || []) as IJsonApiData[],
    }

    let next = response.links.next

    while (next) {
      const response = await this.request<IJsonApiMultipleResponse<IFetchMultipleResponseMeta>>(
        next,
        undefined,
        headers,
      )
      data.data = [...(data.data as IJsonApiData[]), ...(response.data as IJsonApiData[])]
      data.included = [
        ...(data.included as IJsonApiData[]),
        ...((response.included || []) as IJsonApiData[]),
      ]
      next = response.links.next
    }

    return deserialize(data) as T[]
  }
}
