import React from 'react'
import AliceCarousel, { EventObject } from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { forwardRef, Box, BoxProps, IconButton } from '@chakra-ui/react'

import { OmChevronLeft, OmChevronRight, OmCircle } from '../icons'

export const Carousel = forwardRef<BoxProps, 'div'>(({ children, ...rest }, ref) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  let carousel: AliceCarousel | null = null

  const slideTo = (i: number) => carousel?.slideTo(i)
  const slidePrev = (event: Event | React.MouseEvent) => carousel?.slidePrev(event)
  const slideNext = (event: Event | React.MouseEvent) => carousel?.slideNext(event)
  const onSlideChanged = ({ item }: EventObject) => setActiveIndex(item)

  const _children = React.useMemo(() => {
    return React.Children.toArray(children).map((child, index) => (
      <div data-value={index + 1}>{child}</div>
    ))
  }, [children])

  return (
    <Box ref={ref} position="relative" {...rest}>
      {_children.length === 1 && children}
      {_children.length > 1 && (
        <>
          <AliceCarousel
            mouseTracking={false}
            infinite={true}
            autoPlay={true}
            autoPlayInterval={3000}
            items={_children}
            disableButtonsControls={true}
            disableDotsControls={true}
            onSlideChanged={onSlideChanged}
            ref={(el) => (carousel = el)}
          />
          <Box
            position="absolute"
            bottom={4}
            right={4}
            display="flex"
            borderRadius="sm"
            background="rgba(255, 255, 255, 0.65)"
          >
            <IconButton
              size="md"
              aria-label="Previous"
              icon={<OmChevronLeft />}
              variant="unstyled"
              display="inline-flex"
              color="coral.500"
              onClick={slidePrev}
            />
            {_children.map((_, index) => (
              <IconButton
                key={index}
                size="xs"
                aria-label={`Item ${index + 1}`}
                icon={<OmCircle />}
                variant="unstyled"
                display="inline-flex"
                color={index === activeIndex ? 'coral.500' : 'white'}
                onClick={() => slideTo(index)}
                margin={2}
              />
            ))}
            <IconButton
              size="md"
              aria-label="Next"
              icon={<OmChevronRight />}
              variant="unstyled"
              display="inline-flex"
              color="coral.500"
              onClick={slideNext}
            />
          </Box>
        </>
      )}
    </Box>
  )
})
