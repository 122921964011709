import { HStack, useBreakpointValue } from '@chakra-ui/react'

import { IAvailableFacet } from '../../../lib'
import { AllergensFilterMenu } from './allergens-filter-menu'
import { PromotionFilterMenu } from './promotion-filter-menu'
import { MobileFiltersOverlay } from './mobile-filters-overlay'

export interface ISearchFiltersProps {
  facets: IAvailableFacet[]
  onAddFacet: (field: string) => void
  onRemoveFacet: (field: string) => void
  onSetFacets: (fields: string[]) => void
  onRemoveAllFacets: () => void
  hideFacets?: string[]
}

export const SearchFilters: React.FC<ISearchFiltersProps> = (props) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })

  if (isMobile) {
    return <MobileFiltersOverlay {...props} />
  }

  return (
    <>
      <HStack spacing={4}>
        <AllergensFilterMenu {...props} />
        <PromotionFilterMenu {...props} />
      </HStack>
    </>
  )
}
