import { createSelector } from 'reselect'

import { IRootState } from '../../root'

export const selectNonDeliveryDaysState = (state: IRootState) => state.order.nonDeliveryDays

export const selectNonDeliveryDays = createSelector(
  selectNonDeliveryDaysState,
  (state) => state.items,
)
