import React from 'react'
import {
  Box,
  Button,
  ButtonProps,
  Link,
  forwardRef,
  chakra,
  Skeleton,
  Drawer,
  DrawerProps,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  AccordionPanelProps,
  List,
  ListItem,
  useModalContext,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { TCategoryAny } from '../../api'
import { useUser, useRootCategories, useCategoryChildren, categoryPath } from '../../lib'
import { LOGOUT } from '../../stores/account'
import { ACCOUNT_ROUTE, ORDERS_ROOT_ROUTE, MENUS_ROUTE, TICKETS_ROUTE } from '../../pages'

const REACT_APP_PAYMENT_PORTAL_URL = process.env.REACT_APP_PAYMENT_PORTAL_URL as string

interface ICategoryPanelProps extends AccordionPanelProps {
  category: TCategoryAny
  isExpanded: boolean
}

const CategoryListItem = chakra(ListItem, {
  baseStyle: {
    fontWeight: 'light',
    py: 2,
  },
})

const ButtonLink = forwardRef<ButtonProps, 'button'>((props, ref) => {
  return <Button fontWeight="light" variant="link" colorScheme="black" ref={ref} {...props} />
})

const CategoryPanel = forwardRef<ICategoryPanelProps, 'div'>(
  ({ category, isExpanded, ...props }, ref) => {
    const { onClose } = useModalContext()
    return (
      <AccordionPanel ref={ref} {...props}>
        <List>
          <CategoryListItem fontWeight="normal">
            <Link as={RouterLink} to={categoryPath(category)} fontWeight="bold" onClick={onClose}>
              All in {category.name}
            </Link>
          </CategoryListItem>
          {isExpanded && <CategoryItems category={category} />}
        </List>
      </AccordionPanel>
    )
  },
)

interface ICategoryItemsProps {
  category: TCategoryAny
}

function CategoryItems({ category }: ICategoryItemsProps) {
  const categories = useCategoryChildren(category)
  const { onClose } = useModalContext()

  if (!categories.length) {
    return (
      <>
        {[...Array(category.children.length)].map((_, index) => (
          <CategoryListItem key={index}>
            <Skeleton height="1.5rem" width="100%" />
          </CategoryListItem>
        ))}
      </>
    )
  }

  return (
    <>
      {categories.map((child) => (
        <CategoryListItem key={child.id}>
          <Link as={RouterLink} to={categoryPath(child, [category])} onClick={onClose}>
            {child.name}
          </Link>
        </CategoryListItem>
      ))}
    </>
  )
}

export const MobilePrimaryNav: React.FC<Omit<DrawerProps, 'children'>> = (props) => {
  const user = useUser()
  const categories = useRootCategories()
  const dispatch = useDispatch()
  const { onClose } = props

  const handleLogout = () => {
    dispatch(LOGOUT())
    onClose()
  }

  return (
    <Drawer {...props}>
      <DrawerOverlay>
        <DrawerContent p={4} pt={16}>
          <DrawerCloseButton top={4} right={5} />
          <DrawerBody p={0}>
            {user && (
              <Accordion allowToggle={true} defaultIndex={-1} mb={6}>
                <AccordionItem>
                  <AccordionButton p={2} fontSize="xl">
                    <Box flex="1" textAlign="left">
                      My Account
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <List>
                      <CategoryListItem>
                        <Link as={RouterLink} to={ORDERS_ROOT_ROUTE} onClick={onClose}>
                          Orders
                        </Link>
                      </CategoryListItem>
                      <CategoryListItem>
                        <Link as={RouterLink} to={MENUS_ROUTE} onClick={onClose}>
                          Lists &amp; Favourites
                        </Link>
                      </CategoryListItem>
                      <CategoryListItem>
                        <Link as={RouterLink} to={TICKETS_ROUTE} onClick={onClose}>
                          Messages
                        </Link>
                      </CategoryListItem>
                      {user.canAccessPaymentPortal && (
                        <CategoryListItem>
                          <Link href={REACT_APP_PAYMENT_PORTAL_URL}>Payment Portal</Link>
                        </CategoryListItem>
                      )}
                      <CategoryListItem>
                        <Link as={RouterLink} to={ACCOUNT_ROUTE} onClick={onClose}>
                          Profile
                        </Link>
                      </CategoryListItem>
                      <CategoryListItem>
                        <ButtonLink onClick={handleLogout}>Logout</ButtonLink>
                      </CategoryListItem>
                    </List>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}

            <Accordion allowToggle={true} defaultIndex={-1}>
              {categories?.map((category) => (
                <AccordionItem key={category.id}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton p={2} fontSize="xl">
                        <Box flex="1" textAlign="left">
                          {category.name}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <CategoryPanel category={category} isExpanded={isExpanded} p={2} />
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
