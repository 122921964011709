import { createSelector } from 'reselect'
import { memoize } from 'lodash'

import { IRootState } from '../root'

export const selectTicketState = (state: IRootState) => state.ticket

const selectTicketItems = createSelector(selectTicketState, (state) => state.items)

const selectTicketListing = createSelector(selectTicketState, (state) => state.listing)

const selectTicketDetail = createSelector(selectTicketState, (state) => state.detail)

const selectTicketCommentItems = createSelector(selectTicketState, (state) => state.commentItems)

const selectTicketCommentListing = createSelector(
  selectTicketState,
  (state) => state.commentListing,
)

export const selectTicketListingResults = createSelector(
  selectTicketItems,
  selectTicketListing,
  (items, listing) => {
    return {
      ...listing,
      items: items && listing && listing.ids ? listing.ids.map((id) => items[id]) : [],
      ids: (listing && listing.ids) ?? [],
      hasMore: !!(listing && listing.moreUrl),
    }
  },
)

export const ticketDetailSelector = createSelector(
  selectTicketItems,
  selectTicketDetail,
  (items, detail) =>
    memoize((id: string) => {
      return {
        item: items && items[id],
        isFetching: detail && detail.isFetching,
        error: detail && detail.error,
      }
    }),
)

const selectTicketCreateState = createSelector(selectTicketState, (state) => state.create)

export const selectTicketCreate = createSelector(selectTicketCreateState, (state) => {
  return {
    ...state,
    isFetching: state && state.isFetching,
    error: state && state.error,
  }
})

export const selectTicketCommentListingResults = createSelector(
  selectTicketCommentItems,
  selectTicketCommentListing,
  (ticketCommentItems, ticketCommentListing) =>
    memoize((id: string) => {
      const items = ticketCommentItems && ticketCommentItems[id]
      const listing = ticketCommentListing && ticketCommentListing[id]
      return {
        ...listing,
        items: items && listing && listing.ids ? listing.ids.map((id) => items[id]) : [],
        ids: (listing && listing.ids) ?? [],
      }
    }),
)

const selectTicketCommentCreateState = createSelector(
  selectTicketState,
  (state) => state.commentCreate,
)

export const selectTicketCommentCreate = createSelector(
  selectTicketCommentCreateState,
  (commentCreateState) =>
    memoize((id: string) => {
      const state = commentCreateState && commentCreateState[id]
      return {
        ...state,
        isFetching: state && state.isFetching,
        error: state && state.error,
      }
    }),
)
