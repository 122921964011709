import { useCallback, useState, useMemo } from 'react'
import {
  chakra,
  forwardRef,
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionPanelProps,
  List,
  ListItem,
  Checkbox,
  ExpandedIndex,
  useDisclosure,
  DrawerProps,
} from '@chakra-ui/react'

import { IAvailableFacet, useMobileFilterMenu } from '../../../lib'
import { OmFilter, OmCircle } from '../../icons'

export interface IMobileFiltersOverlayProps {
  facets: IAvailableFacet[]
  hideFacets?: string[]
}

export const MobileFiltersOverlay: React.FC<IMobileFiltersOverlayProps> = ({
  facets,
  hideFacets,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const anySelected = !!facets.filter((f) => !hideFacets?.includes(f.field) && f.isSelected).length
  const hasFacets = facets.length > 0

  if (!hasFacets) {
    return null
  }

  return (
    <>
      <Button
        variant="outline"
        colorScheme="birchallBlue"
        rightIcon={<OmFilter />}
        leftIcon={anySelected ? <OmCircle /> : <></>}
        onClick={onOpen}
      >
        Filters
      </Button>
      {isOpen && <MobileFiltersDrawer onClose={onClose} isOpen={isOpen} hideFacets={hideFacets} />}
    </>
  )
}

export interface IMobileFiltersDrawerProps extends Omit<DrawerProps, 'children'> {
  hideFacets?: string[]
}

export const MobileFiltersDrawer = forwardRef<IMobileFiltersDrawerProps, 'div'>((props, ref) => {
  const { onClose, hideFacets, ...rest } = props
  const { facets, onAddFacet, onRemoveFacet, onApply, isLoading } = useMobileFilterMenu({
    hideFacets,
  })
  const selectedFacetFields = facets.filter((f) => f.isSelected).map((f) => f.field)

  const toggleFacet = useCallback(
    (field: string) => {
      if (selectedFacetFields.includes(field)) {
        onRemoveFacet(field)
      } else {
        onAddFacet(field)
      }
    },
    [selectedFacetFields, onAddFacet, onRemoveFacet],
  )

  const handleApply = useCallback(() => {
    onApply()
    onClose()
  }, [onApply, onClose])

  const promotionFacets = facets.filter((f) => f.type === 'promotion')
  const hasPromotionFacets = promotionFacets.length > 0
  const anyPromotionsSelected = !!promotionFacets.filter((f) => f.isSelected).length
  const allergenFacets = facets.filter((f) => f.type === 'allergen')
  const hasAllergentFacets = allergenFacets.length > 0
  const anyAllergensSelected = !!allergenFacets.filter((f) => f.isSelected).length
  const accordions: { [key: string]: number } = {}

  if (hasPromotionFacets) {
    accordions['promotions'] = 0
  }
  if (hasAllergentFacets) {
    accordions['allergens'] = hasPromotionFacets ? 1 : 0
  }

  const initiallyOpenAccordions = useMemo(() => {
    const accordions: string[] = []
    if (anyPromotionsSelected) {
      accordions.push('promotions')
    }
    if (anyAllergensSelected) {
      accordions.push('allergens')
    }
    if (accordions.length === 0) {
      // if there's only one accordion, open it
      const accordionsCount = (hasPromotionFacets ? 1 : 0) + (hasAllergentFacets ? 1 : 0)
      if (accordionsCount === 1) {
        if (hasPromotionFacets) {
          accordions.push('promotions')
        }
        if (hasAllergentFacets) {
          accordions.push('allergens')
        }
      }
    }
    return accordions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [openAccordions, setOpenAccordions] = useState<string[]>(initiallyOpenAccordions)

  const handleAccordionChange = (expandedIndex: ExpandedIndex) => {
    const indexes = Array.isArray(expandedIndex) ? expandedIndex : [expandedIndex]
    setOpenAccordions(
      Object.entries(accordions)
        .filter((accordion) => indexes.includes(accordion[1]))
        .map((accordion) => accordion[0]),
    )
  }

  return (
    <Drawer placement="bottom" size="full" autoFocus={false} onClose={onClose} {...rest}>
      <DrawerOverlay>
        <DrawerContent
          sx={{
            '@supports(height: -webkit-fill-available)': {
              height: '-webkit-fill-available',
            },
          }}
        >
          <DrawerCloseButton />
          <DrawerHeader>Filter Results</DrawerHeader>
          <DrawerBody>
            <Accordion
              allowToggle={true}
              allowMultiple={true}
              // defaultIndex={Array.from(accordionOpenIndexes)}
              index={Object.entries(accordions)
                .filter((accordion) => openAccordions.includes(accordion[0]))
                .map((accordion) => accordion[1])}
              onChange={handleAccordionChange}
            >
              {hasPromotionFacets && (
                <AccordionItem key="promotion">
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton p={2} fontSize="xl">
                        <Box flex="1" textAlign="left">
                          Promotion
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <FacetsPanel
                        facets={promotionFacets}
                        selectedFacetFields={selectedFacetFields}
                        toggleFacet={toggleFacet}
                        isExpanded={isExpanded}
                        p={2}
                      />
                    </>
                  )}
                </AccordionItem>
              )}
              {hasAllergentFacets && (
                <AccordionItem key="allergen">
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton p={2} fontSize="xl">
                        <Box flex="1" textAlign="left">
                          Dietary &amp; Allergens
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <FacetsPanel
                        facets={allergenFacets}
                        selectedFacetFields={selectedFacetFields}
                        toggleFacet={toggleFacet}
                        isExpanded={isExpanded}
                        p={2}
                      />
                    </>
                  )}
                </AccordionItem>
              )}
            </Accordion>
          </DrawerBody>
          <DrawerFooter>
            <Button width="100%" size="lg" onClick={handleApply} isLoading={isLoading}>
              Apply
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
})

interface IFacetsPanelProps extends AccordionPanelProps {
  selectedFacetFields: string[]
  toggleFacet: (field: string) => void
  facets: IAvailableFacet[]
  isExpanded: boolean
}

const FacetsPanel = forwardRef<IFacetsPanelProps, 'div'>(
  ({ facets, isExpanded, selectedFacetFields, toggleFacet, ...props }, ref) => {
    const handleChange = (field: string) => {
      return () => {
        toggleFacet(field)
      }
    }

    return (
      <AccordionPanel ref={ref} {...props}>
        <List>
          {facets.map((facet) => (
            <ListItem key={facet.field}>
              <FacetCheckbox
                colorScheme="green"
                isChecked={selectedFacetFields.includes(facet.field)}
                onChange={handleChange(facet.field)}
              >
                {facet.label} ({facet.count})
              </FacetCheckbox>
            </ListItem>
          ))}
        </List>
      </AccordionPanel>
    )
  },
)

const FacetCheckbox = chakra(Checkbox, {
  baseStyle: {
    fontWeight: 'light',
    py: 2,
  },
})
