import { Button, ButtonProps, forwardRef, useDisclosure } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { TBasketAny, TMenuWithItems, TOrderWithAddressAndItems } from '../../api'
import { basketPath } from '../../lib'
import { SHOW_ORDER_PICKER } from '../../stores/ui'
import { ConfirmDialog, useConfirmDialog } from '../confirm-dialog'
import { BasketForm, IOnSelectExistingProps } from './basket-form'

export interface IBasketFormModalButtonProps extends ButtonProps {
  basket?: TBasketAny
  reorder?: TOrderWithAddressAndItems
  menu?: TMenuWithItems
  onCreated?: (id: string) => void
  onSelectExisting?: (formData: IOnSelectExistingProps) => void
}

export const BasketFormModalButton = forwardRef<IBasketFormModalButtonProps, 'button'>(
  (props, ref) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const notAllItemsWillBeCopiedWarning = useConfirmDialog({
      type: 'some-items-are-unavailable-warning',
      onConfirm: onOpen,
    })
    const { basket, reorder, menu, onCreated, onSelectExisting, children, ...rest } = props
    const _children = children ? children : basket ? 'Edit Order' : 'Create Order'

    const handleOpen = useCallback(() => {
      // const areAllItemsAvailable = menu ? menu.items.filter(({ productIsAvailable }) => productIsAvailable).length
      let areAllItemsAvailable = true
      if (menu) {
        areAllItemsAvailable =
          menu.items.filter(({ productIsAvailable }) => !productIsAvailable).length === 0
      } else if (reorder) {
        areAllItemsAvailable =
          reorder.items.filter(({ productIsAvailable }) => !productIsAvailable).length === 0
      }

      if (areAllItemsAvailable) {
        onOpen()
      } else {
        notAllItemsWillBeCopiedWarning.onOpen()
      }

      // const items = menu ? menu.items : reorder ? reorder.items : []
      // const areItemsAvailable = items.filter((item) => item)

      // if (menu) {
      //   const { items } = menu
      // }
      // if (reorder || menu) {
      //   const { items } = menu ? menu : reorder
      // }
    }, [reorder, menu, onOpen, notAllItemsWillBeCopiedWarning])

    const handleCreated = useCallback(
      (basketId: string) => {
        // by default redirect to the detail page
        if (onCreated) {
          onCreated(basketId)
        } else {
          navigate(basketPath(basketId))
        }
      },
      [navigate, onCreated],
    )

    const handleSelectExisting = useCallback(
      (formData: IOnSelectExistingProps) => {
        onClose()
        if (onSelectExisting) {
          onSelectExisting(formData)
        } else {
          // by default redirect to the detail page
          dispatch(
            SHOW_ORDER_PICKER({
              type: 'CREATE_BASKET_MATCH_EXISTING',
              formData,
            }),
          )
        }
      },
      [dispatch, onClose, onSelectExisting],
    )

    return (
      <>
        <Button onClick={handleOpen} ref={ref} {...rest}>
          {_children}
        </Button>
        {notAllItemsWillBeCopiedWarning.isOpen && (
          <ConfirmDialog {...notAllItemsWillBeCopiedWarning} />
        )}
        {isOpen && (
          <BasketForm
            isOpen={isOpen}
            onClose={onClose}
            basket={basket}
            reorder={reorder}
            menu={menu}
            onCreated={handleCreated}
            onSelectExisting={handleSelectExisting}
          />
        )}
      </>
    )
  },
)
