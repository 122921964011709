import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IAddress } from '../../api'

import {
  selectBasketsListingResults,
  FETCH_BASKETS,
  selectBasketsListingForAddressAndDateResults,
  FETCH_BASKETS_FOR_ADDRESS_AND_DATE,
} from '../../stores/order'

interface IUseBasketsListingProps {
  address: IAddress
  deliveryDate: Date
}

export function useBasketsListing(props?: IUseBasketsListingProps) {
  const dispatch = useDispatch()
  const selector = props
    ? selectBasketsListingForAddressAndDateResults
    : selectBasketsListingResults
  const listing = useSelector(selector)

  const addressId = props?.address.id
  const requestedDeliveryDate = props?.deliveryDate

  const onLoadInitial = useCallback(() => {
    if (addressId && requestedDeliveryDate) {
      dispatch(
        FETCH_BASKETS_FOR_ADDRESS_AND_DATE.request({
          addressId,
          requestedDeliveryDate,
        }),
      )
    } else {
      dispatch(FETCH_BASKETS.request())
    }
  }, [dispatch, addressId, requestedDeliveryDate])

  // automatically call load initial
  useEffect(() => {
    onLoadInitial()
  }, [onLoadInitial])

  return {
    ...listing,
    onLoadInitial,
  }
}
