import { isString } from 'lodash'

import { APIService } from '../service'
import {
  IJsonApiModelWithId,
  IFetchMultipleQueryOptions,
  IFetchMultipleResponse,
  IFetchMultipleResponseMeta,
  IFetchOneQueryOptions,
} from '../types'

declare module '../service' {
  interface APIService {
    fetchPublicProducts(
      url: string,
    ): Promise<IFetchMultipleResponse<IPublicProduct, IFetchMultiplePublicProductResponseMeta>>
    fetchPublicProducts(
      requestOpts?: IFetchMultipleQueryOptions,
    ): Promise<IFetchMultipleResponse<IPublicProduct, IFetchMultiplePublicProductResponseMeta>>
    fetchPublicProduct(id: string, requestOpts?: IFetchOneQueryOptions): Promise<IPublicProduct>
  }
}

export const ENTITY_TYPE_PUBLIC_PRODUCT = 'public-products'

export interface IFacetBucket {
  key: number
  key_as_string: string
  doc_count: number
}

export interface IFacet {
  field: string
  buckets: IFacetBucket[]
}

export interface IFetchMultiplePublicProductResponseMeta extends IFetchMultipleResponseMeta {
  facets: IFacet[]
}

export interface IProductImage {
  mobile: string
  medium: string
}

export interface INutritionalData {
  unit: 'ml' | 'g'
  servingSize?: string
  data: {
    label: string
    perPortion: string
    per100: string
    additional: {
      label: string
      perPortion: string
      per100: string
    }[]
  }[]
}

export interface IAllergenData {
  label: string
  field: string
  value: string
}

export interface IBgxyData {
  isY: boolean
  xLabel: string
  yLabel: string
  xCodes: string[]
  yCodes: string[]
  description: string
}

export interface IProductBase extends IJsonApiModelWithId {
  id: string
  name: string
  description: string
  packSize: string
  splitSize: string
  canSplit: boolean
  isFrozen: string
  isPreOrder: string
  cutoffTime: string
  images: IProductImage[]
  ingredients: string
  nutritionalData: INutritionalData | null
  allergenData: IAllergenData[] | null
  categories: IJsonApiModelWithId[]
  favourite: IJsonApiModelWithId | null
  directionsForUse: string
  storageInstructions: string
  isAvailable: boolean
  substitutes: IJsonApiModelWithId[]
  isPurchasable: boolean
  isFreeProductPromotion: boolean
  bxgy: IBgxyData | null
}

export interface IPublicProduct extends IProductBase {
  type: 'public-products'
}

APIService.prototype.fetchPublicProducts = async function (
  requestOptsOrUrl?: IFetchMultipleQueryOptions | string,
) {
  if (isString(requestOptsOrUrl)) {
    return this.fetchMultiple<IPublicProduct, IFetchMultiplePublicProductResponseMeta>(
      requestOptsOrUrl,
    )
  }
  return this.fetchMultiple<IPublicProduct, IFetchMultiplePublicProductResponseMeta>(
    ENTITY_TYPE_PUBLIC_PRODUCT,
    requestOptsOrUrl,
  )
}

APIService.prototype.fetchPublicProduct = async function (
  id: string,
  requestOpts?: IFetchOneQueryOptions,
) {
  return this.fetchOne<IPublicProduct>(ENTITY_TYPE_PUBLIC_PRODUCT + '/' + id, requestOpts)
}
