import { isString } from 'lodash'
import { APIService } from '../service'
import {
  IJsonApiModel,
  IJsonApiModelWithId,
  IFetchMultipleQueryOptions,
  IFetchMultipleResponse,
  IFetchMultipleResponseMeta,
} from '../types'

import { IProduct } from './product'

declare module '../service' {
  interface APIService {
    fetchFavourites(
      url: string,
    ): Promise<IFetchMultipleResponse<TFavouriteWithProduct, IFetchMultipleResponseMeta>>
    fetchFavourites(
      requestOpts?: IFetchMultipleQueryOptions,
    ): Promise<IFetchMultipleResponse<TFavouriteWithProduct, IFetchMultipleResponseMeta>>
    createFavourite(data: INewFavourite): Promise<TFavouriteAny>
    deleteFavorite(id: string): Promise<unknown>
  }
}

export const ENTITY_TYPE_FAVOURITE = 'favourites'

export interface IFavourite<P extends IJsonApiModelWithId> extends IJsonApiModelWithId {
  id: string
  product: P
}

export type TFavouriteAny = IFavourite<IJsonApiModelWithId>
export type TFavouriteWithProduct = IFavourite<IProduct>

export interface INewFavourite extends Omit<IJsonApiModel, 'type'> {
  product: IJsonApiModelWithId
}

APIService.prototype.fetchFavourites = async function (
  requestOptsOrUrl?: IFetchMultipleQueryOptions | string,
) {
  if (isString(requestOptsOrUrl)) {
    return this.fetchMultiple<TFavouriteWithProduct, IFetchMultipleResponseMeta>(requestOptsOrUrl)
  }
  return this.fetchMultiple<TFavouriteWithProduct, IFetchMultipleResponseMeta>(
    ENTITY_TYPE_FAVOURITE,
    {
      ...requestOptsOrUrl,
      include: [...(requestOptsOrUrl?.include || []), 'product'],
    },
  )
}

APIService.prototype.createFavourite = async function (data: INewFavourite) {
  return this.create<TFavouriteAny>(ENTITY_TYPE_FAVOURITE, {
    type: ENTITY_TYPE_FAVOURITE,
    ...data,
  })
}

APIService.prototype.deleteFavorite = async function (id: string) {
  return this.delete(`${ENTITY_TYPE_FAVOURITE}/${id}`)
}
