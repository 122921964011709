import React from 'react'
import {
  Box,
  BoxProps,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  forwardRef,
} from '@chakra-ui/react'

import { TabList, TabButton } from '../tab-list'
import { SelectMenu } from '../select-menu'
import { useMenuPicker, useMenusListing } from '../../lib'
import { MenuItemBox } from './menu-item-box'
import { MenuForm } from '../menu-form'

export const MenuPickerModal: React.FC = () => {
  const { isOpen, onClose, onChangeSelectedMenu } = useMenuPicker()
  const [content, setContent] = React.useState<'new' | 'select'>('select')

  const handlePushToNew = () => {
    setContent('new')
  }

  const handleClose = React.useCallback(() => {
    setContent('select') // reset to the default state
    onClose()
  }, [onClose])

  const handleMenuCreated = React.useCallback(
    (id: string) => {
      setContent('select')
      onChangeSelectedMenu(id)
    },
    [setContent, onChangeSelectedMenu],
  )

  if (!isOpen) {
    return null
  }

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add to List</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={0}>
          <Box>
            <TabList>
              <TabButton isActive={content === 'select'} onClick={() => setContent('select')}>
                List
              </TabButton>
              <TabButton isActive={content === 'new'} onClick={() => setContent('new')}>
                Create List
              </TabButton>
            </TabList>
            <Box>
              {content === 'new' && (
                <Box pt={4}>
                  <MenuForm
                    asModal={false}
                    isOpen={isOpen}
                    onClose={handleClose} // for cancel
                    onSuccess={handleMenuCreated}
                  />
                </Box>
              )}
              {content === 'select' && (
                <MenuPickerAdjustItem
                  maxHeight="50vh"
                  overflowY="auto"
                  mx={-6}
                  px={6}
                  onEmptyClick={handlePushToNew}
                />
              )}
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface IMenuPickerAdjustItemProps extends BoxProps {
  onEmptyClick: () => void
}

const MenuPickerAdjustItem = forwardRef<IMenuPickerAdjustItemProps, 'div'>((props, ref) => {
  const { onEmptyClick, ...rest } = props
  const { items } = useMenusListing()
  const { product, selectedMenu, selectedItem, onChangeSelectedMenu } = useMenuPicker()
  const hasItems = items.length > 0

  const handleChange = (value: string) => {
    onChangeSelectedMenu(value)
  }

  return (
    <Box ref={ref} {...rest}>
      {!hasItems && (
        <Box textAlign="center" p={16}>
          You have no lists,{' '}
          <Button variant="link" onClick={onEmptyClick}>
            create one
          </Button>
          .
        </Box>
      )}
      {hasItems && (
        <>
          <Box>
            <SelectMenu
              name="list"
              value={selectedMenu?.id}
              onChange={handleChange}
              // onClose={onClose}
              placeholder="Select List"
              options={items.map((menu) => ({
                value: menu.id,
                label: menu.title,
              }))}
              my={4}
            />
            {product && product.type === 'products' && (
              <MenuItemBox product={product} menu={selectedMenu} item={selectedItem} my={4} />
            )}
          </Box>
          {/* {items.map((item) => (
          <OrderPickerOrderBox key={item.id} my={4} order={item} />
        ))} */}
        </>
      )}
    </Box>
  )
})
