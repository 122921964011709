import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser'
import { Element } from 'domhandler'
import { Link as RouterLink } from 'react-router-dom'
import {
  chakra,
  forwardRef,
  Heading,
  Text,
  HTMLChakraProps,
  Link as ChackraLink,
  UnorderedList as ChakraUnorderedList,
  OrderedList as ChakraOrderedList,
  ListItem as ChackraListItem,
} from '@chakra-ui/react'

import { ResponsiveContainer } from '../../responsive-container'
import { CmsStack } from '../cms-stack'
import { IBaseBlock } from '../types'
import { checkExternalLink } from '../../../lib'

const UnorderedList = chakra(ChakraUnorderedList, {})

const OrderedList = chakra(ChakraOrderedList, {})

const ListItem = chakra(ChackraListItem, {})

const Link = chakra(ChackraLink, {
  baseStyle: {
    color: 'coral.500',
  },
})

export interface IRichTextBlock extends IBaseBlock {
  type: 'rich_text'
  value: string
}

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.attribs) {
      switch (domNode.tagName) {
        case 'h2': {
          return (
            <Heading as="h2" fontSize="2xl">
              {domToReact(domNode.children, options)}
            </Heading>
          )
        }
        case 'h3': {
          return (
            <Heading as="h3" fontSize="xl">
              {domToReact(domNode.children, options)}
            </Heading>
          )
        }
        case 'p': {
          if (!domNode.children.length) {
            // strip empty paragraphs
            return <></>
          }
          return <Text>{domToReact(domNode.children, options)}</Text>
        }
        case 'a': {
          const { href, ...rest } = domNode.attribs
          if (!href) {
            return null
          }
          const { url, isPageLink } = checkExternalLink(href)
          if (isPageLink) {
            return (
              <Link as={RouterLink} to={url} {...rest}>
                {domToReact(domNode.children, options)}
              </Link>
            )
          }
          return (
            <Link href={url} {...rest}>
              {domToReact(domNode.children, options)}
            </Link>
          )
        }
        case 'ul': {
          return <UnorderedList>{domToReact(domNode.children, options)}</UnorderedList>
        }
        case 'ol': {
          return <OrderedList>{domToReact(domNode.children, options)}</OrderedList>
        }
        case 'li': {
          return <ListItem>{domToReact(domNode.children, options)}</ListItem>
        }
      }
    }
  },
}

export interface IRichTextBlockProps extends HTMLChakraProps<'div'> {
  block: IRichTextBlock
}

export const RichTextBlock = forwardRef<IRichTextBlockProps, 'div'>((props, ref) => {
  const { block, ...rest } = props
  const parsed = parse(block.value, options)
  return (
    <ResponsiveContainer ref={ref} {...rest}>
      <CmsStack ref={ref} spacing={4} {...rest}>
        {parsed}
      </CmsStack>
    </ResponsiveContainer>
  )
})
