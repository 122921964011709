import { memoize } from 'lodash'
import { createSelector } from 'reselect'

import { IRootState } from '../root'
import { addressEnabledDaysOfTheWeek } from '../../lib'

export const selectAccountRootState = (state: IRootState) => state.account

export const selectLogin = createSelector(selectAccountRootState, (state) => state.login)

export const selectUserState = createSelector(selectAccountRootState, (state) => state.user)

export const selectUser = createSelector(selectUserState, (state) => state && state.item)

export const selectAccountState = createSelector(selectAccountRootState, (state) => state.account)

export const selectAccount = createSelector(selectAccountState, (state) => state && state.item)

export const selectAddressesState = createSelector(
  selectAccountRootState,
  (state) => state.addresses,
)

export const selectAddresses = createSelector(
  selectAddressesState,
  (state) => (state && state.items) || [],
)

export const addressesSelector = createSelector(selectAddresses, (addresses) =>
  memoize((withDeliveryDays: boolean) => {
    if (!withDeliveryDays) {
      return addresses
    }
    return addresses.filter((address) => addressEnabledDaysOfTheWeek(address).length > 0)
  }),
)

export const addressDetailSelector = createSelector(selectAddresses, (items) =>
  memoize((id: string) => {
    return items.filter((a) => a.id === id).pop()
  }),
)

const selectPasswordResetRequestState = createSelector(
  selectAccountRootState,
  (state) => state.passwordResetRequest,
)

export const selectPasswordResetRequest = createSelector(
  selectPasswordResetRequestState,
  (state) => {
    return {
      email: state?.item,
      isFetching: state?.isFetching ?? false,
      error: state?.error,
    }
  },
)

const selectPasswordResetState = createSelector(
  selectAccountRootState,
  (state) => state.passwordReset,
)

export const selectPasswordReset = createSelector(selectPasswordResetState, (state) => {
  return {
    isSuccess: state?.item ?? false,
    isFetching: state?.isFetching ?? false,
    error: state?.error,
  }
})

export const selectNextUrl = createSelector(selectAccountRootState, (state) => state.nextUrl)

export const selectAccountIsOnHold = createSelector(selectAccount, (state) => state?.isOnHold)
