import { createIcon } from '@chakra-ui/react'

export const OmDietaryHalal = createIcon({
  displayName: 'Halal',
  viewBox: '0 0 62 32',
  path: (
    <g>
      <path
        fill="currentColor"
        d="M 20.606 6.661 C 20.721 6.654 20.825 6.593 20.886 6.496 C 20.947 6.4 20.958 6.279 20.915 6.173 L 18.629 0.497 C 18.588 0.394 18.502 0.317 18.394 0.287 C 18.287 0.257 18.174 0.278 18.085 0.344 C 17.731 0.609 17.329 0.865 16.903 1.136 C 15.998 1.71 15.063 2.304 14.445 3.079 C 14.359 3.187 14.343 3.335 14.405 3.458 C 16.333 7.345 15.597 13.436 15.005 18.331 C 14.868 19.468 14.739 20.542 14.651 21.512 C 14.338 24.983 11.481 26.537 8.788 26.537 C 6.838 26.537 5.14 25.71 4.355 24.379 C 3.516 22.955 3.711 21.055 4.92 18.886 C 4.989 18.763 4.979 18.611 4.893 18.498 C 4.817 18.398 4.701 18.341 4.573 18.341 C 3.742 18.341 0.342 23.277 0.165 26.974 C 0.07 28.971 0.853 31.438 5.125 31.844 C 5.503 31.881 5.904 31.899 6.314 31.899 C 10.454 31.899 17.215 29.896 17.729 24.524 C 17.804 23.736 17.843 22.458 17.89 20.979 C 18.033 16.376 18.333 6.798 20.606 6.661 Z"
      />
      <path
        fill="currentColor"
        d="M 61.45 22.116 C 59.556 21.881 57.886 21.1 56.478 19.791 C 55.919 19.297 55.35 18.769 54.798 18.257 C 53.238 16.81 51.624 15.314 49.836 14.178 C 49.433 13.922 48.982 13.792 48.492 13.792 C 46.033 13.792 43.437 16.963 43.172 18.88 C 43.047 19.788 43.42 20.434 44.199 20.653 C 44.341 20.692 44.494 20.641 44.583 20.521 C 45.365 19.458 46.118 18.941 46.884 18.941 C 47.953 18.941 49.081 19.932 50.276 20.982 C 50.773 21.418 51.28 21.864 51.816 22.274 C 49.973 22.498 48.234 22.616 46.744 22.616 C 43.894 22.616 40.457 22.191 40.276 20.165 C 39.943 16.438 40.609 12.472 41.251 8.636 C 41.59 6.616 41.94 4.526 42.132 2.524 C 42.144 2.39 42.08 2.261 41.966 2.189 C 41.851 2.118 41.706 2.118 41.591 2.187 C 37.99 4.378 36.733 7.649 35.403 11.113 C 34.503 13.456 33.575 15.872 31.867 18.176 C 30.878 11.241 28.235 5.967 23.803 2.069 C 23.709 1.986 23.576 1.958 23.457 1.999 C 23.337 2.037 23.246 2.138 23.219 2.26 C 23.079 2.898 22.908 3.534 22.742 4.149 C 21.889 7.304 21.084 10.285 24.815 12.527 C 24.919 12.59 25.049 12.595 25.16 12.54 C 25.269 12.485 25.342 12.378 25.354 12.256 L 25.394 11.829 C 27.53 14.918 28.712 17.684 29.28 20.893 C 28.29 21.757 26.838 22.52 25.433 23.258 C 23.666 24.187 21.84 25.147 20.858 26.349 C 20.747 26.485 20.752 26.681 20.869 26.811 C 22.209 28.306 23.664 29.065 25.195 29.065 C 30.509 29.065 35.264 20.062 37.436 15.223 C 37.426 15.291 37.415 15.358 37.404 15.424 C 36.589 20.662 35.745 26.079 42.203 27.773 C 42.227 27.78 42.253 27.784 42.278 27.785 C 42.863 27.81 43.506 27.823 44.19 27.823 L 44.191 27.823 C 46.784 27.823 59.808 27.57 61.738 22.596 C 61.778 22.494 61.768 22.38 61.712 22.286 C 61.655 22.193 61.559 22.13 61.45 22.116 Z"
      />
    </g>
  ),
})

// <?xml version="1.0" encoding="UTF-8"?>
// <svg
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 62 32"
//     id="vector">
//     <path
//         id="path_1"
//         d="M 20.606 6.661 C 20.721 6.654 20.825 6.593 20.886 6.496 C 20.947 6.4 20.958 6.279 20.915 6.173 L 18.629 0.497 C 18.588 0.394 18.502 0.317 18.394 0.287 C 18.287 0.257 18.174 0.278 18.085 0.344 C 17.731 0.609 17.329 0.865 16.903 1.136 C 15.998 1.71 15.063 2.304 14.445 3.079 C 14.359 3.187 14.343 3.335 14.405 3.458 C 16.333 7.345 15.597 13.436 15.005 18.331 C 14.868 19.468 14.739 20.542 14.651 21.512 C 14.338 24.983 11.481 26.537 8.788 26.537 C 6.838 26.537 5.14 25.71 4.355 24.379 C 3.516 22.955 3.711 21.055 4.92 18.886 C 4.989 18.763 4.979 18.611 4.893 18.498 C 4.817 18.398 4.701 18.341 4.573 18.341 C 3.742 18.341 0.342 23.277 0.165 26.974 C 0.07 28.971 0.853 31.438 5.125 31.844 C 5.503 31.881 5.904 31.899 6.314 31.899 C 10.454 31.899 17.215 29.896 17.729 24.524 C 17.804 23.736 17.843 22.458 17.89 20.979 C 18.033 16.376 18.333 6.798 20.606 6.661 Z"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
//     <path
//         id="path_3"
//         d="M 61.45 22.116 C 59.556 21.881 57.886 21.1 56.478 19.791 C 55.919 19.297 55.35 18.769 54.798 18.257 C 53.238 16.81 51.624 15.314 49.836 14.178 C 49.433 13.922 48.982 13.792 48.492 13.792 C 46.033 13.792 43.437 16.963 43.172 18.88 C 43.047 19.788 43.42 20.434 44.199 20.653 C 44.341 20.692 44.494 20.641 44.583 20.521 C 45.365 19.458 46.118 18.941 46.884 18.941 C 47.953 18.941 49.081 19.932 50.276 20.982 C 50.773 21.418 51.28 21.864 51.816 22.274 C 49.973 22.498 48.234 22.616 46.744 22.616 C 43.894 22.616 40.457 22.191 40.276 20.165 C 39.943 16.438 40.609 12.472 41.251 8.636 C 41.59 6.616 41.94 4.526 42.132 2.524 C 42.144 2.39 42.08 2.261 41.966 2.189 C 41.851 2.118 41.706 2.118 41.591 2.187 C 37.99 4.378 36.733 7.649 35.403 11.113 C 34.503 13.456 33.575 15.872 31.867 18.176 C 30.878 11.241 28.235 5.967 23.803 2.069 C 23.709 1.986 23.576 1.958 23.457 1.999 C 23.337 2.037 23.246 2.138 23.219 2.26 C 23.079 2.898 22.908 3.534 22.742 4.149 C 21.889 7.304 21.084 10.285 24.815 12.527 C 24.919 12.59 25.049 12.595 25.16 12.54 C 25.269 12.485 25.342 12.378 25.354 12.256 L 25.394 11.829 C 27.53 14.918 28.712 17.684 29.28 20.893 C 28.29 21.757 26.838 22.52 25.433 23.258 C 23.666 24.187 21.84 25.147 20.858 26.349 C 20.747 26.485 20.752 26.681 20.869 26.811 C 22.209 28.306 23.664 29.065 25.195 29.065 C 30.509 29.065 35.264 20.062 37.436 15.223 C 37.426 15.291 37.415 15.358 37.404 15.424 C 36.589 20.662 35.745 26.079 42.203 27.773 C 42.227 27.78 42.253 27.784 42.278 27.785 C 42.863 27.81 43.506 27.823 44.19 27.823 L 44.191 27.823 C 46.784 27.823 59.808 27.57 61.738 22.596 C 61.778 22.494 61.768 22.38 61.712 22.286 C 61.655 22.193 61.559 22.13 61.45 22.116 Z"
//         fill="#e0e1dc"
//         stroke="#00000000"
//         stroke-width="1"
//         fill-rule="evenodd"/>
// </svg>
