import { ActionType, getType } from 'typesafe-actions'
import { zipObject } from 'lodash'

import { IPaginatedRequest, IRequestDetail, IAllRequest } from '../types'
import { ITicket, ITicketComment } from '../../api'

import * as actions from './actions'

interface IState {
  items?: { [key: string]: ITicket }
  listing?: IPaginatedRequest<ITicket>
  detail?: IRequestDetail<Error>
  create?: IRequestDetail<Error>
  commentItems?: { [key: string]: { [key: string]: ITicketComment } }
  commentListing?: { [key: string]: IAllRequest<ITicketComment> } // keyed by ticket id
  commentCreate?: { [key: string]: IRequestDetail<Error> } // keyed by ticket id
}

const initialState: IState = {}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions>,
): IState => {
  switch (action.type) {
    case getType(actions.FETCH_MORE_TICKETS.request):
    case getType(actions.FETCH_TICKETS.request): {
      const { listing } = state
      return {
        ...state,
        listing: {
          ...listing,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_MORE_TICKETS.success):
    case getType(actions.FETCH_TICKETS.success): {
      const {
        data,
        links,
        meta: {
          pagination: { count },
        },
      } = action.payload
      const ids = data.map((p) => p.id)
      const items = zipObject(ids, data)
      const { listing } = state
      const isMore = action.type === getType(actions.FETCH_MORE_TICKETS.success)
      const existingIds = isMore && listing && listing.ids ? listing.ids : []
      return {
        ...state,
        items: { ...(state.items || {}), ...items },
        listing: {
          ...listing,
          isFetching: false,
          ids: [...existingIds, ...ids],
          moreUrl: links.next ?? undefined,
          totalCount: count,
        },
      }
    }

    case getType(actions.FETCH_TICKET.request): {
      return {
        ...state,
        detail: {
          id: action.payload,
          isFetching: true,
        },
      }
    }

    case getType(actions.FETCH_TICKET.success): {
      const item = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
        detail: {
          id: item.id,
          isFetching: false,
        },
      }
    }

    case getType(actions.CREATE_TICKET.request): {
      return {
        ...state,
        create: {
          isFetching: true,
        },
      }
    }

    case getType(actions.CREATE_TICKET.success): {
      const item = action.payload
      return {
        ...state,
        items: {
          ...state.items,
          [item.id]: item,
        },
        create: {
          id: item.id,
          isFetching: false,
        },
      }
    }

    case getType(actions.CREATE_TICKET.failure): {
      return {
        ...state,
        create: {
          ...state.create,
          error: action.payload,
          isFetching: false,
        },
      }
    }

    case getType(actions.FETCH_TICKET_COMMENTS.request): {
      const ticketId = action.payload
      const { commentListing } = state
      const listing = commentListing && commentListing[ticketId]
      return {
        ...state,
        commentListing: {
          ...commentListing,
          [ticketId]: {
            ...listing,
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.FETCH_TICKET_COMMENTS.success): {
      const { ticketId, comments } = action.payload
      const ids = comments.map((p) => p.id)
      const items = zipObject(ids, comments)
      const { commentItems, commentListing } = state
      const listing = commentListing && commentListing[ticketId]
      return {
        ...state,
        commentItems: {
          ...commentItems,
          [ticketId]: {
            ...items,
          },
        },
        commentListing: {
          ...commentListing,
          [ticketId]: {
            ...listing,
            isFetching: false,
            ids: [...ids],
            totalCount: comments.length,
          },
        },
      }
    }

    case getType(actions.FETCH_TICKET_COMMENTS.failure): {
      const { ticketId } = action.payload
      const { commentListing } = state
      const listing = commentListing && commentListing[ticketId]
      return {
        ...state,
        commentListing: {
          ...commentListing,
          [ticketId]: {
            ...listing,
            isFetching: false,
          },
        },
      }
    }

    case getType(actions.CREATE_TICKET_COMMENT.request): {
      const { ticketId } = action.payload
      const { commentCreate } = state
      return {
        ...state,
        commentCreate: {
          ...commentCreate,
          [ticketId]: {
            isFetching: true,
          },
        },
      }
    }

    case getType(actions.CREATE_TICKET_COMMENT.success): {
      const { ticketId, comment } = action.payload
      const { commentItems, commentListing, commentCreate } = state
      const create = commentCreate && commentCreate[ticketId]
      const ticketComments = commentItems && commentItems[ticketId]
      const listing = commentListing && commentListing[ticketId]
      return {
        ...state,
        commentItems: {
          ...commentItems,
          [ticketId]: {
            ...ticketComments,
            [comment.id]: comment,
          },
        },
        commentListing: {
          ...commentListing,
          [ticketId]: {
            ...listing,
            ids: [...((listing && listing.ids) || []), comment.id],
          },
        },
        commentCreate: {
          ...commentCreate,
          [ticketId]: {
            ...create,
            id: comment.id,
            isFetching: false,
          },
        },
      }
    }

    case getType(actions.CREATE_TICKET_COMMENT.failure): {
      const { ticketId, error } = action.payload
      const { commentCreate } = state
      const create = commentCreate && commentCreate[ticketId]
      return {
        ...state,
        commentCreate: {
          ...commentCreate,
          [ticketId]: {
            ...create,
            error,
            isFetching: false,
          },
        },
      }
    }
  }

  return state
}
