import { BoxProps, forwardRef } from '@chakra-ui/react'

import { OrderItemBox, TSubstitutes } from '../order-item-box'
import { TMenuAny, TMenuItemAny } from '../../api'
import { useMenuItemDetail } from '../../lib'

export interface IItemBoxProps extends BoxProps {
  menu: TMenuAny
  item: TMenuItemAny
  substitutes: TSubstitutes
}

export const ItemBox = forwardRef<IItemBoxProps, 'button'>((props, ref) => {
  const { menu, item, ...rest } = props
  const {
    hasProduct,
    canRemove,
    canEdit,
    splitsSubtotal,
    packsSubtotal,
    onRemove,
    onUpdateSplitQuantity,
    onUpdatePackQuantity,
  } = useMenuItemDetail(item.productCode, menu, item)

  return (
    <OrderItemBox
      ref={ref}
      item={item}
      hasProduct={hasProduct}
      canRemove={canRemove}
      canEdit={canEdit}
      splitsSubtotal={splitsSubtotal}
      packsSubtotal={packsSubtotal}
      onRemove={onRemove}
      onUpdateSplitQuantity={onUpdateSplitQuantity}
      onUpdatePackQuantity={onUpdatePackQuantity}
      {...rest}
    />
  )
})
