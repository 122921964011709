import { all, call, cancel, fork, put, take, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'

import API, { CallReturnType } from '../../../api'
import { displayErrorToastSaga, ensureError } from '../../../lib'
import * as actions from './actions'

function* fetchDuplicateOrderCheck(addressId: string, requestedDeliveryDate: Date) {
  try {
    const cutoff: CallReturnType<typeof API.fetchDuplicateOrderCheck> = yield call(
      [API, API.fetchDuplicateOrderCheck],
      addressId,
      requestedDeliveryDate,
    )
    yield put(actions.FETCH_DUPLICATE_ORDER_CHECK.success(cutoff))
  } catch (e) {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, e)
    yield put(actions.FETCH_DUPLICATE_ORDER_CHECK.failure(error))
  }
}

function* duplicateOrderCheckRequest(
  action: ReturnType<typeof actions.FETCH_DUPLICATE_ORDER_CHECK.request>,
) {
  const { addressId, requestedDeliveryDate } = action.payload

  // @ts-ignore
  const task: any = yield fork(fetchDuplicateOrderCheck, addressId, requestedDeliveryDate)

  // wait for the check to be complete or cancelled
  // @ts-ignore
  const resultAction: ActionType<typeof actions.FETCH_DUPLICATE_ORDER_CHECK> = yield take([
    actions.FETCH_DUPLICATE_ORDER_CHECK.success,
    actions.FETCH_DUPLICATE_ORDER_CHECK.failure,
    actions.FETCH_DUPLICATE_ORDER_CHECK.cancel,
  ])

  if (resultAction.type === getType(actions.FETCH_DUPLICATE_ORDER_CHECK.cancel)) {
    yield cancel(task)
  }
}

export function* saga() {
  yield all([takeEvery(actions.FETCH_DUPLICATE_ORDER_CHECK.request, duplicateOrderCheckRequest)])
  yield all([takeEvery(actions.FETCH_DUPLICATE_ORDER_CHECK.failure, displayErrorToastSaga)])
}
