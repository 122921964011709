import { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Alert,
  AlertIcon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  forwardRef,
  Box,
  Stack,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormHelperText,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react'
import { formatISO } from 'date-fns'
import { find } from 'lodash'
import { Formik, Field, FieldProps, Form } from 'formik'

import {
  useAccount,
  useAddresses,
  useTomorrowsCutoff,
  useNonDeliveryDays,
  orderName,
  useMaxDeliveryDays,
} from '../../lib'
import { FormikAddressField } from './address-field'
import { FormikDeliveryDateField, getEnabledDates } from './delivery-date-field'
import { TBasketAny, IAddress, TOrderAny, TMenuAny } from '../../api'
import {
  CREATE_BASKET,
  selectBasketsCreate,
  UPDATE_BASKET,
  selectBasketsUpdate,
} from '../../stores/order'
import { BasketFormSubmitButtons } from './basket-form-submit-buttons'

interface IFormValues {
  title?: string
  poNumber?: string
  address?: string
  date?: Date
}

const getValidator = (
  initialAddressId: string | undefined,
  isPoNumberRequired: boolean,
  maxDeliveryDays: number,
  addresses: IAddress[],
  tomorrowsCutoff?: Date,
  nonDeliveryDates?: Date[],
) => {
  const addressIds = addresses.map((a) => a.id)
  let previousAddressId: string | undefined = undefined
  let enabledDates: ReturnType<typeof getEnabledDates> = { dates: [], isoDates: [] }

  function handleAddressChanged(addressId: string | undefined) {
    if (addressId === previousAddressId) {
      return
    }
    const address = find(addresses, ['id', addressId])
    previousAddressId = addressId
    enabledDates = getEnabledDates(maxDeliveryDays, address, tomorrowsCutoff, nonDeliveryDates)
  }

  // setup the validation for the initial address
  handleAddressChanged(initialAddressId)

  return (values: IFormValues) => {
    const { poNumber, address: addressId, date } = values
    handleAddressChanged(addressId)

    const errors: IBasketFormErrors = {}
    if (isPoNumberRequired && (!poNumber || poNumber.trim() === '')) {
      errors.poNumber = 'Purchase Order Number is required'
    }

    if (!addressId) {
      errors.address = 'Required'
    }
    if (addressId && !addressIds.includes(addressId)) {
      errors.address = 'Invalid address'
    }

    if (!date) {
      errors.date = 'Required'
    }
    if (date && !enabledDates.isoDates.includes(formatISO(date, { representation: 'date' }))) {
      errors.date = 'Date not available for the selected address'
    }

    return errors
  }
}

export interface IBasketFormValues {
  title: string
  poNumber: string
  address: string
  date?: Date
}

export interface IBasketFormErrors {
  title?: string
  poNumber?: string
  address?: string
  date?: string
}

export interface IOnSelectExistingProps extends Omit<IBasketFormValues, 'date' | 'address'> {
  address: IAddress
  deliveryDate: Date
}

export interface IBasketFormProps extends Omit<ModalProps, 'children'> {
  basket?: TBasketAny
  reorder?: TOrderAny
  menu?: TMenuAny
  onCreated?: (id: string) => void
  onUpdated?: (id: string) => void
  onSelectExisting?: (values: IOnSelectExistingProps) => void
  asModal?: boolean
}

export const BasketForm = forwardRef<IBasketFormProps, 'div'>((props, ref) => {
  const {
    basket,
    reorder,
    onCreated,
    onUpdated,
    onSelectExisting,
    asModal = true,
    ...modalProps
  } = props
  const { onClose } = modalProps
  const dispatch = useDispatch()
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const {
    isFetching: isCreating,
    error: creationError,
    id: createdId,
  } = useSelector(selectBasketsCreate)
  const { isFetching: isUpdating, error: updationError } = useSelector(selectBasketsUpdate)
  const account = useAccount()
  const addresses = useAddresses()
  const tomorrowsCutoff = useTomorrowsCutoff()?.cutoff
  const nonDeliveryDays = useNonDeliveryDays()
  const maxDeliveryDays = useMaxDeliveryDays()

  const nonDeliveryDates = useMemo(() => nonDeliveryDays?.map((d) => d.date), [nonDeliveryDays])

  // Users are not allowed to change the delivery address on orders which
  // have already been confirmed
  const isAddressDisabled = basket?.order ? true : false

  const isLoading = hasSubmitted && (isCreating || isUpdating)
  const apiError = hasSubmitted && (creationError || updationError)

  const isPoRequired = account?.isPoRequired ?? true
  const initialAddressId = basket?.deliverTo.id || ''
  const validate = useMemo(
    () =>
      getValidator(
        initialAddressId,
        isPoRequired,
        maxDeliveryDays,
        addresses,
        tomorrowsCutoff,
        nonDeliveryDates,
      ),
    [initialAddressId, isPoRequired, maxDeliveryDays, addresses, tomorrowsCutoff, nonDeliveryDates],
  )

  const { initialValues, initialErrors } = useMemo(() => {
    const initialValues: IBasketFormValues = {
      title: basket?.title || '',
      poNumber: basket?.poNumber || '',
      address: initialAddressId,
      date: basket?.requestedDeliveryDate,
    }
    const initialErrors = validate(initialValues)
    return {
      initialValues,
      initialErrors,
    }
  }, [basket, initialAddressId, validate])

  useEffect(() => {
    if (hasSubmitted && !isLoading && !apiError) {
      onClose()
      // onSuccess(basket ? basket.id : createdId!)
      if (onCreated && createdId) {
        onCreated(createdId)
      }
      if (onUpdated && basket) {
        onUpdated(basket.id)
      }
    }
  }, [onClose, hasSubmitted, isLoading, apiError, onCreated, onUpdated, basket, createdId])

  if (!account || !addresses) {
    return null
  }

  const hideErrorUntilTouched = !basket

  const handleSubmit = ({ title, poNumber, address, date }: IBasketFormValues) => {
    if (basket) {
      dispatch(
        UPDATE_BASKET.request({
          id: basket.id,
          title,
          poNumber,
          requestedDeliveryDate: date!,
          addressId: address,
        }),
      )
    } else {
      dispatch(
        CREATE_BASKET.request({
          title,
          poNumber,
          requestedDeliveryDate: date!,
          addressId: address,
        }),
      )
    }
    setHasSubmitted(true)
  }

  const formBody = (
    <>
      <Stack spacing={4}>
        <Box>
          <Field name="title">
            {({ field, form }: FieldProps) => {
              const { name } = field
              const { touched, errors } = form
              const errorMessage = hideErrorUntilTouched
                ? touched[name]
                  ? errors[name]
                  : ''
                : errors[name]
              return (
                <FormControl isInvalid={!!errorMessage}>
                  <FormLabel htmlFor={name}>Order Title</FormLabel>
                  <InputGroup>
                    <Input {...field} id={name} placeholder="Order title" />
                  </InputGroup>
                  <FormHelperText>Optional</FormHelperText>
                  {errorMessage && <FormErrorMessage>{`${errorMessage}`}</FormErrorMessage>}
                </FormControl>
              )
            }}
          </Field>
        </Box>
        <Box>
          <Field name="poNumber">
            {({ field, form }: FieldProps) => {
              const { name } = field
              const { touched, errors } = form
              const errorMessage = hideErrorUntilTouched
                ? touched[name]
                  ? errors[name]
                  : ''
                : errors[name]
              return (
                <FormControl isInvalid={!!errorMessage}>
                  <FormLabel htmlFor={name}>Purchase Order Number</FormLabel>
                  <InputGroup>
                    <Input {...field} id={name} placeholder="Purchase order number" />
                  </InputGroup>
                  {!errorMessage && (
                    <FormHelperText>{isPoRequired ? 'Required' : 'Optional'}</FormHelperText>
                  )}
                  {errorMessage && <FormErrorMessage>{`${errorMessage}`}</FormErrorMessage>}
                </FormControl>
              )
            }}
          </Field>
        </Box>
        <Box>
          <FormikAddressField
            hideErrorUntilTouched={hideErrorUntilTouched}
            addresses={addresses}
            isDisabled={isAddressDisabled}
          />
        </Box>
        <Box>
          <FormikDeliveryDateField
            hideErrorUntilTouched={hideErrorUntilTouched}
            maxDeliveryDays={maxDeliveryDays}
            addresses={addresses}
            tomorrowsCutoff={tomorrowsCutoff}
            nonDeliveryDates={nonDeliveryDates}
          />
        </Box>
      </Stack>
      {apiError && (
        <Alert status="error" mt={4} p={4} borderRadius="sm">
          <AlertIcon />
          <Box>
            <Text fontWeight="bold">{apiError.message}</Text>
            {/* <Text fontWeight="light">Please check the form and try again.</Text> */}
          </Box>
        </Alert>
      )}
    </>
  )

  if (!asModal) {
    return (
      <Formik
        initialValues={initialValues}
        initialErrors={initialErrors}
        enableReinitialize={true}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            {formBody}
            <Box display="flex" py={4} justifyContent="flex-end">
              <BasketFormSubmitButtons
                isEditing={!!basket}
                asModal={asModal}
                isFormLoading={isLoading}
                onClose={onClose}
                onSelectExisting={onSelectExisting}
              />
            </Box>
          </Form>
        )}
      </Formik>
    )
  }
  return (
    <Modal size="3xl" {...modalProps}>
      <ModalOverlay />
      <Formik
        initialValues={initialValues}
        initialErrors={initialErrors}
        enableReinitialize={true}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <ModalContent>
            <Form>
              <ModalHeader>
                {reorder ? `Reorder ${orderName(reorder)}` : basket ? 'Edit Order' : 'Create Order'}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>{formBody}</ModalBody>
              <ModalFooter>
                <BasketFormSubmitButtons
                  isEditing={!!basket}
                  asModal={asModal}
                  isFormLoading={isLoading}
                  onClose={onClose}
                  onSelectExisting={onSelectExisting}
                />
              </ModalFooter>
            </Form>
          </ModalContent>
        )}
      </Formik>
    </Modal>
  )
})
