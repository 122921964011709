import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectMenuPickerState,
  SHOW_MENU_PICKER,
  HIDE_MENU_PICKER,
  IShowMenuPicker,
} from '../../stores/ui'
import { selectMenuSelectedMenu, SELECT_MENU, UNSELECT_MENU } from '../../stores/menu'
import { productDetailSelector } from '../../stores/catalog'
import { useHasAuth } from './use-has-auth'

export function useMenuPicker() {
  const dispatch = useDispatch()
  const state = useSelector(selectMenuPickerState)
  const selected = useSelector(selectMenuSelectedMenu)
  const productSelector = useSelector(productDetailSelector)
  const { hasAuth, onRedirectToLogin } = useHasAuth()

  const selectedMenu = selected?.menu

  const isOpen = !!state?.picker?.isOpen
  const isFetching = !!state?.create?.isFetching
  const error = state?.create?.error
  const productId = state?.picker?.props?.productId
  const product = useMemo(() => {
    if (productId) {
      // for the modal the product should always be on OrderMate
      // and should have already loaded in the product listing
      // or detail screens
      return productSelector(productId).item
    }
  }, [productSelector, productId])
  const selectedItem = useMemo(() => {
    if (!productId) {
      return
    }
    const items = selectedMenu?.items.filter((item) => item.productCode === productId)
    if (items?.length) {
      return items[0]
    }
  }, [selectedMenu, productId])

  const onOpen = useCallback(
    (props?: IShowMenuPicker) => {
      if (!hasAuth) {
        onRedirectToLogin()
      } else {
        dispatch(SHOW_MENU_PICKER(props))
      }
    },
    [dispatch, hasAuth, onRedirectToLogin],
  )

  const onClose = useCallback(() => {
    dispatch(HIDE_MENU_PICKER())
  }, [dispatch])

  const onChangeSelectedMenu = useCallback(
    (menuId?: string) => {
      if (menuId) {
        dispatch(SELECT_MENU(menuId))
      } else {
        dispatch(UNSELECT_MENU())
      }
    },
    [dispatch],
  )

  return {
    selectedMenu,
    selectedItem,
    product,
    isOpen,
    isFetching,
    error,
    onOpen,
    onClose,
    onChangeSelectedMenu,
  }
}
