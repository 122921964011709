import { all, put, takeLeading, take, call, select } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import { basketPath, displayErrorToastSaga, ensureError, history } from '../../lib'
import * as actions from './actions'
import {
  SELECT_BASKET,
  CREATE_BASKET_ITEM,
  OPEN_ORDER_FOR_EDITING,
  UNSELECT_BASKET,
  COPY_MENU_TO_BASKET,
  COPY_ORDER_TO_BASKET,
} from '../order'
import { CallReturnType } from '../../api'
import { selectOrderPickerProps } from '.'

function* selectBasketAndCreateItem(
  action: ReturnType<typeof actions.SELECT_BASKET_AND_CREATE_ITEM.request>,
) {
  yield put(SELECT_BASKET.request({ basketId: action.payload.basketId }))

  // @ts-ignore
  const selectBasketActionResult: ActionType<any> = yield take([
    SELECT_BASKET.success,
    SELECT_BASKET.failure,
  ])

  if (selectBasketActionResult.type === getType(SELECT_BASKET.failure)) {
    // cancel the selection
    yield put(actions.SELECT_BASKET_AND_CREATE_ITEM.cancel())
    return
  }

  yield put(
    CREATE_BASKET_ITEM.request({
      ...action.payload,
      asBasketSelectionFlow: true,
    }),
  )

  // wait for the item to be created or the picker to be closed
  // @ts-ignore
  const createBasketItemActionResult: ActionType<any> = yield take([
    CREATE_BASKET_ITEM.success,
    CREATE_BASKET_ITEM.failure,
    CREATE_BASKET_ITEM.cancel,
    actions.HIDE_ORDER_PICKER,
  ])

  const successTypes = [getType(CREATE_BASKET_ITEM.success), getType(CREATE_BASKET_ITEM.cancel)]
  const failureTypes = [getType(CREATE_BASKET_ITEM.failure)]

  if (successTypes.includes(createBasketItemActionResult.type)) {
    yield put(actions.HIDE_ORDER_PICKER())
    yield put(actions.SELECT_BASKET_AND_CREATE_ITEM.success())
  } else if (failureTypes.includes(createBasketItemActionResult.type)) {
    const failureActionError = createBasketItemActionResult.payload?.error
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, failureActionError)
    yield put(UNSELECT_BASKET())
    yield put(actions.SELECT_BASKET_AND_CREATE_ITEM.failure(error))
  }
}

function* selectOrderAndCreateItem(
  action: ReturnType<typeof actions.SELECT_ORDER_AND_CREATE_ITEM.request>,
) {
  yield put(OPEN_ORDER_FOR_EDITING.request(action.payload.orderId))

  // wait for the basket to be created or the picker to be closed
  // @ts-ignore
  const resultAction: ActionType<typeof OPEN_ORDER_FOR_EDITING | typeof actions.HIDE_ORDER_PICKER> =
    yield take([
      OPEN_ORDER_FOR_EDITING.success,
      OPEN_ORDER_FOR_EDITING.failure,
      actions.HIDE_ORDER_PICKER,
    ])

  let isSuccess = false
  let failureActionError: Error | undefined

  if (resultAction.type === getType(OPEN_ORDER_FOR_EDITING.success)) {
    const { basket } = resultAction.payload
    yield put(SELECT_BASKET.request({ basketId: basket.id }))
    yield put(
      CREATE_BASKET_ITEM.request({
        ...action.payload,
        basketId: basket.id,
      }),
    )

    // wait for the item to be created or the picker to be closed
    // @ts-ignore
    const createItemResultAction: ActionType<any> = yield take([
      CREATE_BASKET_ITEM.success,
      CREATE_BASKET_ITEM.failure,
      CREATE_BASKET_ITEM.cancel,
      actions.HIDE_ORDER_PICKER,
    ])

    const successTypes = [getType(CREATE_BASKET_ITEM.success), getType(CREATE_BASKET_ITEM.cancel)]
    isSuccess = successTypes.includes(createItemResultAction.type)
    failureActionError = createItemResultAction.payload?.error
  }

  if (isSuccess) {
    yield put(actions.SELECT_ORDER_AND_CREATE_ITEM.success())
  } else {
    const error: CallReturnType<typeof ensureError> = yield call(ensureError, failureActionError)
    yield put(actions.SELECT_ORDER_AND_CREATE_ITEM.failure(error))
  }
}

function* selectOrder(action: ReturnType<typeof actions.SELECT_ORDER.request>) {
  const { payload: orderId } = action
  yield put(OPEN_ORDER_FOR_EDITING.request(orderId))

  // wait for the basket to be created or the picker to be closed
  // @ts-ignore
  const resultAction: ActionType<typeof OPEN_ORDER_FOR_EDITING | typeof actions.HIDE_ORDER_PICKER> =
    yield take([
      OPEN_ORDER_FOR_EDITING.success,
      OPEN_ORDER_FOR_EDITING.failure,
      actions.HIDE_ORDER_PICKER,
    ])

  if (resultAction.type === getType(OPEN_ORDER_FOR_EDITING.success)) {
    const { basket } = resultAction.payload
    yield put(SELECT_BASKET.request({ basketId: basket.id }))
    yield put(
      actions.SELECT_ORDER.success({
        basketId: basket.id,
        orderId,
      }),
    )
  } else if (resultAction.type === getType(OPEN_ORDER_FOR_EDITING.failure)) {
    yield put(actions.SELECT_ORDER.failure(resultAction.payload))
  }
}

function* pickBasket(action: ReturnType<typeof actions.ORDER_PICKER_BASKET_SELECTED>) {
  const { payload: basketId } = action
  const pickerProps: ReturnType<typeof selectOrderPickerProps> =
    yield select(selectOrderPickerProps)

  switch (pickerProps?.type) {
    case 'ADD_PRODUCT':
    case 'ADD_PRODUCT_MATCH_EXISTING':
      // when selecting the basket and a product is being added
      const { basketItemData } = pickerProps
      yield put(
        actions.SELECT_BASKET_AND_CREATE_ITEM.request({
          ...basketItemData,
          basketId,
        }),
      )
      break
    case 'CREATE_BASKET_MATCH_EXISTING':
      // when a basket is being created, go to the basket detail
      history.push(basketPath(basketId))
      yield put(actions.HIDE_ORDER_PICKER())
      break
    case 'REORDER_MATCH_EXISTING':
      // when a confirmed order is being copied to an existing basket
      yield put(
        COPY_ORDER_TO_BASKET.request({
          basketId,
          orderId: pickerProps.order.id,
        }),
      )
      yield put(actions.HIDE_ORDER_PICKER())
      break
    case 'FROM_MENU_MATCH_EXISTING':
      // when a menu is being copied to an existing basket
      // COPY_MENU_TO_BASKET does the redirects to the basket detail
      yield put(
        COPY_MENU_TO_BASKET.request({
          basketId,
          menuId: pickerProps.menu.id,
        }),
      )
      yield put(actions.HIDE_ORDER_PICKER())
      break
    case 'SELECT_ACTIVE_BASKET':
    case 'SELECT_ACTIVE_BASKET_MATCH_EXISTING':
    default:
      // no product data so select the basket and close the modal
      yield put(SELECT_BASKET.request({ basketId }))
      yield put(actions.HIDE_ORDER_PICKER())
      break
  }
}

function* pickOrder(action: ReturnType<typeof actions.ORDER_PICKER_ORDER_SELECTED>) {
  const { payload: orderId } = action
  const pickerProps: ReturnType<typeof selectOrderPickerProps> =
    yield select(selectOrderPickerProps)

  switch (pickerProps?.type) {
    case 'ADD_PRODUCT':
    case 'ADD_PRODUCT_MATCH_EXISTING': {
      // when selecting the basket a product is being added
      const { basketItemData } = pickerProps
      yield put(
        actions.SELECT_ORDER_AND_CREATE_ITEM.request({
          ...basketItemData,
          orderId,
        }),
      )
      // wait for the select to pass or fail
      const resultAction: ActionType<
        | typeof actions.SELECT_ORDER_AND_CREATE_ITEM.success
        | typeof actions.SELECT_ORDER_AND_CREATE_ITEM.failure
      > = yield take([
        actions.SELECT_ORDER_AND_CREATE_ITEM.success,
        actions.SELECT_ORDER_AND_CREATE_ITEM.failure,
      ])
      if (resultAction.type === getType(actions.SELECT_ORDER_AND_CREATE_ITEM.success)) {
        // if success close the picker
        yield put(actions.HIDE_ORDER_PICKER())
      }
      break
    }
    case 'CREATE_BASKET_MATCH_EXISTING': {
      yield put(actions.SELECT_ORDER.request(orderId))
      // wait for the select to pass or fail
      const resultAction: ActionType<
        typeof actions.SELECT_ORDER.success | typeof actions.SELECT_ORDER.failure
      > = yield take([actions.SELECT_ORDER.success, actions.SELECT_ORDER.failure])
      if (resultAction.type === getType(actions.SELECT_ORDER.success)) {
        // if successful redirect to the basket details page
        yield put(actions.HIDE_ORDER_PICKER())
        const { basketId } = resultAction.payload
        history.push(basketPath(basketId))
      }
      break
    }
    case 'REORDER_MATCH_EXISTING': {
      yield put(actions.SELECT_ORDER.request(orderId))
      // wait for the select to pass or fail
      const resultAction: ActionType<
        typeof actions.SELECT_ORDER.success | typeof actions.SELECT_ORDER.failure
      > = yield take([actions.SELECT_ORDER.success, actions.SELECT_ORDER.failure])
      if (resultAction.type === getType(actions.SELECT_ORDER.success)) {
        // if successful copy the order to the basket
        // COPY_ORDER_TO_BASKET does the redirect to the basket detail
        const { basketId } = resultAction.payload
        yield put(actions.HIDE_ORDER_PICKER())
        yield put(
          COPY_ORDER_TO_BASKET.request({
            basketId,
            orderId,
          }),
        )
      }
      break
    }
    case 'FROM_MENU_MATCH_EXISTING': {
      yield put(actions.SELECT_ORDER.request(orderId))
      // wait for the select to pass or fail
      const resultAction: ActionType<
        typeof actions.SELECT_ORDER.success | typeof actions.SELECT_ORDER.failure
      > = yield take([actions.SELECT_ORDER.success, actions.SELECT_ORDER.failure])
      if (resultAction.type === getType(actions.SELECT_ORDER.success)) {
        // if successful copy the menu to the basket
        // COPY_MENU_TO_BASKET does the redirect to the basket detail
        const { basketId } = resultAction.payload
        yield put(actions.HIDE_ORDER_PICKER())
        yield put(
          COPY_MENU_TO_BASKET.request({
            basketId,
            menuId: pickerProps.menu.id,
          }),
        )
      }
      break
    }
    case 'SELECT_ACTIVE_BASKET':
    case 'SELECT_ACTIVE_BASKET_MATCH_EXISTING':
    default:
      yield put(actions.SELECT_ORDER.request(orderId))
      break
  }
}

function* showOrderPickerPickExisting(
  action: ReturnType<typeof actions.SHOW_ORDER_PICKER_PICK_EXISTING>,
) {
  // get current payload (which may contain the product) and change the shape of the payload
  const { payload } = action
  const pickerProps: ReturnType<typeof selectOrderPickerProps> =
    yield select(selectOrderPickerProps)

  // open a new picker based on the values from the previous picker
  switch (pickerProps?.type) {
    case 'ADD_PRODUCT':
      yield put(
        actions.SHOW_ORDER_PICKER({
          ...pickerProps,
          type: 'ADD_PRODUCT_MATCH_EXISTING',
          formData: payload,
        }),
      )
      break
    case 'SELECT_ACTIVE_BASKET':
      yield put(
        actions.SHOW_ORDER_PICKER({
          ...pickerProps,
          type: 'SELECT_ACTIVE_BASKET_MATCH_EXISTING',
          formData: payload,
        }),
      )
      break
    default:
      yield put(
        actions.SHOW_ORDER_PICKER({
          type: 'CREATE_BASKET_MATCH_EXISTING',
          formData: payload,
        }),
      )
  }
}

export function* saga() {
  yield all([
    takeLeading(actions.SELECT_BASKET_AND_CREATE_ITEM.request, selectBasketAndCreateItem),
    takeLeading(actions.SELECT_BASKET_AND_CREATE_ITEM.failure, displayErrorToastSaga),
    takeLeading(actions.SELECT_ORDER_AND_CREATE_ITEM.request, selectOrderAndCreateItem),
    takeLeading(actions.SELECT_ORDER_AND_CREATE_ITEM.failure, displayErrorToastSaga),
    takeLeading(actions.SELECT_ORDER.request, selectOrder),
    takeLeading(actions.SELECT_ORDER.failure, displayErrorToastSaga),
    takeLeading(actions.ORDER_PICKER_BASKET_SELECTED, pickBasket),
    takeLeading(actions.ORDER_PICKER_ORDER_SELECTED, pickOrder),
    takeLeading(actions.SHOW_ORDER_PICKER_PICK_EXISTING, showOrderPickerPickExisting),
  ])
}
