import { forwardRef, BoxProps, Box, SimpleGrid, Skeleton, SkeletonText } from '@chakra-ui/react'

interface ISearchResultsSkeletonProps extends BoxProps {
  count?: number
}

export const SearchResultsSkeleton = forwardRef<ISearchResultsSkeletonProps, 'div'>(
  (props, ref) => {
    const { count = 10, ...rest } = props
    return (
      <Box mt={4} ref={ref} {...rest}>
        <SimpleGrid columns={[2, 2, 3, 4, 5]} spacing={4} mt={4}>
          {[...Array(count)].map((_, index) => (
            <ProductBoxSkeleton key={index} />
          ))}
        </SimpleGrid>
      </Box>
    )
  },
)

const ProductBoxSkeleton = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      borderColor="gray.100"
      borderWidth={1}
      borderRadius="sm"
    >
      <Box position="relative" pt="100%" width="100%">
        <Skeleton width="100%" height="100%" position="absolute" top={0} left={0} />
      </Box>
      <SkeletonText noOfLines={3} spacing={2} p={2} />
      <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end" p={2}>
        <Box mt={2}>
          <Skeleton height={8} mt={4} />
          <Skeleton height={8} mt={4} />
          <Box borderTopWidth={1} borderColor="gray.300" mt={2} pt={2}>
            <Box display="flex" justifyContent="center">
              <Skeleton width={10} height={10} mx={2} />
              <Skeleton width={10} height={10} mx={2} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
