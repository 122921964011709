import { ActionType, getType } from 'typesafe-actions'

import { IFetchedResource } from '../../types'
import { ICutoffTime } from '../../../api'

import * as actions from './actions'

interface IState {
  tomorrow: IFetchedResource<ICutoffTime>
}

const initialState: IState = {
  tomorrow: {
    isFetching: false,
  },
}

export const reducer = (
  state: IState = initialState,
  action: ActionType<typeof actions>,
): IState => {
  switch (action.type) {
    case getType(actions.FETCH_TOMORROWS_CUTOFF.failure):
    case getType(actions.FETCH_TOMORROWS_CUTOFF.request): {
      return {
        ...state,
        tomorrow: {
          ...initialState.tomorrow,
        },
      }
    }

    case getType(actions.FETCH_TOMORROWS_CUTOFF.success): {
      return {
        ...state,
        tomorrow: {
          item: action.payload,
          isFetching: false,
        },
      }
    }
  }

  return state
}
