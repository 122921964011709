import { useSelector } from 'react-redux'

import { selectBasketsConfirm } from '../../stores/order'

export function useBasketsConfirm() {
  const confirmState = useSelector(selectBasketsConfirm)

  return {
    ...confirmState,
  }
}
