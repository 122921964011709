import { createAction, createAsyncAction } from 'typesafe-actions'
import { IAddress, TMenuAny, TOrderAny } from '../../api'
import { IBasketItemData } from '../types'

export interface IBasketFormData {
  title: string
  poNumber: string
  address: IAddress
  deliveryDate: Date
}

type IShowOrderPickerBase<
  TTypeConstant,
  TFormData = undefined,
  TBasketItemData = undefined,
  TOrder = undefined,
  TMenu = undefined,
> = {
  type: TTypeConstant
  formData?: TFormData
  basketItemData?: TBasketItemData
  order?: TOrder
  menu?: TMenu
} & (TFormData extends undefined
  ? {}
  : {
      formData: TFormData
    }) &
  (TBasketItemData extends undefined
    ? {}
    : {
        basketItemData: TBasketItemData
      }) &
  (TOrder extends undefined
    ? {}
    : {
        order: TOrder
      }) &
  (TMenu extends undefined
    ? {}
    : {
        menu: TMenu
      })

type IShowOrderPickerForAddingProduct = IShowOrderPickerBase<
  'ADD_PRODUCT',
  undefined,
  IBasketItemData
>
type IShowOrderPickerForAddingProductMatchExisting = IShowOrderPickerBase<
  'ADD_PRODUCT_MATCH_EXISTING',
  IBasketFormData,
  IBasketItemData
>
type IShowOrderPickerForSelectingActiveBasket = IShowOrderPickerBase<'SELECT_ACTIVE_BASKET'>
type IShowOrderPickerForSelectingActiveBasketMatchExisting = IShowOrderPickerBase<
  'SELECT_ACTIVE_BASKET_MATCH_EXISTING',
  IBasketFormData
>
type IShowOrderPickerForCreatingBasketMatchExisting = IShowOrderPickerBase<
  'CREATE_BASKET_MATCH_EXISTING',
  IBasketFormData
>
type IShowOrderPickerForReorder = IShowOrderPickerBase<
  'REORDER_MATCH_EXISTING',
  IBasketFormData,
  undefined,
  TOrderAny
>
type IShowOrderPickerForMenu = IShowOrderPickerBase<
  'FROM_MENU_MATCH_EXISTING',
  IBasketFormData,
  undefined,
  undefined,
  TMenuAny
>

export type IShowOrderPickerProps =
  | IShowOrderPickerForAddingProduct
  | IShowOrderPickerForAddingProductMatchExisting
  | IShowOrderPickerForSelectingActiveBasket
  | IShowOrderPickerForSelectingActiveBasketMatchExisting
  | IShowOrderPickerForCreatingBasketMatchExisting
  | IShowOrderPickerForReorder
  | IShowOrderPickerForMenu

export const SHOW_ORDER_PICKER = createAction('ui/SHOW_ORDER_PICKER')<IShowOrderPickerProps>()

export const HIDE_ORDER_PICKER = createAction('ui/HIDE_ORDER_PICKER')()

export const SHOW_ORDER_PICKER_PICK_EXISTING = createAction(
  'ui/SHOW_ORDER_PICKER_PICK_EXISTING',
)<IBasketFormData>()

export const ORDER_PICKER_BASKET_SELECTED = createAction(
  'ui/ORDER_PICKER_BASKET_SELECTED',
)<string>()
export const ORDER_PICKER_ORDER_SELECTED = createAction('ui/ORDER_PICKER_ORDER_SELECTED')<string>()

export interface ISelectBasketAndCreateItem extends IBasketItemData {
  basketId: string
}

export const SELECT_BASKET_AND_CREATE_ITEM = createAsyncAction(
  'ui/SELECT_BASKET_AND_CREATE_ITEM',
  'ui/SELECT_BASKET_AND_CREATE_ITEM_SUCCESS',
  'ui/SELECT_BASKET_AND_CREATE_ITEM_FAILURE',
  'ui/SELECT_BASKET_AND_CREATE_ITEM_CANCEL',
)<ISelectBasketAndCreateItem, undefined, Error, undefined>()

export interface ISelectOrderAndCreateItem extends IBasketItemData {
  orderId: string
}

export const SELECT_ORDER_AND_CREATE_ITEM = createAsyncAction(
  'ui/SELECT_ORDER_AND_CREATE_ITEM',
  'ui/SELECT_ORDER_AND_CREATE_ITEM_SUCCESS',
  'ui/SELECT_ORDER_AND_CREATE_ITEM_FAILURE',
)<ISelectOrderAndCreateItem, undefined, Error>()

export const SELECT_ORDER = createAsyncAction(
  'ui/SELECT_ORDER',
  'ui/SELECT_ORDER_SUCCESS',
  'ui/SELECT_ORDER_FAILURE',
)<string, { orderId: string; basketId: string }, Error>()

export interface IShowMenuPicker {
  productId: string
}

export const SHOW_MENU_PICKER = createAction('ui/SHOW_MENU_PICKER')<IShowMenuPicker | undefined>()
export const HIDE_MENU_PICKER = createAction('ui/HIDE_MENU_PICKER')()
