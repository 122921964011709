import { forwardRef, BoxProps, Box } from '@chakra-ui/react'

import { TProduct } from '../../api'
import { IAvailableFacet } from '../../lib'
import { SearchResultsFilters } from './search-results-filters'
import { SearchResultsGrid } from './search-results-grid'
import { LoadMoreButton } from './load-more-button'

interface ISearchResultsProps {
  items: TProduct[]
  categoryId?: string
  hasMore: boolean
  onLoadMore: () => void
  sort?: string
  onSort: (field?: string) => void
  facets: {
    available: IAvailableFacet[]
    selected: IAvailableFacet[]
  }
  onAddFacet: (field: string) => void
  onRemoveFacet: (field: string) => void
  onSetFacets: (fields: string[]) => void
  onRemoveAllFacets: () => void
  boxProps?: BoxProps
  hideFacets?: string[]
}

export const SearchResults = forwardRef<ISearchResultsProps, 'div'>((props, ref) => {
  const {
    items,
    categoryId,
    hasMore,
    onLoadMore,
    sort,
    onSort,
    facets,
    onAddFacet,
    onRemoveFacet,
    onSetFacets,
    onRemoveAllFacets,
    boxProps,
    hideFacets,
  } = props

  return (
    <Box mt={4} ref={ref} {...boxProps}>
      <SearchResultsFilters
        sort={sort}
        onSort={onSort}
        facets={facets}
        onAddFacet={onAddFacet}
        onRemoveFacet={onRemoveFacet}
        onSetFacets={onSetFacets}
        onRemoveAllFacets={onRemoveAllFacets}
        hideFacets={hideFacets}
      />
      <SearchResultsGrid items={items} categoryId={categoryId} />
      <LoadMoreButton hasMore={hasMore} onClick={onLoadMore} />
    </Box>
  )
})
