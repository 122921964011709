import { createAsyncAction } from 'typesafe-actions'
import {
  ITicket,
  ITicketComment,
  IFetchMultipleResponse,
  IFetchMultipleResponseMeta,
} from '../../api'

export const FETCH_TICKETS = createAsyncAction(
  'ticket/FETCH_TICKETS',
  'ticket/FETCH_TICKETS_SUCCESS',
  'ticket/FETCH_TICKETS_FAILURE',
)<undefined, IFetchMultipleResponse<ITicket, IFetchMultipleResponseMeta>, Error>()

export const FETCH_MORE_TICKETS = createAsyncAction(
  'ticket/FETCH_MORE_TICKETS',
  'ticket/FETCH_MORE_TICKETS_SUCCESS',
  'ticket/FETCH_MORE_TICKETS_FAILURE',
)<undefined, IFetchMultipleResponse<ITicket, IFetchMultipleResponseMeta>, Error>()

export const FETCH_TICKET = createAsyncAction(
  'ticket/FETCH_TICKET',
  'ticket/FETCH_TICKET_SUCCESS',
  'ticket/FETCH_TICKET_FAILURE',
)<string, ITicket, Error>()

export interface ICreateTicketPayload {
  subject: string
  description: string
}

export const CREATE_TICKET = createAsyncAction(
  'ticket/CREATE_TICKET',
  'ticket/CREATE_TICKET_SUCCESS',
  'ticket/CREATE_TICKET_FAILURE',
)<ICreateTicketPayload, ITicket, Error>()

export const FETCH_TICKET_COMMENTS = createAsyncAction(
  'ticket/FETCH_TICKET_COMMENTS',
  'ticket/FETCH_TICKET_COMMENTS_SUCCESS',
  'ticket/FETCH_TICKET_COMMENTS_FAILURE',
)<string, { ticketId: string; comments: ITicketComment[] }, { ticketId: string; error: Error }>()

export interface ICreateTicketCommentPayload {
  ticketId: string
  description: string
}

export const CREATE_TICKET_COMMENT = createAsyncAction(
  'ticket/CREATE_TICKET_COMMENT',
  'ticket/CREATE_TICKET_COMMENT_SUCCESS',
  'ticket/CREATE_TICKET_COMMENT_FAILURE',
)<
  ICreateTicketCommentPayload,
  { ticketId: string; comment: ITicketComment },
  { ticketId: string; error: Error }
>()
