import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { FETCH_ORDER_SETTINGS, selectOrderSettings } from '../../stores/order'

export function useOrderSettings() {
  const dispatch = useDispatch()
  const orderSettings = useSelector(selectOrderSettings)

  useEffect(() => {
    dispatch(FETCH_ORDER_SETTINGS.request())
  }, [dispatch])

  return orderSettings
}
