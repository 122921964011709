import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  FETCH_ITEM_SUBSTITUTES,
  FETCH_PRODUCT_SUBSTITUTES,
  itemSubstitutesSelector,
  productSubstitutesSelector,
} from '../../stores/catalog'
import {
  TBasketWithAddressAndItems,
  TMenuWithItems,
  TOrderWithAddressAndItems,
  TProduct,
} from '../../api'

export const useProductSubstitutes = (product: TProduct) => {
  const dispatch = useDispatch()
  const { id: productId } = product
  const substitutes = useSelector(productSubstitutesSelector)(productId)

  const onLoadSubstitutes = useCallback(() => {
    const substituteIds = product.substitutes.map(({ id }) => id)

    if (!substituteIds.length) {
      return
    }

    dispatch(
      FETCH_PRODUCT_SUBSTITUTES.request({
        productId,
        substituteIds,
      }),
    )
  }, [dispatch, productId, product])

  // automatically call load initial
  useEffect(() => {
    onLoadSubstitutes()
  }, [onLoadSubstitutes])

  return {
    ...substitutes,
  }
}

type UseItemSubstitutesReturn = {
  substitutes: {
    [itemProductId: string]: {
      substitute: TProduct
      isAlreadyIncluded: boolean
    }[]
  }
  isFetching: boolean
}

export const useItemSubstitutes = (
  order: TBasketWithAddressAndItems | TOrderWithAddressAndItems | TMenuWithItems,
): UseItemSubstitutesReturn => {
  const dispatch = useDispatch()

  const orderKey = useMemo(() => {
    switch (order.type) {
      case 'baskets':
        return `basket:${order.id}`
      case 'orders':
        return `order:${order.id}`
      case 'menus':
        return `menu:${order.id}`
    }
  }, [order])

  const selector = useSelector(itemSubstitutesSelector)
  const result = useMemo(() => {
    return orderKey
      ? selector(orderKey)
      : {
        isFetching: false,
        items: {},
      }
  }, [orderKey, selector])

  const onLoadSubstitutes = useCallback(() => {
    if (!orderKey) {
      return
    }

    const itemsWithSubstitutes = order.items
      .filter(({ substitutes }) => substitutes.length)
      .map(({ productCode, substitutes }) => ({
        productId: productCode,
        substituteIds: substitutes.map(({ id }) => id),
      }))

    if (!itemsWithSubstitutes.length) {
      return
    }

    dispatch(
      FETCH_ITEM_SUBSTITUTES.request({
        parentKey: orderKey,
        items: itemsWithSubstitutes,
      }),
    )
  }, [dispatch, order.items, orderKey])

  // automatically call load initial
  useEffect(() => {
    onLoadSubstitutes()
  }, [onLoadSubstitutes])

  const substitutes = useMemo(() => {
    // check if the sub is already included in the order
    const { items } = result
    const productCodes = order.items.map(({ productCode }) => productCode)

    const substitutes: UseItemSubstitutesReturn['substitutes'] = {}

    for (const productCode of productCodes) {
      const itemSubs = items[productCode] ?? []
      substitutes[productCode] = itemSubs.map((substitute) => {
        return {
          substitute,
          isAlreadyIncluded: productCodes.includes(substitute.id),
        }
      })
    }

    return substitutes
  }, [order.items, result])

  return {
    substitutes,
    isFetching: result.isFetching,
  }
}
