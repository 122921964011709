import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { basketDetailSelector, FETCH_BASKET, selectBasketsDelete } from '../../stores/order'

export function useBasketDetail(id: string) {
  const dispatch = useDispatch()
  const [requested, setRequested] = useState(false)
  const detail = useSelector(basketDetailSelector)(id)
  const deleteState = useSelector(selectBasketsDelete)
  const isCancelled =
    deleteState.id === id && deleteState.isFetching === false && !deleteState.error

  useEffect(() => {
    if (!requested) {
      setRequested(true)
      dispatch(FETCH_BASKET.request(id))
    }
  }, [dispatch, id, requested, setRequested])

  return {
    ...detail,
    isCancelled,
  }
}
