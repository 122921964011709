import { PropsWithChildren, useCallback } from 'react'
import {
  Box,
  Button,
  ButtonProps,
  forwardRef,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  useDisclosure,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { OmCart } from '../icons'
import { DefinitionList, DefinitionTerm, Definition } from '../order-box'
import {
  useSelectedBasket,
  deliveryAddressDisplay,
  formatDeliveryDate,
  basketPath,
  useOrderPickerOpener,
} from '../../lib'
import { TBasketWithAddressAndItems } from '../../api'

export const BasketButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const { children, ...rest } = props
  const { basket } = useSelectedBasket()
  const { onOpen: onOpenPicker } = useOrderPickerOpener()

  const handleOpenPicker = useCallback(() => {
    onOpenPicker({
      type: 'SELECT_ACTIVE_BASKET',
    })
  }, [onOpenPicker])

  const button = (
    <Button
      ref={ref}
      {...rest}
      variant="ghost"
      colorScheme="gray"
      fontFamily="body"
      rightIcon={<OmCart boxSize={6} />}
      onClick={basket ? undefined : handleOpenPicker}
    >
      £{basket?.netTotal ?? '0.00'}
      <Box
        as="span"
        position="absolute"
        top={1}
        right={1.5}
        w={5}
        h={5}
        backgroundColor="gray.200"
        borderRadius="50%"
        fontSize={10}
        lineHeight="1.4rem"
        textAlign="center"
        fontWeight={300}
        display="block"
      >
        {basket?.items.length ?? 0}
      </Box>
    </Button>
  )

  if (basket) {
    return (
      <BasketButtonWrapper basket={basket} onOpenPicker={handleOpenPicker}>
        {button}
      </BasketButtonWrapper>
    )
  }

  return button
})

interface IBasketButtonWrapperProps {
  basket: TBasketWithAddressAndItems
  onOpenPicker: () => void
}

const BasketButtonWrapper: React.FC<PropsWithChildren<IBasketButtonWrapperProps>> = ({
  basket,
  onOpenPicker,
  children,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  const href = basketPath(basket)
  const addressDisplay = deliveryAddressDisplay(basket.deliverTo)
  const deliveryDateDisplay = formatDeliveryDate(basket.requestedDeliveryDate)

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody p={4}>
          <DefinitionList>
            {basket.title && (
              <>
                <DefinitionTerm>Title</DefinitionTerm>
                <Definition>{basket.title || '—'}</Definition>
              </>
            )}

            {basket.poNumber && (
              <>
                <DefinitionTerm>Purchase Order Number</DefinitionTerm>
                <Definition>{basket.poNumber || '—'}</Definition>
              </>
            )}

            <DefinitionTerm>Delivery Address</DefinitionTerm>
            <Definition>{addressDisplay || '—'}</Definition>

            <DefinitionTerm>Delivery Date</DefinitionTerm>
            <Definition>{deliveryDateDisplay || '—'}</Definition>
          </DefinitionList>
          <Button w="100%" mt={3} as={RouterLink} to={href} onClick={onClose}>
            View Details
          </Button>
        </PopoverBody>
        <PopoverFooter p={4}>
          <Button variant="outline" w="100%" onClick={onOpenPicker}>
            Switch Order
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}
